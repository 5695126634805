import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import {
  bizService,
  CreateSquadServiceRequest,
  CreateSquadsServiceResponse,
  GetSquadServiceRequest,
  GetSquadServiceResponse,
  ListSquadsServiceRequest,
  ListSquadsServiceResponse,
  SearchSquadsServiceRequest,
  SearchSquadsServiceResponse,
  SetSquadStatusServiceRequest,
  SetSquadStatusServiceResponse,
  TSquad,
  UpdateSquadInfoServiceRequest,
  UpdateSquadInfoServiceResponse,
} from '@services/api/profiler/biz';

import {
  GetSquadRequest,
  ListSquadsRequest,
  SearchSquadsRequest,
  SetSquadStatusRequest,
} from '@proto/profiler/biz/v1/squad_pb';

import { useCallback } from 'react';
import { userSlice } from '@store/profiler/slices';
import { combineReducers } from '@reduxjs/toolkit';
import { Timestamp } from '@bufbuild/protobuf';
import { TMember } from '@services/api/profiler/profile';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { filterEntitiesByType } from '@utils/filteredEntitiesByType';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { showSnackbar } from '@store/snackbars';
import { squadsSlices } from './slices';

const rolesParam = withDefault(StringParam, '');
const langParam = withDefault(StringParam, '');
const countryParam = withDefault(StringParam, '');
const industryParam = withDefault(StringParam, '');
const programmingLangParam = withDefault(StringParam, '');

const {
  actions: { setSquads, setPinnedSquads, setExploreSquads, setExploreSquad, setPaginationSquads },
} = squadsSlices;

const {
  actions: { deleteSelectedProfile },
} = userSlice;
export const squadsReducer = combineReducers({
  squads: squadsSlices.reducer,
});

export const getListUserSquads = createServiceCall<
  ListSquadsServiceResponse,
  ListSquadsServiceRequest
>(
  squadsSlices.name,
  bizService.listSquads,
  ({ response: { squads }, dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setSquads(squads));
  },
  ({ dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setSquads({ squads: [] }));
  }
);
const getListPinSquads = createServiceCall<ListSquadsServiceResponse, ListSquadsServiceRequest>(
  squadsSlices.name,
  bizService.listSquads,
  ({ response: { squads }, dispatch }) => {
    dispatch(setPinnedSquads(squads));
  }
);

const createSquad = createServiceCall<CreateSquadsServiceResponse, CreateSquadServiceRequest>(
  squadsSlices.name,
  bizService.createSquad,
  ({ payload, response, dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'createSquad',
        message: 'Squad created successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(showSnackbar({ id: 'createSquad', message: errorMessage, severity: 'error' }));
  }
);

const setSquadStatus = createServiceCall<
  SetSquadStatusServiceResponse,
  SetSquadStatusServiceRequest
>(squadsSlices.name, bizService.setSquadStatus, ({ response, dispatch }) => {
  // dispatch(deleteSquad({squadId}));
});

const searchSquads = createServiceCall<SearchSquadsServiceResponse, SearchSquadsServiceRequest>(
  squadsSlices.name,
  bizService.searchSquads,
  ({ response: { squads }, dispatch }) => {
    dispatch(setExploreSquads(squads));
  },
  ({ dispatch }) => {
    dispatch(setExploreSquads({ squads: [] }));
  }
);

export const getCurrentSquad = createServiceCall<GetSquadServiceResponse, GetSquadServiceRequest>(
  squadsSlices.name,
  bizService.getSquad,
  ({ response: { squad }, dispatch }) => {
    dispatch(setExploreSquad(squad));
  }
);
const searchSquadsPagination = createServiceCall<
  SearchSquadsServiceResponse,
  SearchSquadsServiceRequest
>(squadsSlices.name, bizService.searchSquads, ({ response: { squads }, dispatch }) => {
  dispatch(setPaginationSquads(squads));
});

const updateSquadInfo = createServiceCall<
  UpdateSquadInfoServiceResponse,
  UpdateSquadInfoServiceRequest
>(squadsSlices.name, bizService.updateSquadInfo, ({ response, dispatch }) => {});

export const useSquads = (): {
  listUserSquads: TSquad[] | undefined;
  listPinnedSquads: TSquad[] | undefined;
  listExploreSquads: TSquad[] | undefined;
  currentSquad: TSquad | null;
  squadsError: string | undefined;
  squadsFilters: { [key: string]: string };
  getListPinSquads: (payload: string[]) => void;
  createSquad: (payload: CreateSquadServiceRequest) => void;
  setSquadStatus: (payload: { groupId: string; status: boolean }) => void;
  getListUserSquad: (payload: TMember[]) => void;
  getCurrentSquad: (payload: string) => void;
  searchSquads: (payload: any) => void;
  searchSquadsPagination: (payload: Timestamp) => void;
  setSquadsFilters: (payload: any) => void;
  updateSquadInfo: (payload: { squadId: string; about: string }) => void;
} => {
  const dispatch = useAppDispatch();

  const [query, setQuery] = useQueryParams({
    roles: rolesParam,
    languages: langParam,
    countries: countryParam,
    industryDomains: industryParam,
    programmingLanguages: programmingLangParam,
  });

  return {
    listUserSquads: useAppSelector((state) => state.squadsReducer.squads.squadsList),
    listPinnedSquads: useAppSelector((state) => state.squadsReducer.squads.pinnedSquads),
    listExploreSquads: useAppSelector((state) => state.squadsReducer.squads.exploreSquadsList),
    squadsError: useAppSelector((state) => state.squadsReducer.squads.error),
    currentSquad: useAppSelector((state) => state.squadsReducer.squads.exploreSquad),
    squadsFilters: query,
    setSquadsFilters: useCallback(
      (payload) => {
        if (payload) {
          setQuery((prevQuery) => {
            const newQuery = { ...prevQuery, ...payload };

            return newQuery;
          });
        }
      },
      [setQuery]
    ),

    createSquad: useCallback(
      (payload) => {
        dispatch(createSquad(payload));
      },
      [dispatch]
    ),
    setSquadStatus: useCallback(
      (payload) => {
        const data = new SetSquadStatusRequest({
          squadId: payload?.groupId,
          active: payload?.status,
        });
        dispatch(setSquadStatus(data));
      },
      [dispatch]
    ),
    getListPinSquads: useCallback(
      (payload) => {
        if (payload)
          dispatch(
            getListPinSquads(
              new ListSquadsRequest({
                squadIds: new UUIDS({
                  ids: payload,
                }),
              })
            )
          );
      },
      [dispatch]
    ),
    getListUserSquad: useCallback(
      (payload) => {
        if (payload && filterEntitiesByType('TYPE_OF_ENTITY_SQUAD', payload).length)
          dispatch(
            getListUserSquads(
              new ListSquadsRequest({
                squadIds: new UUIDS({
                  ids: filterEntitiesByType('TYPE_OF_ENTITY_SQUAD', payload),
                }),
              })
            )
          );
      },
      [dispatch]
    ),

    getCurrentSquad: useCallback(
      (payload) =>
        dispatch(
          getCurrentSquad(
            new GetSquadRequest({
              by: {
                case: 'squadId',
                value: payload,
              },
            })
          )
        ),
      [dispatch]
    ),
    searchSquads: useCallback(
      (payload) => {
        if (!payload.length)
          return dispatch(
            searchSquads(
              new SearchSquadsRequest({
                // tags: transformAttributesByDemand(payload),
              })
            )
          );
        return dispatch(
          searchSquads(
            new SearchSquadsRequest({
              tags: {
                tags: payload,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    searchSquadsPagination: useCallback(
      (payload) => {
        dispatch(
          searchSquadsPagination(
            new SearchSquadsRequest({
              created: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    updateSquadInfo: useCallback(
      (payload) => {
        dispatch(
          updateSquadInfo({
            squadId: payload.squadId,
            about: payload.about,
          })
        );
      },
      [dispatch]
    ),
  };
};
