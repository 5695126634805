import { useQuery } from '@tanstack/react-query';
import { profileClient } from '@services/api/profiler/profile';
import {
  GetMyProfileRequest,
  GetMyProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
} from '@proto/profiler/user/v1/profile_pb';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';

export const useGetMyProfile = () => {
  const getMyProfile = async (): Promise<GetMyProfileResponse> => {
    return profileClient.getMyProfile(new GetMyProfileRequest());
  };

  return useQuery({
    queryKey: ['myProfile'],
    queryFn: getMyProfile,
  });
};

export const useListProfiles = (payload: UUIDS) => {
  const getSquad = async (): Promise<ListProfilesResponse> => {
    return profileClient.listProfiles(
      new ListProfilesRequest({
        profiles: payload,
      })
    );
  };

  return useQuery({
    queryKey: ['listProfiles', payload],
    queryFn: getSquad,
  });
};
