// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/candidate.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddCandidateRequest,
  AddCandidateResponse,
  InviteCandidatesRequest,
  InviteCandidatesResponse,
  ListCandidatesRequest,
  ListCandidatesResponse,
  RemoveCandidateRequest,
  RemoveCandidateResponse,
} from './candidate_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.demand.v1.CandidateService
 */
export const CandidateService = {
  typeName: 'marketplace.demand.v1.CandidateService',
  methods: {
    /**
     * @generated from rpc marketplace.demand.v1.CandidateService.AddCandidate
     */
    addCandidate: {
      name: 'AddCandidate',
      I: AddCandidateRequest,
      O: AddCandidateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.CandidateService.InviteCandidates
     */
    inviteCandidates: {
      name: 'InviteCandidates',
      I: InviteCandidatesRequest,
      O: InviteCandidatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.CandidateService.RemoveCandidate
     */
    removeCandidate: {
      name: 'RemoveCandidate',
      I: RemoveCandidateRequest,
      O: RemoveCandidateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.CandidateService.ListCandidates
     */
    listCandidates: {
      name: 'ListCandidates',
      I: ListCandidatesRequest,
      O: ListCandidatesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
