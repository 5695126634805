import { Form } from '@components/Form';
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { InputController } from '@components/Form/controllers/InputController';
import { TSquad } from '@services/api/profiler/biz';
import Joi from 'joi';
import { useSquads } from '@store/squads';
import SquadStatusSwitcher from '@pages/Squads/ManageSquads/SquadStatusSwitcher';
import { Squad } from '@proto/profiler/biz/v1/squad_pb';

export enum FieldNames {
  ABOUT = 'about',
  SQUAD_ID = 'squadId',
}

export const FIELD_LABELS = {
  [FieldNames.ABOUT]: 'Squad about',
};

export const UpdateSquadAboutResolverSchema = {
  [FieldNames.SQUAD_ID]: Joi.string().allow(null),
  [FieldNames.ABOUT]: Joi.string().required(),
};

type UpdateSquadAboutFormProps = {
  squad: TSquad | Squad;
};

const UpdateSquadAboutForm = (props: UpdateSquadAboutFormProps) => {
  const { squad } = props;
  const { updateSquadInfo } = useSquads();
  const [isEdited, setIsEdited] = useState(true);
  const [squadStatus, setStatusSquad] = useState(squad.active);

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: { squadId: squad?.squadId, about: squad?.about } }}
      submitHandler={(data) => {
        if (isEdited) {
          setIsEdited(!isEdited);
          return;
        }
        updateSquadInfo(data);
        setIsEdited(!isEdited);
      }}
      resolverSchema={UpdateSquadAboutResolverSchema}
    >
      {isEdited ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography flexGrow="grow" sx={{ width: '100%', color: '#bbb' }} variant="subtitle2">
            {squad?.about || 'No title'}
          </Typography>
          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </LoadingButton>
          {squadStatus ? (
            <Typography textAlign="center">Active</Typography>
          ) : (
            <Typography textAlign="center">Inactive</Typography>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          <InputController
            fullWidth
            name={FieldNames.ABOUT}
            label={FIELD_LABELS[FieldNames.ABOUT]}
            variant="standard"
          />

          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </LoadingButton>
          <SquadStatusSwitcher squadStatus={squadStatus} setStatusSquad={setStatusSquad} />
        </Box>
      )}
    </Form>
  );
};
export default UpdateSquadAboutForm;
