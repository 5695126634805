import { useAppDispatch, useAppSelector } from '@store/helpers';
import { useCallback } from 'react';
import { modalSlice, ModalState } from './slices';

const { showModal, hideModal } = modalSlice.actions;

export const useModal = (): {
  currentModal: ModalState;
  showModal: (data: ModalState) => void;
  hideModal: (result: never) => void;
} => {
  const dispatch = useAppDispatch();

  return {
    currentModal: useAppSelector((state) => state.modal),
    showModal: useCallback((data) => dispatch(showModal(data)), [dispatch]),
    hideModal: useCallback((result) => dispatch(hideModal(result)), [dispatch]),
  };
};
