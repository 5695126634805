// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file iam/auth/v1/mfa.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  GenerateTotpRequest,
  GenerateTotpResponse,
  RecoverTotpRequest,
  RecoverTotpResponse,
  ValidateTotpRequest,
  ValidateTotpResponse,
} from './mfa_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service iam.auth.v1.MFAService
 */
export const MFAService = {
  typeName: 'iam.auth.v1.MFAService',
  methods: {
    /**
     * @generated from rpc iam.auth.v1.MFAService.GenerateTotp
     */
    generateTotp: {
      name: 'GenerateTotp',
      I: GenerateTotpRequest,
      O: GenerateTotpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.MFAService.ValidateTotp
     */
    validateTotp: {
      name: 'ValidateTotp',
      I: ValidateTotpRequest,
      O: ValidateTotpResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.MFAService.RecoverTotp
     */
    recoverTotp: {
      name: 'RecoverTotp',
      I: RecoverTotpRequest,
      O: RecoverTotpResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
