import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

type SnackbarProps = {
  isOpen: boolean;
  handleClose: () => void;
  label: string;
  severity: AlertColor | undefined;
};
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AppSnackbar = (props: SnackbarProps) => {
  const { isOpen, handleClose, label, severity } = props;
  return (
    <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {label}
      </Alert>
    </Snackbar>
  );
};
export default AppSnackbar;
