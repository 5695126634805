import { useCallback, useState } from 'react';

export const useSnackbar = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isDirtyState, setDirtyState] = useState(false);

  const handleSnackbarOpen = useCallback(() => setIsSnackbarOpen(true), []);

  const handleCloseSnackbar = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);

  return { isSnackbarOpen, handleSnackbarOpen, handleCloseSnackbar, setDirtyState };
};
