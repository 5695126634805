import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import CreateProductButton from '@components/CreateProductForm/CreateProductButton';
import { Entity, TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import ProductsList from '@pages/Product/ProductsList';
import React from 'react';

const Products = ({ id, type }: { id: string; type: TypeOfEntity }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Products
        </Typography>
        <CreateProductButton
          owner={
            new Entity({
              id,
              type,
            })
          }
        />
      </Box>
      <ProductsList
        value={
          new Entity({
            type,
            id,
          })
        }
        requestType="owner"
      />
    </Box>
  );
};

export default Products;
