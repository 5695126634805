import { Suspense } from 'react';
import { useModal } from '@store/modal';
import { loadableModalComponentsMap } from './loadableModalComponentsMap';

export const ModalManager = () => {
  const { currentModal } = useModal();

  if (!currentModal.modalType) {
    return null;
  }

  const ModalComponent = loadableModalComponentsMap[currentModal.modalType]();

  return (
    <Suspense fallback={null}>
      <ModalComponent {...currentModal.modalProps} />
    </Suspense>
  );
};
