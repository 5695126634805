import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '@components/Form';

import {
  createInterviewResolverSchema,
  TCreateInterviewForm,
} from '@pages/Marketplace/ManageDemands/settings';
import CreateInterviewInputs from '@pages/Marketplace/Dashboards/CreateInterviewInputs';
import { useCreateInterview } from '@services/api/marketplace/calls';

type CreateInterviewsFormProps = {
  handleCloseModal: () => void;
  dashboardId: string;
};

const CreateInterviewForm = (props: CreateInterviewsFormProps) => {
  const { handleCloseModal, dashboardId } = props;
  const { mutate, isError, error } = useCreateInterview();

  return (
    <Form<TCreateInterviewForm>
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          dashboardId,
        },
      }}
      submitHandler={(data) => {
        mutate(data);
        handleCloseModal();
      }}
      resolverSchema={createInterviewResolverSchema}
    >
      <CreateInterviewInputs />
      <LoadingButton type="submit" variant="outlined">
        Create
      </LoadingButton>
    </Form>
  );
};

export default CreateInterviewForm;
