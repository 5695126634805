import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { Page } from '@components/Page/Page';
import React from 'react';
import UpdateDemandInfoForm from '@pages/Marketplace/ManageDemands/UpdateDashboardInfoForm';
import KanbanSection from '@pages/Marketplace/Dashboards/KanbanSection';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CreateDemandButton from '@pages/Marketplace/ManageDemands/CreateDemandButton';
import InfoIcon from '@mui/icons-material/Info';
import { useGetDashboard } from '@services/api/marketplace/calls';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import { DemandStatus_Status } from '@proto/marketplace/demand/v1/demand_pb';

const KanbanBoard = () => {
  const { dashboardId } = useParams();
  const { data, isLoading, error } = useGetDashboard(dashboardId || '');

  if (isLoading) return <LoaderWithDelay />;

  return (
    <Page description="Kanbanboard" title="Kanbanboard">
      <Fade in timeout={500}>
        <Container>
          <Box display="flex" flexDirection="column" gap={2}>
            {data?.kanbanBoard && <UpdateDemandInfoForm dashboard={data.kanbanBoard} />}

            {data?.demands ? (
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                {Object.values(DemandStatus_Status)
                  .filter((value) => typeof value === 'number' && value !== 0)
                  .map((status) => (
                    <KanbanSection status={Number(status)} demands={data?.demands} />
                  ))}
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" justifyContent="center" gap={0.5}>
                  <InfoIcon />
                  <Typography variant="subtitle2">You not have demands</Typography>
                  <CreateDemandButton />
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Fade>
    </Page>
  );
};
export default KanbanBoard;
