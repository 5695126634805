import React from 'react';
import { Link, useParams } from 'react-router-dom';
import CreateDashboardButton from '@pages/Marketplace/CreateDashboardButton';
import { Entity, TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import Box from '@mui/material/Box';
import { MenuItem, Typography } from '@mui/material';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { useProfiler } from '@store/profiler';
import Card from '@components/Card/Card';
import { useListDashboards } from '@services/api/marketplace/calls';

const ManageOrgKanbanboards = () => {
  const { userProfile, listRoles } = useProfiler();
  const { entityId } = useParams();
  const { data, error, isLoading } = useListDashboards();

  const requiredRoles = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_BILLING_MANAGER,
    IAMRoleStrings.IAM_ROLE_MEMBER,
  ];
  const requiredRolesToCreateKanbanBoard = [IAMRoleStrings.IAM_ROLE_ADMIN];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );
  const hasAccessToCreateKanbanBoard = hasCommonRoles(
    requiredRolesToCreateKanbanBoard,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  return hasAccess ? (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="flex-start" alignItems="center" gap={2}>
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Kanbanboards
        </Typography>
        {hasAccessToCreateKanbanBoard && (
          <MenuItem>
            <CreateDashboardButton
              entity={
                new Entity({
                  id: entityId,
                  type: TypeOfEntity.ORG,
                })
              }
            />
          </MenuItem>
        )}
      </Box>

      <Box display="flex" flexDirection="column" gap={1}>
        {data?.dashboards?.dashboards
          .filter((dashboard) => dashboard.owner?.type === TypeOfEntity.ORG)
          .map((dashboard) => {
            return (
              <Link
                key={dashboard.dashboardId}
                className="NavLink "
                to={`/${dashboard.dashboardId}`}
              >
                <Card>
                  <Box key={dashboard.dashboardId}>
                    <Box display="flex" alignContent="center" gap={1}>
                      <Typography>{dashboard.title}</Typography>
                    </Box>
                  </Box>
                </Card>
              </Link>
            );
          })
          .reverse()}
      </Box>
    </Box>
  ) : null;
};
export default ManageOrgKanbanboards;
