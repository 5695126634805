import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';

const ListEvaluations = () => {
  const { evaluationList, listEvaluations } = useMarketplace();

  useEffect(() => {
    listEvaluations();
  }, [listEvaluations]);

  return Object.values(evaluationList).length ? (
    <Box>
      {Object.values(evaluationList)?.map((evaluation) => {
        return (
          <Link to={`${ROUTES_PREFIXES.EVALUATION}/${evaluation.evaluationId}`}>
            <Box>{evaluation?.evaluationId}</Box>
          </Link>
        );
      })}
    </Box>
  ) : null;
};

export default ListEvaluations;
