import { TProfile } from '@services/api/profiler/profile';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { Chip, Tooltip, Typography } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Card from '@components/Card/Card';

const ParticipantCard = ({ participant }: { participant: TProfile }) => {
  return (
    <Card>
      <Box display="flex" flexDirection="column" justifyContent="space-between" gap={0.5}>
        <Box display="flex" gap={1}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              backgroundColor: 'rgb(255, 255, 255, 0.2)',
              color: 'white',
            }}
            alt={participant.name?.nickName.toUpperCase() || 'User'}
            src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${participant?.profileId}/${participant?.profileId}`}
          />
          <Box>
            <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
              {participant?.name?.firstName} {participant?.name?.lastName}
            </Typography>
            <Typography fontWeight="bold">@{participant?.name?.nickName}</Typography>
          </Box>
        </Box>
        {participant?.traits?.experience && (
          <Tooltip placement="right" title="years">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Experience: {participant.traits.experience}+ </Typography>
            </Box>
          </Tooltip>
        )}
        {participant?.traits?.capacity && (
          <Tooltip placement="right" title="hours per week">
            <Box display="flex" gap={1} alignItems="center">
              <InfoIcon fontSize="small" />
              <Typography>Capacity: {participant.traits.capacity} </Typography>
            </Box>
          </Tooltip>
        )}
        {participant?.traits?.rate && <Typography>Rate: ${participant.traits.rate}</Typography>}
        <Box display="flex" flexWrap="wrap" gap={1}>
          {participant?.tags?.tags &&
            participant.tags?.tags.map((tag) => {
              return <Chip key={tag.value} label={tag.value} variant="outlined" />;
            })}
        </Box>
      </Box>
    </Card>
  );
};
export default ParticipantCard;
