import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Achieved, AchievementId } from '@proto/rewarder/achievements/v1/achievements_pb';
import Typography from '@mui/material/Typography';
import { generateEnumDescriptions } from '@utils/generateEnumDescription';
import { Timestamp } from '@bufbuild/protobuf';

export function formatTimestampToDate(timestamp: Timestamp | undefined): string {
  if (!timestamp) {
    throw new Error('Timestamp не знайдено');
  }
  const date = timestamp.toDate();
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const AchievementBox = ({ achieved }: { achieved: Achieved }) => {
  const [hover, setHover] = useState(false);
  const enumDescriptions = generateEnumDescriptions(AchievementId);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Box
      position="relative"
      display="inline-block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box width="40px" height="40px">
        <img
          src="https://admin.kisomi-sushi.com/uploads/thumbnail_Screenshot_from_2024_06_21_15_30_22_removebg_preview_0871b48f98.png"
          alt="alt"
          width="100%"
          height="100%"
        />
      </Box>
      <Box
        borderRadius="6px"
        sx={{
          position: 'absolute',
          top: -155,
          left: 0,
          width: hover ? 600 : 0,
          height: 150,
          bgcolor: '#37474f',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          transition: 'width 0.3s ease',
        }}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{ opacity: hover ? 1 : 0, transition: 'opacity 0.3s ease' }}
          >
            <Typography fontSize="13px" whiteSpace="nowrap">
              Level {achieved?.achievedLevel?.level}
            </Typography>
            <Typography variant="h6" whiteSpace="nowrap">
              {enumDescriptions[achieved.achievementId]}
            </Typography>
            <Box display="flex">
              <Typography whiteSpace="nowrap">{achieved.description}</Typography>
            </Box>
            <Typography fontSize="13px" whiteSpace="nowrap">
              Unlocked on {formatTimestampToDate(achieved?.achievedLevel?.awarded)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AchievementBox;
