// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/dashboard/v1/dashboad.proto (package marketplace.dashboard.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateDashboardRequest,
  CreateDashboardResponse,
  DeleteDashboardRequest,
  DeleteDashboardResponse,
  GetDashboardRequest,
  GetDashboardResponse,
  ListDashboardsRequest,
  ListDashboardsResponse,
  ListFeedItemsRequest,
  ListFeedItemsResponse,
  ResetCacheRequest,
  ResetCacheResponse,
  UpdateDashboardRequest,
  UpdateDashboardResponse,
} from './dashboad_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.dashboard.v1.DashboardService
 */
export const DashboardService = {
  typeName: 'marketplace.dashboard.v1.DashboardService',
  methods: {
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.CreateDashboard
     */
    createDashboard: {
      name: 'CreateDashboard',
      I: CreateDashboardRequest,
      O: CreateDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.GetDashboard
     */
    getDashboard: {
      name: 'GetDashboard',
      I: GetDashboardRequest,
      O: GetDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.ListDashboards
     */
    listDashboards: {
      name: 'ListDashboards',
      I: ListDashboardsRequest,
      O: ListDashboardsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.ListFeedItems
     */
    listFeedItems: {
      name: 'ListFeedItems',
      I: ListFeedItemsRequest,
      O: ListFeedItemsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.UpdateDashboard
     */
    updateDashboard: {
      name: 'UpdateDashboard',
      I: UpdateDashboardRequest,
      O: UpdateDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.DeleteDashboard
     */
    deleteDashboard: {
      name: 'DeleteDashboard',
      I: DeleteDashboardRequest,
      O: DeleteDashboardResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.dashboard.v1.DashboardService.ResetCache
     */
    resetCache: {
      name: 'ResetCache',
      I: ResetCacheRequest,
      O: ResetCacheResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
