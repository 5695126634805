import { FunctionComponent, lazy, LazyExoticComponent } from 'react';

export enum ModalTypes {
  CONFIRM,
}

const ConfirmModal = lazy(() => import('./ConfirmModal/ConfirmModal'));

export const loadableModalComponentsMap: {
  [key: number]: () => LazyExoticComponent<FunctionComponent>;
} = {
  [ModalTypes.CONFIRM]: () => ConfirmModal,
};
