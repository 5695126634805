import React, { Suspense } from 'react';
import Box from '@mui/material/Box';
import { useDialog } from '@components/hooks/useDialog';
import CreateGroupingDialog from '@components/CreateGroupingDialog/CreateGroupingDialog';
import { createSquadResolverSchema } from '@components/CreateGroupingForm/config';
import Typography from '@mui/material/Typography';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useCreateSquad } from '@services/api/profiler/SquadsQueries';

const squadLabels = {
  title: 'Create Your Squad',
  name: 'Enter a unique name for your squad',
  about: "Provide a brief description to highlight your squad's skills and mission",
  description:
    'Welcome to our Platform! Here, you can create your squad to collaborate on exciting projects and secure lucrative contracts. Whether your squad is focused on product development or specialized services, our platform provides the perfect space for your team to thrive.',
  button: 'Create',
  footer:
    'Form your squad now and unlock new opportunities for collaborative success in the freelance world!',
};

const CreateSquadButton = () => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { mutate: handleCreate } = useCreateSquad();
  return (
    <Box justifyContent="space-between" display="flex" alignItems="center">
      <Box onClick={handleOpen} display="flex" gap={1}>
        <Diversity3Icon />
        <Typography>New squad</Typography>
      </Box>

      <Suspense fallback={null}>
        <CreateGroupingDialog
          isOpen={isOpen}
          handleClose={handleClose}
          labels={squadLabels}
          handleCreate={handleCreate}
          resolverSchema={createSquadResolverSchema}
        />
      </Suspense>
    </Box>
  );
};

export default CreateSquadButton;
