import { createServiceSlice } from '@store/helpers';
import { TNotificatorReducers, TNotificatorState } from '@store/notificator/notificator';

export const notificatorSlice = createServiceSlice<TNotificatorState, TNotificatorReducers>({
  name: 'notificator',
  initialState: {
    events: {},
  },
  reducers: {
    // eslint-disable-next-line consistent-return
    pushEvent: (state, action) => {
      if (state.events && action.payload.message in state.events) return state.events;

      const notification = {
        [action.payload.message]: action.payload,
      };

      state.events = Object.assign(state.events, notification);
    },
    clearEvents: (state) => {
      state.events = {};
    },
  },
});
