// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/product.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';
import { Price } from './price_pb.js';
import { Tags } from '../../../profiler/tags/v1/tags_pb.js';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from enum marketplace.product.v1.ProductStatus
 */
export enum ProductStatus {
  /**
   * @generated from enum value: PRODUCT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_STATUS_NEW = 1;
   */
  NEW = 1,

  /**
   * @generated from enum value: PRODUCT_STATUS_IN_REVIEW = 2;
   */
  IN_REVIEW = 2,

  /**
   * @generated from enum value: PRODUCT_STATUS_CHANGES_REQUIRED = 3;
   */
  CHANGES_REQUIRED = 3,

  /**
   * @generated from enum value: PRODUCT_STATUS_APPROVED = 4;
   */
  APPROVED = 4,

  /**
   * @generated from enum value: PRODUCT_STATUS_DECLINED = 5;
   */
  DECLINED = 5,

  /**
   * @generated from enum value: PRODUCT_STATUS_ARCHIVED = 6;
   */
  ARCHIVED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductStatus)
proto3.util.setEnumType(ProductStatus, 'marketplace.product.v1.ProductStatus', [
  { no: 0, name: 'PRODUCT_STATUS_UNSPECIFIED' },
  { no: 1, name: 'PRODUCT_STATUS_NEW' },
  { no: 2, name: 'PRODUCT_STATUS_IN_REVIEW' },
  { no: 3, name: 'PRODUCT_STATUS_CHANGES_REQUIRED' },
  { no: 4, name: 'PRODUCT_STATUS_APPROVED' },
  { no: 5, name: 'PRODUCT_STATUS_DECLINED' },
  { no: 6, name: 'PRODUCT_STATUS_ARCHIVED' },
]);

/**
 * @generated from message marketplace.product.v1.Product
 */
export class Product extends Message<Product> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  /**
   * @generated from field: string creator_id = 2;
   */
  creatorId = '';

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.Entity owner = 4;
   */
  owner?: Entity;

  /**
   * @generated from field: google.protobuf.Timestamp latest_release_date = 5;
   */
  latestReleaseDate?: Timestamp;

  /**
   * @generated from field: marketplace.product.v1.ProductStatus status = 6;
   */
  status = ProductStatus.UNSPECIFIED;

  /**
   * @generated from field: string title = 7;
   */
  title = '';

  /**
   * @generated from field: string version = 8;
   */
  version = '';

  /**
   * @generated from field: string specification = 9;
   */
  specification = '';

  /**
   * @generated from field: string description = 10;
   */
  description = '';

  /**
   * @generated from field: string demo_url = 11;
   */
  demoUrl = '';

  /**
   * @generated from field: marketplace.product.v1.Price price = 12;
   */
  price?: Price;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 13;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Product';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'created', kind: 'message', T: Timestamp },
    { no: 4, name: 'owner', kind: 'message', T: Entity },
    { no: 5, name: 'latest_release_date', kind: 'message', T: Timestamp },
    { no: 6, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductStatus) },
    { no: 7, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 9, name: 'specification', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 11, name: 'demo_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 12, name: 'price', kind: 'message', T: Price },
    { no: 13, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Product {
    return new Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJsonString(jsonString, options);
  }

  static equals(
    a: Product | PlainMessage<Product> | undefined,
    b: Product | PlainMessage<Product> | undefined
  ): boolean {
    return proto3.util.equals(Product, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductRequest
 */
export class GetProductRequest extends Message<GetProductRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  constructor(data?: PartialMessage<GetProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductRequest {
    return new GetProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductRequest {
    return new GetProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductRequest | PlainMessage<GetProductRequest> | undefined,
    b: GetProductRequest | PlainMessage<GetProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetProductResponse
 */
export class GetProductResponse extends Message<GetProductResponse> {
  /**
   * @generated from field: marketplace.product.v1.Product product = 1;
   */
  product?: Product;

  constructor(data?: PartialMessage<GetProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'message', T: Product },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductResponse {
    return new GetProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductResponse {
    return new GetProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProductResponse {
    return new GetProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProductResponse | PlainMessage<GetProductResponse> | undefined,
    b: GetProductResponse | PlainMessage<GetProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteProductRequest
 */
export class DeleteProductRequest extends Message<DeleteProductRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  constructor(data?: PartialMessage<DeleteProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProductRequest {
    return new DeleteProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProductRequest {
    return new DeleteProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProductRequest {
    return new DeleteProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteProductRequest | PlainMessage<DeleteProductRequest> | undefined,
    b: DeleteProductRequest | PlainMessage<DeleteProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.DeleteProductResponse
 */
export class DeleteProductResponse extends Message<DeleteProductResponse> {
  constructor(data?: PartialMessage<DeleteProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.DeleteProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteProductResponse {
    return new DeleteProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProductResponse {
    return new DeleteProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteProductResponse {
    return new DeleteProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteProductResponse | PlainMessage<DeleteProductResponse> | undefined,
    b: DeleteProductResponse | PlainMessage<DeleteProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductsRequest
 */
export class ListProductsRequest extends Message<ListProductsRequest> {
  /**
   * @generated from oneof marketplace.product.v1.ListProductsRequest.by
   */
  by:
    | {
        /**
         * @generated from field: grpc.type.v1.Entity owner = 1;
         */
        value: Entity;
        case: 'owner';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS product_ids = 2;
         */
        value: UUIDS;
        case: 'productIds';
      }
    | {
        /**
         * @generated from field: google.protobuf.Timestamp offset = 3;
         */
        value: Timestamp;
        case: 'offset';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'owner', kind: 'message', T: Entity, oneof: 'by' },
    { no: 2, name: 'product_ids', kind: 'message', T: UUIDS, oneof: 'by' },
    { no: 3, name: 'offset', kind: 'message', T: Timestamp, oneof: 'by' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsRequest {
    return new ListProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductsRequest {
    return new ListProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined,
    b: ListProductsRequest | PlainMessage<ListProductsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProductsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListProductsResponse
 */
export class ListProductsResponse extends Message<ListProductsResponse> {
  /**
   * @generated from field: repeated marketplace.product.v1.Product products = 1;
   */
  products: Product[] = [];

  constructor(data?: PartialMessage<ListProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListProductsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'products', kind: 'message', T: Product, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProductsResponse {
    return new ListProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProductsResponse {
    return new ListProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined,
    b: ListProductsResponse | PlainMessage<ListProductsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProductsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductRequest
 */
export class CreateProductRequest extends Message<CreateProductRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity owner = 1;
   */
  owner?: Entity;

  /**
   * @generated from field: string title = 2;
   */
  title = '';

  /**
   * @generated from field: string version = 3;
   */
  version = '';

  /**
   * @generated from field: string specification = 4;
   */
  specification = '';

  /**
   * @generated from field: string description = 5;
   */
  description = '';

  /**
   * @generated from field: string demo_url = 6;
   */
  demoUrl = '';

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 7;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<CreateProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'owner', kind: 'message', T: Entity },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'specification', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'demo_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProductRequest {
    return new CreateProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProductRequest {
    return new CreateProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductRequest {
    return new CreateProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductRequest | PlainMessage<CreateProductRequest> | undefined,
    b: CreateProductRequest | PlainMessage<CreateProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.CreateProductResponse
 */
export class CreateProductResponse extends Message<CreateProductResponse> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  constructor(data?: PartialMessage<CreateProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.CreateProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProductResponse {
    return new CreateProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProductResponse {
    return new CreateProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProductResponse {
    return new CreateProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProductResponse | PlainMessage<CreateProductResponse> | undefined,
    b: CreateProductResponse | PlainMessage<CreateProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateProductRequest
 */
export class UpdateProductRequest extends Message<UpdateProductRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  /**
   * @generated from field: string title = 2;
   */
  title = '';

  /**
   * @generated from field: string specification = 3;
   */
  specification = '';

  /**
   * @generated from field: string description = 4;
   */
  description = '';

  /**
   * @generated from field: string demo_url = 5;
   */
  demoUrl = '';

  /**
   * @generated from field: string version = 6;
   */
  version = '';

  constructor(data?: PartialMessage<UpdateProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateProductRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'specification', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'description', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'demo_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProductRequest {
    return new UpdateProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductRequest {
    return new UpdateProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProductRequest {
    return new UpdateProductRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProductRequest | PlainMessage<UpdateProductRequest> | undefined,
    b: UpdateProductRequest | PlainMessage<UpdateProductRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProductRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.UpdateProductResponse
 */
export class UpdateProductResponse extends Message<UpdateProductResponse> {
  constructor(data?: PartialMessage<UpdateProductResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.UpdateProductResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProductResponse {
    return new UpdateProductResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProductResponse {
    return new UpdateProductResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProductResponse {
    return new UpdateProductResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProductResponse | PlainMessage<UpdateProductResponse> | undefined,
    b: UpdateProductResponse | PlainMessage<UpdateProductResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProductResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.SetProductStatusRequest
 */
export class SetProductStatusRequest extends Message<SetProductStatusRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  /**
   * @generated from field: marketplace.product.v1.ProductStatus status = 2;
   */
  status = ProductStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<SetProductStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SetProductStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'status', kind: 'enum', T: proto3.getEnumType(ProductStatus) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetProductStatusRequest {
    return new SetProductStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetProductStatusRequest {
    return new SetProductStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetProductStatusRequest {
    return new SetProductStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetProductStatusRequest | PlainMessage<SetProductStatusRequest> | undefined,
    b: SetProductStatusRequest | PlainMessage<SetProductStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetProductStatusRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.SetProductStatusResponse
 */
export class SetProductStatusResponse extends Message<SetProductStatusResponse> {
  constructor(data?: PartialMessage<SetProductStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SetProductStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetProductStatusResponse {
    return new SetProductStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetProductStatusResponse {
    return new SetProductStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetProductStatusResponse {
    return new SetProductStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetProductStatusResponse | PlainMessage<SetProductStatusResponse> | undefined,
    b: SetProductStatusResponse | PlainMessage<SetProductStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetProductStatusResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.SearchProductsRequest
 */
export class SearchProductsRequest extends Message<SearchProductsRequest> {
  /**
   * @generated from field: optional string text = 1;
   */
  text?: string;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 2;
   */
  tags?: Tags;

  constructor(data?: PartialMessage<SearchProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SearchProductsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'text', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: 'tags', kind: 'message', T: Tags },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchProductsRequest {
    return new SearchProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchProductsRequest {
    return new SearchProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchProductsRequest {
    return new SearchProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchProductsRequest | PlainMessage<SearchProductsRequest> | undefined,
    b: SearchProductsRequest | PlainMessage<SearchProductsRequest> | undefined
  ): boolean {
    return proto3.util.equals(SearchProductsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.SearchProductsResponse
 */
export class SearchProductsResponse extends Message<SearchProductsResponse> {
  /**
   * @generated from field: repeated marketplace.product.v1.Product products = 1;
   */
  products: Product[] = [];

  constructor(data?: PartialMessage<SearchProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SearchProductsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'products', kind: 'message', T: Product, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchProductsResponse {
    return new SearchProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchProductsResponse {
    return new SearchProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchProductsResponse {
    return new SearchProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchProductsResponse | PlainMessage<SearchProductsResponse> | undefined,
    b: SearchProductsResponse | PlainMessage<SearchProductsResponse> | undefined
  ): boolean {
    return proto3.util.equals(SearchProductsResponse, a, b);
  }
}
