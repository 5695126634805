// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file iam/auth/v1/auth.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { Device, Tokens } from './token_pb.js';

/**
 * @generated from message iam.auth.v1.PreSignRequest
 */
export class PreSignRequest extends Message<PreSignRequest> {
  /**
   * @generated from field: optional string email = 1;
   */
  email?: string;

  constructor(data?: PartialMessage<PreSignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.PreSignRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'email', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreSignRequest {
    return new PreSignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreSignRequest {
    return new PreSignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreSignRequest {
    return new PreSignRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: PreSignRequest | PlainMessage<PreSignRequest> | undefined,
    b: PreSignRequest | PlainMessage<PreSignRequest> | undefined
  ): boolean {
    return proto3.util.equals(PreSignRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.PreSignResponse
 */
export class PreSignResponse extends Message<PreSignResponse> {
  /**
   * @generated from field: string state = 1;
   */
  state = '';

  constructor(data?: PartialMessage<PreSignResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.PreSignResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PreSignResponse {
    return new PreSignResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PreSignResponse {
    return new PreSignResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PreSignResponse {
    return new PreSignResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: PreSignResponse | PlainMessage<PreSignResponse> | undefined,
    b: PreSignResponse | PlainMessage<PreSignResponse> | undefined
  ): boolean {
    return proto3.util.equals(PreSignResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GoogleOAuth
 */
export class GoogleOAuth extends Message<GoogleOAuth> {
  /**
   * @generated from field: string code = 1;
   */
  code = '';

  /**
   * @generated from field: string state = 2;
   */
  state = '';

  constructor(data?: PartialMessage<GoogleOAuth>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GoogleOAuth';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GoogleOAuth {
    return new GoogleOAuth().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GoogleOAuth {
    return new GoogleOAuth().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GoogleOAuth {
    return new GoogleOAuth().fromJsonString(jsonString, options);
  }

  static equals(
    a: GoogleOAuth | PlainMessage<GoogleOAuth> | undefined,
    b: GoogleOAuth | PlainMessage<GoogleOAuth> | undefined
  ): boolean {
    return proto3.util.equals(GoogleOAuth, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SignInRequest
 */
export class SignInRequest extends Message<SignInRequest> {
  /**
   * @generated from oneof iam.auth.v1.SignInRequest.type
   */
  type:
    | {
        /**
         * @generated from field: iam.auth.v1.GoogleOAuth oauth = 1;
         */
        value: GoogleOAuth;
        case: 'oauth';
      }
    | {
        /**
         * @generated from field: string token = 2;
         */
        value: string;
        case: 'token';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: iam.auth.v1.Device device = 3;
   */
  device?: Device;

  constructor(data?: PartialMessage<SignInRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SignInRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'oauth', kind: 'message', T: GoogleOAuth, oneof: 'type' },
    { no: 2, name: 'token', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'type' },
    { no: 3, name: 'device', kind: 'message', T: Device },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignInRequest {
    return new SignInRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignInRequest {
    return new SignInRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignInRequest {
    return new SignInRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SignInRequest | PlainMessage<SignInRequest> | undefined,
    b: SignInRequest | PlainMessage<SignInRequest> | undefined
  ): boolean {
    return proto3.util.equals(SignInRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SignInResponse
 */
export class SignInResponse extends Message<SignInResponse> {
  /**
   * @generated from field: iam.auth.v1.Tokens tokens = 1;
   */
  tokens?: Tokens;

  /**
   * @generated from field: optional string session = 2;
   */
  session?: string;

  constructor(data?: PartialMessage<SignInResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SignInResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tokens', kind: 'message', T: Tokens },
    { no: 2, name: 'session', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignInResponse {
    return new SignInResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignInResponse {
    return new SignInResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignInResponse {
    return new SignInResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SignInResponse | PlainMessage<SignInResponse> | undefined,
    b: SignInResponse | PlainMessage<SignInResponse> | undefined
  ): boolean {
    return proto3.util.equals(SignInResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SignOutRequest
 */
export class SignOutRequest extends Message<SignOutRequest> {
  /**
   * @generated from field: repeated iam.auth.v1.Device devices = 1;
   */
  devices: Device[] = [];

  constructor(data?: PartialMessage<SignOutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SignOutRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'devices', kind: 'message', T: Device, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignOutRequest {
    return new SignOutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignOutRequest {
    return new SignOutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignOutRequest {
    return new SignOutRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SignOutRequest | PlainMessage<SignOutRequest> | undefined,
    b: SignOutRequest | PlainMessage<SignOutRequest> | undefined
  ): boolean {
    return proto3.util.equals(SignOutRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SignOutResponse
 */
export class SignOutResponse extends Message<SignOutResponse> {
  constructor(data?: PartialMessage<SignOutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SignOutResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignOutResponse {
    return new SignOutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignOutResponse {
    return new SignOutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignOutResponse {
    return new SignOutResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SignOutResponse | PlainMessage<SignOutResponse> | undefined,
    b: SignOutResponse | PlainMessage<SignOutResponse> | undefined
  ): boolean {
    return proto3.util.equals(SignOutResponse, a, b);
  }
}
