import { useMarketplace } from '@store/marketplace';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useListAppliedDemands } from '@services/api/marketplace/calls';
import { sortItemsByDate } from '@pages/Marketplace/Dashboards/config';
import DemandAppliedCard from '@pages/Main/MainPage/DemandAppliedCard';

const AppliedDemandsSection = () => {
  const [currentStatus, setCurrentStatus] = useState('all');
  const { displayListType } = useMarketplace();

  const { data, isLoading, isError } = useListAppliedDemands();
  const demands = data?.demands;

  const contractList =
    (demands && Object.values(demands[currentStatus as keyof typeof demands]?.contracts)) || [];

  const interviewsList =
    (demands && Object.values(demands[currentStatus as keyof typeof demands]?.interviews)) || [];

  const combinedList = [...contractList, ...interviewsList];

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setCurrentStatus(event.target.value as string);
  };

  let displayList;

  if (displayListType === 'all') displayList = sortItemsByDate(combinedList);
  if (displayListType === 'contracts') displayList = sortItemsByDate(contractList);
  if (displayListType === 'interviews') displayList = sortItemsByDate(interviewsList);

  return !isLoading ? (
    <Box display="flex" flexDirection="column" width="100%" minWidth={500} height="75vh">
      <Box
        border="1px solid rgba(255, 255, 255, 0.12)"
        borderRadius="5px"
        display="flex"
        flexDirection="column"
        p={2}
        gap={1}
        overflow="auto"
        height="100%"
      >
        {demands ? (
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {demands && (
                <Select fullWidth value={currentStatus} onChange={handleFilterChange}>
                  {Object.keys(demands).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              {displayList &&
                displayList.map((demand) => {
                  return (
                    <Box key={demand?.entity?.value?.dashboardId}>
                      <DemandAppliedCard demand={demand} />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <Box display="flex" gap={1}>
            <Typography>You have not yet become a supplier.</Typography>
            <Link className="NavLink" to="/feed-list">
              <Typography sx={{ textDecoration: 'underline' }}>Explore feed</Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box>...</Box>
  );
};

export default AppliedDemandsSection;
