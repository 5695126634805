import { Form } from '@components/Form';
import { useState } from 'react';
import { useMarketplace } from '@store/marketplace';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { InputController } from '@components/Form/controllers/InputController';
import { KanbanBoard } from '@proto/marketplace/dashboard/v1/dashboad_pb';
import { FIELD_LABELS, FieldNames, getDefaultValues } from './settings';

type UpdateDashboardInfoFormProps = {
  dashboard: KanbanBoard;
};

const UpdateDashboardInfoForm = (props: UpdateDashboardInfoFormProps) => {
  const { dashboard } = props;
  const { updateDashboardInfo } = useMarketplace();
  const [isEdited, setIsEdited] = useState(true);

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(dashboard) }}
      submitHandler={(data) => {
        if (isEdited) {
          setIsEdited(!isEdited);
          return;
        }
        updateDashboardInfo(data);
        setIsEdited(!isEdited);
      }}
    >
      {isEdited ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" flex="grow">
            {dashboard?.title || 'No title'}
          </Typography>
          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                <EditIcon />{' '}
              </IconButton>
            </Tooltip>
          </LoadingButton>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          <InputController
            fullWidth
            name={FieldNames.TITLE}
            label={FIELD_LABELS[FieldNames.TITLE]}
            variant="standard"
          />

          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                {' '}
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </LoadingButton>
        </Box>
      )}
    </Form>
  );
};
export default UpdateDashboardInfoForm;
