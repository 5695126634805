// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/user/v1/tool_tips.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ListToolTipsRequest,
  ListToolTipsResponse,
} from './tool_tips_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.user.v1.ToolTipsService
 */
export const ToolTipsService = {
  typeName: 'profiler.user.v1.ToolTipsService',
  methods: {
    /**
     * @generated from rpc profiler.user.v1.ToolTipsService.ListToolTips
     */
    listToolTips: {
      name: 'ListToolTips',
      I: ListToolTipsRequest,
      O: ListToolTipsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ToolTipsService.FinishToolTips
     */
    finishToolTips: {
      name: 'FinishToolTips',
      I: FinishToolTipsRequest,
      O: FinishToolTipsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
