import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import UserProfileDrawer from '@pages/Main/ProfilePopover';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';
import NotificationPopover from '@components/NotificationPopover/NotificationPopover';
import MobileDrawer from '@pages/Main/MobileDrawer';
import Sidebar from '@pages/Main/Sidebar';
import CreateEntityMenu from '@pages/Main/CreateEntityMenu';
import { Logo } from '@components/Logo';
import { ROUTES } from '@common/routes';
import { NavLink, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import SnackbarManager from '@components/SnackbarManager/SnackbarManager';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  children?: React.ReactNode;
}

export const DashboardContainer = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const getLinkStyle = (paths: string[]) => {
    return paths.some((path) => location.pathname === path)
      ? { color: '#0087ca' }
      : { color: 'white' };
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar sx={{ width: '100%', display: { xs: 'block', md: 'none' } }} position="fixed" open>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <MobileDrawer />
          <Box display="flex" alignItems="center" gap={2}>
            <NotificationPopover />
            <UserProfileDrawer />
          </Box>
        </Toolbar>
      </AppBar>

      {location.pathname !== ROUTES.PROFILE_SETTINGS_ONBOARDING && (
        <AppBar color="inherit" sx={{ display: { xs: 'none', md: 'block' } }} position="fixed" open>
          <Toolbar sx={{ backgroundColor: '#04020f' }}>
            <Box display="flex" flexGrow={1} alignItems="center" gap={5}>
              <Logo />
              <Box display="flex" gap={2}>
                <NavLink
                  className="NavLink "
                  to={ROUTES.HOME}
                  style={() => getLinkStyle([ROUTES.HOME])}
                  onClick={toggleDrawer(false)}
                >
                  <Typography fontWeight="bold" sx={{ transition: 'all 0.3s linear' }}>
                    Main
                  </Typography>
                </NavLink>
                <NavLink
                  className="NavLink "
                  to={ROUTES.FEED_LIST}
                  style={() => getLinkStyle([ROUTES.FEED_LIST])}
                  onClick={toggleDrawer(false)}
                >
                  <Typography fontWeight="bold" sx={{ transition: 'all 0.3s linear' }}>
                    Feed
                  </Typography>
                </NavLink>
                <NavLink
                  className="NavLink "
                  to={ROUTES.ORGS_LIST}
                  style={() => getLinkStyle([ROUTES.ORGS_LIST, ROUTES.EXPLORE_ORGS])}
                  onClick={toggleDrawer(false)}
                >
                  <Typography fontWeight="bold" sx={{ transition: 'all 0.3s linear' }}>
                    Organizations
                  </Typography>
                </NavLink>
                <NavLink
                  className="NavLink "
                  to={ROUTES.SQUADS_LIST}
                  style={() => getLinkStyle([ROUTES.SQUADS_LIST, ROUTES.EXPLORE_SQUADS])}
                  onClick={toggleDrawer(false)}
                >
                  <Typography fontWeight="bold" sx={{ transition: 'all 0.3s linear' }}>
                    Squads
                  </Typography>
                </NavLink>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
              <CreateEntityMenu />
              <NotificationPopover />
              <UserProfileDrawer />
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Sidebar toggleDrawer={toggleDrawer} isOpen={open} />
      <Box
        sx={{
          py: { lg: '2rem', md: 5 },
        }}
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Toolbar />
        {children}
      </Box>

      <SnackbarManager />
    </Box>
  );
};
