import Box from '@mui/material/Box';
import Card from '@components/Card/Card';
import { Divider } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Link } from 'react-router-dom';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';

const DemandAppliedCard = ({ demand }: { demand: Demand }) => {
  return (
    <Box sx={{ cursor: 'pointer' }}>
      <Link
        to={`/${demand?.entity?.case}/${
          // @ts-ignore
          demand?.entity.value?.interviewId || demand?.entity.value?.contractId
        }`}
        className="NavLink"
      >
        <Card>
          <Box display="flex" gap={1} flexDirection="column" justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="14px" fontWeight="bold">
                {demand?.entity?.case?.toUpperCase()}
              </Typography>
              {demand?.entity.value?.createdAt && (
                <Typography fontSize="14px">
                  {format(new Date(demand?.entity.value?.createdAt.toDate()), 'dd/MM/yyyy')}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" gap={1}>
              <DemandTraits fontSize="14px" gap={0} demand={demand} />
              {/* {demand?.tags?.tags && <Tags tags={demand?.tags?.tags}/>} */}
            </Box>
          </Box>
        </Card>
      </Link>
    </Box>
  );
};

export default DemandAppliedCard;
