// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file iam/auth/v1/token.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message iam.auth.v1.Tokens
 */
export class Tokens extends Message<Tokens> {
  /**
   * @generated from field: string access = 1;
   */
  access = '';

  /**
   * @generated from field: string refresh = 2;
   */
  refresh = '';

  constructor(data?: PartialMessage<Tokens>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.Tokens';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'access', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'refresh', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Tokens {
    return new Tokens().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Tokens {
    return new Tokens().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Tokens {
    return new Tokens().fromJsonString(jsonString, options);
  }

  static equals(
    a: Tokens | PlainMessage<Tokens> | undefined,
    b: Tokens | PlainMessage<Tokens> | undefined
  ): boolean {
    return proto3.util.equals(Tokens, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RefreshTokensRequest
 */
export class RefreshTokensRequest extends Message<RefreshTokensRequest> {
  /**
   * @generated from field: iam.auth.v1.Device device = 1;
   */
  device?: Device;

  constructor(data?: PartialMessage<RefreshTokensRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RefreshTokensRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'device', kind: 'message', T: Device },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokensRequest {
    return new RefreshTokensRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokensRequest {
    return new RefreshTokensRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RefreshTokensRequest {
    return new RefreshTokensRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RefreshTokensRequest | PlainMessage<RefreshTokensRequest> | undefined,
    b: RefreshTokensRequest | PlainMessage<RefreshTokensRequest> | undefined
  ): boolean {
    return proto3.util.equals(RefreshTokensRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RefreshTokensResponse
 */
export class RefreshTokensResponse extends Message<RefreshTokensResponse> {
  /**
   * @generated from field: iam.auth.v1.Tokens tokens = 1;
   */
  tokens?: Tokens;

  constructor(data?: PartialMessage<RefreshTokensResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RefreshTokensResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tokens', kind: 'message', T: Tokens },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RefreshTokensResponse {
    return new RefreshTokensResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokensResponse {
    return new RefreshTokensResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RefreshTokensResponse {
    return new RefreshTokensResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RefreshTokensResponse | PlainMessage<RefreshTokensResponse> | undefined,
    b: RefreshTokensResponse | PlainMessage<RefreshTokensResponse> | undefined
  ): boolean {
    return proto3.util.equals(RefreshTokensResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ValidateTokenRequest
 */
export class ValidateTokenRequest extends Message<ValidateTokenRequest> {
  constructor(data?: PartialMessage<ValidateTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ValidateTokenRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ValidateTokenRequest {
    return new ValidateTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateTokenRequest {
    return new ValidateTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ValidateTokenRequest {
    return new ValidateTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ValidateTokenRequest | PlainMessage<ValidateTokenRequest> | undefined,
    b: ValidateTokenRequest | PlainMessage<ValidateTokenRequest> | undefined
  ): boolean {
    return proto3.util.equals(ValidateTokenRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ValidateTokenResponse
 */
export class ValidateTokenResponse extends Message<ValidateTokenResponse> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  constructor(data?: PartialMessage<ValidateTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ValidateTokenResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ValidateTokenResponse {
    return new ValidateTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ValidateTokenResponse {
    return new ValidateTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ValidateTokenResponse {
    return new ValidateTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ValidateTokenResponse | PlainMessage<ValidateTokenResponse> | undefined,
    b: ValidateTokenResponse | PlainMessage<ValidateTokenResponse> | undefined
  ): boolean {
    return proto3.util.equals(ValidateTokenResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.Device
 */
export class Device extends Message<Device> {
  /**
   * @generated from field: string fingerprint = 1;
   */
  fingerprint = '';

  /**
   * @generated from field: string os = 2;
   */
  os = '';

  constructor(data?: PartialMessage<Device>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.Device';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'fingerprint', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'os', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Device {
    return new Device().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Device {
    return new Device().fromJsonString(jsonString, options);
  }

  static equals(
    a: Device | PlainMessage<Device> | undefined,
    b: Device | PlainMessage<Device> | undefined
  ): boolean {
    return proto3.util.equals(Device, a, b);
  }
}
