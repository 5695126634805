import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import { combineReducers } from '@reduxjs/toolkit';

import {
  ActivateAvatarServiceRequest,
  ActivateAvatarServiceResponse,
  AttachRoleServiceRequest,
  AttachRoleServiceResponse,
  ChangeProfileStatusServiceRequest,
  ChangeProfileStatusServiceResponse,
  DeleteAvatarServiceRequest,
  DeleteAvatarServiceResponse,
  DetachRolesServiceRequest,
  DetachRolesServiceResponse,
  FinishToolTipsServiceRequest,
  FinishToolTipsServiceResponse,
  GetAvatarUploadUrlServiceRequest,
  GetAvatarUploadUrlServiceResponse,
  GetMyProfileServiceRequest,
  GetMyProfileServiceResponse,
  GetProfileServiceRequest,
  GetProfileServiceResponse,
  LeaveMembershipServiceRequest,
  LeaveMembershipServiceResponse,
  ListAvatarsServiceRequest,
  ListAvatarsServiceResponse,
  ListMembersRolesServiceRequest,
  ListMembersRolesServiceResponse,
  ListMembersServiceRequest,
  ListMembersServiceResponse,
  ListProfileMembershipsServiceRequest,
  ListProfileMembershipsServiceResponse,
  ListProfilesServiceRequest,
  ListProfilesServiceResponse,
  ListToolTipsServiceResponse,
  PinItemServiceRequest,
  PinItemServiceResponse,
  profileService,
  RemoveMemberServiceRequest,
  RemoveMemberServiceResponse,
  SelectProfilesServiceRequest,
  SelectProfilesServiceResponse,
  TMember,
  TProfile,
  UnPinItemServiceRequest,
  UnPinItemServiceResponse,
  UpdateProfilePersonalInfoServiceRequest,
  UpdateProfilePersonalInfoServiceResponse,
  UpdateProfileTraitsServiceRequest,
  UpdateProfileTraitsServiceResponse,
} from '@services/api/profiler/profile';

import {
  bizService,
  InviteActionServiceRequest,
  InviteActionServiceResponse,
  ListInvitesServiceRequest,
  ListInvitesServiceResponse,
  ListUserInvitesServiceResponse,
  SendInviteServiceRequest,
  SendInviteServiceResponse,
} from '@services/api/profiler/biz';

import { useCallback } from 'react';

import {
  GetProfileRequest,
  ListProfilesRequest,
  PinItemRequest,
  PinnedItem,
  UnpinItemRequest,
  UpdateProfilePersonalInfoRequest,
  UpdateProfileTraitsRequest,
} from '@proto/profiler/user/v1/profile_pb';

import { JWT } from '@services/jwt';

import {
  transformInvitesArrayToMap,
  transformSearchProfiles,
  TUserInvite,
} from '@store/profiler/helpers';
import {
  InviteAction,
  InviteActionRequest,
  ListInvitesRequest,
  ObjectInvite,
  SendInvitesRequest,
} from '@proto/profiler/invite/v1/invite_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { FinishToolTipsRequest, ToolTips } from '@proto/profiler/user/v1/tool_tips_pb';
import {
  Avatars,
  DeleteAvatarRequest,
  GetAvatarUploadUrlRequest,
  ListAvatarsRequest,
} from '@proto/profiler/avatars/v1/avatar_pb';
import {
  ListMembersRequest,
  ListProfileMembershipsRequest,
  RemoveMemberRequest,
} from '@proto/profiler/biz/v1/membership_pb';
import { Entity, TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { TObjectInvite } from '@store/squads/squads';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import {
  AttachRoleRequest,
  DetachRolesRequest,
  ListMembersRolesRequest,
} from '@proto/iam/auth/v1/iam_pb';
import { IAMRole } from '@components/RolesAutocomplete/RolesAutocomplete';
import { IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { TEntity, TransformedPinnedItems, transformPinItems } from '@utils/transformPinItemsToMap';
import { StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { groupEntitiesByIds } from '@store/marketplace/helpers';
import { GetSquadRequest, ListSquadsRequest } from '@proto/profiler/biz/v1/squad_pb';
import { getCurrentSquad, getListUserSquads } from '@store/squads';
import { getCurrentOrg, getListUserOrgs } from '@store/orgs';
import { ListOrgsRequest } from '@proto/profiler/biz/v1/org_pb';
import { showSnackbar } from '@store/snackbars';
import { userSlice } from './slices';
import { Profile, TSearchProfiles } from './profiler';
import { statusConverterToNumber } from '../../helpers/statusConverter';

const {
  actions: {
    setProfile,
    setProfileName,
    setProfileStatus,
    setSelectedProfile,
    setFoundProfiles,
    deleteSelectedProfile,
    setUserIvitesList,
    setUploadAvatarUrl,
    setUserAvatars,
    setTooltipsList,
    setCurrentProfile,
    setInvitesLists,
    setListMembers,
    setListMembersRoles,
    setPinnedItems,
    setProfileTraits,
    setUserProfiles,
  },
} = userSlice;

export const profilerReducer = combineReducers({
  user: userSlice.reducer,
});

// PROFILE
export const getMyProfile = createServiceCall<GetMyProfileServiceResponse, never>(
  userSlice.name,
  profileService.getMyProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
    dispatch(setPinnedItems({ pinnedItems: transformPinItems(profile?.pinnedItems || []) }));
  },
  ({ dispatch }) => {
    JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);
const getWelcomeProfile = createServiceCall<
  GetMyProfileServiceResponse,
  GetMyProfileServiceRequest
>(
  userSlice.name,
  profileService.getWelcomeProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setProfile({ profile }));
  },
  ({ dispatch }) => {
    JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);
export const getEntityInvitesList = createServiceCall<
  ListInvitesServiceResponse,
  ListInvitesServiceRequest
>(userSlice.name, bizService.listInvites, ({ payload, response, dispatch }) => {
  const entityId = payload.entity?.id;
  if (entityId) dispatch(setInvitesLists({ [entityId]: response.invites }));
});

const getListMembersRoles = createServiceCall<
  ListMembersRolesServiceResponse,
  ListMembersRolesServiceRequest
>(userSlice.name, profileService.getListMembersRoles, ({ payload, response, dispatch }) => {
  dispatch(setListMembersRoles({ [payload.domainId as string]: response.users }));
});

const getListMembers = createServiceCall<ListMembersServiceResponse, ListMembersServiceRequest>(
  userSlice.name,
  profileService.getListMembers,
  ({ payload, response: { members }, dispatch }) => {
    dispatch(
      getListMembersRoles(
        new ListMembersRolesRequest({
          domainId: payload?.entityIds?.ids[0],
          selector: {
            case: 'subjectIds',
            value: new UUIDS({
              ids: members[
                payload?.entityIds?.ids[0] as keyof typeof members
              ]?.profiles?.profiles?.map((member) => member.profileId),
            }),
          },
        })
      )
    );
    dispatch(setListMembers({ profiles: members }));
  }
);

const removeMember = createServiceCall<RemoveMemberServiceResponse, RemoveMemberServiceRequest>(
  userSlice.name,
  profileService.removeMember,
  ({ payload, dispatch }) => {
    dispatch(getListMembers({ entityIds: new UUIDS({ ids: [payload.entityId] }) }));
  }
);

const leaveMembership = createServiceCall<
  LeaveMembershipServiceResponse,
  LeaveMembershipServiceRequest
>(userSlice.name, profileService.leaveMembership);

export const getListProfiles = createServiceCall<
  ListProfilesServiceResponse,
  ListProfilesServiceRequest
>(userSlice.name, profileService.listProfiles, ({ response: { profiles }, dispatch }) => {
  dispatch(
    setUserProfiles(
      profiles?.profiles.reduce((acc, profile) => {
        return { ...acc, [profile.profileId]: profile };
      }, [])
    )
  );
});

const getProfile = createServiceCall<GetProfileServiceResponse, GetProfileServiceRequest>(
  userSlice.name,
  profileService.getProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setSelectedProfile({ profile }));
  }
);

const getCurrentProfile = createServiceCall<GetProfileServiceResponse, GetProfileServiceRequest>(
  userSlice.name,
  profileService.getProfile,
  ({ response: { profile = undefined }, dispatch }) => {
    dispatch(setCurrentProfile({ profile }));
    if (profile) dispatch(setUserProfiles({ [profile?.profileId]: profile }));
  }
);

const updateProfileName = createServiceCall<
  UpdateProfilePersonalInfoServiceResponse,
  UpdateProfilePersonalInfoServiceRequest
>(
  userSlice.name,
  profileService.updateProfileName,
  ({ response: { names }, dispatch }) => {
    dispatch(setProfileName({ names }));
    dispatch(
      showSnackbar({ message: 'Profile name updated', severity: 'success', id: 'profile-name' })
    );
  },
  ({ dispatch }) => {
    dispatch(
      showSnackbar({ message: 'Profile name update failed', severity: 'error', id: 'profile-name' })
    );
  }
);

const changeProfileStatus = createServiceCall<
  ChangeProfileStatusServiceResponse,
  ChangeProfileStatusServiceRequest
>(userSlice.name, profileService.changeProfileStatus, ({ response: { active }, dispatch }) => {
  dispatch(setProfileStatus({ active }));
});
const getAvatarUploadUrl = createServiceCall<
  GetAvatarUploadUrlServiceResponse,
  GetAvatarUploadUrlServiceRequest
>(
  userSlice.name,
  profileService.getAvatarUpload,
  ({ response: { url }, dispatch, state }) => {
    dispatch(setUploadAvatarUrl({ url }));
  },
  ({ dispatch, state }) => {}
);
const deleteAvatar = createServiceCall<DeleteAvatarServiceResponse, DeleteAvatarServiceRequest>(
  userSlice.name,
  profileService.deleteAvatar
);
const getProfileAvatar = createServiceCall<ListAvatarsServiceResponse, ListAvatarsServiceRequest>(
  userSlice.name,
  profileService.listAvatars,
  ({ response: { avatars }, dispatch }) => {
    dispatch(setUserAvatars({ avatars }));
  }
);

export const activateAvatar = createServiceCall<
  ActivateAvatarServiceResponse,
  ActivateAvatarServiceRequest
>(userSlice.name, profileService.activateAvatar, ({ dispatch }) => {
  dispatch(getMyProfile());
});

const updateTraits = createServiceCall<
  UpdateProfileTraitsServiceResponse,
  UpdateProfileTraitsServiceRequest
>(
  userSlice.name,
  profileService.updateTraits,
  ({ response: { traits }, dispatch }) => {
    dispatch(setProfileTraits({ traits }));
    dispatch(
      showSnackbar({ message: 'Profile traits updated', severity: 'success', id: 'profile-traits' })
    );
  },
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        message: 'Profile traits update failed',
        severity: 'error',
        id: 'profile-traits',
      })
    );
  }
);

const getListToolTips = createServiceCall<ListToolTipsServiceResponse, never>(
  userSlice.name,
  profileService.listToolTips,
  ({ response: { toolTips }, dispatch }) => {
    if (toolTips === undefined) return;
    dispatch(setTooltipsList({ tooltips: toolTips }));
  }
);
const finishToolTips = createServiceCall<
  FinishToolTipsServiceResponse,
  FinishToolTipsServiceRequest
>(userSlice.name, profileService.finishTooltips, ({ dispatch }) => {
  dispatch(getListToolTips());
});

export const getListMemberShips = createServiceCall<
  ListProfileMembershipsServiceResponse,
  ListProfileMembershipsServiceRequest
>(userSlice.name, profileService.listMemberShips, ({ dispatch, response }) => {
  const ids = groupEntitiesByIds(response?.memberships);
  dispatch(
    getListUserSquads(
      new ListSquadsRequest({ squadIds: new UUIDS({ ids: ids?.TYPE_OF_ENTITY_SQUAD || [] }) })
    )
  );
  dispatch(
    getListUserOrgs(
      new ListOrgsRequest({ orgIds: new UUIDS({ ids: ids?.TYPE_OF_ENTITY_ORG || [] }) })
    )
  );
});

const getlistUserInvites = createServiceCall<ListUserInvitesServiceResponse, never>(
  userSlice.name,
  bizService.listUserInvites,
  ({ response: { invites }, dispatch }) => {
    dispatch(setUserIvitesList({ invites: transformInvitesArrayToMap(invites) }));
  },
  ({ dispatch }) => {
    dispatch(setUserIvitesList({ invites: [] }));
  }
);

const sendInvites = createServiceCall<SendInviteServiceResponse, SendInviteServiceRequest>(
  userSlice.name,
  bizService.sendInvites,
  ({ payload, dispatch }) => {
    // clear searchProfiles
    if (payload.entity)
      dispatch(
        getEntityInvitesList(
          new ListInvitesRequest({
            entity: payload.entity,
          })
        )
      );
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
  }
);
const pinEntity = createServiceCall<PinItemServiceResponse, PinItemServiceRequest>(
  userSlice.name,
  profileService.pinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);
const unpinEntity = createServiceCall<UnPinItemServiceResponse, UnPinItemServiceRequest>(
  userSlice.name,
  profileService.unPinItem,
  ({ dispatch }) => {
    dispatch(getMyProfile());
  }
);

const getCurrentEntity = {
  2: (payload: string[]) =>
    getCurrentOrg(
      new ListOrgsRequest({
        orgIds: new UUIDS({
          ids: payload,
        }),
      })
    ),
  3: (payload: string) =>
    getCurrentSquad(
      new GetSquadRequest({
        by: {
          case: 'squadId',
          value: payload,
        },
      })
    ),
};

const acceptUserInvite = createServiceCall<
  InviteActionServiceResponse,
  InviteActionServiceRequest & {
    entity?: TEntity;
  }
>(userSlice.name, bizService.inviteAction, ({ payload, dispatch }) => {
  if (payload && payload?.entity) {
    // @ts-ignore
    window.location.reload();
  }

  dispatch(getlistUserInvites());
});

const closeUserInvite = createServiceCall<InviteActionServiceResponse, InviteActionServiceRequest>(
  userSlice.name,
  bizService.inviteAction,
  ({ payload, dispatch }) => {
    dispatch(getlistUserInvites());
  }
);

const closeGroupingInvite = createServiceCall<
  InviteActionServiceResponse,
  InviteActionServiceRequest
>(userSlice.name, bizService.inviteAction, ({ payload, dispatch }) => {});

const selectProfiles = createServiceCall<
  SelectProfilesServiceResponse,
  SelectProfilesServiceRequest
>(userSlice.name, profileService.selectProfiles, ({ response: { profiles }, dispatch }) => {
  const data: any = transformSearchProfiles(profiles);
  // @ts-ignore
  dispatch(setFoundProfiles(data));
});

const attachRole = createServiceCall<AttachRoleServiceResponse, AttachRoleServiceRequest>(
  userSlice.name,
  profileService.attachRole
);
const detachRole = createServiceCall<DetachRolesServiceResponse, DetachRolesServiceRequest>(
  userSlice.name,
  profileService.detachRole
);

export const useProfiler = (): {
  userProfile: Profile | null;
  userProfiles: { [key: string]: Profile };
  pinnedItems: TransformedPinnedItems;
  selectedProfile: Profile | undefined;
  currentProfile: Profile | undefined;
  foundProfiles: TSearchProfiles[];
  invitesList: ObjectInvite[] | null;
  uploadAvatarUrl: string | null;
  listMemberShips: TMember[] | undefined;
  listUserInvites: { [key: string]: TUserInvite };
  profilesList: Profile[] | null;
  listMembers: { [key: string]: { profiles: { profiles: TProfile[] } } };
  userPermissions: {
    [key: string]: string;
  } | null;
  invitesLists: { [key: string]: TObjectInvite[] };
  listUserAvatars: Avatars | null;
  tooltipsList: string[];
  listRoles: { [key: string]: { id: string; roles: { roles: IAMRoleStrings[] } }[] };

  getAvatarUploadUrl: (payload: {
    file: string | null;
    contentLength: any;
    entityId: string | undefined;
  }) => void;
  getProfileAvatar: (payload: string | undefined) => void;
  deleteProfileAvatar: () => void;
  getMyProfile: () => void;
  getListToolTips: () => void;
  getWelcomeProfile: (payload: object) => void;
  getProfile: (payload: GetProfileServiceRequest) => void;
  getCurrentProfile: (payload: string) => void;
  updateProfileName: (payload: {
    names: {
      firstName: string;
      lastName: string;
      nickName: string;
    };
    birthDate: Date | null;
  }) => void;
  changeProfileStatus: (payload: ChangeProfileStatusServiceRequest) => void;
  getListProfiles: (payload: string[]) => void;
  updateTraits: (payload: UpdateProfileTraitsServiceRequest) => void;
  finishToolTips: (payload: ToolTips) => void;
  getListMemberShips: () => void;
  selectProfiles: (payload: SelectProfilesServiceRequest) => void;
  sendInvites: (payload: { id: string; type: TypeOfEntity; profileIds: string[] }) => void;
  getlistUserInvites: () => void;
  acceptUserInvite: (payload: InviteActionServiceRequest & { entity?: TEntity }) => void;
  closeUserInvite: (payload: InviteActionServiceRequest) => void;
  closeGroupingInvite: (payload: { inviteId: string; action: InviteAction }) => void;
  getEntityInvitesList: (payload: { id: string; type: TypeOfEntity }) => void;
  getListMembers: (payload: { id: string; type: TypeOfEntity }) => void;
  attachRole: (payload: any) => void;
  detachRole: (payload: any) => void;
  pinEntity: (payload: { entityId: string; entityType: TypeOfEntity }) => void;
  unpinEntity: (payload: { entityId: string; entityType: TypeOfEntity }) => void;
  profileId: string | undefined | null;
  setProfileId: (
    newValue: string | null | undefined,
    updateType?: UrlUpdateType | undefined
  ) => void;
  removeMember: (payload: { entityId: string; profileId: string }) => void;
  leaveMembership: (payload: { entityId: string }) => void;
} => {
  const dispatch = useAppDispatch();
  const [profileId, setProfileId] = useQueryParam('profileId', StringParam);

  return {
    profileId,
    setProfileId,
    userProfile: useAppSelector((state) => state.profiler.user.profile),
    pinnedItems: useAppSelector((state) => state.profiler.user.pinnedItems),
    selectedProfile: useAppSelector((state) => state.profiler.user.selectedProfile),
    currentProfile: useAppSelector((state) => state.profiler.user.currentProfile),
    uploadAvatarUrl: useAppSelector((state) => state.profiler.user.uploadAvatarUrl),
    foundProfiles: useAppSelector((state) => state.profiler.user.searchProfiles),
    invitesList: useAppSelector((state) => state.profiler.user.invitesList),
    listUserInvites: useAppSelector((state) => state.profiler.user.listUserInvites),
    profilesList: useAppSelector((state) => state.profiler.user.listProfiles),
    listMembers: useAppSelector((state) => state.profiler.user.listMembers),
    userPermissions: useAppSelector((state) => state.profiler.user.userPermissions),
    listUserAvatars: useAppSelector((state) => state.profiler.user.userAvatars),
    tooltipsList: useAppSelector((state) => state.profiler.user.tooltipsList),
    listMemberShips: useAppSelector((state) => state.profiler.user.listMemberShips),
    invitesLists: useAppSelector((state) => state.profiler.user.invitesLists),
    userProfiles: useAppSelector((state) => state.profiler.user.userProfiles),
    listRoles: useAppSelector((state) => state.profiler.user.listRoles),

    attachRole: useCallback(
      (payload) => {
        dispatch(
          attachRole(
            new AttachRoleRequest({
              subjectId: payload.subjectId,
              domainId: payload.domainId,
              role: IAMRole[payload.role as keyof typeof IAMRole],
            })
          )
        );
      },
      [dispatch]
    ),
    detachRole: useCallback(
      (payload) => {
        dispatch(
          detachRole(
            new DetachRolesRequest({
              roleAttachement: {
                subjectIds: new UUIDS({
                  ids: [payload.subjectId],
                }),
                domainId: payload.domainId,
                role: IAMRole[payload.role as keyof typeof IAMRole],
              },
            })
          )
        );
      },
      [dispatch]
    ),
    pinEntity: useCallback(
      (payload) => {
        dispatch(
          pinEntity(
            new PinItemRequest({
              pin: new PinnedItem({
                entity: new Entity({
                  id: payload.entityId,
                  type: payload.entityType,
                }),
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    unpinEntity: useCallback(
      (payload) => {
        dispatch(
          unpinEntity(
            new UnpinItemRequest({
              entity: new Entity({
                id: payload.entityId,
                type: payload.entityType,
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    getAvatarUploadUrl: useCallback(
      (payload) => {
        if (payload.file)
          dispatch(
            getAvatarUploadUrl(
              new GetAvatarUploadUrlRequest({
                fileName: payload.file,
                contentLength: payload.contentLength,
                entityId: payload.entityId,
              })
            )
          );
      },
      [dispatch]
    ),
    getListMemberShips: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens)
        dispatch(
          getListMemberShips(
            new ListProfileMembershipsRequest({
              entityType: TypeOfEntity.UNSPECIFIED,
            })
          )
        );
    }, [dispatch]),

    getProfileAvatar: useCallback(
      (payload) => {
        if (payload === undefined) return;

        dispatch(
          getProfileAvatar(
            new ListAvatarsRequest({
              option: {
                case: 'entityId',
                value: payload,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    getListMembers: useCallback(
      (payload) => {
        dispatch(
          getListMembers(
            new ListMembersRequest({
              entityIds: new UUIDS({
                ids: [payload.id],
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    getEntityInvitesList: useCallback(
      (payload) => {
        dispatch(
          getEntityInvitesList(
            new ListInvitesRequest({
              entity: new Entity({
                id: payload.id,
                type: payload.type,
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    deleteProfileAvatar: useCallback(() => {
      dispatch(
        deleteAvatar(
          new DeleteAvatarRequest({
            avatarId: 'a61929b4-07e5-4112-b9b4-27b851b16a95',
            entityId: '099a3c8c-ce20-4b9a-8651-407eedab250c',
          })
        )
      );
    }, [dispatch]),
    // We use this method to init app, so to cut down unnecessary requests we check for tokens in advance
    getMyProfile: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getMyProfile());
      }
    }, [dispatch]),
    getListToolTips: useCallback(() => {
      const tokens = JWT.getJWTTokens();

      if (tokens) {
        dispatch(getListToolTips());
      }
    }, [dispatch]),
    finishToolTips: useCallback(
      (payload) => {
        dispatch(
          finishToolTips(
            new FinishToolTipsRequest({
              toolTips: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    getWelcomeProfile: useCallback(
      (payload) => {
        const tokens = JWT.getJWTTokens();
        if (tokens) {
          dispatch(getWelcomeProfile(payload));
        }
      },
      [dispatch]
    ),
    getProfile: useCallback(
      (payload) => {
        dispatch(getProfile(payload));
      },
      [dispatch]
    ),
    getCurrentProfile: useCallback(
      (payload) => {
        dispatch(
          getCurrentProfile(
            new GetProfileRequest({
              profileId: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    updateProfileName: useCallback(
      (payload) => {
        const data = new UpdateProfilePersonalInfoRequest({
          names: payload.names,
          birthDate: Timestamp.fromDate(
            // @ts-ignore
            payload.birthDate !== '' ? new Date(payload.birthDate) : new Date()
          ),
        });
        dispatch(updateProfileName(data));
      },
      [dispatch]
    ),
    changeProfileStatus: useCallback(
      (payload) => dispatch(changeProfileStatus(payload)),
      [dispatch]
    ),
    getListProfiles: useCallback(
      (payload) => {
        dispatch(
          getListProfiles(
            new ListProfilesRequest({
              profiles: new UUIDS({
                ids: payload,
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    updateTraits: useCallback(
      (payload) => {
        const traits = new UpdateProfileTraitsRequest({
          traits: {
            rate: payload.traits?.rate,
            capacity: payload.traits?.capacity,
            experience: payload.traits?.experience,
            interviewer: statusConverterToNumber(payload.traits?.interviewer),
            squader: statusConverterToNumber(payload.traits?.squader),
          },
        });
        dispatch(updateTraits(traits));
      },
      [dispatch]
    ),
    selectProfiles: useCallback(
      (payload) => {
        dispatch(selectProfiles(payload));
      },
      [dispatch]
    ),
    sendInvites: useCallback(
      (payload) => {
        dispatch(
          sendInvites(
            new SendInvitesRequest({
              entity: {
                id: payload.id,
                type: payload.type,
              },
              profiles: new UUIDS({
                ids: payload.profileIds,
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    acceptUserInvite: useCallback(
      (payload) => {
        dispatch(acceptUserInvite(payload));
      },
      [dispatch]
    ),
    closeUserInvite: useCallback(
      (payload) => {
        dispatch(closeUserInvite(payload));
      },
      [dispatch]
    ),
    closeGroupingInvite: useCallback(
      (payload) => {
        dispatch(
          closeGroupingInvite(
            new InviteActionRequest({
              inviteId: payload.inviteId,
              action: payload.action,
            })
          )
        );
      },
      [dispatch]
    ),
    getlistUserInvites: useCallback(() => {
      dispatch(getlistUserInvites());
    }, [dispatch]),
    removeMember: useCallback(
      (payload) => {
        dispatch(removeMember(new RemoveMemberRequest(payload)));
      },
      [dispatch]
    ),
    leaveMembership: useCallback(
      (payload) => {
        dispatch(leaveMembership(payload));
      },
      [dispatch]
    ),
  };
};
