import { TMember } from '@services/api/profiler/profile';

export const filterEntitiesByType = (typeString: string, entities: TMember[]): string[] => {
  return entities
    .filter((entity) => {
      if (entity.entity) return entity?.entity.type.toString() === typeString;
      return false;
    })
    .map((filteredEntity) => {
      if (filteredEntity.entity) return filteredEntity?.entity.id;
      return '';
    });
};
