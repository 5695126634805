import Box from '@mui/material/Box';
import { useProfiler } from '@store/profiler';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { InviteAction, InviteActionRequest } from '@proto/profiler/invite/v1/invite_pb';
import React from 'react';

type TEntityInviteCardProps = {
  entity?: any;
  entityId?: string;
};
const EntityInviteCard = (props: TEntityInviteCardProps) => {
  const { entity, entityId } = props;
  const { listUserInvites } = useProfiler();
  const { closeUserInvite, acceptUserInvite } = useProfiler();

  const handleAccept = () => {
    acceptUserInvite({
      inviteId: listUserInvites[entityId as keyof typeof listUserInvites].inviteId,
      action: InviteAction.ACCEPT,
      entity: {
        id: entity?.entity?.id,
        type: entity?.entity?.type,
      },
    });
  };

  const handleDecline = () => {
    closeUserInvite(
      new InviteActionRequest({
        inviteId: listUserInvites[entityId as keyof typeof listUserInvites].inviteId,
        action: InviteAction.REJECT,
      })
    );
  };

  return listUserInvites[entityId as keyof typeof listUserInvites] ? (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2} alignItems="center">
      <Typography variant="subtitle1">You were invited</Typography>
      <Box display="flex" gap={1}>
        <Button variant="outlined" color="success" onClick={handleAccept}>
          Accept
        </Button>
        <Button onClick={handleDecline} variant="outlined" color="error">
          Decline
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default EntityInviteCard;
