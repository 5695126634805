import { TagTypeEnum, TagTypeNumberEnum, TTag } from '@services/api/profiler/tags';
import { PlainMsg } from '@services/api/helpers';
import { ObjectTags, RemoveTagsRequest, Tag, Tags } from '@proto/profiler/tags/v1/tags_pb';
import { TAutocompleteItem } from '@components/TagAutocomplete/TagAutocomplete';
import { Entity } from '@proto/grpc/type/v1/entity_pb';

export const getDefaultTags = (tags: [TagTypeEnum, PlainMsg<Tag>[]]) => {
  return tags?.[1].map((el) => {
    return {
      tagId: el.tagId,
      value: el.value,
      active: el.active,
      tagType: el.tagType,
    };
  });
};

export const selectHandler = (tag: TTag, data: any, request: any) => {
  const tags = new ObjectTags({
    entity: new Entity({
      id: data.entityId,
      type: data.entity,
    }),
    tags: new Tags({
      tags: [
        new Tag({
          tagType: TagTypeNumberEnum[tag.tagType as unknown as keyof typeof TagTypeNumberEnum],
          tagId: tag.tagId,
          value: tag.value,
          active: true,
        }),
      ],
    }),
  });

  return request({ tags });
};
export const statusChangeHandler = (tag: TAutocompleteItem, data: any, request: any) => {
  const tags = new ObjectTags({
    entity: new Entity({
      id: data.entityId,
      type: data.entity,
    }),
    tags: new Tags({
      tags: [
        new Tag({
          tagType: TagTypeNumberEnum[tag.tagType as unknown as keyof typeof TagTypeNumberEnum],
          tagId: tag.tagId,
          value: tag.value,
          active: data.status,
        }),
      ],
    }),
  });
  //
  return request({ tags });
};

export const removeHandler = (tag: TTag, data: any, request: any) => {
  const tags = new RemoveTagsRequest({
    entity: new Entity({
      id: data.entityId,
      type: data.entity,
    }),
    tagIds: [tag.tagId],
  });

  return request(tags);
};

export const statusConvertToBoolean = (status: string) => {
  return status === 'STATUS_TRUE';
};
export const statusConvertToString = (status: boolean) => {
  return status ? 'STATUS_TRUE' : 'STATUS_FALSE';
};
