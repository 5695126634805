// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file iam/auth/v1/iam.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from enum iam.auth.v1.Action
 */
export enum Action {
  /**
   * @generated from enum value: ACTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ACTION_ALL = 1;
   */
  ALL = 1,

  /**
   * @generated from enum value: ACTION_R_ROLE = 2;
   */
  R_ROLE = 2,

  /**
   * @generated from enum value: ACTION_W_ROLE = 3;
   */
  W_ROLE = 3,

  /**
   * @generated from enum value: ACTION_R_MEMBER = 4;
   */
  R_MEMBER = 4,

  /**
   * @generated from enum value: ACTION_W_MEMBER = 5;
   */
  W_MEMBER = 5,

  /**
   * @generated from enum value: ACTION_W_DYNAMIC_SQUAD = 6;
   */
  W_DYNAMIC_SQUAD = 6,

  /**
   * @generated from enum value: ACTION_R_DYNAMIC_SQUAD = 7;
   */
  R_DYNAMIC_SQUAD = 7,

  /**
   * @generated from enum value: ACTION_R_INVITE = 8;
   */
  R_INVITE = 8,

  /**
   * @generated from enum value: ACTION_W_INVITE = 9;
   */
  W_INVITE = 9,

  /**
   * @generated from enum value: ACTION_R_PRODUCT = 10;
   */
  R_PRODUCT = 10,

  /**
   * @generated from enum value: ACTION_W_PRODUCT = 11;
   */
  W_PRODUCT = 11,

  /**
   * @generated from enum value: ACTION_R_EVALUATION = 12;
   */
  R_EVALUATION = 12,

  /**
   * @generated from enum value: ACTION_W_EVALUATION = 13;
   */
  W_EVALUATION = 13,

  /**
   * @generated from enum value: ACTION_R_DEMAND = 14;
   */
  R_DEMAND = 14,

  /**
   * @generated from enum value: ACTION_W_DEMAND = 15;
   */
  W_DEMAND = 15,

  /**
   * @generated from enum value: ACTION_R_ORDER = 16;
   */
  R_ORDER = 16,

  /**
   * @generated from enum value: ACTION_W_ORDER = 17;
   */
  W_ORDER = 17,

  /**
   * @generated from enum value: ACTION_R_SUPPLY = 18;
   */
  R_SUPPLY = 18,

  /**
   * @generated from enum value: ACTION_W_SUPPLY = 19;
   */
  W_SUPPLY = 19,

  /**
   * @generated from enum value: ACTION_R_FEEDBACK = 20;
   */
  R_FEEDBACK = 20,

  /**
   * @generated from enum value: ACTION_W_FEEDBACK = 21;
   */
  W_FEEDBACK = 21,
}
// Retrieve enum metadata with: proto3.getEnumType(Action)
proto3.util.setEnumType(Action, 'iam.auth.v1.Action', [
  { no: 0, name: 'ACTION_UNSPECIFIED' },
  { no: 1, name: 'ACTION_ALL' },
  { no: 2, name: 'ACTION_R_ROLE' },
  { no: 3, name: 'ACTION_W_ROLE' },
  { no: 4, name: 'ACTION_R_MEMBER' },
  { no: 5, name: 'ACTION_W_MEMBER' },
  { no: 6, name: 'ACTION_W_DYNAMIC_SQUAD' },
  { no: 7, name: 'ACTION_R_DYNAMIC_SQUAD' },
  { no: 8, name: 'ACTION_R_INVITE' },
  { no: 9, name: 'ACTION_W_INVITE' },
  { no: 10, name: 'ACTION_R_PRODUCT' },
  { no: 11, name: 'ACTION_W_PRODUCT' },
  { no: 12, name: 'ACTION_R_EVALUATION' },
  { no: 13, name: 'ACTION_W_EVALUATION' },
  { no: 14, name: 'ACTION_R_DEMAND' },
  { no: 15, name: 'ACTION_W_DEMAND' },
  { no: 16, name: 'ACTION_R_ORDER' },
  { no: 17, name: 'ACTION_W_ORDER' },
  { no: 18, name: 'ACTION_R_SUPPLY' },
  { no: 19, name: 'ACTION_W_SUPPLY' },
  { no: 20, name: 'ACTION_R_FEEDBACK' },
  { no: 21, name: 'ACTION_W_FEEDBACK' },
]);

/**
 * @generated from enum iam.auth.v1.IAMRole
 */
export enum IAMRole {
  /**
   * @generated from enum value: IAM_ROLE_UNSPECIFIED = 0;
   */
  IAM_ROLE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: IAM_ROLE_ADMIN = 1;
   */
  IAM_ROLE_ADMIN = 1,

  /**
   * @generated from enum value: IAM_ROLE_MANAGER = 2;
   */
  IAM_ROLE_MANAGER = 2,

  /**
   * @generated from enum value: IAM_ROLE_MEMBER = 3;
   */
  IAM_ROLE_MEMBER = 3,

  /**
   * @generated from enum value: IAM_ROLE_BILLING_MANAGER = 4;
   */
  IAM_ROLE_BILLING_MANAGER = 4,

  /**
   * @generated from enum value: IAM_ROLE_PEOPLE_MANAGER = 5;
   */
  IAM_ROLE_PEOPLE_MANAGER = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(IAMRole)
proto3.util.setEnumType(IAMRole, 'iam.auth.v1.IAMRole', [
  { no: 0, name: 'IAM_ROLE_UNSPECIFIED' },
  { no: 1, name: 'IAM_ROLE_ADMIN' },
  { no: 2, name: 'IAM_ROLE_MANAGER' },
  { no: 3, name: 'IAM_ROLE_MEMBER' },
  { no: 4, name: 'IAM_ROLE_BILLING_MANAGER' },
  { no: 5, name: 'IAM_ROLE_PEOPLE_MANAGER' },
]);

/**
 * @generated from message iam.auth.v1.EnforceRequest
 */
export class EnforceRequest extends Message<EnforceRequest> {
  /**
   * @generated from field: optional string subject_id = 1;
   */
  subjectId?: string;

  /**
   * @generated from field: optional string domain_id = 2;
   */
  domainId?: string;

  /**
   * @generated from field: optional string object_id = 3;
   */
  objectId?: string;

  /**
   * @generated from field: iam.auth.v1.Action action = 4;
   */
  action = Action.UNSPECIFIED;

  /**
   * @generated from field: iam.auth.v1.Roles roles = 5;
   */
  roles?: Roles;

  constructor(data?: PartialMessage<EnforceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.EnforceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: 'object_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: 'action', kind: 'enum', T: proto3.getEnumType(Action) },
    { no: 5, name: 'roles', kind: 'message', T: Roles },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnforceRequest {
    return new EnforceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnforceRequest {
    return new EnforceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnforceRequest {
    return new EnforceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: EnforceRequest | PlainMessage<EnforceRequest> | undefined,
    b: EnforceRequest | PlainMessage<EnforceRequest> | undefined
  ): boolean {
    return proto3.util.equals(EnforceRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.EnforceResponse
 */
export class EnforceResponse extends Message<EnforceResponse> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  constructor(data?: PartialMessage<EnforceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.EnforceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnforceResponse {
    return new EnforceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnforceResponse {
    return new EnforceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnforceResponse {
    return new EnforceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: EnforceResponse | PlainMessage<EnforceResponse> | undefined,
    b: EnforceResponse | PlainMessage<EnforceResponse> | undefined
  ): boolean {
    return proto3.util.equals(EnforceResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.BatchEnforceRequest
 */
export class BatchEnforceRequest extends Message<BatchEnforceRequest> {
  /**
   * @generated from field: iam.auth.v1.Action action = 1;
   */
  action = Action.UNSPECIFIED;

  /**
   * @generated from oneof iam.auth.v1.BatchEnforceRequest.ids
   */
  ids:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS object_ids = 2;
         */
        value: UUIDS;
        case: 'objectIds';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS domain_ids = 3;
         */
        value: UUIDS;
        case: 'domainIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<BatchEnforceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.BatchEnforceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'action', kind: 'enum', T: proto3.getEnumType(Action) },
    { no: 2, name: 'object_ids', kind: 'message', T: UUIDS, oneof: 'ids' },
    { no: 3, name: 'domain_ids', kind: 'message', T: UUIDS, oneof: 'ids' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchEnforceRequest {
    return new BatchEnforceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchEnforceRequest {
    return new BatchEnforceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchEnforceRequest {
    return new BatchEnforceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: BatchEnforceRequest | PlainMessage<BatchEnforceRequest> | undefined,
    b: BatchEnforceRequest | PlainMessage<BatchEnforceRequest> | undefined
  ): boolean {
    return proto3.util.equals(BatchEnforceRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.BatchEnforceResponse
 */
export class BatchEnforceResponse extends Message<BatchEnforceResponse> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  /**
   * @generated from oneof iam.auth.v1.BatchEnforceResponse.ids
   */
  ids:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS object_ids = 2;
         */
        value: UUIDS;
        case: 'objectIds';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS domain_ids = 3;
         */
        value: UUIDS;
        case: 'domainIds';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<BatchEnforceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.BatchEnforceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'object_ids', kind: 'message', T: UUIDS, oneof: 'ids' },
    { no: 3, name: 'domain_ids', kind: 'message', T: UUIDS, oneof: 'ids' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchEnforceResponse {
    return new BatchEnforceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchEnforceResponse {
    return new BatchEnforceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): BatchEnforceResponse {
    return new BatchEnforceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: BatchEnforceResponse | PlainMessage<BatchEnforceResponse> | undefined,
    b: BatchEnforceResponse | PlainMessage<BatchEnforceResponse> | undefined
  ): boolean {
    return proto3.util.equals(BatchEnforceResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AdminEnforceRequest
 */
export class AdminEnforceRequest extends Message<AdminEnforceRequest> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  constructor(data?: PartialMessage<AdminEnforceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AdminEnforceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminEnforceRequest {
    return new AdminEnforceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminEnforceRequest {
    return new AdminEnforceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AdminEnforceRequest {
    return new AdminEnforceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AdminEnforceRequest | PlainMessage<AdminEnforceRequest> | undefined,
    b: AdminEnforceRequest | PlainMessage<AdminEnforceRequest> | undefined
  ): boolean {
    return proto3.util.equals(AdminEnforceRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AdminEnforceResponse
 */
export class AdminEnforceResponse extends Message<AdminEnforceResponse> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  constructor(data?: PartialMessage<AdminEnforceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AdminEnforceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdminEnforceResponse {
    return new AdminEnforceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdminEnforceResponse {
    return new AdminEnforceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AdminEnforceResponse {
    return new AdminEnforceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AdminEnforceResponse | PlainMessage<AdminEnforceResponse> | undefined,
    b: AdminEnforceResponse | PlainMessage<AdminEnforceResponse> | undefined
  ): boolean {
    return proto3.util.equals(AdminEnforceResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AttachRoleRequest
 */
export class AttachRoleRequest extends Message<AttachRoleRequest> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  /**
   * @generated from field: string domain_id = 2;
   */
  domainId = '';

  /**
   * @generated from field: iam.auth.v1.IAMRole role = 3;
   */
  role = IAMRole.IAM_ROLE_UNSPECIFIED;

  constructor(data?: PartialMessage<AttachRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AttachRoleRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'role', kind: 'enum', T: proto3.getEnumType(IAMRole) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttachRoleRequest {
    return new AttachRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttachRoleRequest {
    return new AttachRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AttachRoleRequest {
    return new AttachRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AttachRoleRequest | PlainMessage<AttachRoleRequest> | undefined,
    b: AttachRoleRequest | PlainMessage<AttachRoleRequest> | undefined
  ): boolean {
    return proto3.util.equals(AttachRoleRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AttachRoleResponse
 */
export class AttachRoleResponse extends Message<AttachRoleResponse> {
  constructor(data?: PartialMessage<AttachRoleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AttachRoleResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttachRoleResponse {
    return new AttachRoleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttachRoleResponse {
    return new AttachRoleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AttachRoleResponse {
    return new AttachRoleResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AttachRoleResponse | PlainMessage<AttachRoleResponse> | undefined,
    b: AttachRoleResponse | PlainMessage<AttachRoleResponse> | undefined
  ): boolean {
    return proto3.util.equals(AttachRoleResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RoleAttachement
 */
export class RoleAttachement extends Message<RoleAttachement> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  /**
   * @generated from field: iam.auth.v1.IAMRole role = 2;
   */
  role = IAMRole.IAM_ROLE_UNSPECIFIED;

  /**
   * @generated from field: grpc.type.v1.UUIDS subject_ids = 3;
   */
  subjectIds?: UUIDS;

  constructor(data?: PartialMessage<RoleAttachement>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RoleAttachement';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'role', kind: 'enum', T: proto3.getEnumType(IAMRole) },
    { no: 3, name: 'subject_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleAttachement {
    return new RoleAttachement().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleAttachement {
    return new RoleAttachement().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleAttachement {
    return new RoleAttachement().fromJsonString(jsonString, options);
  }

  static equals(
    a: RoleAttachement | PlainMessage<RoleAttachement> | undefined,
    b: RoleAttachement | PlainMessage<RoleAttachement> | undefined
  ): boolean {
    return proto3.util.equals(RoleAttachement, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AttachRolesRequest
 */
export class AttachRolesRequest extends Message<AttachRolesRequest> {
  /**
   * @generated from field: iam.auth.v1.RoleAttachement role_attachement = 1;
   */
  roleAttachement?: RoleAttachement;

  constructor(data?: PartialMessage<AttachRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AttachRolesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_attachement', kind: 'message', T: RoleAttachement },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttachRolesRequest {
    return new AttachRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttachRolesRequest {
    return new AttachRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AttachRolesRequest {
    return new AttachRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AttachRolesRequest | PlainMessage<AttachRolesRequest> | undefined,
    b: AttachRolesRequest | PlainMessage<AttachRolesRequest> | undefined
  ): boolean {
    return proto3.util.equals(AttachRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AttachRolesResponse
 */
export class AttachRolesResponse extends Message<AttachRolesResponse> {
  constructor(data?: PartialMessage<AttachRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AttachRolesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AttachRolesResponse {
    return new AttachRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AttachRolesResponse {
    return new AttachRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AttachRolesResponse {
    return new AttachRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AttachRolesResponse | PlainMessage<AttachRolesResponse> | undefined,
    b: AttachRolesResponse | PlainMessage<AttachRolesResponse> | undefined
  ): boolean {
    return proto3.util.equals(AttachRolesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DetachRolesRequest
 */
export class DetachRolesRequest extends Message<DetachRolesRequest> {
  /**
   * @generated from field: iam.auth.v1.RoleAttachement role_attachement = 1;
   */
  roleAttachement?: RoleAttachement;

  constructor(data?: PartialMessage<DetachRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DetachRolesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'role_attachement', kind: 'message', T: RoleAttachement },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DetachRolesRequest {
    return new DetachRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DetachRolesRequest {
    return new DetachRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DetachRolesRequest {
    return new DetachRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DetachRolesRequest | PlainMessage<DetachRolesRequest> | undefined,
    b: DetachRolesRequest | PlainMessage<DetachRolesRequest> | undefined
  ): boolean {
    return proto3.util.equals(DetachRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DetachRolesResponse
 */
export class DetachRolesResponse extends Message<DetachRolesResponse> {
  constructor(data?: PartialMessage<DetachRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DetachRolesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DetachRolesResponse {
    return new DetachRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DetachRolesResponse {
    return new DetachRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DetachRolesResponse {
    return new DetachRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DetachRolesResponse | PlainMessage<DetachRolesResponse> | undefined,
    b: DetachRolesResponse | PlainMessage<DetachRolesResponse> | undefined
  ): boolean {
    return proto3.util.equals(DetachRolesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveSubjectsFromDomainRequest
 */
export class RemoveSubjectsFromDomainRequest extends Message<RemoveSubjectsFromDomainRequest> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  /**
   * @generated from field: grpc.type.v1.UUIDS subject_ids = 2;
   */
  subjectIds?: UUIDS;

  constructor(data?: PartialMessage<RemoveSubjectsFromDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveSubjectsFromDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'subject_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveSubjectsFromDomainRequest {
    return new RemoveSubjectsFromDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveSubjectsFromDomainRequest {
    return new RemoveSubjectsFromDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveSubjectsFromDomainRequest {
    return new RemoveSubjectsFromDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveSubjectsFromDomainRequest | PlainMessage<RemoveSubjectsFromDomainRequest> | undefined,
    b: RemoveSubjectsFromDomainRequest | PlainMessage<RemoveSubjectsFromDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveSubjectsFromDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveSubjectsFromDomainResponse
 */
export class RemoveSubjectsFromDomainResponse extends Message<RemoveSubjectsFromDomainResponse> {
  constructor(data?: PartialMessage<RemoveSubjectsFromDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveSubjectsFromDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveSubjectsFromDomainResponse {
    return new RemoveSubjectsFromDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveSubjectsFromDomainResponse {
    return new RemoveSubjectsFromDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveSubjectsFromDomainResponse {
    return new RemoveSubjectsFromDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | RemoveSubjectsFromDomainResponse
      | PlainMessage<RemoveSubjectsFromDomainResponse>
      | undefined,
    b: RemoveSubjectsFromDomainResponse | PlainMessage<RemoveSubjectsFromDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveSubjectsFromDomainResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateDomainRequest
 */
export class CreateDomainRequest extends Message<CreateDomainRequest> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  constructor(data?: PartialMessage<CreateDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDomainRequest {
    return new CreateDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDomainRequest {
    return new CreateDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDomainRequest {
    return new CreateDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDomainRequest | PlainMessage<CreateDomainRequest> | undefined,
    b: CreateDomainRequest | PlainMessage<CreateDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.CreateDomainResponse
 */
export class CreateDomainResponse extends Message<CreateDomainResponse> {
  constructor(data?: PartialMessage<CreateDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.CreateDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateDomainResponse {
    return new CreateDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateDomainResponse {
    return new CreateDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDomainResponse {
    return new CreateDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDomainResponse | PlainMessage<CreateDomainResponse> | undefined,
    b: CreateDomainResponse | PlainMessage<CreateDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateDomainResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteDomainRequest
 */
export class DeleteDomainRequest extends Message<DeleteDomainRequest> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  constructor(data?: PartialMessage<DeleteDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDomainRequest {
    return new DeleteDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDomainRequest {
    return new DeleteDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDomainRequest {
    return new DeleteDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDomainRequest | PlainMessage<DeleteDomainRequest> | undefined,
    b: DeleteDomainRequest | PlainMessage<DeleteDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.DeleteDomainResponse
 */
export class DeleteDomainResponse extends Message<DeleteDomainResponse> {
  constructor(data?: PartialMessage<DeleteDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.DeleteDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDomainResponse {
    return new DeleteDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDomainResponse {
    return new DeleteDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDomainResponse {
    return new DeleteDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDomainResponse | PlainMessage<DeleteDomainResponse> | undefined,
    b: DeleteDomainResponse | PlainMessage<DeleteDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDomainResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.IAMRule
 */
export class IAMRule extends Message<IAMRule> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  /**
   * @generated from field: iam.auth.v1.IAMRole role = 2;
   */
  role = IAMRole.IAM_ROLE_UNSPECIFIED;

  /**
   * @generated from field: string object_id = 3;
   */
  objectId = '';

  /**
   * @generated from field: iam.auth.v1.Action action = 4;
   */
  action = Action.UNSPECIFIED;

  constructor(data?: PartialMessage<IAMRule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.IAMRule';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'role', kind: 'enum', T: proto3.getEnumType(IAMRole) },
    { no: 3, name: 'object_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'action', kind: 'enum', T: proto3.getEnumType(Action) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IAMRule {
    return new IAMRule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IAMRule {
    return new IAMRule().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IAMRule {
    return new IAMRule().fromJsonString(jsonString, options);
  }

  static equals(
    a: IAMRule | PlainMessage<IAMRule> | undefined,
    b: IAMRule | PlainMessage<IAMRule> | undefined
  ): boolean {
    return proto3.util.equals(IAMRule, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SetPermissionsRequest
 */
export class SetPermissionsRequest extends Message<SetPermissionsRequest> {
  /**
   * @generated from field: repeated iam.auth.v1.IAMRule rules = 1;
   */
  rules: IAMRule[] = [];

  /**
   * @generated from field: bool return_manager_ids = 2;
   */
  returnManagerIds = false;

  constructor(data?: PartialMessage<SetPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SetPermissionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rules', kind: 'message', T: IAMRule, repeated: true },
    { no: 2, name: 'return_manager_ids', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetPermissionsRequest {
    return new SetPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetPermissionsRequest {
    return new SetPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetPermissionsRequest {
    return new SetPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetPermissionsRequest | PlainMessage<SetPermissionsRequest> | undefined,
    b: SetPermissionsRequest | PlainMessage<SetPermissionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetPermissionsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.SetPermissionsResponse
 */
export class SetPermissionsResponse extends Message<SetPermissionsResponse> {
  /**
   * @generated from field: grpc.type.v1.UUIDS manager_ids = 1;
   */
  managerIds?: UUIDS;

  constructor(data?: PartialMessage<SetPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.SetPermissionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'manager_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SetPermissionsResponse {
    return new SetPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SetPermissionsResponse {
    return new SetPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SetPermissionsResponse {
    return new SetPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetPermissionsResponse | PlainMessage<SetPermissionsResponse> | undefined,
    b: SetPermissionsResponse | PlainMessage<SetPermissionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetPermissionsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemovePermissionsRequest
 */
export class RemovePermissionsRequest extends Message<RemovePermissionsRequest> {
  /**
   * @generated from field: repeated iam.auth.v1.IAMRule rules = 1;
   */
  rules: IAMRule[] = [];

  /**
   * @generated from field: bool return_manager_ids = 2;
   */
  returnManagerIds = false;

  constructor(data?: PartialMessage<RemovePermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemovePermissionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rules', kind: 'message', T: IAMRule, repeated: true },
    { no: 2, name: 'return_manager_ids', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemovePermissionsRequest {
    return new RemovePermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemovePermissionsRequest {
    return new RemovePermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemovePermissionsRequest {
    return new RemovePermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemovePermissionsRequest | PlainMessage<RemovePermissionsRequest> | undefined,
    b: RemovePermissionsRequest | PlainMessage<RemovePermissionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemovePermissionsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemovePermissionsResponse
 */
export class RemovePermissionsResponse extends Message<RemovePermissionsResponse> {
  /**
   * @generated from field: grpc.type.v1.UUIDS manager_ids = 1;
   */
  managerIds?: UUIDS;

  constructor(data?: PartialMessage<RemovePermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemovePermissionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'manager_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemovePermissionsResponse {
    return new RemovePermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemovePermissionsResponse {
    return new RemovePermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemovePermissionsResponse {
    return new RemovePermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemovePermissionsResponse | PlainMessage<RemovePermissionsResponse> | undefined,
    b: RemovePermissionsResponse | PlainMessage<RemovePermissionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemovePermissionsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveObjectPermissionsRequest
 */
export class RemoveObjectPermissionsRequest extends Message<RemoveObjectPermissionsRequest> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = '';

  constructor(data?: PartialMessage<RemoveObjectPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveObjectPermissionsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveObjectPermissionsRequest {
    return new RemoveObjectPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveObjectPermissionsRequest {
    return new RemoveObjectPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveObjectPermissionsRequest {
    return new RemoveObjectPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveObjectPermissionsRequest | PlainMessage<RemoveObjectPermissionsRequest> | undefined,
    b: RemoveObjectPermissionsRequest | PlainMessage<RemoveObjectPermissionsRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveObjectPermissionsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveObjectPermissionsResponse
 */
export class RemoveObjectPermissionsResponse extends Message<RemoveObjectPermissionsResponse> {
  constructor(data?: PartialMessage<RemoveObjectPermissionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveObjectPermissionsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RemoveObjectPermissionsResponse {
    return new RemoveObjectPermissionsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RemoveObjectPermissionsResponse {
    return new RemoveObjectPermissionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveObjectPermissionsResponse {
    return new RemoveObjectPermissionsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveObjectPermissionsResponse | PlainMessage<RemoveObjectPermissionsResponse> | undefined,
    b: RemoveObjectPermissionsResponse | PlainMessage<RemoveObjectPermissionsResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveObjectPermissionsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.Roles
 */
export class Roles extends Message<Roles> {
  /**
   * @generated from field: repeated iam.auth.v1.IAMRole roles = 1;
   */
  roles: IAMRole[] = [];

  constructor(data?: PartialMessage<Roles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.Roles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'roles', kind: 'enum', T: proto3.getEnumType(IAMRole), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Roles {
    return new Roles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Roles {
    return new Roles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Roles {
    return new Roles().fromJsonString(jsonString, options);
  }

  static equals(
    a: Roles | PlainMessage<Roles> | undefined,
    b: Roles | PlainMessage<Roles> | undefined
  ): boolean {
    return proto3.util.equals(Roles, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListMembersRolesRequest
 */
export class ListMembersRolesRequest extends Message<ListMembersRolesRequest> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  /**
   * @generated from oneof iam.auth.v1.ListMembersRolesRequest.selector
   */
  selector:
    | {
        /**
         * @generated from field: grpc.type.v1.UUIDS subject_ids = 2;
         */
        value: UUIDS;
        case: 'subjectIds';
      }
    | {
        /**
         * @generated from field: iam.auth.v1.Roles roles = 3;
         */
        value: Roles;
        case: 'roles';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListMembersRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListMembersRolesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'subject_ids', kind: 'message', T: UUIDS, oneof: 'selector' },
    { no: 3, name: 'roles', kind: 'message', T: Roles, oneof: 'selector' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersRolesRequest {
    return new ListMembersRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesRequest {
    return new ListMembersRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesRequest {
    return new ListMembersRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersRolesRequest | PlainMessage<ListMembersRolesRequest> | undefined,
    b: ListMembersRolesRequest | PlainMessage<ListMembersRolesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersRolesRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListMembersRolesResponse
 */
export class ListMembersRolesResponse extends Message<ListMembersRolesResponse> {
  /**
   * @generated from field: repeated iam.auth.v1.ListMembersRolesResponse.User users = 1;
   */
  users: ListMembersRolesResponse_User[] = [];

  constructor(data?: PartialMessage<ListMembersRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListMembersRolesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'users', kind: 'message', T: ListMembersRolesResponse_User, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersRolesResponse {
    return new ListMembersRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesResponse {
    return new ListMembersRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesResponse {
    return new ListMembersRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersRolesResponse | PlainMessage<ListMembersRolesResponse> | undefined,
    b: ListMembersRolesResponse | PlainMessage<ListMembersRolesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersRolesResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListMembersRolesResponse.User
 */
export class ListMembersRolesResponse_User extends Message<ListMembersRolesResponse_User> {
  /**
   * @generated from field: string id = 1;
   */
  id = '';

  /**
   * @generated from field: iam.auth.v1.Roles roles = 3;
   */
  roles?: Roles;

  constructor(data?: PartialMessage<ListMembersRolesResponse_User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListMembersRolesResponse.User';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'roles', kind: 'message', T: Roles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersRolesResponse_User {
    return new ListMembersRolesResponse_User().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesResponse_User {
    return new ListMembersRolesResponse_User().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersRolesResponse_User {
    return new ListMembersRolesResponse_User().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersRolesResponse_User | PlainMessage<ListMembersRolesResponse_User> | undefined,
    b: ListMembersRolesResponse_User | PlainMessage<ListMembersRolesResponse_User> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersRolesResponse_User, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AddMemberRequest
 */
export class AddMemberRequest extends Message<AddMemberRequest> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  /**
   * @generated from field: string domain_id = 2;
   */
  domainId = '';

  constructor(data?: PartialMessage<AddMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AddMemberRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberRequest {
    return new AddMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddMemberRequest | PlainMessage<AddMemberRequest> | undefined,
    b: AddMemberRequest | PlainMessage<AddMemberRequest> | undefined
  ): boolean {
    return proto3.util.equals(AddMemberRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.AddMemberResponse
 */
export class AddMemberResponse extends Message<AddMemberResponse> {
  constructor(data?: PartialMessage<AddMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.AddMemberResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddMemberResponse {
    return new AddMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: AddMemberResponse | PlainMessage<AddMemberResponse> | undefined,
    b: AddMemberResponse | PlainMessage<AddMemberResponse> | undefined
  ): boolean {
    return proto3.util.equals(AddMemberResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveMemberRequest
 */
export class RemoveMemberRequest extends Message<RemoveMemberRequest> {
  /**
   * @generated from field: string subject_id = 1;
   */
  subjectId = '';

  /**
   * @generated from field: string domain_id = 2;
   */
  domainId = '';

  constructor(data?: PartialMessage<RemoveMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveMemberRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined,
    b: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.RemoveMemberResponse
 */
export class RemoveMemberResponse extends Message<RemoveMemberResponse> {
  constructor(data?: PartialMessage<RemoveMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.RemoveMemberResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined,
    b: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListManagedDomainsRequest
 */
export class ListManagedDomainsRequest extends Message<ListManagedDomainsRequest> {
  constructor(data?: PartialMessage<ListManagedDomainsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListManagedDomainsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListManagedDomainsRequest {
    return new ListManagedDomainsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListManagedDomainsRequest {
    return new ListManagedDomainsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListManagedDomainsRequest {
    return new ListManagedDomainsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListManagedDomainsRequest | PlainMessage<ListManagedDomainsRequest> | undefined,
    b: ListManagedDomainsRequest | PlainMessage<ListManagedDomainsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListManagedDomainsRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.ListManagedDomainsResponse
 */
export class ListManagedDomainsResponse extends Message<ListManagedDomainsResponse> {
  /**
   * @generated from field: grpc.type.v1.UUIDS domain_ids = 1;
   */
  domainIds?: UUIDS;

  constructor(data?: PartialMessage<ListManagedDomainsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.ListManagedDomainsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListManagedDomainsResponse {
    return new ListManagedDomainsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListManagedDomainsResponse {
    return new ListManagedDomainsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListManagedDomainsResponse {
    return new ListManagedDomainsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListManagedDomainsResponse | PlainMessage<ListManagedDomainsResponse> | undefined,
    b: ListManagedDomainsResponse | PlainMessage<ListManagedDomainsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListManagedDomainsResponse, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GetObjectDomainRequest
 */
export class GetObjectDomainRequest extends Message<GetObjectDomainRequest> {
  /**
   * @generated from field: string object_id = 1;
   */
  objectId = '';

  constructor(data?: PartialMessage<GetObjectDomainRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GetObjectDomainRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'object_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetObjectDomainRequest {
    return new GetObjectDomainRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetObjectDomainRequest {
    return new GetObjectDomainRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetObjectDomainRequest {
    return new GetObjectDomainRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetObjectDomainRequest | PlainMessage<GetObjectDomainRequest> | undefined,
    b: GetObjectDomainRequest | PlainMessage<GetObjectDomainRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetObjectDomainRequest, a, b);
  }
}

/**
 * @generated from message iam.auth.v1.GetObjectDomainResponse
 */
export class GetObjectDomainResponse extends Message<GetObjectDomainResponse> {
  /**
   * @generated from field: string domain_id = 1;
   */
  domainId = '';

  constructor(data?: PartialMessage<GetObjectDomainResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'iam.auth.v1.GetObjectDomainResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'domain_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetObjectDomainResponse {
    return new GetObjectDomainResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetObjectDomainResponse {
    return new GetObjectDomainResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetObjectDomainResponse {
    return new GetObjectDomainResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetObjectDomainResponse | PlainMessage<GetObjectDomainResponse> | undefined,
    b: GetObjectDomainResponse | PlainMessage<GetObjectDomainResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetObjectDomainResponse, a, b);
  }
}
