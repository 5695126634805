import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useProfiler } from '@store/profiler';

type ConfirmLeaveOrgDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId: string;
};

const ConfirmLeaveOrgDialog = (props: ConfirmLeaveOrgDialogProps) => {
  const { isOpen, handleClose, entityId } = props;
  const { leaveMembership } = useProfiler();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box sx={{ backgroundColor: '#3f3d50', width: '600px' }} py={5} px={{ xs: 3 }}>
        <Typography textAlign="center" mb={5} variant="subtitle1">
          Are sure you want leave?
        </Typography>
        <Box display="flex" gap={2} justifyContent="center">
          <Button onClick={() => leaveMembership({ entityId })} variant="outlined">
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmLeaveOrgDialog;
