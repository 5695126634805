import qs from 'qs';

import GoogleLogo from '@assets/images/icons/google.svg';
import GithubLogo from '@assets/images/icons/github.svg';

export enum OAuthAppTypes {
  google = 'google',
  github = 'github',
}

export const useOAuthAppSignIn = (type: OAuthAppTypes) => {
  // const { googleSignIn, githubSignIn } = useAuth();
  // switch (type) {
  //     // case OAuthAppTypes.google: {
  //     //   return googleSignIn;
  //     // }
  //     // case OAuthAppTypes.github: {
  //     //   return githubSignIn;
  //     // }
  //     default: {
  //         throw Error('Unknown OAuth app type');
  //     }
  // }
};

export const oAuthAppTypeQueryParam = 'oauth_app';
const getRedirectUri = (type: OAuthAppTypes): string => {
  const redirectUriParams = qs.stringify({ [oAuthAppTypeQueryParam]: type });
  return `${window.location.href}?${redirectUriParams}`;
};

export const getOAuthCodeRequestUrl = (type: OAuthAppTypes): string => {
  const redirectUri = getRedirectUri(type);

  switch (type) {
    case OAuthAppTypes.google: {
      const params = qs.stringify({
        scope: 'https://www.googleapis.com/auth/userinfo.email',
        response_type: 'code',
        redirect_uri: redirectUri,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      });
      return `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
    }
    case OAuthAppTypes.github: {
      const oAuthCodeRequestUrlParams = qs.stringify({
        scope: 'user:email',
        redirect_uri: redirectUri,
        client_id: process.env.REACT_APP_GITHUB_CLIENT_ID,
      });
      return `https://github.com/login/oauth/authorize?${oAuthCodeRequestUrlParams}`;
    }
    default: {
      throw Error('Unknown OAuth app type');
    }
  }
};

export const getButtonText = (type: OAuthAppTypes): string => {
  switch (type) {
    case OAuthAppTypes.google: {
      return 'Continue with Google';
    }
    case OAuthAppTypes.github: {
      return 'Continue with GitHub';
    }
    default: {
      throw Error('Unknown OAuth app type');
    }
  }
};

export const getButtonLogo = (type: OAuthAppTypes): string => {
  switch (type) {
    case OAuthAppTypes.google: {
      return GoogleLogo;
    }
    case OAuthAppTypes.github: {
      return GithubLogo;
    }
    default: {
      throw Error('Unknown OAuth app type');
    }
  }
};
