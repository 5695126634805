// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file iam/auth/v1/auth.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  PreSignRequest,
  PreSignResponse,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
} from './auth_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service iam.auth.v1.AuthService
 */
export const AuthService = {
  typeName: 'iam.auth.v1.AuthService',
  methods: {
    /**
     * @generated from rpc iam.auth.v1.AuthService.PreSign
     */
    preSign: {
      name: 'PreSign',
      I: PreSignRequest,
      O: PreSignResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.AuthService.SignIn
     */
    signIn: {
      name: 'SignIn',
      I: SignInRequest,
      O: SignInResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.AuthService.SignOut
     */
    signOut: {
      name: 'SignOut',
      I: SignOutRequest,
      O: SignOutResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
