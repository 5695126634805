import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RolesAutocomplete from '@components/RolesAutocomplete/RolesAutocomplete';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProfiler } from '@store/profiler';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';

type CreateGroupingDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  member?: any | undefined;
};

const ManageMemberDialog = (props: CreateGroupingDialogProps) => {
  const { isOpen, handleClose, member } = props;
  const { entityId } = useParams();
  const { listRoles, removeMember } = useProfiler();

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const handleConfirmOpen = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    // Тут логіка, що виконується після підтвердження
    if (entityId) removeMember({ entityId, profileId: member?.profileId });
    handleConfirmClose(); // Закрити діалог
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        sx={{ width: { xs: '300px', sm: '400px', md: '600px' }, backgroundColor: '#3f3d50' }}
        py={5}
        px={{ xs: 3 }}
      >
        <Typography textAlign="center" mb={5} variant="subtitle1">
          Configure
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          {entityId !== undefined && (
            <RolesAutocomplete
              subjectId={member?.profileId}
              entityId={entityId}
              defaultRoles={
                listRoles[entityId]?.find((role) => role.id === member?.profileId)?.roles.roles
              }
            />
          )}
          {entityId && <DeleteForeverIcon cursor="pointer" onClick={handleConfirmOpen} />}
        </Box>

        <ConfirmDialog
          isOpen={isConfirmOpen}
          onClose={handleConfirmClose}
          onConfirm={handleConfirm}
          title="Action confirmation"
          message="Are you sure you want to continue?"
        />
      </Box>
    </Dialog>
  );
};

export default ManageMemberDialog;
