import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ListAchievementsRequest,
  ListAchievementsResponse,
  ListProgressRequest,
  ListProgressResponse,
  UpdateProgressRequest,
  UpdateProgressResponse,
} from '@proto/rewarder/achievements/v1/achievements_pb';
import { getClient } from '@services/api/helpers';
import { AchievementService } from '@proto/rewarder/achievements/v1/achievements_connect';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';

export const achievementsClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_REWARDER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AchievementService
);

export const useListAchievements = () => {
  const listAchievements = async (): Promise<ListAchievementsResponse> => {
    const request = new ListAchievementsRequest();
    return achievementsClient.listAchievements(request);
  };

  return useQuery({
    queryKey: ['listAchievements'],
    queryFn: listAchievements,
  });
};

export const useListProgress = (payload: UUIDS) => {
  const listProgress = async (): Promise<ListProgressResponse> => {
    const request = new ListProgressRequest({
      entities: payload,
    });
    return achievementsClient.listProgress(request);
  };

  return useQuery({
    queryKey: ['listProgress', payload],
    queryFn: listProgress,
  });
};

export const useUpdateProgress = () => {
  const updateProgress = async (
    payload: UpdateProgressRequest
  ): Promise<UpdateProgressResponse> => {
    console.log(payload);
    return achievementsClient.updateProgress(payload);
  };

  return useMutation({
    mutationFn: updateProgress,
  });
};
