import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import PinUnpinButton from '@components/PinUnpinButton/PinUnpinButton';

const typesDemand = {
  contract: TypeOfEntity.CONTRACT,
  interview: TypeOfEntity.INTERVIEW,
};

const DemandCard = ({
  demand,
  isPreApplied,
  showActiveState,
  errors,
  recommendations,
}: {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
  errors?: { [key: string]: boolean };
  recommendations: any;
}) => {
  const { demandIdFromUrl, setDemandId } = useMarketplace();
  const demandId = getDemandId(demand);
  const matches =
    (demandId &&
      Object?.values(
        // @ts-ignore
        recommendations?.recommendations[
          // @ts-ignore
          demandId as keyof typeof recommendations.recommendations
        ]?.recommendations || {}
        // @ts-ignore
      ).map((recommendation) => recommendation?.suppliers.map((supplier) => supplier))) ||
    [];

  const toggleDemand = () => {
    setDemandId({ demandId: demandId || '', demandType: demand?.entity?.case || '' });
  };
  console.log('bdsabdsabsdabasdb', demand?.entity?.case);
  return (
    <Box className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}>
      {isPreApplied && <ContractorSelect contractId={demandId} />}
      {Object.keys(errors || {}).some((key) => key === demandId) ? 'Choose contractor' : null}
      <Card>
        <Box display="flex" gap={0.5} flexDirection="column" justifyContent="center">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              {demand?.entity?.case && demandId && (
                <PinUnpinButton type={typesDemand[demand?.entity?.case]} id={demandId} />
              )}
              <Typography fontSize="13px" fontWeight="bold">
                {demand?.entity?.case?.toUpperCase()}
              </Typography>
            </Box>
            <Typography fontSize="13px">
              {format(new Date(demand.entity.value?.createdAt?.toDate() || ''), 'dd/MM/yyyy')}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            gap={1}
            justifyContent="space-between"
            sx={{ cursor: 'pointer' }}
            onClick={toggleDemand}
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <DemandTraits
                matches={matches.length && matches[0][0]?.traits}
                flexDirection="row"
                fontSize="13px"
                gap={2}
                demand={demand.entity.value}
              />
              {demand.entity.value?.tags?.tags && (
                <Tags
                  matchedTags={matches.length && matches[0][0]?.matchedTags?.tags}
                  showType
                  tags={demand.entity.value?.tags?.tags}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default DemandCard;
