import { combineReducers } from '@reduxjs/toolkit';
import {
  AddCandidateServiceRequest,
  AddCandidateServiceResponse,
  ApplyAsInterviewerServiceRequest,
  ApplyAsInterviewerServiceResponse,
  ApproveContractorServiceRequest,
  ApproveContractorServiceResponse,
  ApproveInterviewerServiceRequest,
  ApproveInterviewerServiceResponse,
  CreateContractServiceRequest,
  CreateContractServiceResponse,
  CreateInterviewServiceRequest,
  CreateInterviewServiceResponse,
  DeleteContractServiceRequest,
  DeleteContractServiceResponse,
  DeleteInterviewServiceRequest,
  DeleteInterviewServiceResponse,
  demandService,
  GetContractServiceRequest,
  GetContractServiceResponse,
  GetDemandServiceRequest,
  GetDemandServiceResponse,
  GetEvaluationServiceRequest,
  GetEvaluationServiceResponse,
  GetInterviewServiceRequest,
  GetInterviewServiceResponse,
  InviteCandidatesServiceRequest,
  InviteCandidatesServiceResponse,
  ListAppliedDemandsServiceResponse,
  ListCandidatesServiceRequest,
  ListCandidatesServiceResponse,
  ListEvaluationServiceResponse,
  RejectContractorServiceRequest,
  RejectContractorServiceResponse,
  RejectInterviewerServiceRequest,
  RejectInterviewerServiceResponse,
  RevokeContractorApplyServiceRequest,
  RevokeContractorApplyServiceResponse,
  RevokeInterviewerApplyServiceRequest,
  RevokeInterviewerApplyServiceResponse,
  SaveEvaluationFeedbackDraftServiceRequest,
  SaveEvaluationFeedbackDraftServiceResponse,
  SendEvaluationFeedbackServiceRequest,
  SendEvaluationFeedbackServiceResponse,
  SetDemandStatusServiceRequest,
  SetDemandStatusServiceResponse,
  UpdateContractDescriptionServiceRequest,
  UpdateContractDescriptionServiceResponse,
  UpdateContractTraitsServiceRequest,
  UpdateContractTraitsServiceResponse,
  UpdateInterviewRequirementsServiceRequest,
  UpdateInterviewRequirementsServiceResponse,
  UpdateInterviewServiceRequest,
  UpdateInterviewServiceResponse,
} from '@services/api/marketplace/demand';
import { marketplaceSlice } from '@store/marketplace/slices';
import { TEntity } from '@utils/transformPinItemsToMap';

import {
  ContractTraits,
  CreateContractRequest,
  DeleteContractRequest,
  GetContractRequest,
  UpdateContractDescriptionRequest,
  UpdateContractTraitsRequest,
} from '@proto/marketplace/demand/v1/contract_pb';
import { useCallback } from 'react';
import {
  CreateDashboardServiceRequest,
  CreateDashboardServiceResponse,
  dashboardService,
  GetDashboardServiceRequest,
  GetDashboardServiceResponse,
  ListDashboardsServiceResponse,
  ListFeedItemsServiceRequest,
  ListFeedItemsServiceResponse,
  UpdateDashboardServiceRequest,
  UpdateDashboardServiceResponse,
} from '@services/api/marketplace/dashboard';
import {
  CreateDashboardRequest,
  GetDashboardRequest,
  ListFeedItemsRequest,
  UpdateDashboardRequest,
} from '@proto/marketplace/dashboard/v1/dashboad_pb';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';
import {
  EvaluationMap,
  Owner,
  TDashboardMapItem,
  TEvaluation,
  TKanbanBoards,
  TRecommendations,
  TUnitedDemand,
} from '@store/marketplace/marketplace';
import {
  filterSuppliersByStatus,
  getTagsFromPayload,
  organizeDemands,
  TCustomDemands,
  UpdateContractForm,
} from '@store/marketplace/helpers';
import { TCreateContractForm } from '@pages/Marketplace/Dashboards/CreateContracForm';
import {
  CreateInterviewRequest,
  CreateInterviewRequest_NewInterview,
  DeleteInterviewRequest,
  GetInterviewRequest,
  InterviewTraits,
  UpdateInterviewRequest,
  UpdateInterviewRequirementsRequest,
} from '@proto/marketplace/demand/v1/interview_pb';
import { GetDemandRequest, SetDemandStatusRequest } from '@proto/marketplace/demand/v1/demand_pb';
import {
  ApproveContractorRequest,
  MatchedContracts,
  RejectContractorRequest,
} from '@proto/marketplace/demand/v1/contractor_pb';
import {
  ApplyAsInterviewerRequest,
  ApproveInterviewerRequest,
  RejectInterviewerRequest,
} from '@proto/marketplace/demand/v1/interviewer_pb';
import { ObjectParam, useQueryParam } from 'use-query-params';
import { Timestamp } from '@bufbuild/protobuf';
import { TOrg } from '@services/api/profiler/biz';
import {
  ListProfilesServiceRequest,
  ListProfilesServiceResponse,
  profileService,
  TProfile,
} from '@services/api/profiler/profile';
import { showSnackbar } from '@store/snackbars';
import { userSlice } from '@store/profiler/slices';
import { ListProfilesRequest, Profile } from '@proto/profiler/user/v1/profile_pb';
import {
  AddCandidateRequest,
  InviteCandidatesRequest,
  ListCandidatesRequest,
} from '@proto/marketplace/demand/v1/candidate_pb';
import { getEntityInvitesList, getListProfiles } from '@store/profiler';
import { Entity, TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { ListInvitesRequest } from '@proto/profiler/invite/v1/invite_pb';
import {
  GetEvaluationRequest,
  SaveEvaluationFeedbackDraftRequest,
  SendEvaluationFeedbackRequest,
} from '@proto/marketplace/demand/v1/evaluation_pb';
import { TCreateInterviewForm } from '@pages/Marketplace/ManageDemands/settings';
import { SortedDemandsBySupply } from '@services/api/marketplace/config';
import { Org } from '@proto/profiler/biz/v1/org_pb';
import { createServiceCall, useAppDispatch, useAppSelector } from '../helpers';

const {
  setDashboards,
  setFeedRecommendations,
  setFeedItems,
  setDashboardsMap,
  setDisplayListType,
  addPreAppliesContracts,
  deletePreAppliesContracts,
  setChosenContract,
  setAppliedDemands,
  setFeedItemsByContract,
  setFeedOwner,
  setFoundDemand,
  setCandidatesList,
  setListEvaluations,
  setListInterviewEvaluations,
  setListEvaluationsByManager,
  setCurrentContractorId,
  setContractors,
} = marketplaceSlice.actions;

const {
  actions: { setUserProfiles },
} = userSlice;

export const marketplaceReducer = combineReducers({
  marketplace: marketplaceSlice.reducer,
});

const listDashboards = createServiceCall<ListDashboardsServiceResponse, never>(
  marketplaceSlice.name,
  dashboardService.listDashboards,
  ({ response, dispatch }) => {
    if (response.dashboards) dispatch(setDashboards(response.dashboards));
  }
);

const createDashboard = createServiceCall<
  CreateDashboardServiceResponse,
  CreateDashboardServiceRequest
>(
  marketplaceSlice.name,
  dashboardService.createDashboard,
  ({ response, dispatch }) => {
    dispatch(listDashboards());
    dispatch(
      showSnackbar({
        id: 'create-dashboard',
        message: 'Dashboard created successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'create-dashboard',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const getKanbanBoard = createServiceCall<GetDashboardServiceResponse, GetDashboardServiceRequest>(
  marketplaceSlice.name,
  dashboardService.getDashboard,
  ({ response, dispatch }) => {
    if (response)
      dispatch(
        setDashboardsMap({
          [response.kanban.dashboardId]: {
            demands: organizeDemands(response?.demands?.demands || []),
            kanbanBoard: response.kanban,
            recommendations: response.demands?.recommendations,
          },
        })
      );
  }
);

const updateDashboardInfo = createServiceCall<
  UpdateDashboardServiceResponse,
  UpdateDashboardServiceRequest
>(marketplaceSlice.name, dashboardService.updateDashboard, ({ payload, dispatch }) => {
  dispatch(getKanbanBoard(new GetDashboardRequest({ dashboardId: payload.dashboardId })));
});

const listFeedItems = createServiceCall<ListFeedItemsServiceResponse, ListFeedItemsServiceRequest>(
  marketplaceSlice.name,
  dashboardService.listFeedItems,
  ({ response, dispatch }) => {
    if (response.demands) {
      dispatch(setFeedItems(organizeDemands(response.demands.demands)));
    }
    if (response.demands?.recommendations) {
      dispatch(setFeedRecommendations(response.demands.recommendations));
    }
  }
);

const listFeedItemsByContractId = createServiceCall<
  ListFeedItemsServiceResponse,
  ListFeedItemsServiceRequest
>(marketplaceSlice.name, dashboardService.listFeedItems, ({ response, dispatch }) => {
  if (response.demands) {
    dispatch(setFeedItemsByContract(organizeDemands(response.demands.demands)));
  }
});

const createContract = createServiceCall<
  CreateContractServiceResponse,
  CreateContractServiceRequest
>(
  marketplaceSlice.name,
  demandService.createContract,
  ({ payload, dispatch }) => {
    dispatch(getKanbanBoard(new GetDashboardRequest({ dashboardId: payload.dashboardId })));
    dispatch(
      showSnackbar({
        id: 'create-contract',
        message: 'Contract created successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'create-contract',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const getContract = createServiceCall<GetContractServiceResponse, GetContractServiceRequest>(
  marketplaceSlice.name,
  demandService.getContract
);

const updateContractTraits = createServiceCall<
  UpdateContractTraitsServiceResponse,
  UpdateContractTraitsServiceRequest
>(marketplaceSlice.name, demandService.updateContractTraits);

const updateContractDescription = createServiceCall<
  UpdateContractDescriptionServiceResponse,
  UpdateContractDescriptionServiceRequest
>(marketplaceSlice.name, demandService.updateContractDescription);

const deleteContract = createServiceCall<
  DeleteContractServiceResponse,
  DeleteContractServiceRequest
>(marketplaceSlice.name, demandService.deleteContract);

const createInterview = createServiceCall<
  CreateInterviewServiceResponse,
  CreateInterviewServiceRequest
>(
  marketplaceSlice.name,
  demandService.createInterview,
  ({ payload, dispatch }) => {
    dispatch(getKanbanBoard(new GetDashboardRequest({ dashboardId: payload.dashboardId })));
    dispatch(
      showSnackbar({
        id: 'create-interview',
        message: 'Interview created successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'create-interview',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const getInterview = createServiceCall<GetInterviewServiceResponse, GetInterviewServiceRequest>(
  marketplaceSlice.name,
  demandService.getInterview,
  ({ response, dispatch }) => {
    if (response?.interview)
      dispatch(
        setListEvaluationsByManager({
          [response?.interview.interviewId]: response.evaluations?.evaluations,
        })
      );
  }
);

export const getSuppliesProfiles = createServiceCall<
  ListProfilesServiceResponse,
  ListProfilesServiceRequest
>(userSlice.name, profileService.listProfiles, ({ response: { profiles }, dispatch }) => {
  dispatch(
    setUserProfiles(
      profiles?.profiles.reduce((acc, profile) => {
        return { ...acc, [profile.profileId]: profile };
      }, [])
    )
  );
});

const getDemand = createServiceCall<GetDemandServiceResponse, GetDemandServiceRequest>(
  marketplaceSlice.name,
  demandService.getDemand,
  ({ response, dispatch }) => {
    // @ts-ignore
    const suppliersList = response.demand?.supply?.list?.list?.reduce(
      (acc: any, currentItem: any) => {
        const { entity } = currentItem;
        if (entity) {
          if (!(entity.type in acc)) {
            acc[entity.type] = {};
          }

          acc[entity.type][entity.id] = currentItem;
          return acc;
        }
        return acc;
      },
      {}
    );

    if (suppliersList)
      Object.keys(suppliersList)?.forEach((type) => {
        // @ts-ignore
        const ids: string[] = Object.values(suppliersList[type]).map(
          // @ts-ignore
          (supplier) => supplier.entity.id
        );

        if (type === 'TYPE_OF_ENTITY_USER') {
          dispatch(
            getSuppliesProfiles(
              new ListProfilesRequest({
                profiles: { ids },
              })
            )
          );
        }
        if (type === 'TYPE_OF_ENTITY_SQUAD') {
          console.log(ids);
        }
        if (type === 'TYPE_OF_ENTITY_ORG') {
          console.log(ids);
        }
        if (type === 'TYPE_OF_ENTITY_DASHBOARD') {
          console.log(ids);
        }
      });

    if (response.demand) {
      // @ts-ignore
      const demand = response.demand.interview || response.demand.contract;
      // @ts-ignore
      const demandType = response.demand.interview ? 'interview' : 'contract';
      dispatch(
        setFoundDemand({
          [demand.contractId || demand.interviewId]: {
            id: demand.contractId || demand.interviewId,
            createdAt: demand.createdAt,
            description: demand.description,
            requirements: demand.requirements,
            interviewId: demand?.interviewId || undefined,
            traits: demand.traits,
            updatedAt: demand.updatedAt,
            type: demandType,
            tags: demand.tags,
            status: response.demand.status,
            // @ts-ignore
            supply: filterSuppliersByStatus(response.demand?.supply?.list?.list),
          },
        })
      );
    }
  }
);

const updateInterviewRequirements = createServiceCall<
  UpdateInterviewRequirementsServiceResponse,
  UpdateInterviewRequirementsServiceRequest
>(
  marketplaceSlice.name,
  demandService.updateInterviewRequirements,
  ({ dispatch, payload }) => {
    dispatch(
      getDemand(
        new GetDemandRequest({
          id: {
            case: 'interview',
            value: payload.interviewId,
          },
        })
      )
    );
    dispatch(
      showSnackbar({
        id: 'update-interview-requirements',
        message: 'Requirements updated successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'update-interview-requirements',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const deleteInterview = createServiceCall<
  DeleteInterviewServiceResponse,
  DeleteInterviewServiceRequest
>(marketplaceSlice.name, demandService.deleteInterview);

const setDemandStatus = createServiceCall<
  SetDemandStatusServiceResponse,
  SetDemandStatusServiceRequest
>(marketplaceSlice.name, demandService.setDemandStatus);

const applyAsInterviewer = createServiceCall<
  ApplyAsInterviewerServiceResponse,
  ApplyAsInterviewerServiceRequest
>(
  marketplaceSlice.name,
  demandService.applyAsInterviewer,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'apply-as-interviewer',
        message: 'You have successfully applied to the demand',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'apply-as-interviewer',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const updateInterview = createServiceCall<
  UpdateInterviewServiceResponse,
  UpdateInterviewServiceRequest
>(
  marketplaceSlice.name,
  demandService.updateInterview,
  ({ dispatch, payload }) => {
    dispatch(getDemand({ id: { case: 'interview', value: payload.interviewId } }));
    dispatch(
      showSnackbar({
        id: 'update-interview',
        message: 'Traits updated successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'update-interview',
        message: errorMessage,
        severity: 'success',
      })
    );
  }
);

const listAppliedDemands = createServiceCall<ListAppliedDemandsServiceResponse, never>(
  marketplaceSlice.name,
  demandService.listAppliedDemands,
  ({ response, dispatch }) => {
    if (response.demands) {
      const demands = organizeDemands(response.demands.demands);
      // dispatch(setAppliedDemands(sortDemandsBySupply(demands)));
    }
  }
);

const approveContractor = createServiceCall<
  ApproveContractorServiceResponse,
  ApproveContractorServiceRequest
>(
  marketplaceSlice.name,
  demandService.approveContractor,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'approve-contractor',
        message: 'Contractor approved successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'approve-contractor',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const rejectContractor = createServiceCall<
  RejectContractorServiceResponse,
  RejectContractorServiceRequest
>(
  marketplaceSlice.name,
  demandService.rejectContractor,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'reject-contractor',
        message: 'Contractor rejected successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'reject-contractor',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const approveInterviewer = createServiceCall<
  ApproveInterviewerServiceResponse,
  ApproveInterviewerServiceRequest
>(
  marketplaceSlice.name,
  demandService.approveInterviewer,
  ({ dispatch, payload }) => {
    dispatch(getDemand({ id: { case: 'interview', value: payload.interviewId } }));
    dispatch(
      showSnackbar({
        id: 'approve-interviewer',
        message: 'Interviewer approved successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'approve-interviewer',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const rejectInterviewer = createServiceCall<
  RejectInterviewerServiceResponse,
  RejectInterviewerServiceRequest
>(
  marketplaceSlice.name,
  demandService.rejectInterviewer,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'reject-interviewer',
        message: 'Interviewer rejected successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'reject-interviewer',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const revokeContractorApply = createServiceCall<
  RevokeContractorApplyServiceResponse,
  RevokeContractorApplyServiceRequest
>(
  marketplaceSlice.name,
  demandService.revokeContractorApply,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'revoke-contractor-apply',
        message: 'Contractor application revoked successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'revoke-contractor-apply',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const revokeInterviewerApply = createServiceCall<
  RevokeInterviewerApplyServiceResponse,
  RevokeInterviewerApplyServiceRequest
>(
  marketplaceSlice.name,
  demandService.revokeInterviewerApply,
  ({ dispatch }) => {
    dispatch(
      showSnackbar({
        id: 'revoke-interviewer-apply',
        message: 'Interviewer application revoked successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'revoke-interviewer-apply',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const inviteCandidates = createServiceCall<
  InviteCandidatesServiceResponse,
  InviteCandidatesServiceRequest
>(
  marketplaceSlice.name,
  demandService.inviteCandidates,
  ({ payload, dispatch }) => {
    dispatch(
      getEntityInvitesList(
        new ListInvitesRequest({
          entity: new Entity({
            id: payload.interviewId,
            type: TypeOfEntity.INTERVIEW,
          }),
        })
      )
    );
    dispatch(
      showSnackbar({
        id: 'invite-candidates',
        message: 'Candidates invited successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'invite-candidates',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const addCandidate = createServiceCall<AddCandidateServiceResponse, AddCandidateServiceRequest>(
  marketplaceSlice.name,
  demandService.addCandidate
);

const listCandidates = createServiceCall<
  ListCandidatesServiceResponse,
  ListCandidatesServiceRequest
>(marketplaceSlice.name, demandService.listCandidates, ({ payload, response, dispatch }) => {
  dispatch(setCandidatesList({ [payload.interviewId]: response.candidates?.profiles }));
  dispatch(
    getListProfiles(
      new ListProfilesRequest({
        profiles: { ids: response.candidates?.profiles.map((candidate) => candidate.profileId) },
      })
    )
  );
});

const listEvaluations = createServiceCall<ListEvaluationServiceResponse, never>(
  marketplaceSlice.name,
  demandService.listEvaluations,
  ({ response, dispatch }) => {
    dispatch(
      setListEvaluations(
        response.evaluations?.evaluations.reduce((acc, evaluation) => {
          acc[evaluation.evaluationId] = evaluation;
          return acc;
        }, {} as EvaluationMap)
      )
    );
    dispatch(
      setListInterviewEvaluations(
        response.evaluations?.evaluations.reduce((acc, evaluation) => {
          acc[evaluation.interviewId] = evaluation;
          return acc;
        }, {} as EvaluationMap)
      )
    );
  }
);

const getEvaluation = createServiceCall<GetEvaluationServiceResponse, GetEvaluationServiceRequest>(
  marketplaceSlice.name,
  demandService.getEvaluation,
  ({ response, dispatch }) => {
    dispatch(
      setListEvaluations({
        [response?.evaluation?.evaluationId || '']: response.evaluation,
      })
    );
  }
);

const sendEvaluationFeedback = createServiceCall<
  SendEvaluationFeedbackServiceResponse,
  SendEvaluationFeedbackServiceRequest
>(
  marketplaceSlice.name,
  demandService.sendEvaluationFeedback,
  ({ dispatch, payload }) => {
    dispatch(getEvaluation({ evaluationId: payload.evaluationId }));
    dispatch(
      showSnackbar({
        id: 'send-evaluation-feedback',
        message: 'Feedback sent successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'send-evaluation-feedback',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

const saveEvaluationFeedbackDraft = createServiceCall<
  SaveEvaluationFeedbackDraftServiceResponse,
  SaveEvaluationFeedbackDraftServiceRequest
>(
  marketplaceSlice.name,
  demandService.saveEvaluationFeedbackDraft,
  ({ dispatch, payload }) => {
    dispatch(getEvaluation({ evaluationId: payload.evaluationId }));
    dispatch(
      showSnackbar({
        id: 'save-evaluation-feedback-draft',
        message: 'Feedback saved successfully',
        severity: 'success',
      })
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(
      showSnackbar({
        id: 'save-evaluation-feedback-draft',
        message: errorMessage,
        severity: 'error',
      })
    );
  }
);

export const useMarketplace = (): {
  isLoading: boolean;
  listDashboards: TKanbanBoards;
  appliedDemands: SortedDemandsBySupply | undefined;
  displayListType: string;
  demandIdFromUrl: { [key: string]: string | undefined } | null | undefined;
  dashboardsMap: { [key: string]: TDashboardMapItem };
  createDashboard: (payload: { title: string; owner: TEntity }) => void;
  feedItems: TCustomDemands;
  foundDemands: { [key: string]: TUnitedDemand };
  feedItemsByContract: TCustomDemands;
  feedRecommendations: TRecommendations;
  feedOwner: { [key: string]: Owner } | null;
  preAppliesContracts: string[];
  chosenContract: string | undefined;
  candidatesList: { [key: string]: TProfile[] };
  evaluationList: { [key: string]: TEvaluation };
  evaluationInterviewList: EvaluationMap;
  evaluationsByManager: { [key: string]: TEvaluation[] };
  currentContractorId: { id: string; case: 'orgId' | 'squadId' } | undefined;
  contractors: MatchedContracts[] | undefined;

  createContract: (payload: TCreateContractForm) => void;
  getContract: (payload: string) => void;
  getInterview: (payload: string) => void;
  createInterview: (payload: TCreateInterviewForm) => void;
  createInterviewForContract: (payload: {
    dashboardId: string;
    contractId: string;
    requirements: string;
  }) => void;
  getListDashboards: () => void;
  updateDashboardInfo: (payload: { dashboardId: string; title: string }) => void;
  getCurrentDashboard: (id: string) => void;
  listFeedItems: (payload?: { entity: TEntity }) => void;
  listFeedItemsByContractId: (payload: { entity: TEntity }) => void;
  updateContractTraits: (payload: UpdateContractForm) => void;
  updateContractDescription: (payload: { description: string; contractId: string }) => void;
  updateInterviewInfo: (payload: any) => void;
  deleteContract: (payload: string) => void;
  deleteInterview: (payload: string) => void;
  setDemandStatus: (payload: { demandId: string; status: string }) => void;
  setDisplayListType: (payload: string) => void;
  addPreAppliesContracts: (payload: string) => void;
  setCurrentContractorId: (payload: { id: string; case: 'org' | 'squad' }) => void;
  setContractors: (payload: MatchedContracts[]) => void;
  deletePreAppliesContracts: (payload: string) => void;
  applyAsContractor: (payload: {
    contractIds: string[];
    contractor: { case: 'profileId' | 'squadId' | 'orgId'; value: string };
  }) => void;
  applyAsInterviewer: (payload: string) => void;
  setDemandId: (value: { [key: string]: string | undefined } | null | undefined) => void;
  setChosenContract: (payload: string | null) => void;
  setFeedOwner: (payload: { [key: string]: TOrg | TProfile | Org | Profile } | null) => void;
  listAppliedDemands: () => void;
  getDemand: (payload: { type: 'contract' | 'interview'; id: string }) => void;
  approveContractor: (payload: { profileId: string; contractId: string }) => void;
  rejectContractor: (payload: { profileId: string; contractId: string }) => void;
  approveInterviewer: (payload: { profileId: string; interviewId: string }) => void;
  rejectInterviewer: (payload: { profileId: string; interviewId: string }) => void;
  inviteCandidates: (payload: { interviewId: string; profileIds: string[] }) => void;
  addCandidate: (payload: { interviewId: string }) => void;
  listCandidates: (payload: { interviewId: string }) => void;
  listEvaluations: () => void;
  getEvaluation: (payload: string) => void;
  sendEvaluationFeedback: (payload: { evaluationId: string; feedback: string }) => void;
  saveEvaluationFeedbackDraft: (payload: { evaluationId: string; feedback: string }) => void;
  updateInterviewRequirements: (payload: { interviewId: string; requirements: string }) => void;
} => {
  const [demandIdFromUrl, setDemandId] = useQueryParam('demandId', ObjectParam);
  const dispatch = useAppDispatch();

  return {
    demandIdFromUrl,
    chosenContract: useAppSelector((state) => state.marketplaceReducer.marketplace.chosenContract),
    isLoading: useAppSelector((state) => state.marketplaceReducer.marketplace.isLoading),
    contractors: useAppSelector((state) => state.marketplaceReducer.marketplace.contractors),
    displayListType: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.displayListType
    ),
    currentContractorId: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.currentContractorId
    ),
    foundDemands: useAppSelector((state) => state.marketplaceReducer.marketplace.foundDemands),
    feedItemsByContract: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.feedItemsByContract
    ),
    listDashboards: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.myDashboardsList
    ),
    feedOwner: useAppSelector((state) => state.marketplaceReducer.marketplace.feedOwner),
    dashboardsMap: useAppSelector((state) => state.marketplaceReducer.marketplace.dashboardsMap),
    feedItems: useAppSelector((state) => state.marketplaceReducer.marketplace.feedItems),
    appliedDemands: useAppSelector((state) => state.marketplaceReducer.marketplace.appliedDemands),
    feedRecommendations: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.feedRecommendations
    ),
    preAppliesContracts: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.preAppliesContracts
    ),
    candidatesList: useAppSelector((state) => state.marketplaceReducer.marketplace.candidatesList),
    evaluationList: useAppSelector((state) => state.marketplaceReducer.marketplace.listEvaluations),
    evaluationInterviewList: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.listInterviewEvaluations
    ),
    evaluationsByManager: useAppSelector(
      (state) => state.marketplaceReducer.marketplace.listEvaluationsByManager
    ),

    createDashboard: (payload) => {
      dispatch(
        createDashboard(
          new CreateDashboardRequest({
            title: payload.title,
            owner: payload.owner,
          })
        )
      );
    },
    createContract: (payload) => {
      dispatch(
        createContract(
          new CreateContractRequest({
            dashboardId: payload.dashboardId,
            traits: new ContractTraits({
              rate: payload.rate,
              capacity: payload.capacity,
              experience: payload.experience,
            }),
            description: payload.description,
            tags: new Tags({
              tags: getTagsFromPayload(payload),
            }),
            createInterview: payload.createInterview,
          })
        )
      );
    },
    updateContractTraits: (payload) => {
      dispatch(
        updateContractTraits(
          new UpdateContractTraitsRequest({
            contractId: payload.contractId,
            traits: new ContractTraits({
              rate: payload.rate,
              capacity: payload.capacity,
              experience: payload.experience,
            }),
          })
        )
      );
    },
    updateInterviewInfo: (payload) => {
      dispatch(
        updateInterview(
          new UpdateInterviewRequest({
            interviewId: payload.interviewId,
            traits: new InterviewTraits({
              price: payload.price,
              experience: payload.experience,
            }),
          })
        )
      );
    },
    setContractors: (payload) => {
      dispatch(setContractors(payload));
    },
    updateContractDescription: useCallback(
      (payload) => {
        dispatch(
          updateContractDescription(
            new UpdateContractDescriptionRequest({
              contractId: payload.contractId,
              description: payload.description,
            })
          )
        );
      },
      [dispatch]
    ),
    deleteContract: (payload) => {
      dispatch(
        deleteContract(
          new DeleteContractRequest({
            contractId: payload,
          })
        )
      );
    },
    getContract: useCallback(
      (payload) => {
        dispatch(getContract(new GetContractRequest({ contractId: payload })));
      },
      [dispatch]
    ),
    createInterview: (payload) => {
      dispatch(
        createInterview(
          new CreateInterviewRequest({
            dashboardId: payload.dashboardId,
            requirements: payload.requirements,
            interview: {
              value: new CreateInterviewRequest_NewInterview({
                traits: new InterviewTraits({
                  price: payload.price,
                  experience: payload.experience,
                }),
                tags: new Tags({
                  tags: getTagsFromPayload(payload),
                }),
              }),
              case: 'newInterview',
            },
          })
        )
      );
    },
    createInterviewForContract: useCallback(
      (payload) => {
        dispatch(
          createInterview(
            new CreateInterviewRequest({
              dashboardId: payload.dashboardId,
              requirements: payload.requirements,
              interview: {
                value: payload.contractId,
                case: 'contractId',
              },
            })
          )
        );
      },
      [dispatch]
    ),
    deleteInterview: (payload) => {
      dispatch(
        deleteInterview(
          new DeleteInterviewRequest({
            interviewId: payload,
          })
        )
      );
    },
    getListDashboards: useCallback(() => {
      dispatch(listDashboards());
    }, [dispatch]),
    updateDashboardInfo: (payload) => {
      dispatch(updateDashboardInfo(new UpdateDashboardRequest(payload)));
    },
    getCurrentDashboard: useCallback(
      (payload) => {
        dispatch(getKanbanBoard(new GetDashboardRequest({ dashboardId: payload })));
      },
      [dispatch]
    ),
    listFeedItems: useCallback(
      (payload) => {
        dispatch(
          listFeedItems(
            new ListFeedItemsRequest({
              recommendations: true,
              filter: payload?.entity
                ? {
                    case: 'demand',
                    value: payload.entity,
                  }
                : {
                    case: 'offset',
                    value: new Timestamp(),
                  },
            })
          )
        );
      },
      [dispatch]
    ),
    listFeedItemsByContractId: useCallback(
      (payload) => {
        dispatch(
          listFeedItemsByContractId(
            new ListFeedItemsRequest({
              recommendations: true,
              filter: {
                case: 'demand',
                value: payload.entity,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    getInterview: useCallback(
      (payload) => {
        dispatch(getInterview(new GetInterviewRequest({ interviewId: payload })));
      },
      [dispatch]
    ),
    setDemandStatus: useCallback(
      (payload) => {
        dispatch(
          setDemandStatus(
            new SetDemandStatusRequest({
              demandId: payload.demandId,
              // status: Number(payload.status),
            })
          )
        );
      },
      [dispatch]
    ),
    setDisplayListType: (payload) => {
      dispatch(setDisplayListType(payload));
    },
    addPreAppliesContracts: useCallback(
      (payload) => {
        dispatch(addPreAppliesContracts(payload));
      },
      [dispatch]
    ),
    setCurrentContractorId: useCallback(
      (payload) => {
        dispatch(setCurrentContractorId(payload));
      },
      [dispatch]
    ),
    deletePreAppliesContracts: useCallback(
      (payload) => {
        dispatch(deletePreAppliesContracts(payload));
      },
      [dispatch]
    ),
    applyAsContractor: useCallback((payload) => {
      // dispatch(
      //     applyAsContractor(
      //         new ApplyAsContractorRequest({
      //             contractIds: new UUIDS({ids: payload.contractIds}),
      //             contractor: {
      //                 value: payload.contractor.value,
      //                 case: payload.contractor.case,
      //             },
      //         })
      //     )
      // );
    }, []),
    applyAsInterviewer: useCallback(
      (payload) => {
        dispatch(
          applyAsInterviewer(
            new ApplyAsInterviewerRequest({
              interviewId: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    setDemandId: useCallback(
      (value) => {
        setDemandId(value);
      },
      [setDemandId]
    ),
    setChosenContract: useCallback(
      (payload) => {
        dispatch(setChosenContract(payload));
      },
      [dispatch]
    ),
    listAppliedDemands: useCallback(() => {
      dispatch(listAppliedDemands());
    }, [dispatch]),
    setFeedOwner: useCallback(
      (payload) => {
        dispatch(setFeedOwner(payload));
      },
      [dispatch]
    ),
    getDemand: useCallback(
      (payload) => {
        dispatch(
          getDemand(
            new GetDemandRequest({
              id: {
                case: payload.type,
                value: payload.id,
              },
            })
          )
        );
      },
      [dispatch]
    ),
    approveContractor: useCallback(
      (payload) => {
        dispatch(
          approveContractor(
            new ApproveContractorRequest({
              supplyId: payload.profileId,
              contractId: payload.contractId,
            })
          )
        );
      },
      [dispatch]
    ),
    rejectContractor: useCallback(
      (payload) => {
        dispatch(
          rejectContractor(
            new RejectContractorRequest({
              supplyId: payload.profileId,
              contractId: payload.contractId,
            })
          )
        );
      },
      [dispatch]
    ),
    approveInterviewer: useCallback(
      (payload) => {
        dispatch(
          approveInterviewer(
            new ApproveInterviewerRequest({
              supplyId: payload.profileId,
              interviewId: payload.interviewId,
            })
          )
        );
      },
      [dispatch]
    ),
    rejectInterviewer: useCallback(
      (payload) => {
        dispatch(
          rejectInterviewer(
            new RejectInterviewerRequest({
              supplyId: payload.profileId,
              interviewId: payload.interviewId,
            })
          )
        );
      },
      [dispatch]
    ),
    inviteCandidates: useCallback(
      (payload) => {
        dispatch(
          inviteCandidates(
            new InviteCandidatesRequest({
              interviewId: payload.interviewId,
              profiles: { ids: payload.profileIds },
            })
          )
        );
      },
      [dispatch]
    ),
    addCandidate: useCallback(
      (payload) => {
        dispatch(
          addCandidate(
            new AddCandidateRequest({
              interviewId: payload.interviewId,
            })
          )
        );
      },
      [dispatch]
    ),
    listCandidates: useCallback(
      (payload) => {
        dispatch(
          listCandidates(
            new ListCandidatesRequest({
              interviewId: payload.interviewId,
            })
          )
        );
      },
      [dispatch]
    ),
    listEvaluations: useCallback(() => {
      dispatch(listEvaluations());
    }, [dispatch]),
    getEvaluation: useCallback(
      (payload) => {
        dispatch(getEvaluation(new GetEvaluationRequest({ evaluationId: payload })));
      },
      [dispatch]
    ),
    sendEvaluationFeedback: useCallback(
      (payload) => {
        dispatch(
          sendEvaluationFeedback(
            new SendEvaluationFeedbackRequest({
              evaluationId: payload.evaluationId,
            })
          )
        );
      },
      [dispatch]
    ),
    saveEvaluationFeedbackDraft: useCallback(
      (payload) => {
        dispatch(
          saveEvaluationFeedbackDraft(
            new SaveEvaluationFeedbackDraftRequest({
              evaluationId: payload.evaluationId,
              feedback: payload.feedback,
            })
          )
        );
      },
      [dispatch]
    ),
    updateInterviewRequirements: useCallback(
      (payload) => {
        dispatch(
          updateInterviewRequirements(
            new UpdateInterviewRequirementsRequest({
              interviewId: payload.interviewId,
              requirements: payload.requirements,
            })
          )
        );
      },
      [dispatch]
    ),
  };
};

export {};
