import Box from '@mui/material/Box';
import { useMarketplace } from '@store/marketplace';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Card from '@components/Card/Card';
import EventIcon from '@mui/icons-material/Event';
import { format } from 'date-fns';
import ParticipantCard from '@pages/Marketplace/Dashboards/ParticipantCard';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { TProfile } from '@services/api/profiler/profile';
import UserSupplierCard from '@pages/Marketplace/Dashboards/UserSupplierCard';
import { useProfiler } from '@store/profiler';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListInvitedCandidates from '@pages/Marketplace/ManageDemands/Interviews/ListInvitedCandidates';
import ScrollableContainer from '@components/ScrollableContainerІ';

const ManageEvaluationsList = () => {
  const { demandId } = useParams();
  const navigate = useNavigate();
  const { evaluationsByManager, getInterview, foundDemands, getDemand } = useMarketplace();
  const { userProfiles } = useProfiler();
  const evaluationList = evaluationsByManager[demandId || ''];
  const demand = foundDemands[demandId || ''];

  useEffect(() => {
    if (demandId) {
      getInterview(demandId);
      getDemand({ type: 'interview', id: demandId });
    }
  }, [demandId, getDemand, getInterview]);

  return (
    <Container>
      <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
      <Box display="flex" gap={2}>
        <Box display="flex" gap={1} flexDirection="column" width="25%">
          <ScrollableContainer height="75vh">
            <Box display="flex" flexDirection="column" gap={2}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="subtitle2">Interviewers</Typography>
              </Stack>
              {demand?.supply?.applied?.length ? (
                <Box display="flex" gap={2} flexDirection="column">
                  {Object.entries(demand?.supply || {}).map(([status, suppliers]: any) => {
                    return suppliers.length && status === 'applied' ? (
                      <Box display="flex" flexDirection="column" gap={1}>
                        {suppliers
                          .map((supplier: any) => userProfiles[supplier.entity.id])
                          ?.map((profile: TProfile) => {
                            return (
                              <UserSupplierCard
                                demandType={demand.type}
                                supplierStatus={status}
                                supplier={profile}
                              />
                            );
                          })}
                      </Box>
                    ) : null;
                  })}
                </Box>
              ) : (
                <Typography>No interviewers</Typography>
              )}
            </Box>
          </ScrollableContainer>
        </Box>
        <Box width="25%">
          {demandId && <ListInvitedCandidates objectName="Interview" entityId={demandId} />}
        </Box>
        <Box width="50%">
          <ScrollableContainer height="75vh">
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="subtitle2">Evaluations</Typography>
              {evaluationList?.length ? (
                <Box display="flex" flexDirection="column" gap={1}>
                  {evaluationList?.map((evaluation) => {
                    return (
                      <Card>
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Box display="flex" gap={1} alignItems="center">
                            <EventIcon />
                            <Typography variant="subtitle2">
                              {evaluation?.createdAt &&
                                format(new Date(evaluation?.createdAt.toString()), 'dd/MM/yyyy')}
                            </Typography>
                          </Box>
                          <Box display="flex" gap={2} justifyContent="space-between">
                            {evaluation?.participants &&
                              Object.entries(evaluation?.participants)?.map(
                                ([type, participant]: any) => {
                                  return (
                                    <Box display="flex" flexDirection="column" gap={1} width="50%">
                                      <Typography>{type.toUpperCase()}</Typography>
                                      {participant ? (
                                        <ParticipantCard participant={participant} />
                                      ) : (
                                        <Box display="flex" gap={1}>
                                          <HourglassEmptyIcon />
                                          <Typography>Wait</Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  );
                                }
                              )}
                          </Box>

                          {evaluation.feedback && (
                            <Box>
                              <Typography>Feedback:</Typography>
                              <Typography>
                                {evaluation.feedback &&
                                  HTMLReactParser(
                                    stateToHTML(convertFromRaw(JSON.parse(evaluation.feedback)))
                                  )}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Card>
                    );
                  })}
                </Box>
              ) : (
                <Typography>No evaluations yet</Typography>
              )}
            </Box>
          </ScrollableContainer>
        </Box>
      </Box>
    </Container>
  );
};

export default ManageEvaluationsList;
