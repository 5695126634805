import {
  useAcceptEvaluationScheduleSlot,
  useListEvaluationScheduleSlots,
} from '@services/api/marketplace/calls';
import Box from '@mui/material/Box';
import { Timestamp } from '@bufbuild/protobuf';
import Typography from '@mui/material/Typography';
import Card from '@components/Card/Card';
import EventIcon from '@mui/icons-material/Event';
import * as React from 'react';
import { useState } from 'react';
import { AcceptEvaluationScheduleSlotRequest } from '@proto/marketplace/demand/v1/evaluation_pb';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddEventLink from '@components/AddEventLink/AddEventLink';

const formatTimestamp = (timestamp: Timestamp) => {
  const date = timestamp.toDate();

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleString(undefined, options);

  return <Typography>{formattedDate}</Typography>;
};

const SchedulesList = (props: { evaluationId: string }) => {
  const { evaluationId } = props;
  const [activeSchedule, setActiveSchedule] = useState('');
  const { data: listSchedulestSlots, isLoading } = useListEvaluationScheduleSlots({ evaluationId });
  const { mutate: acceptEvaluationSlot } = useAcceptEvaluationScheduleSlot();
  const isAccepted = listSchedulestSlots?.schedules.some((schedule) => schedule.acceptedAt);
  const acceptedSchedule = listSchedulestSlots?.schedules.find((schedule) => schedule.acceptedAt);

  function timestampToDate(timestamp: Timestamp) {
    const milliseconds = Number(timestamp.seconds) * 1000 + Math.floor(timestamp.nanos / 1000000);
    return new Date(milliseconds);
  }

  function timestampToDatePlusOneHour(timestamp: Timestamp) {
    const milliseconds = Number(timestamp.seconds) * 1000 + Math.floor(timestamp.nanos / 1000000);
    const newMilliseconds = milliseconds + 3600000;
    return new Date(newMilliseconds);
  }

  const event = {
    title: 'Interview on the squad platform',
    description: 'This is a description of the event.',
    startDate: timestampToDate(acceptedSchedule?.scheduledAt || new Timestamp()),
    endDate: timestampToDatePlusOneHour(acceptedSchedule?.scheduledAt || new Timestamp()),
  };

  if (isLoading) return <CircularProgress />;

  return listSchedulestSlots ? (
    <Card>
      <Box display="flex" gap={2} flexDirection="column">
        <Typography variant="subtitle2">
          {isAccepted
            ? 'We wish you success in passing the interview. Your interview is scheduled for:'
            : 'Choose interview date:'}
        </Typography>
        <List>
          {listSchedulestSlots?.schedules.map((schedule) => (
            <ListItem
              disablePadding
              onClick={() => setActiveSchedule(schedule.evaluationScheduleSlotId)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText>
                  {schedule?.scheduledAt && formatTimestamp(schedule?.scheduledAt)}
                </ListItemText>
                {!schedule?.acceptedAt && schedule.evaluationScheduleSlotId === activeSchedule && (
                  <Box display="flex" justifyContent="center">
                    <CheckIcon
                      sx={{
                        transition: 'color 0.3s ease',
                        '&:hover': { color: '#388e3c' },
                      }}
                      onClick={() =>
                        acceptEvaluationSlot(
                          new AcceptEvaluationScheduleSlotRequest({
                            evaluationScheduleSlotId: schedule.evaluationScheduleSlotId,
                          })
                        )
                      }
                    />
                  </Box>
                )}
                {isAccepted && <AddEventLink event={event} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  ) : (
    <Typography variant="subtitle2">Evaluation not have schedule</Typography>
  );
};

export default SchedulesList;
