import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs';
import { UAParser } from 'ua-parser-js';
import { Device } from '@proto/iam/auth/v1/token_pb';

export type GetDeviceServiceResponse = {
  device: {
    fingerprint: Device['fingerprint'];
    os: Device['os'];
  };
};

class DeviceService {
  fp: Agent | null = null;

  getDevice = async (): Promise<GetDeviceServiceResponse> => {
    if (!this.fp) {
      this.fp = await FingerprintJS.load();
    }

    const { visitorId } = await this.fp.get();
    const device = {
      os: UAParser(navigator.userAgent).os.name || 'Unknown',
      fingerprint: visitorId,
    };

    return { device };
  };
}

export const deviceService = new DeviceService();
