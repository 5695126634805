import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type ConfirmDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  demandId: string;
  deleteMethod: (id: string) => void;
  setIsEdited: (payload: boolean) => void;
};

const ConfirmDeleteDialog = (props: ConfirmDialogProps) => {
  const { dashboardId } = useParams();
  const { isOpen, handleClose, demandId, deleteMethod, setIsEdited } = props;
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteMethod(demandId);
    handleClose();
    setIsEdited(true);
    navigate(`/${dashboardId}`);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box sx={{ backgroundColor: '#3f3d50', width: '600px' }} py={5} px={{ xs: 3 }}>
        <Typography textAlign="center" mb={5} variant="subtitle1">
          Are you sure delete this demand?
        </Typography>
        <Box display="flex" gap={2} justifyContent="center">
          <Button variant="outlined" onClick={handleDelete}>
            Yes
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            No
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
