// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/org.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateOrgRequest,
  CreateOrgResponse,
  GetOrgRequest,
  GetOrgResponse,
  ListOrgsRequest,
  ListOrgsResponse,
  SearchOrgsRequest,
  SearchOrgsResponse,
  SetOrgStatusRequest,
  SetOrgStatusResponse,
  UpdateOrgInfoRequest,
  UpdateOrgInfoResponse,
} from './org_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.biz.v1.OrgService
 */
export const OrgService = {
  typeName: 'profiler.biz.v1.OrgService',
  methods: {
    /**
     * @generated from rpc profiler.biz.v1.OrgService.CreateOrg
     */
    createOrg: {
      name: 'CreateOrg',
      I: CreateOrgRequest,
      O: CreateOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.OrgService.GetOrg
     */
    getOrg: {
      name: 'GetOrg',
      I: GetOrgRequest,
      O: GetOrgResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.OrgService.SetOrgStatus
     */
    setOrgStatus: {
      name: 'SetOrgStatus',
      I: SetOrgStatusRequest,
      O: SetOrgStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.OrgService.ListOrgs
     */
    listOrgs: {
      name: 'ListOrgs',
      I: ListOrgsRequest,
      O: ListOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.OrgService.SearchOrgs
     */
    searchOrgs: {
      name: 'SearchOrgs',
      I: SearchOrgsRequest,
      O: SearchOrgsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.OrgService.UpdateOrgInfo
     */
    updateOrgInfo: {
      name: 'UpdateOrgInfo',
      I: UpdateOrgInfoRequest,
      O: UpdateOrgInfoResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
