import { Page } from '@components/Page/Page';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import Container from '@mui/material/Container';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Products from '@components/Products/Products';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import SquadTags from '@pages/Squads/ExploreSquads/SquadTags';
import ManageSquadMembersList from '@pages/Squads/ManageSquads/ManageSquadMembersList';
import { useProfiler } from '@store/profiler';
import UpdateSquadAboutForm from '@pages/Squads/ManageSquads/UpdateSquadInfoForm';
import Avatars from '@components/Avatars/Avatars';
import { replaceDashesWithSpaces } from '@utils/parseStrings';

export const Squad = () => {
  const { name } = useParams();
  const { listRoles, userProfile, getListMembers } = useProfiler();
  const { data: squad, error } = useGetSquad({
    case: 'name',
    value: replaceDashesWithSpaces(name || ''),
  });

  const requiredRoles = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_MEMBER,
  ];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[squad?.squad?.squadId as keyof typeof listRoles]?.find(
      (el) => el.id === userProfile?.profileId
    )?.roles.roles
  );

  useEffect(() => {
    if (squad?.squad?.squadId) {
      getListMembers({ id: squad.squad?.squadId, type: TypeOfEntity.SQUAD });
    }
  }, [getListMembers, squad]);
  console.log('davdasvasdvasdvasdvadsv', error);
  return (
    <Page description="Squad" title="Squad">
      <Container>
        <EntityInviteCard entityId={squad?.squad?.squadId} entity={squad?.squad} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              {hasAccess && squad?.squad?.squadId && (
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${squad?.squad?.squadId}/${squad?.squad?.avatar}`}
                  entityId={squad?.squad?.squadId}
                />
              )}
            </Box>
            <Box width="100%">
              <Typography variant="subtitle1">{squad?.squad?.name}</Typography>
              {hasAccess ? (
                squad?.squad && <UpdateSquadAboutForm squad={squad.squad} />
              ) : (
                <Typography flexGrow="grow" sx={{ color: '#bbb' }} variant="subtitle2">
                  {squad?.squad?.about || 'No title'}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider />
          {squad?.squad && <Products id={squad.squad.squadId} type={TypeOfEntity.SQUAD} />}
          <Divider />
          {hasAccess && <SquadTags />}
          {squad?.squad && <ManageSquadMembersList entityId={squad.squad.squadId} />}
        </Box>
      </Container>
    </Page>
  );
};
