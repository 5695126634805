import { TOrg } from '@services/api/profiler/biz';
import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';

type TPinnedOrgCard = {
  org: TOrg;
};

const MembershipOrgCard = (props: TPinnedOrgCard) => {
  const { org } = props;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Link className="NavLink" to={`${ROUTES_PREFIXES.ORGS}/${org.orgId}`}>
        <Box display="flex" alignItems="center" width="100%" gap={1}>
          <Avatar
            sx={{
              width: 35,
              height: 35,
              backgroundColor: 'rgb(255, 255, 255, 0.2)',
              color: 'white',
              fontSize: '13px',
            }}
            alt={org.name.toUpperCase() || 'User'}
            src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${org?.orgId}/${org?.avatar}`}
          />
          <Typography sx={{ '&:hover': { textDecoration: 'underline' } }} fontSize="13px">
            {org.name}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default MembershipOrgCard;
