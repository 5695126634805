import { TSquad } from '@services/api/profiler/biz';
import { Chip, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { ROUTES_PREFIXES } from '@common/routes';
import Card from '@components/Card/Card';

type TExploreSquadCardProps = {
  squad: TSquad;
};

const ExploreSquadCard = (props: TExploreSquadCardProps) => {
  const { squad } = props;
  const link = `${ROUTES_PREFIXES.SQUADS}/${squad.name}`;

  return (
    <Card>
      <Box display="flex" gap={1}>
        <Link className="NavLink" to={link}>
          <Avatar
            sx={{ width: 40, height: 40 }}
            variant="rounded"
            src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${squad?.squadId}/${squad?.avatar}`}
          />
        </Link>
        <Box display="flex" flexDirection="column" gap={1}>
          <Link className="NavLink" to={link}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography fontWeight="bold">{squad.name}</Typography>
            </Box>
            <Typography
              sx={{ wordBreak: 'break-all' }}
              mb={1}
              variant="body2"
              color="text.secondary"
            >
              {squad.about}
            </Typography>
          </Link>

          <Box display="flex" flexWrap="wrap" gap={1}>
            {squad?.tags?.tags?.map((tag) => {
              return <Chip variant="outlined" label={tag.value} />;
            })}
            {squad?.profiles?.profiles && (
              <Box>
                <Typography>
                  Mates:
                  <span style={{ fontWeight: 'bold' }}>{squad?.profiles?.profiles?.length}</span>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ExploreSquadCard;
