import React from 'react';
import { useNotificator } from '@store/notificator';
import Box from '@mui/material/Box';
import { notificatorSlice } from '@store/notificator/slices';
import { useDispatch } from 'react-redux';
import { Badge, Popover } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Typography from '@mui/material/Typography';

const {
  actions: { clearEvents },
} = notificatorSlice;
const NotificationPopover = () => {
  const { notificatorEvents } = useNotificator();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    dispatch(clearEvents());
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return notificatorEvents ? (
    <Box>
      <Badge
        badgeContent={Object.values(notificatorEvents).length}
        color="primary"
        onClick={handleClick}
      >
        {Object.values(notificatorEvents).length === 0 ? (
          <NotificationsIcon />
        ) : (
          <NotificationsActiveIcon className="BellAnimation" />
        )}
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box py={3} px={4}>
          {Object.values(notificatorEvents).length === 0 ? (
            <Typography sx={{ py: 2 }}>You have no notifications.</Typography>
          ) : (
            <Box>
              {Object.values(notificatorEvents).map((event, index) => {
                return (
                  <Typography key={event.subject} sx={{ py: 2 }}>
                    {event.subject}
                  </Typography>
                );
              })}
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  ) : null;
};
export default NotificationPopover;
