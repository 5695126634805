import React, { Dispatch, SetStateAction } from 'react';
import { useSquads } from '@store/squads';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { setGroupStatus } from '../../../helpers/setGroupStatus';

type SquadStatusSwitcherProps = {
  squadStatus: boolean | undefined;
  setStatusSquad: Dispatch<SetStateAction<boolean>>;
};

const SquadStatusSwitcher = (props: SquadStatusSwitcherProps) => {
  const { squadStatus, setStatusSquad } = props;
  const { setSquadStatus, currentSquad } = useSquads();

  return (
    <Box className="fstep">
      <Switch
        defaultChecked={currentSquad?.active}
        color="success"
        onChange={(e, checked) => {
          setStatusSquad(checked);
          setGroupStatus(setSquadStatus, currentSquad?.squadId, checked);
        }}
      />
      {squadStatus ? (
        <Typography textAlign="center">Active</Typography>
      ) : (
        <Typography textAlign="center">Inactive</Typography>
      )}
    </Box>
  );
};

export default SquadStatusSwitcher;
