import React from 'react';
import { format } from 'date-fns';

interface Event {
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
}

interface AddEventLinkProps {
  event: Event;
}

const AddEventLink: React.FC<AddEventLinkProps> = ({ event }) => {
  const { title, description, startDate, endDate } = event;

  const formatDate = (date: Date): string => format(date, "yyyyMMdd'T'HHmmss");

  const link = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    title
  )}&dates=${formatDate(startDate)}/${formatDate(endDate)}`;

  return (
    <a
      className="NavLink HoverUnderlineAnimation"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      Add to Google Calendar
    </a>
  );
};

export default AddEventLink;
