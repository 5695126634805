// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file iam/auth/v1/iam.proto (package iam.auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddMemberRequest,
  AddMemberResponse,
  AdminEnforceRequest,
  AdminEnforceResponse,
  AttachRoleRequest,
  AttachRoleResponse,
  AttachRolesRequest,
  AttachRolesResponse,
  BatchEnforceRequest,
  BatchEnforceResponse,
  CreateDomainRequest,
  CreateDomainResponse,
  DeleteDomainRequest,
  DeleteDomainResponse,
  DetachRolesRequest,
  DetachRolesResponse,
  EnforceRequest,
  EnforceResponse,
  GetObjectDomainRequest,
  GetObjectDomainResponse,
  ListManagedDomainsRequest,
  ListManagedDomainsResponse,
  ListMembersRolesRequest,
  ListMembersRolesResponse,
  RemoveMemberRequest,
  RemoveMemberResponse,
  RemoveObjectPermissionsRequest,
  RemoveObjectPermissionsResponse,
  RemovePermissionsRequest,
  RemovePermissionsResponse,
  RemoveSubjectsFromDomainRequest,
  RemoveSubjectsFromDomainResponse,
  SetPermissionsRequest,
  SetPermissionsResponse,
} from './iam_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service iam.auth.v1.IAMService
 */
export const IAMService = {
  typeName: 'iam.auth.v1.IAMService',
  methods: {
    /**
     * @generated from rpc iam.auth.v1.IAMService.Enforce
     */
    enforce: {
      name: 'Enforce',
      I: EnforceRequest,
      O: EnforceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.AdminEnforce
     */
    adminEnforce: {
      name: 'AdminEnforce',
      I: AdminEnforceRequest,
      O: AdminEnforceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.BatchEnforce
     */
    batchEnforce: {
      name: 'BatchEnforce',
      I: BatchEnforceRequest,
      O: BatchEnforceResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.AttachRole
     */
    attachRole: {
      name: 'AttachRole',
      I: AttachRoleRequest,
      O: AttachRoleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.AttachRoles
     */
    attachRoles: {
      name: 'AttachRoles',
      I: AttachRolesRequest,
      O: AttachRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.DetachRoles
     */
    detachRoles: {
      name: 'DetachRoles',
      I: DetachRolesRequest,
      O: DetachRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.RemoveSubjectsFromDomain
     */
    removeSubjectsFromDomain: {
      name: 'RemoveSubjectsFromDomain',
      I: RemoveSubjectsFromDomainRequest,
      O: RemoveSubjectsFromDomainResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.CreateDomain
     */
    createDomain: {
      name: 'CreateDomain',
      I: CreateDomainRequest,
      O: CreateDomainResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.DeleteDomain
     */
    deleteDomain: {
      name: 'DeleteDomain',
      I: DeleteDomainRequest,
      O: DeleteDomainResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.SetPermissions
     */
    setPermissions: {
      name: 'SetPermissions',
      I: SetPermissionsRequest,
      O: SetPermissionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.RemovePermissions
     */
    removePermissions: {
      name: 'RemovePermissions',
      I: RemovePermissionsRequest,
      O: RemovePermissionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.RemoveObjectPermissions
     */
    removeObjectPermissions: {
      name: 'RemoveObjectPermissions',
      I: RemoveObjectPermissionsRequest,
      O: RemoveObjectPermissionsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.ListMembersRoles
     */
    listMembersRoles: {
      name: 'ListMembersRoles',
      I: ListMembersRolesRequest,
      O: ListMembersRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.AddMember
     */
    addMember: {
      name: 'AddMember',
      I: AddMemberRequest,
      O: AddMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.RemoveMember
     */
    removeMember: {
      name: 'RemoveMember',
      I: RemoveMemberRequest,
      O: RemoveMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.ListManagedDomains
     */
    listManagedDomains: {
      name: 'ListManagedDomains',
      I: ListManagedDomainsRequest,
      O: ListManagedDomainsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc iam.auth.v1.IAMService.GetObjectDomain
     */
    getObjectDomain: {
      name: 'GetObjectDomain',
      I: GetObjectDomainRequest,
      O: GetObjectDomainResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
