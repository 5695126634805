import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';

import { useProfiler } from '@store/profiler';

import { DatePickerController } from '@components/Form/controllers/DatePickerController/DatePickerController';
import { FIELD_LABELS, FieldNames, getDefaultValues, myProfileNameFormSchema } from './config';

type ProfileNameFormProps = {
  isOnboarding?: () => void;
};

export const ProfileNameForm = (props: ProfileNameFormProps) => {
  const { isOnboarding } = props;
  const { userProfile, updateProfileName } = useProfiler();

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile) }}
      submitHandler={(data) => {
        if (isOnboarding) {
          isOnboarding();
        }
        updateProfileName(data);
      }}
      resolverSchema={myProfileNameFormSchema}
    >
      <Stack spacing={3} direction="column" mb={2} className="second-step">
        <InputController
          name={`names.${FieldNames.FIRSTNAME}`}
          label={FIELD_LABELS[FieldNames.FIRSTNAME]}
        />
        <InputController
          name={`names.${FieldNames.LASTNAME}`}
          label={FIELD_LABELS[FieldNames.LASTNAME]}
        />
        <InputController
          name={`names.${FieldNames.NICKNAME}`}
          label={FIELD_LABELS[FieldNames.NICKNAME]}
        />
        <DatePickerController name={FieldNames.BIRTHDAY} />
      </Stack>

      <Stack>
        <LoadingButton type="submit" variant="contained">
          {isOnboarding ? 'Next' : 'Update profile info'}
        </LoadingButton>
      </Stack>
    </Form>
  );
};
