import { useProfiler } from '@store/profiler';
import { useListProgress } from '@services/api/rewarder/rewarder';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import AchievementBox from '@pages/Main/MainPage/AchievementBox';

const ListUserAchievements = () => {
  const { userProfile } = useProfiler();
  const { data: progressData } = useListProgress(
    new UUIDS({
      ids: [userProfile?.profileId || ''],
    })
  );

  return (
    <Box>
      {progressData?.progress &&
        Object.values(progressData?.progress).map((progress) => {
          return (
            <Box>
              <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold" mb={1}>
                Achievements
              </Typography>
              <Box display="flex" gap={1}>
                {progress.progress.map((ach) => {
                  return (
                    <Box key={ach.achievementId}>
                      <AchievementBox achieved={ach} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ListUserAchievements;
