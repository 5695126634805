import React, { useState } from 'react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateSelectArg, EventApi, EventClickArg, EventContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import Box from '@mui/material/Box';
import {
  AddEvaluationScheduleSlotsRequest,
  DeleteEvaluationScheduleSlotRequest,
} from '@proto/marketplace/demand/v1/evaluation_pb';
import { createEventId } from '@pages/Evaluation/event-utils';
import {
  useAddEvaluationScheduleSlot,
  useDeleteEvaluationScheduleSlot,
  useListEvaluationScheduleAcceptedSlots,
  useListEvaluationScheduleSlots,
} from '@services/api/marketplace/calls';
import {
  convertDateToTimeStamp,
  getDefaulEvents,
  selectAllow,
  validRange,
} from '@pages/Evaluation/EvaluationSchedulesCalendar/config';
import { Timestamp } from '@bufbuild/protobuf';
import { CircularProgress } from '@mui/material';

const CalendarComponent = (props: { evaluationId: string; evaluationdCreatedAt: Timestamp }) => {
  const { evaluationId, evaluationdCreatedAt } = props;
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const { data: listSchedulestSlots, isLoading: listSchedulestSlotsLoading } =
    useListEvaluationScheduleSlots({ evaluationId });
  const { data: listAcceptedSchedilesSlots, isLoading: listAcceptedSchedilesSlotsLoading } =
    useListEvaluationScheduleAcceptedSlots();
  const { mutate: addEvaluationSlot } = useAddEvaluationScheduleSlot();
  const { mutate: deleteEvaluationSlot } = useDeleteEvaluationScheduleSlot();

  if (listAcceptedSchedilesSlotsLoading || listSchedulestSlotsLoading) return <CircularProgress />;

  const convertTimestampToDate = (timestamp: Timestamp) => {
    const milliseconds = Number(timestamp.seconds) * 1000 + Math.floor(timestamp.nanos / 1000000);
    return new Date(milliseconds);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    const isConflict = currentEvents.some(
      (event) =>
        new Date(event.start as Date).getTime() <= selectInfo.start.getTime() &&
        new Date(event.end as Date).getTime() > selectInfo.start.getTime()
    );

    if (isConflict) {
      return;
    }
    addEvaluationSlot(
      new AddEvaluationScheduleSlotsRequest({
        evaluationId,
        slots: [convertDateToTimeStamp(selectInfo.startStr)],
      })
    );

    calendarApi.addEvent({
      id: createEventId(),
      start: selectInfo.start,
      end: new Date(selectInfo.start.getTime() + 60 * 60 * 1000).toISOString(),
      title: 'Convenient time for you',
      color: '#0d47a1',
      extendedProps: {
        remove: true,
      },
    });
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    deleteEvaluationSlot(
      new DeleteEvaluationScheduleSlotRequest({
        evaluationScheduleSlotId: clickInfo.event.id,
        evaluationId,
      })
    );
    clickInfo.event.remove();
  };

  const renderEventContent = (eventContent: EventContentArg) => (
    <>
      {eventContent.timeText} - {eventContent.event.title}
    </>
  );

  return (
    <Box width="600px">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: '',
          right: 'title',
        }}
        height={440}
        allDaySlot={false}
        initialView="timeGridDay"
        dayMaxEvents
        weekends
        selectable
        initialEvents={
          listSchedulestSlots && listAcceptedSchedilesSlots
            ? getDefaulEvents(listSchedulestSlots, listAcceptedSchedilesSlots)
            : listSchedulestSlots && getDefaulEvents(listSchedulestSlots, undefined)
        }
        select={handleDateSelect}
        eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        eventsSet={setCurrentEvents} // called after events are initialized/added/changed/removed
        validRange={() => validRange(convertTimestampToDate(evaluationdCreatedAt))} // set the valid range for the calendar
        selectAllow={selectAllow} // limit date selection to next 7 days
        scrollTimeReset={false}
      />
    </Box>
  );
};

export default CalendarComponent;
