import { useQuery } from '@tanstack/react-query';
import { membershipClient } from '@services/api/profiler/profile';
import { ListProfileMembershipsRequest } from '@proto/profiler/biz/v1/membership_pb';
import { ListSquadsRequest } from '@proto/profiler/biz/v1/squad_pb';
import { squadClient } from '@services/api/profiler/SquadsQueries';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { ListOrgsRequest } from '@proto/profiler/biz/v1/org_pb';
import { orgClient } from '@services/api/profiler/OrgsQueries';

const fetchMembershipsAndSquads = async () => {
  const membershipsResponse = await membershipClient.listProfileMemberships(
    new ListProfileMembershipsRequest()
  );

  const squadIds = membershipsResponse.memberships
    .filter((member) => member.entity?.type === TypeOfEntity.SQUAD)
    .map((member) => member.entity?.id)
    .filter((id) => id !== undefined) as string[];

  const orgIds = membershipsResponse.memberships
    .filter((member) => member.entity?.type === TypeOfEntity.ORG)
    .map((member) => member.entity?.id)
    .filter((id) => id !== undefined) as string[];

  const [squadsResponse, orgsResponse] = await Promise.all([
    squadIds.length > 0
      ? squadClient.listSquads(new ListSquadsRequest({ squadIds: { ids: squadIds } }))
      : { squads: [] },
    orgIds.length > 0
      ? orgClient.listOrgs(new ListOrgsRequest({ orgIds: { ids: orgIds } }))
      : { orgs: [] },
  ]);

  return {
    squads: squadsResponse.squads,
    orgs: orgsResponse.orgs,
  };
};

export const useMembershipsAndSquads = () => {
  return useQuery({
    queryKey: ['fetchMembershipsAndSquads'],
    queryFn: fetchMembershipsAndSquads,
  });
};
