// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/dashboard/v1/dashboad.proto (package marketplace.dashboard.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity } from '../../../grpc/type/v1/entity_pb.js';
import { Demands } from '../../demand/v1/demand_pb.js';

/**
 * @generated from message marketplace.dashboard.v1.KanbanBoard
 */
export class KanbanBoard extends Message<KanbanBoard> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  /**
   * @generated from field: string creator_id = 2;
   */
  creatorId = '';

  /**
   * @generated from field: string title = 3;
   */
  title = '';

  /**
   * @generated from field: grpc.type.v1.Entity owner = 4;
   */
  owner?: Entity;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<KanbanBoard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.KanbanBoard';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'creator_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'owner', kind: 'message', T: Entity },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KanbanBoard {
    return new KanbanBoard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KanbanBoard {
    return new KanbanBoard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KanbanBoard {
    return new KanbanBoard().fromJsonString(jsonString, options);
  }

  static equals(
    a: KanbanBoard | PlainMessage<KanbanBoard> | undefined,
    b: KanbanBoard | PlainMessage<KanbanBoard> | undefined
  ): boolean {
    return proto3.util.equals(KanbanBoard, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.KanbanBoards
 */
export class KanbanBoards extends Message<KanbanBoards> {
  /**
   * @generated from field: repeated marketplace.dashboard.v1.KanbanBoard dashboards = 1;
   */
  dashboards: KanbanBoard[] = [];

  constructor(data?: PartialMessage<KanbanBoards>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.KanbanBoards';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboards', kind: 'message', T: KanbanBoard, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KanbanBoards {
    return new KanbanBoards().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KanbanBoards {
    return new KanbanBoards().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KanbanBoards {
    return new KanbanBoards().fromJsonString(jsonString, options);
  }

  static equals(
    a: KanbanBoards | PlainMessage<KanbanBoards> | undefined,
    b: KanbanBoards | PlainMessage<KanbanBoards> | undefined
  ): boolean {
    return proto3.util.equals(KanbanBoards, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.CreateDashboardRequest
 */
export class CreateDashboardRequest extends Message<CreateDashboardRequest> {
  /**
   * @generated from field: string title = 1;
   */
  title = '';

  /**
   * @generated from field: grpc.type.v1.Entity owner = 2;
   */
  owner?: Entity;

  constructor(data?: PartialMessage<CreateDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.CreateDashboardRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'owner', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateDashboardRequest {
    return new CreateDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateDashboardRequest {
    return new CreateDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDashboardRequest {
    return new CreateDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDashboardRequest | PlainMessage<CreateDashboardRequest> | undefined,
    b: CreateDashboardRequest | PlainMessage<CreateDashboardRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateDashboardRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.CreateDashboardResponse
 */
export class CreateDashboardResponse extends Message<CreateDashboardResponse> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  constructor(data?: PartialMessage<CreateDashboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.CreateDashboardResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateDashboardResponse {
    return new CreateDashboardResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CreateDashboardResponse {
    return new CreateDashboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateDashboardResponse {
    return new CreateDashboardResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateDashboardResponse | PlainMessage<CreateDashboardResponse> | undefined,
    b: CreateDashboardResponse | PlainMessage<CreateDashboardResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateDashboardResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.GetDashboardRequest
 */
export class GetDashboardRequest extends Message<GetDashboardRequest> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  constructor(data?: PartialMessage<GetDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.GetDashboardRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDashboardRequest {
    return new GetDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDashboardRequest {
    return new GetDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetDashboardRequest {
    return new GetDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetDashboardRequest | PlainMessage<GetDashboardRequest> | undefined,
    b: GetDashboardRequest | PlainMessage<GetDashboardRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetDashboardRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.GetDashboardResponse
 */
export class GetDashboardResponse extends Message<GetDashboardResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Demands demands = 1;
   */
  demands?: Demands;

  /**
   * @generated from oneof marketplace.dashboard.v1.GetDashboardResponse.dashboard
   */
  dashboard:
    | {
        /**
         * @generated from field: marketplace.dashboard.v1.KanbanBoard kanban = 2;
         */
        value: KanbanBoard;
        case: 'kanban';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<GetDashboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.GetDashboardResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'demands', kind: 'message', T: Demands },
    { no: 2, name: 'kanban', kind: 'message', T: KanbanBoard, oneof: 'dashboard' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDashboardResponse {
    return new GetDashboardResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDashboardResponse {
    return new GetDashboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetDashboardResponse {
    return new GetDashboardResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetDashboardResponse | PlainMessage<GetDashboardResponse> | undefined,
    b: GetDashboardResponse | PlainMessage<GetDashboardResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetDashboardResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.UpdateDashboardRequest
 */
export class UpdateDashboardRequest extends Message<UpdateDashboardRequest> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  /**
   * @generated from field: string title = 2;
   */
  title = '';

  constructor(data?: PartialMessage<UpdateDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.UpdateDashboardRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateDashboardRequest {
    return new UpdateDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateDashboardRequest {
    return new UpdateDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateDashboardRequest {
    return new UpdateDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateDashboardRequest | PlainMessage<UpdateDashboardRequest> | undefined,
    b: UpdateDashboardRequest | PlainMessage<UpdateDashboardRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateDashboardRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.UpdateDashboardResponse
 */
export class UpdateDashboardResponse extends Message<UpdateDashboardResponse> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  /**
   * @generated from field: string title = 2;
   */
  title = '';

  constructor(data?: PartialMessage<UpdateDashboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.UpdateDashboardResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateDashboardResponse {
    return new UpdateDashboardResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateDashboardResponse {
    return new UpdateDashboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateDashboardResponse {
    return new UpdateDashboardResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateDashboardResponse | PlainMessage<UpdateDashboardResponse> | undefined,
    b: UpdateDashboardResponse | PlainMessage<UpdateDashboardResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateDashboardResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ListDashboardsRequest
 */
export class ListDashboardsRequest extends Message<ListDashboardsRequest> {
  constructor(data?: PartialMessage<ListDashboardsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ListDashboardsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDashboardsRequest {
    return new ListDashboardsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListDashboardsRequest {
    return new ListDashboardsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDashboardsRequest {
    return new ListDashboardsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListDashboardsRequest | PlainMessage<ListDashboardsRequest> | undefined,
    b: ListDashboardsRequest | PlainMessage<ListDashboardsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListDashboardsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ListDashboardsResponse
 */
export class ListDashboardsResponse extends Message<ListDashboardsResponse> {
  /**
   * @generated from field: marketplace.dashboard.v1.KanbanBoards dashboards = 1;
   */
  dashboards?: KanbanBoards;

  constructor(data?: PartialMessage<ListDashboardsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ListDashboardsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboards', kind: 'message', T: KanbanBoards },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDashboardsResponse {
    return new ListDashboardsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDashboardsResponse {
    return new ListDashboardsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDashboardsResponse {
    return new ListDashboardsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListDashboardsResponse | PlainMessage<ListDashboardsResponse> | undefined,
    b: ListDashboardsResponse | PlainMessage<ListDashboardsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListDashboardsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ListFeedItemsRequest
 */
export class ListFeedItemsRequest extends Message<ListFeedItemsRequest> {
  /**
   * @generated from field: bool recommendations = 1;
   */
  recommendations = false;

  /**
   * @generated from oneof marketplace.dashboard.v1.ListFeedItemsRequest.filter
   */
  filter:
    | {
        /**
         * @generated from field: google.protobuf.Timestamp offset = 2;
         */
        value: Timestamp;
        case: 'offset';
      }
    | {
        /**
         * @generated from field: grpc.type.v1.Entity demand = 3;
         */
        value: Entity;
        case: 'demand';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ListFeedItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ListFeedItemsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'recommendations', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: 'offset', kind: 'message', T: Timestamp, oneof: 'filter' },
    { no: 3, name: 'demand', kind: 'message', T: Entity, oneof: 'filter' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListFeedItemsRequest {
    return new ListFeedItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFeedItemsRequest {
    return new ListFeedItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeedItemsRequest {
    return new ListFeedItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListFeedItemsRequest | PlainMessage<ListFeedItemsRequest> | undefined,
    b: ListFeedItemsRequest | PlainMessage<ListFeedItemsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListFeedItemsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ListFeedItemsResponse
 */
export class ListFeedItemsResponse extends Message<ListFeedItemsResponse> {
  /**
   * @generated from field: map<string, int32> dashboard_active_demands = 1;
   */
  dashboardActiveDemands: { [key: string]: number } = {};

  /**
   * @generated from field: marketplace.demand.v1.Demands demands = 2;
   */
  demands?: Demands;

  constructor(data?: PartialMessage<ListFeedItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ListFeedItemsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'dashboard_active_demands',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    },
    { no: 2, name: 'demands', kind: 'message', T: Demands },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListFeedItemsResponse {
    return new ListFeedItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListFeedItemsResponse {
    return new ListFeedItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListFeedItemsResponse {
    return new ListFeedItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListFeedItemsResponse | PlainMessage<ListFeedItemsResponse> | undefined,
    b: ListFeedItemsResponse | PlainMessage<ListFeedItemsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListFeedItemsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.DeleteDashboardRequest
 */
export class DeleteDashboardRequest extends Message<DeleteDashboardRequest> {
  /**
   * @generated from field: string dashboard_id = 1;
   */
  dashboardId = '';

  constructor(data?: PartialMessage<DeleteDashboardRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.DeleteDashboardRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'dashboard_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteDashboardRequest {
    return new DeleteDashboardRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteDashboardRequest {
    return new DeleteDashboardRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDashboardRequest {
    return new DeleteDashboardRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDashboardRequest | PlainMessage<DeleteDashboardRequest> | undefined,
    b: DeleteDashboardRequest | PlainMessage<DeleteDashboardRequest> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDashboardRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.DeleteDashboardResponse
 */
export class DeleteDashboardResponse extends Message<DeleteDashboardResponse> {
  constructor(data?: PartialMessage<DeleteDashboardResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.DeleteDashboardResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteDashboardResponse {
    return new DeleteDashboardResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteDashboardResponse {
    return new DeleteDashboardResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteDashboardResponse {
    return new DeleteDashboardResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: DeleteDashboardResponse | PlainMessage<DeleteDashboardResponse> | undefined,
    b: DeleteDashboardResponse | PlainMessage<DeleteDashboardResponse> | undefined
  ): boolean {
    return proto3.util.equals(DeleteDashboardResponse, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ResetCacheRequest
 */
export class ResetCacheRequest extends Message<ResetCacheRequest> {
  constructor(data?: PartialMessage<ResetCacheRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ResetCacheRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetCacheRequest {
    return new ResetCacheRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetCacheRequest {
    return new ResetCacheRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetCacheRequest {
    return new ResetCacheRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResetCacheRequest | PlainMessage<ResetCacheRequest> | undefined,
    b: ResetCacheRequest | PlainMessage<ResetCacheRequest> | undefined
  ): boolean {
    return proto3.util.equals(ResetCacheRequest, a, b);
  }
}

/**
 * @generated from message marketplace.dashboard.v1.ResetCacheResponse
 */
export class ResetCacheResponse extends Message<ResetCacheResponse> {
  constructor(data?: PartialMessage<ResetCacheResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.dashboard.v1.ResetCacheResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetCacheResponse {
    return new ResetCacheResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetCacheResponse {
    return new ResetCacheResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ResetCacheResponse {
    return new ResetCacheResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ResetCacheResponse | PlainMessage<ResetCacheResponse> | undefined,
    b: ResetCacheResponse | PlainMessage<ResetCacheResponse> | undefined
  ): boolean {
    return proto3.util.equals(ResetCacheResponse, a, b);
  }
}
