import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useProfiler } from '@store/profiler';
import {
  getDefaultValues,
  myProfileNameFormSchema,
} from '@pages/ProfileSettings/ProfileNameForm/config';
import { Form } from '@components/Form';
import { motion } from 'framer-motion';

const SidebarProfileNameForm = () => {
  const { userProfile, updateProfileName } = useProfiler();
  const [isEdited, setIsEdited] = useState(true);

  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
      return;
    }
    if (
      userProfile?.name?.firstName !== data.names.firstName ||
      userProfile?.name?.lastName !== data.names.lastName ||
      userProfile?.name?.nickName !== data.names.nickName
    ) {
      updateProfileName(data);
    }
    setIsEdited(!isEdited);
  };

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile) }}
      submitHandler={(data) => {
        handleSubmit(data);
      }}
      resolverSchema={myProfileNameFormSchema}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <motion.div
          key={!isEdited ? 'show' : 'hide'}
          initial={{
            opacity: isEdited ? 0 : 1,
            x: isEdited ? -10 : 0,
          }}
          animate={{
            opacity: isEdited ? 1 : 0,
            x: isEdited ? 0 : -10,
          }}
          transition={{ duration: 0.3 }}
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
                {userProfile?.name?.firstName} {userProfile?.name?.lastName}
              </Typography>
              <Typography fontWeight="bold">@{userProfile?.name?.nickName}</Typography>
            </Box>
          </Box>
        </motion.div>
      </Box>
    </Form>
  );
};

export default SidebarProfileNameForm;
