import React, { useMemo, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useActivateAvatar, useListAvatars } from '@services/api/profiler/AvatarQueries';
import { ActivateAvatarRequest, ListAvatarsRequest } from '@proto/profiler/avatars/v1/avatar_pb';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  bgcolor: '#263238',
  p: 4,
};

const AvatarLightbox = ({ link, entityId }: { link: string; entityId: string }) => {
  const { data: listAvatars } = useListAvatars(
    new ListAvatarsRequest({
      option: {
        case: 'entityId',
        value: entityId,
      },
    })
  );
  const { mutate } = useActivateAvatar();
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const sources = useMemo(
    () =>
      listAvatars?.avatars?.avatars?.map(
        (avatar) =>
          `${process.env.REACT_APP_AVATARS_ENDPOINT}/${avatar.entityId}/${avatar.fileName}`
      ) || [],
    [listAvatars]
  );

  const openModal = () => {
    const initialIndex = sources.indexOf(link);
    if (initialIndex !== -1) {
      setCurrentIndex(initialIndex);
    }
    setIsOpen(true);
  };

  const handleButtonClick = () => {
    const selectedAvatar = listAvatars?.avatars?.avatars[currentIndex];
    if (selectedAvatar) {
      mutate(
        new ActivateAvatarRequest({
          option: {
            case: 'avatarId',
            value: selectedAvatar.avatarId,
          },
        })
      );
    }
  };

  return (
    <>
      <Avatar
        sx={{ width: 200, height: 200, cursor: 'pointer' }}
        variant="rounded"
        src={link}
        onClick={openModal}
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
            mb={1}
          >
            <Box
              component="img"
              src={sources[currentIndex]}
              alt={`Slide ${currentIndex}`}
              sx={{ width: '400px', height: '400px' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
              sx={{ margin: '10px' }}
            >
              Activate
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {sources.map((src, index) => (
              <Box
                key={src}
                component="img"
                src={src}
                alt={`Thumbnail ${index}`}
                sx={{
                  width: '60px',
                  height: '60px',
                  margin: '0 5px',
                  cursor: 'pointer',
                  border: currentIndex === index ? '2px solid #1769aa' : 'none',
                  borderRadius: '5px',
                }}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </Box>
          <Button
            sx={{ position: 'absolute', top: '50%', left: '5px', transform: 'translateY(-50%)' }}
            onClick={() => setCurrentIndex((currentIndex - 1 + sources.length) % sources.length)}
            variant="contained"
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            sx={{ position: 'absolute', top: '50%', right: '5px', transform: 'translateY(-50%)' }}
            onClick={() => setCurrentIndex((currentIndex + 1) % sources.length)}
            variant="contained"
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AvatarLightbox;
