// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/avatars/v1/avatar.proto (package profiler.avatars.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ActivateAvatarRequest,
  ActivateAvatarResponse,
  DeleteAvatarRequest,
  DeleteAvatarResponse,
  GetAvatarUploadUrlRequest,
  GetAvatarUploadUrlResponse,
  ListAvatarsRequest,
  ListAvatarsResponse,
} from './avatar_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.avatars.v1.AvatarService
 */
export const AvatarService = {
  typeName: 'profiler.avatars.v1.AvatarService',
  methods: {
    /**
     * @generated from rpc profiler.avatars.v1.AvatarService.DeleteAvatar
     */
    deleteAvatar: {
      name: 'DeleteAvatar',
      I: DeleteAvatarRequest,
      O: DeleteAvatarResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.avatars.v1.AvatarService.GetAvatarUploadUrl
     */
    getAvatarUploadUrl: {
      name: 'GetAvatarUploadUrl',
      I: GetAvatarUploadUrlRequest,
      O: GetAvatarUploadUrlResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.avatars.v1.AvatarService.ListAvatars
     */
    listAvatars: {
      name: 'ListAvatars',
      I: ListAvatarsRequest,
      O: ListAvatarsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.avatars.v1.AvatarService.ActivateAvatar
     */
    activateAvatar: {
      name: 'ActivateAvatar',
      I: ActivateAvatarRequest,
      O: ActivateAvatarResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
