import { useDialog } from '@components/hooks/useDialog';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Suspense } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import Button from '@mui/material/Button';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import UpdateContractDescription from '@pages/Marketplace/Dashboards/UpdateContractDescription';
import CreateInterviewForContractForm from '@pages/Marketplace/Dashboards/CreateInterviewForContractorm';
import Container from '@mui/material/Container';
import Tags from '@components/Tags/Tags';
import { useGetDemand } from '@services/api/marketplace/calls';

const ManageContractPage = () => {
  const { demandId, dashboardId } = useParams();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { data: demand } = useGetDemand({ demandId: demandId || '', type: 'contract' });

  const navigate = useNavigate();

  const contract = demand?.demand?.entity?.value;

  const DrawerList = (
    <Box px={3}>
      <Container>
        <Box display="flex" flexDirection="column" py={2} gap={2}>
          <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">
              {demand?.demand?.entity.case?.toUpperCase()}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <EventIcon />
              {/* <Typography variant="subtitle2"> */}
              {/*    {demand?.createdAt && format(new Date(demand?.createdAt), 'dd/MM/yyyy')} */}
              {/* </Typography> */}
            </Box>
          </Box>

          <SelectDemandStatus status={demand?.demand?.status?.status?.toString() || ''} />

          {demand?.demand?.entity?.value?.interviewId && (
            <Link
              className="NavLink"
              to={`/${dashboardId}/interview-manage/${demand?.demand?.entity?.value?.interviewId}`}
            >
              <Typography sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}>
                Connected interview
              </Typography>
            </Link>
          )}
          {!demand?.demand?.entity?.value?.interviewId && (
            <Button variant="outlined" onClick={handleOpen}>
              Create interview for this contract
            </Button>
          )}

          {demand?.demand && <UpdateDemandTraits demand={demand?.demand} />}
          {demand?.demand?.entity?.value?.tags?.tags && (
            <Tags tags={demand?.demand?.entity.value.tags.tags} />
          )}
          {demand?.demand && <UpdateContractDescription demand={demand?.demand} />}
        </Box>

        {dashboardId && demandId && demand?.demand && (
          <Suspense fallback={null}>
            <CreateInterviewForContractForm
              dashboardId={dashboardId}
              contractId={demandId}
              isOpen={isOpen}
              demand={demand?.demand}
              handleCloseModal={handleClose}
            />
          </Suspense>
        )}
      </Container>
    </Box>
  );

  return <div>{DrawerList}</div>;
};

export default ManageContractPage;
