import { createServiceCall, useAppDispatch, useAppSelector } from '@store/helpers';

import {
  bizService,
  CreateOrgServiceRequest,
  CreateOrgServiceResponse,
  ListOrgsServiceRequest,
  ListOrgsServiceResponse,
  SearchOrgsServiceRequest,
  SearchOrgsServiceResponse,
  SetOrgStatusServiceRequest,
  SetOrgStatusServiceResponse,
  UpdateOrgInfoServiceRequest,
  UpdateOrgInfoServiceResponse,
} from '@services/api/profiler/biz';
import { userSlice } from '@store/profiler/slices';
import { combineReducers } from '@reduxjs/toolkit';
import { orgSlices } from '@store/orgs/slices';
import { Org } from '@store/profiler/profiler';
import { useCallback } from 'react';
import { Tag } from '@proto/profiler/tags/v1/tags_pb';
import { getTagType } from '@store/profiler/helpers';
import {
  CreateOrgRequest,
  ListOrgsRequest,
  SearchOrgsRequest,
  SetOrgStatusRequest,
  UpdateOrgInfoRequest,
} from '@proto/profiler/biz/v1/org_pb';
import { squadsSlices } from '@store/squads/slices';
import { UUIDS } from '@proto/grpc/type/v1/uuid_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { TMember } from '@services/api/profiler/profile';
import { filterEntitiesByType } from '@utils/filteredEntitiesByType';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { getListMemberShips } from '@store/profiler';
import { ListProfileMembershipsRequest } from '@proto/profiler/biz/v1/membership_pb';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { showSnackbar } from '@store/snackbars';

const OrgFilterParam = withDefault(ArrayParam, []);

const {
  actions: { setUserOrgs, setPinnedOrgs, setExploreOrgs, setCurrentOrg, setPaginationOrgs },
} = orgSlices;

const {
  actions: { deleteSelectedProfile },
} = userSlice;
export const orgsReducer = combineReducers({
  orgs: orgSlices.reducer,
});
export const getListUserOrgs = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  orgSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setUserOrgs(orgs));
  },
  ({ dispatch }) => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    dispatch(setUserOrgs({ orgs: undefined }));
  }
);
const getListPinOrgs = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  orgSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setPinnedOrgs(orgs));
  }
);

const createOrg = createServiceCall<CreateOrgServiceResponse, CreateOrgServiceRequest>(
  orgSlices.name,
  bizService.createOrg,
  ({ response, dispatch }) => {
    dispatch(
      showSnackbar({ id: 'create-org', message: 'Organization created', severity: 'success' })
    );
    dispatch(
      getListMemberShips(
        new ListProfileMembershipsRequest({
          entityType: TypeOfEntity.UNSPECIFIED,
        })
      )
    );
  },
  ({ dispatch, errorMessage }) => {
    dispatch(showSnackbar({ id: 'create-org', message: errorMessage, severity: 'error' }));
  }
);

const setOrgStatus = createServiceCall<SetOrgStatusServiceResponse, SetOrgStatusServiceRequest>(
  orgSlices.name,
  bizService.setOrgStatus,
  ({ response, dispatch }) => {
    // dispatch(deleteOrg({orgId}));
  }
);
const searchOrgs = createServiceCall<SearchOrgsServiceResponse, SearchOrgsServiceRequest>(
  orgSlices.name,
  bizService.searchOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setExploreOrgs(orgs));
  }
);
const searchPaginationOrgs = createServiceCall<SearchOrgsServiceResponse, SearchOrgsServiceRequest>(
  orgSlices.name,
  bizService.searchOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setPaginationOrgs(orgs));
  }
);
export const getCurrentOrg = createServiceCall<ListOrgsServiceResponse, ListOrgsServiceRequest>(
  squadsSlices.name,
  bizService.listOrgs,
  ({ response: { orgs }, dispatch }) => {
    dispatch(setCurrentOrg(orgs));
  }
);

const updateOrgInfo = createServiceCall<UpdateOrgInfoServiceResponse, UpdateOrgInfoServiceRequest>(
  orgSlices.name,
  bizService.updateOrgInfo,
  ({ response, dispatch }) => {}
);

export const useOrgs = (): {
  listUserOrgs: Org[] | undefined;
  listPinnedOrgs: Org[] | undefined;
  listExploreOrgs: Org[] | undefined;
  currentOrg: Org | undefined;
  orgsFilters: any[];
  queryParams: any;
  createOrg: (payload: CreateOrgServiceRequest) => void;
  setOrgStatus: (payload: { groupId: string; status: boolean }) => void;
  getListUserOrgs: (payload: TMember[]) => void;
  getListPinOrgs: (payload: string[]) => void;
  searchOrgs: (payload: { name?: string; industry_domain?: number[] }) => void;
  setOrgsFilters: (payload: string[]) => void;
  getCurrentOrg: (payload: string[]) => void;
  searchPaginationOrgs: (payload: Timestamp) => void;
  updateOrgInfo: (payload: { orgId: string; about: string }) => void;
} => {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useQueryParams({
    orgsFilters: OrgFilterParam,
  });

  return {
    listUserOrgs: useAppSelector((state) => state.orgsReducer.orgs.listUserOrgs),
    listPinnedOrgs: useAppSelector((state) => state.orgsReducer.orgs.pinnedOrgs),
    listExploreOrgs: useAppSelector((state) => state.orgsReducer.orgs.exploreOrgsList),
    currentOrg: useAppSelector((state) => state.orgsReducer.orgs.currentOrg),
    orgsFilters: useAppSelector((state) => state.orgsReducer.orgs.orgsFilters),
    queryParams: query,
    createOrg: useCallback(
      (payload) => {
        const orgTags: Tag[] = [];
        Object.entries(payload).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.map((element) => {
              const tag = new Tag({
                tagId: element.tagId,
                value: element.value,
                tagType: getTagType[key as keyof typeof getTagType],
                active: true,
              });
              return orgTags.push(tag);
            });
          }
        });
        dispatch(
          createOrg(
            new CreateOrgRequest({
              name: payload.name,
              about: payload.about,
              tags: { tags: orgTags },
            })
          )
        );
      },
      [dispatch]
    ),
    getListPinOrgs: useCallback(
      (payload) => {
        dispatch(
          getListPinOrgs(
            new ListOrgsRequest({
              orgIds: new UUIDS({
                ids: payload,
              }),
            })
          )
        );
      },
      [dispatch]
    ),
    setOrgStatus: useCallback(
      (payload) => {
        const data = new SetOrgStatusRequest({
          orgId: payload?.groupId,
          active: payload?.status,
        });
        dispatch(setOrgStatus(data));
      },
      [dispatch]
    ),
    searchPaginationOrgs: useCallback(
      (payload) => {
        dispatch(
          searchPaginationOrgs(
            new SearchOrgsRequest({
              created: payload,
            })
          )
        );
      },
      [dispatch]
    ),
    getListUserOrgs: useCallback(
      (payload) => {
        if (payload && filterEntitiesByType('TYPE_OF_ENTITY_ORG', payload).length)
          dispatch(
            getListUserOrgs(
              new ListOrgsRequest({
                orgIds: new UUIDS({
                  ids: filterEntitiesByType('TYPE_OF_ENTITY_ORG', payload),
                }),
              })
            )
          );
      },
      [dispatch]
    ),

    setOrgsFilters: useCallback(
      (payload) => {
        if (payload) {
          setQuery((prevQuery) => {
            const newQuery = { ...prevQuery };
            if (payload) {
              newQuery.orgsFilters = payload;
            }
            return newQuery;
          });
        }
      },
      [setQuery]
    ),

    searchOrgs: useCallback(
      (payload) => {
        if (payload.name !== undefined) {
          return dispatch(
            searchOrgs(
              new SearchOrgsRequest({
                name: payload.name,
              })
            )
          );
        }
        if (payload.industry_domain !== undefined) {
          return dispatch(
            searchOrgs(
              new SearchOrgsRequest({
                industryDomanIds: payload.industry_domain,
              })
            )
          );
        }

        return dispatch(searchOrgs(new SearchOrgsRequest({})));
      },
      [dispatch]
    ),
    getCurrentOrg: useCallback(
      (payload) =>
        dispatch(
          getCurrentOrg(
            new ListOrgsRequest({
              orgIds: new UUIDS({
                ids: payload,
              }),
            })
          )
        ),
      [dispatch]
    ),
    updateOrgInfo: useCallback(
      (payload) => {
        dispatch(
          updateOrgInfo(
            new UpdateOrgInfoRequest({
              orgId: payload.orgId,
              about: payload.about,
            })
          )
        );
      },
      [dispatch]
    ),
  };
};
