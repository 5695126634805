import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useOrgs } from '@store/orgs';
import { setGroupStatus } from '../../helpers/setGroupStatus';

type OrgStatusSwitcherProps = {
  orgStatus: boolean | undefined;
  setStatusOrg: Dispatch<SetStateAction<boolean>>;
};

const OrgStatusSwitcher = (props: OrgStatusSwitcherProps) => {
  const { orgStatus, setStatusOrg } = props;
  const { setOrgStatus, currentOrg } = useOrgs();

  return (
    <Box>
      <Switch
        defaultChecked={currentOrg?.active}
        color="success"
        onChange={(e, checked) => {
          setStatusOrg(checked);
          setGroupStatus(setOrgStatus, currentOrg?.orgId, checked);
        }}
      />
      {orgStatus ? (
        <Typography textAlign="center">Active</Typography>
      ) : (
        <Typography textAlign="center">Inactive</Typography>
      )}
    </Box>
  );
};

export default OrgStatusSwitcher;
