import { useProfiler } from '@store/profiler';
import * as React from 'react';
import { useTags } from 'store/tags';
import {
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import TagAutocomplete from '@components/TagAutocomplete/TagAutocomplete';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const techSpec = [
  'Security engineer',
  'System Architect',
  'Site Reliability Engineer',
  'Software Engineer',
  'Quality Assurance Engineer',
  'Machine Learning Engineer',
  'Data Scientist',
];

type ProfileTagsProps = {
  isOnboarding?: () => void;
};

function organizeTagsByType(tags: TTag[]): { [key: string]: TTag[] } {
  const initialStructure: { [key: string]: TTag[] } = {
    TAG_TYPE_INDUSTRY_DOMAIN: [],
    TAG_TYPE_LANGUAGE: [],
    TAG_TYPE_COUNTRY: [],
    TAG_TYPE_PROGRAMMING_LANGUAGE: [],
    TAG_TYPE_ROLE: [],
  };

  return tags.reduce((acc, tag) => {
    if (!acc[tag.tagType]) {
      acc[tag.tagType] = [];
    }
    acc[tag.tagType].push(tag);
    return acc;
  }, initialStructure);
}

export const ProfileTags = (props: ProfileTagsProps) => {
  const { isOnboarding } = props;
  const { userProfile } = useProfiler();
  const {
    getRoles,
    getCountries,
    getLanguages,
    getProgrammingLanguages,
    getIndustryDomains,
    tags,
    addTags,
    removeTags,
    setTagstatus,
  } = useTags();
  const defaultTags = userProfile?.tags?.tags && organizeTagsByType(userProfile?.tags?.tags);

  const getTags = {
    TAG_TYPE_COUNTRY: getCountries,
    TAG_TYPE_ROLE: getRoles,
    TAG_TYPE_LANGUAGE: getLanguages,
    TAG_TYPE_INDUSTRY_DOMAIN: getIndustryDomains,
    TAG_TYPE_PROGRAMMING_LANGUAGE: getProgrammingLanguages,
  };

  const tagsLabels = {
    TAG_TYPE_COUNTRY: 'Countries',
    TAG_TYPE_ROLE: 'Roles',
    TAG_TYPE_LANGUAGE: 'Languages',
    TAG_TYPE_INDUSTRY_DOMAIN: 'Industry domains',
    TAG_TYPE_PROGRAMMING_LANGUAGE: 'Programming languages',
  };

  const tagTypetoEnumValue = {
    TAG_TYPE_COUNTRY: TagType.COUNTRY,
    TAG_TYPE_ROLE: TagType.ROLE,
    TAG_TYPE_LANGUAGE: TagType.LANGUAGE,
    TAG_TYPE_INDUSTRY_DOMAIN: TagType.INDUSTRY_DOMAIN,
    TAG_TYPE_PROGRAMMING_LANGUAGE: TagType.PROGRAMMING_LANGUAGE,
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {defaultTags &&
        Object.entries(defaultTags).map(([key, value]) => {
          return (
            <TagAutocomplete
              items={tags[key]}
              getItems={getTags[key as keyof typeof getTags]}
              defaultItems={value}
              label={tagsLabels[key as keyof typeof tagsLabels]}
              statusChanger={(id, status) => {
                statusChangeHandler(
                  id,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: TypeOfEntity.USER,
                    entityId: userProfile?.profileId,
                    status,
                  },
                  setTagstatus
                );
              }}
              onSelect={(tag: TTag) =>
                selectHandler(
                  tag,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: TypeOfEntity.USER,
                    entityId: userProfile?.profileId,
                  },
                  addTags
                )
              }
              onRemove={(tag: TTag) =>
                removeHandler(
                  tag,
                  {
                    tagType: tagTypetoEnumValue[key as keyof typeof tagTypetoEnumValue],
                    entity: TypeOfEntity.USER,
                    entityId: userProfile?.profileId,
                  },
                  removeTags
                )
              }
            />
          );
        })}
      {isOnboarding && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Button variant="contained" onClick={isOnboarding}>
            Next
          </Button>
          <Button onClick={isOnboarding} variant="contained">
            Skip
          </Button>
        </Box>
      )}
    </Box>
  );
};
