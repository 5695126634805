// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file profiler/user/v1/profile.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entities, Entity } from '../../../grpc/type/v1/entity_pb.js';
import { Tags } from '../../tags/v1/tags_pb.js';
import { IpV4 } from '../../../grpc/type/v1/ip_pb.js';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';
import { Status } from '../../../grpc/type/v1/status_pb.js';

/**
 * @generated from message profiler.user.v1.PinnedItem
 */
export class PinnedItem extends Message<PinnedItem> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: google.protobuf.Timestamp pinned_at = 2;
   */
  pinnedAt?: Timestamp;

  constructor(data?: PartialMessage<PinnedItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinnedItem';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'pinned_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinnedItem {
    return new PinnedItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinnedItem {
    return new PinnedItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinnedItem {
    return new PinnedItem().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinnedItem | PlainMessage<PinnedItem> | undefined,
    b: PinnedItem | PlainMessage<PinnedItem> | undefined
  ): boolean {
    return proto3.util.equals(PinnedItem, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.Profile
 */
export class Profile extends Message<Profile> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 3;
   */
  birthDate?: Timestamp;

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 4;
   */
  name?: ProfileName;

  /**
   * @generated from field: profiler.user.v1.Traits traits = 5;
   */
  traits?: Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 6;
   */
  tags?: Tags;

  /**
   * @generated from field: string avatar = 7;
   */
  avatar = '';

  /**
   * @generated from field: repeated profiler.user.v1.PinnedItem pinned_items = 8;
   */
  pinnedItems: PinnedItem[] = [];

  constructor(data?: PartialMessage<Profile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Profile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'birth_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'name', kind: 'message', T: ProfileName },
    { no: 5, name: 'traits', kind: 'message', T: Traits },
    { no: 6, name: 'tags', kind: 'message', T: Tags },
    { no: 7, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'pinned_items', kind: 'message', T: PinnedItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profile {
    return new Profile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profile {
    return new Profile().fromJsonString(jsonString, options);
  }

  static equals(
    a: Profile | PlainMessage<Profile> | undefined,
    b: Profile | PlainMessage<Profile> | undefined
  ): boolean {
    return proto3.util.equals(Profile, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.Profiles
 */
export class Profiles extends Message<Profiles> {
  /**
   * @generated from field: repeated profiler.user.v1.Profile profiles = 1;
   */
  profiles: Profile[] = [];

  constructor(data?: PartialMessage<Profiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Profiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Profiles {
    return new Profiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Profiles {
    return new Profiles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Profiles {
    return new Profiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: Profiles | PlainMessage<Profiles> | undefined,
    b: Profiles | PlainMessage<Profiles> | undefined
  ): boolean {
    return proto3.util.equals(Profiles, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetProfileRequest
 */
export class GetProfileRequest extends Message<GetProfileRequest> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  constructor(data?: PartialMessage<GetProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined,
    b: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetProfileResponse
 */
export class GetProfileResponse extends Message<GetProfileResponse> {
  /**
   * @generated from field: profiler.user.v1.Profile profile = 1;
   */
  profile?: Profile;

  constructor(data?: PartialMessage<GetProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetProfileResponse {
    return new GetProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined,
    b: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetMyProfileRequest
 */
export class GetMyProfileRequest extends Message<GetMyProfileRequest> {
  constructor(data?: PartialMessage<GetMyProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetMyProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMyProfileRequest {
    return new GetMyProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMyProfileRequest {
    return new GetMyProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMyProfileRequest {
    return new GetMyProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMyProfileRequest | PlainMessage<GetMyProfileRequest> | undefined,
    b: GetMyProfileRequest | PlainMessage<GetMyProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetMyProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetMyProfileResponse
 */
export class GetMyProfileResponse extends Message<GetMyProfileResponse> {
  /**
   * @generated from field: profiler.user.v1.Profile profile = 1;
   */
  profile?: Profile;

  constructor(data?: PartialMessage<GetMyProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetMyProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile', kind: 'message', T: Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMyProfileResponse {
    return new GetMyProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMyProfileResponse {
    return new GetMyProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetMyProfileResponse {
    return new GetMyProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetMyProfileResponse | PlainMessage<GetMyProfileResponse> | undefined,
    b: GetMyProfileResponse | PlainMessage<GetMyProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetMyProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ProfileName
 */
export class ProfileName extends Message<ProfileName> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = '';

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = '';

  /**
   * @generated from field: string nick_name = 3;
   */
  nickName = '';

  constructor(data?: PartialMessage<ProfileName>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ProfileName';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'first_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'last_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'nick_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileName {
    return new ProfileName().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileName {
    return new ProfileName().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileName {
    return new ProfileName().fromJsonString(jsonString, options);
  }

  static equals(
    a: ProfileName | PlainMessage<ProfileName> | undefined,
    b: ProfileName | PlainMessage<ProfileName> | undefined
  ): boolean {
    return proto3.util.equals(ProfileName, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.CreateProfileRequest
 */
export class CreateProfileRequest extends Message<CreateProfileRequest> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  /**
   * @generated from field: optional profiler.user.v1.ProfileName name = 2;
   */
  name?: ProfileName;

  /**
   * @generated from field: string country = 3;
   */
  country = '';

  /**
   * @generated from field: grpc.type.v1.IpV4 ip = 4;
   */
  ip?: IpV4;

  constructor(data?: PartialMessage<CreateProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.CreateProfileRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'message', T: ProfileName, opt: true },
    { no: 3, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'ip', kind: 'message', T: IpV4 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileRequest {
    return new CreateProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileRequest {
    return new CreateProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined,
    b: CreateProfileRequest | PlainMessage<CreateProfileRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.CreateProfileResponse
 */
export class CreateProfileResponse extends Message<CreateProfileResponse> {
  constructor(data?: PartialMessage<CreateProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.CreateProfileResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProfileResponse {
    return new CreateProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CreateProfileResponse {
    return new CreateProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined,
    b: CreateProfileResponse | PlainMessage<CreateProfileResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateProfileResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ChangeProfileStatusRequest
 */
export class ChangeProfileStatusRequest extends Message<ChangeProfileStatusRequest> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  constructor(data?: PartialMessage<ChangeProfileStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ChangeProfileStatusRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusRequest {
    return new ChangeProfileStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChangeProfileStatusRequest | PlainMessage<ChangeProfileStatusRequest> | undefined,
    b: ChangeProfileStatusRequest | PlainMessage<ChangeProfileStatusRequest> | undefined
  ): boolean {
    return proto3.util.equals(ChangeProfileStatusRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ChangeProfileStatusResponse
 */
export class ChangeProfileStatusResponse extends Message<ChangeProfileStatusResponse> {
  /**
   * @generated from field: bool active = 1;
   */
  active = false;

  constructor(data?: PartialMessage<ChangeProfileStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ChangeProfileStatusResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ChangeProfileStatusResponse {
    return new ChangeProfileStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ChangeProfileStatusResponse | PlainMessage<ChangeProfileStatusResponse> | undefined,
    b: ChangeProfileStatusResponse | PlainMessage<ChangeProfileStatusResponse> | undefined
  ): boolean {
    return proto3.util.equals(ChangeProfileStatusResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListProfilesRequest
 */
export class ListProfilesRequest extends Message<ListProfilesRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profiles = 1;
   */
  profiles?: UUIDS;

  constructor(data?: PartialMessage<ListProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesRequest {
    return new ListProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesRequest {
    return new ListProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined,
    b: ListProfilesRequest | PlainMessage<ListProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListProfilesResponse
 */
export class ListProfilesResponse extends Message<ListProfilesResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 1;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProfilesResponse {
    return new ListProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfilesResponse {
    return new ListProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined,
    b: ListProfilesResponse | PlainMessage<ListProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfilePersonalInfoRequest
 */
export class UpdateProfilePersonalInfoRequest extends Message<UpdateProfilePersonalInfoRequest> {
  /**
   * @generated from field: profiler.user.v1.ProfileName names = 1;
   */
  names?: ProfileName;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 2;
   */
  birthDate?: Timestamp;

  constructor(data?: PartialMessage<UpdateProfilePersonalInfoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfilePersonalInfoRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'names', kind: 'message', T: ProfileName },
    { no: 2, name: 'birth_date', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoRequest {
    return new UpdateProfilePersonalInfoRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProfilePersonalInfoRequest
      | PlainMessage<UpdateProfilePersonalInfoRequest>
      | undefined,
    b: UpdateProfilePersonalInfoRequest | PlainMessage<UpdateProfilePersonalInfoRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfilePersonalInfoRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfilePersonalInfoResponse
 */
export class UpdateProfilePersonalInfoResponse extends Message<UpdateProfilePersonalInfoResponse> {
  /**
   * @generated from field: profiler.user.v1.ProfileName names = 1;
   */
  names?: ProfileName;

  /**
   * @generated from field: google.protobuf.Timestamp birth_date = 2;
   */
  birthDate?: Timestamp;

  constructor(data?: PartialMessage<UpdateProfilePersonalInfoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfilePersonalInfoResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'names', kind: 'message', T: ProfileName },
    { no: 2, name: 'birth_date', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfilePersonalInfoResponse {
    return new UpdateProfilePersonalInfoResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | UpdateProfilePersonalInfoResponse
      | PlainMessage<UpdateProfilePersonalInfoResponse>
      | undefined,
    b:
      | UpdateProfilePersonalInfoResponse
      | PlainMessage<UpdateProfilePersonalInfoResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfilePersonalInfoResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListInterviewersRequest
 */
export class ListInterviewersRequest extends Message<ListInterviewersRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS profiles = 1;
   */
  profiles?: UUIDS;

  constructor(data?: PartialMessage<ListInterviewersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListInterviewersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersRequest {
    return new ListInterviewersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInterviewersRequest | PlainMessage<ListInterviewersRequest> | undefined,
    b: ListInterviewersRequest | PlainMessage<ListInterviewersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewersRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.ListInterviewersResponse
 */
export class ListInterviewersResponse extends Message<ListInterviewersResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 1;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListInterviewersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.ListInterviewersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListInterviewersResponse {
    return new ListInterviewersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListInterviewersResponse | PlainMessage<ListInterviewersResponse> | undefined,
    b: ListInterviewersResponse | PlainMessage<ListInterviewersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListInterviewersResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesFilter
 */
export class SearchSupplyProfilesFilter extends Message<SearchSupplyProfilesFilter> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  /**
   * @generated from field: repeated int32 tag_ids = 2;
   */
  tagIds: number[] = [];

  constructor(data?: PartialMessage<SearchSupplyProfilesFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesFilter';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
    { no: 2, name: 'tag_ids', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesFilter {
    return new SearchSupplyProfilesFilter().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesFilter | PlainMessage<SearchSupplyProfilesFilter> | undefined,
    b: SearchSupplyProfilesFilter | PlainMessage<SearchSupplyProfilesFilter> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesFilter, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SupplyProfile
 */
export class SupplyProfile extends Message<SupplyProfile> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  /**
   * @generated from field: profiler.user.v1.Traits traits = 2;
   */
  traits?: Traits;

  /**
   * @generated from field: profiler.tags.v1.Tags tags = 3;
   */
  tags?: Tags;

  /**
   * @generated from field: grpc.type.v1.Entities membership = 4;
   */
  membership?: Entities;

  /**
   * @generated from field: string avatar = 5;
   */
  avatar = '';

  /**
   * @generated from field: profiler.user.v1.ProfileName name = 6;
   */
  name?: ProfileName;

  constructor(data?: PartialMessage<SupplyProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SupplyProfile';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'traits', kind: 'message', T: Traits },
    { no: 3, name: 'tags', kind: 'message', T: Tags },
    { no: 4, name: 'membership', kind: 'message', T: Entities },
    { no: 5, name: 'avatar', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'name', kind: 'message', T: ProfileName },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyProfile {
    return new SupplyProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyProfile {
    return new SupplyProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyProfile {
    return new SupplyProfile().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyProfile | PlainMessage<SupplyProfile> | undefined,
    b: SupplyProfile | PlainMessage<SupplyProfile> | undefined
  ): boolean {
    return proto3.util.equals(SupplyProfile, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SupplyProfiles
 */
export class SupplyProfiles extends Message<SupplyProfiles> {
  /**
   * @generated from field: repeated profiler.user.v1.SupplyProfile profiles = 1;
   */
  profiles: SupplyProfile[] = [];

  constructor(data?: PartialMessage<SupplyProfiles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SupplyProfiles';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: SupplyProfile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupplyProfiles {
    return new SupplyProfiles().fromJsonString(jsonString, options);
  }

  static equals(
    a: SupplyProfiles | PlainMessage<SupplyProfiles> | undefined,
    b: SupplyProfiles | PlainMessage<SupplyProfiles> | undefined
  ): boolean {
    return proto3.util.equals(SupplyProfiles, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesRequest
 */
export class SearchSupplyProfilesRequest extends Message<SearchSupplyProfilesRequest> {
  /**
   * map<demand_id, filter>
   *
   * @generated from field: map<string, profiler.user.v1.SearchSupplyProfilesFilter> search = 1;
   */
  search: { [key: string]: SearchSupplyProfilesFilter } = {};

  constructor(data?: PartialMessage<SearchSupplyProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'search',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: SearchSupplyProfilesFilter },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesRequest {
    return new SearchSupplyProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesRequest | PlainMessage<SearchSupplyProfilesRequest> | undefined,
    b: SearchSupplyProfilesRequest | PlainMessage<SearchSupplyProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SearchSupplyProfilesResponse
 */
export class SearchSupplyProfilesResponse extends Message<SearchSupplyProfilesResponse> {
  /**
   * map<demand_id, profiles>
   *
   * @generated from field: map<string, profiler.user.v1.SupplyProfiles> profiles = 1;
   */
  profiles: { [key: string]: SupplyProfiles } = {};

  constructor(data?: PartialMessage<SearchSupplyProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SearchSupplyProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'profiles',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: SupplyProfiles },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SearchSupplyProfilesResponse {
    return new SearchSupplyProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SearchSupplyProfilesResponse | PlainMessage<SearchSupplyProfilesResponse> | undefined,
    b: SearchSupplyProfilesResponse | PlainMessage<SearchSupplyProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SearchSupplyProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SelectProfilesRequest
 */
export class SelectProfilesRequest extends Message<SelectProfilesRequest> {
  /**
   * @generated from field: string selector = 1;
   */
  selector = '';

  constructor(data?: PartialMessage<SelectProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SelectProfilesRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'selector', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SelectProfilesRequest {
    return new SelectProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SelectProfilesRequest {
    return new SelectProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SelectProfilesRequest {
    return new SelectProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SelectProfilesRequest | PlainMessage<SelectProfilesRequest> | undefined,
    b: SelectProfilesRequest | PlainMessage<SelectProfilesRequest> | undefined
  ): boolean {
    return proto3.util.equals(SelectProfilesRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.SelectProfilesResponse
 */
export class SelectProfilesResponse extends Message<SelectProfilesResponse> {
  /**
   * @generated from field: map<string, profiler.user.v1.ProfileName> profiles = 1;
   */
  profiles: { [key: string]: ProfileName } = {};

  constructor(data?: PartialMessage<SelectProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.SelectProfilesResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'profiles',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ProfileName },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SelectProfilesResponse {
    return new SelectProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SelectProfilesResponse {
    return new SelectProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SelectProfilesResponse {
    return new SelectProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SelectProfilesResponse | PlainMessage<SelectProfilesResponse> | undefined,
    b: SelectProfilesResponse | PlainMessage<SelectProfilesResponse> | undefined
  ): boolean {
    return proto3.util.equals(SelectProfilesResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.Traits
 */
export class Traits extends Message<Traits> {
  /**
   * $ per hour
   *
   * @generated from field: int32 rate = 1;
   */
  rate = 0;

  /**
   * hours per week
   *
   * @generated from field: int32 capacity = 2;
   */
  capacity = 0;

  /**
   * is profile an interviewer
   *
   * @generated from field: grpc.type.v1.Status interviewer = 3;
   */
  interviewer = Status.UNSPECIFIED;

  /**
   * is profile a member of squad
   *
   * @generated from field: grpc.type.v1.Status squader = 4;
   */
  squader = Status.UNSPECIFIED;

  /**
   * years of experience
   *
   * @generated from field: int32 experience = 5;
   */
  experience = 0;

  constructor(data?: PartialMessage<Traits>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.Traits';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'rate', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'capacity', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'interviewer', kind: 'enum', T: proto3.getEnumType(Status) },
    { no: 4, name: 'squader', kind: 'enum', T: proto3.getEnumType(Status) },
    { no: 5, name: 'experience', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Traits {
    return new Traits().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Traits {
    return new Traits().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Traits {
    return new Traits().fromJsonString(jsonString, options);
  }

  static equals(
    a: Traits | PlainMessage<Traits> | undefined,
    b: Traits | PlainMessage<Traits> | undefined
  ): boolean {
    return proto3.util.equals(Traits, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfileTraitsRequest
 */
export class UpdateProfileTraitsRequest extends Message<UpdateProfileTraitsRequest> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  constructor(data?: PartialMessage<UpdateProfileTraitsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfileTraitsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsRequest {
    return new UpdateProfileTraitsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProfileTraitsRequest | PlainMessage<UpdateProfileTraitsRequest> | undefined,
    b: UpdateProfileTraitsRequest | PlainMessage<UpdateProfileTraitsRequest> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileTraitsRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UpdateProfileTraitsResponse
 */
export class UpdateProfileTraitsResponse extends Message<UpdateProfileTraitsResponse> {
  /**
   * @generated from field: profiler.user.v1.Traits traits = 1;
   */
  traits?: Traits;

  constructor(data?: PartialMessage<UpdateProfileTraitsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UpdateProfileTraitsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'traits', kind: 'message', T: Traits },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UpdateProfileTraitsResponse {
    return new UpdateProfileTraitsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UpdateProfileTraitsResponse | PlainMessage<UpdateProfileTraitsResponse> | undefined,
    b: UpdateProfileTraitsResponse | PlainMessage<UpdateProfileTraitsResponse> | undefined
  ): boolean {
    return proto3.util.equals(UpdateProfileTraitsResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetTermsOfUseRequest
 */
export class GetTermsOfUseRequest extends Message<GetTermsOfUseRequest> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  constructor(data?: PartialMessage<GetTermsOfUseRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetTermsOfUseRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTermsOfUseRequest {
    return new GetTermsOfUseRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined,
    b: GetTermsOfUseRequest | PlainMessage<GetTermsOfUseRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetTermsOfUseRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.GetTermsOfUseResponse
 */
export class GetTermsOfUseResponse extends Message<GetTermsOfUseResponse> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  /**
   * @generated from field: google.protobuf.Timestamp accepted = 2;
   */
  accepted?: Timestamp;

  /**
   * @generated from field: grpc.type.v1.IpV4 ip = 3;
   */
  ip?: IpV4;

  constructor(data?: PartialMessage<GetTermsOfUseResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.GetTermsOfUseResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'accepted', kind: 'message', T: Timestamp },
    { no: 3, name: 'ip', kind: 'message', T: IpV4 },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetTermsOfUseResponse {
    return new GetTermsOfUseResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetTermsOfUseResponse | PlainMessage<GetTermsOfUseResponse> | undefined,
    b: GetTermsOfUseResponse | PlainMessage<GetTermsOfUseResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetTermsOfUseResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.PinItemRequest
 */
export class PinItemRequest extends Message<PinItemRequest> {
  /**
   * @generated from field: profiler.user.v1.PinnedItem pin = 1;
   */
  pin?: PinnedItem;

  constructor(data?: PartialMessage<PinItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinItemRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pin', kind: 'message', T: PinnedItem },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinItemRequest {
    return new PinItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinItemRequest {
    return new PinItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinItemRequest {
    return new PinItemRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinItemRequest | PlainMessage<PinItemRequest> | undefined,
    b: PinItemRequest | PlainMessage<PinItemRequest> | undefined
  ): boolean {
    return proto3.util.equals(PinItemRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.PinItemResponse
 */
export class PinItemResponse extends Message<PinItemResponse> {
  constructor(data?: PartialMessage<PinItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.PinItemResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PinItemResponse {
    return new PinItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PinItemResponse {
    return new PinItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PinItemResponse {
    return new PinItemResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: PinItemResponse | PlainMessage<PinItemResponse> | undefined,
    b: PinItemResponse | PlainMessage<PinItemResponse> | undefined
  ): boolean {
    return proto3.util.equals(PinItemResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemRequest
 */
export class UnpinItemRequest extends Message<UnpinItemRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UnpinItemRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnpinItemRequest {
    return new UnpinItemRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemRequest | PlainMessage<UnpinItemRequest> | undefined,
    b: UnpinItemRequest | PlainMessage<UnpinItemRequest> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemResponse
 */
export class UnpinItemResponse extends Message<UnpinItemResponse> {
  constructor(data?: PartialMessage<UnpinItemResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnpinItemResponse {
    return new UnpinItemResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemResponse | PlainMessage<UnpinItemResponse> | undefined,
    b: UnpinItemResponse | PlainMessage<UnpinItemResponse> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemResponse, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemForEveryoneRequest
 */
export class UnpinItemForEveryoneRequest extends Message<UnpinItemForEveryoneRequest> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  constructor(data?: PartialMessage<UnpinItemForEveryoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemForEveryoneRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneRequest {
    return new UnpinItemForEveryoneRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemForEveryoneRequest | PlainMessage<UnpinItemForEveryoneRequest> | undefined,
    b: UnpinItemForEveryoneRequest | PlainMessage<UnpinItemForEveryoneRequest> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemForEveryoneRequest, a, b);
  }
}

/**
 * @generated from message profiler.user.v1.UnpinItemForEveryoneResponse
 */
export class UnpinItemForEveryoneResponse extends Message<UnpinItemForEveryoneResponse> {
  constructor(data?: PartialMessage<UnpinItemForEveryoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.user.v1.UnpinItemForEveryoneResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): UnpinItemForEveryoneResponse {
    return new UnpinItemForEveryoneResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: UnpinItemForEveryoneResponse | PlainMessage<UnpinItemForEveryoneResponse> | undefined,
    b: UnpinItemForEveryoneResponse | PlainMessage<UnpinItemForEveryoneResponse> | undefined
  ): boolean {
    return proto3.util.equals(UnpinItemForEveryoneResponse, a, b);
  }
}
