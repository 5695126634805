import { Form } from '@components/Form';
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { InputController } from '@components/Form/controllers/InputController';
import { TOrg } from '@services/api/profiler/biz';
import { useOrgs } from '@store/orgs';
import Joi from 'joi';
import OrgStatusSwitcher from '@pages/Orgs/OrgStatusSwitcher';

export enum FieldNames {
  ABOUT = 'about',
  ORG_ID = 'orgId',
}

export const FIELD_LABELS = {
  [FieldNames.ABOUT]: 'Organization about',
};

export const UpdateOrgInfoResolverSchema = {
  [FieldNames.ORG_ID]: Joi.string().allow(null),
  [FieldNames.ABOUT]: Joi.string().required(),
};

type UpdateOrgInfoFormProps = {
  org: TOrg;
};

const UpdateOrgInfoForm = (props: UpdateOrgInfoFormProps) => {
  const { org } = props;
  const { updateOrgInfo } = useOrgs();
  const [isEdited, setIsEdited] = useState(true);
  const [orgStatus, setStatusOrg] = useState(org.active);

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: { orgId: org?.orgId, about: org?.about } }}
      submitHandler={(data) => {
        if (isEdited) {
          setIsEdited(!isEdited);
          return;
        }
        updateOrgInfo(data);
        setIsEdited(!isEdited);
      }}
      resolverSchema={UpdateOrgInfoResolverSchema}
    >
      {isEdited ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
          <Typography flexGrow="grow" sx={{ width: '100%', color: '#bbb' }} variant="subtitle2">
            {org?.about || 'No title'}
          </Typography>
          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </LoadingButton>
          {orgStatus ? (
            <Typography textAlign="center">Active</Typography>
          ) : (
            <Typography textAlign="center">Inactive</Typography>
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          <InputController
            fullWidth
            name={FieldNames.ABOUT}
            label={FIELD_LABELS[FieldNames.ABOUT]}
            variant="standard"
          />

          <LoadingButton type="submit">
            <Tooltip placement="top-start" title="Edit dashboard title">
              <IconButton>
                {' '}
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </LoadingButton>
          <OrgStatusSwitcher orgStatus={orgStatus} setStatusOrg={setStatusOrg} />
        </Box>
      )}
    </Form>
  );
};
export default UpdateOrgInfoForm;
