import Box from '@mui/material/Box';
import { useGetProduct } from '@services/api/marketplace/products';
import { useParams } from 'react-router-dom';
import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Tags from '@components/Tags/Tags';
import * as React from 'react';
import Link from '@mui/material/Link/Link';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const Product = () => {
  const { productId } = useParams();
  const { data: product, error: productError } = useGetProduct(productId || '');

  return (
    <Page description="Product" title="Product">
      <Container>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box>
            <Typography variant="subtitle1">{product?.product?.title}</Typography>
            <Link target="blank" href={product?.product?.demoUrl}>
              {product?.product?.demoUrl}
            </Link>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="subtitle2">Version:</Typography>
            <Typography>{product?.product?.version}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Specification:</Typography>
            <Typography>{product?.product?.specification}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Description:</Typography>
            <Typography>
              {product?.product?.description &&
                HTMLReactParser(
                  stateToHTML(convertFromRaw(JSON.parse(product?.product?.description)))
                )}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="subtitle2">Tags:</Typography>
            {product?.product?.tags?.tags && <Tags tags={product?.product?.tags?.tags} />}
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default Product;
