import { useCallback, useEffect, useState } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';

import { JWT } from '@services/jwt';
import { deviceService, GetDeviceServiceResponse } from '@services/device';
import {
  authService,
  GenerateTotpServiceResponse,
  PreSignServiceRequest,
  PreSignServiceResponse,
  SignInServiceRequest,
  SignInServiceResponse,
  ValidateTotpServiceRequest,
  ValidateTotpServiceResponse,
} from '@services/api/auth';

import { getMyProfile } from '@store/profiler';
import { userSlice } from '../profiler/slices';
import { createServiceCall, TRootState, useAppDispatch, useAppSelector } from '../helpers';

import { UseAuthResult } from './auth';
import { authSlice } from './slices';

const { setProfile, deleteProfile } = userSlice.actions;
const { setDevice, setExternalAuthState, setVerifyToken, setSessionId, setOtp } = authSlice.actions;

const generateOtp = createServiceCall<GenerateTotpServiceResponse, never>(
  authSlice.name,
  authService.generateOtp,
  ({ response, dispatch }) => {
    dispatch(setOtp({ response }));
  }
);
const validateOtp = createServiceCall<ValidateTotpServiceResponse, ValidateTotpServiceRequest>(
  authSlice.name,
  authService.validateOtp,
  ({ response, dispatch }) => {
    dispatch(setOtp({ response }));
  }
);

const emailSignIn = createServiceCall<SignInServiceResponse, SignInServiceRequest>(
  authSlice.name,
  authService.emailSignIn,
  ({ response, dispatch }) => {
    if (response.session && response?.tokens) {
      dispatch(setSessionId({ sessionId: response.session }));
      JWT.saveJWTTokens({
        accessToken: response.tokens.access,
        refreshToken: response.tokens.refresh,
      });
      return;
    }

    if (response?.tokens) {
      JWT.saveJWTTokens({
        accessToken: response.tokens.access,
        refreshToken: response.tokens.refresh,
      });
      dispatch(getMyProfile());
    }
  }
);

const getEmailCode = createServiceCall<PreSignServiceResponse, PreSignServiceRequest>(
  authSlice.name,
  authService.getEmailCode,
  ({ response: { state }, dispatch }) => {
    dispatch(setVerifyToken({ verifyToken: state }));
  }
);

const getDevice = createServiceCall<GetDeviceServiceResponse, never>(
  authSlice.name,
  deviceService.getDevice,
  ({ response: { device }, dispatch }) => {
    dispatch(setDevice({ device }));
  }
);

const signOut = createServiceCall(
  authSlice.name,
  authService.signOut,
  ({ dispatch }) => {
    JWT.removeJWTTokens();
    dispatch(deleteProfile({ profile: undefined }));
  },
  ({ dispatch }) => {
    JWT.removeJWTTokens();
    dispatch(setProfile({ profile: undefined }));
  }
);

type UseSignInWithAdditionalDataOptions = {
  addDevice?: boolean;
  addState?: boolean;
};
type UseSignInWithAdditionalDataOptionsResponse = {
  (payload: any): void;
};

interface UseSignInWithAdditionalData {
  (
    signIn: AsyncThunk<any, any, any>,
    options: UseSignInWithAdditionalDataOptions
  ): UseSignInWithAdditionalDataOptionsResponse;
}

// // TODO: Find the way how to split this in 2 separate functions and use them as composition on action
const useSignInWithAdditionalData: UseSignInWithAdditionalData = (signIn, options = {}) => {
  const [signInPayload, setSignInPayload] = useState<Record<string, any> | null>(null);

  const dispatch = useAppDispatch();
  const device = useAppSelector((state: TRootState) => state.auth.device);
  const externalAuthState = useAppSelector((state: TRootState) => state.auth.externalAuthState);

  useEffect(() => {
    if (!signInPayload) {
      return;
    }

    const signInPayloadWithAdditionalData = { ...signInPayload };
    if (options.addDevice) {
      signInPayloadWithAdditionalData.device = device;

      if (!signInPayloadWithAdditionalData.device) {
        return;
      }
    }
    if (options.addState) {
      signInPayloadWithAdditionalData.state = externalAuthState;

      if (!signInPayloadWithAdditionalData.state) {
        return;
      }
    }

    dispatch(signIn(signInPayloadWithAdditionalData));
  }, [
    signInPayload,
    device,
    externalAuthState,
    signIn,
    dispatch,
    options.addDevice,
    options.addState,
  ]);

  return useCallback(
    (payload) => {
      setSignInPayload(payload);

      if (options.addDevice) {
        dispatch(getDevice());
      }
    },
    [dispatch, options.addDevice]
  );
};

// This is temp solution before email service works
interface UseImmediateEmailSignIn {
  (): (payload: PreSignServiceRequest) => void;
}

const useImmediateEmailSignIn: UseImmediateEmailSignIn = () => {
  const dispatch = useAppDispatch();
  const verifyToken = useAppSelector((state: TRootState) => state.auth.verifyToken);
  const [hasEmailSignInStarted, setHasEmailSignInStarted] = useState(false);
  const verifyEmailSignInWithDevice = useSignInWithAdditionalData(emailSignIn, {
    addDevice: true,
  });

  useEffect(() => {
    if (verifyToken && hasEmailSignInStarted) {
      verifyEmailSignInWithDevice({
        type: {
          case: 'token',
          value: verifyToken,
        },
      });
    }
  }, [verifyToken, hasEmailSignInStarted, verifyEmailSignInWithDevice]);

  return useCallback(
    (payload: PreSignServiceRequest) => {
      setHasEmailSignInStarted(true);
      dispatch(getEmailCode(payload));
    },
    [dispatch]
  );
};

export const useAuth = (): UseAuthResult => {
  const dispatch = useAppDispatch();

  const immediateEmailSignIn = useImmediateEmailSignIn();

  return {
    error: useAppSelector((state) => state.auth.error),
    isLoading: useAppSelector((state) => state.auth.isLoading),
    isInitialized: useAppSelector((state) => state.auth.isInitialized),
    sessionId: useAppSelector((state) => state.auth.sessionId),
    otp: useAppSelector((state) => state.auth.otp),
    emailSignIn: immediateEmailSignIn,
    // googleSignIn: (payload) => {
    //     oAuthSignInWithDeviceAndState({...payload, type: OauthSignInType.GOOGLE});
    // },

    signOut: useCallback((payload) => dispatch(signOut(payload)), [dispatch]),
    generateOtp: useCallback(() => dispatch(generateOtp()), [dispatch]),
    validateOtp: useCallback((payload) => dispatch(validateOtp(payload)), [dispatch]),
  };
};
