import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { TProfile } from '@services/api/profiler/profile';
import { Avatar, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

type OrgMemberPopoverProps = {
  member: TProfile;
};

const OrgMemberPopover = (props: OrgMemberPopoverProps) => {
  const { member } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Link to={`/user/${member.profileId}`}>
        <Avatar
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{ cursor: 'pointer', color: 'white', width: '50px', height: '50px' }}
          alt={member?.name?.nickName.toUpperCase() || 'User'}
          src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${member.profileId}/${member?.avatar}`}
        />
      </Link>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box p={3} width="400px" display="flex" flexDirection="column" gap={1}>
          <Box display="flex">
            <Typography fontWeight="bold" sx={{ p: 1 }}>
              {member?.name?.nickName}
            </Typography>
            <Typography color="secondary" sx={{ p: 1 }}>
              {member?.name?.firstName} {member?.name?.lastName}
            </Typography>
          </Box>
          <Box display="flex" gap={1}>
            {member?.tags?.tags
              ?.filter((tag) => tag?.tagType.toString() === 'TAG_TYPE_ROLE')
              ?.map((tag) => (
                <Chip key={tag?.value} label={tag?.value} variant="outlined" />
              ))}
          </Box>
          <Box display="flex" gap={1}>
            {member?.tags?.tags
              ?.filter((tag) => tag?.tagType.toString() === 'TAG_TYPE_COUNTRY')
              ?.map((tag) => (
                <Chip key={tag?.value} label={tag?.value} variant="outlined" />
              ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default OrgMemberPopover;
