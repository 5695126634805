import { TSearchProfiles } from '@store/profiler/profiler';
import { userSlice } from '@store/profiler/slices';
import { Dispatch } from 'react';

const {
  actions: { deleteSelectedProfile },
} = userSlice;
export const onInputChange = (
  data: string,
  request: (payload: { selector: string }) => void,
  dispatch: Dispatch<any>
) => {
  if (data.length < 5) {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
  }
  if (data.length >= 5) {
    setTimeout(() => request({ selector: data }), 500);
  }
};
export const changeHandler = (
  data: TSearchProfiles | null,
  request: (payload: { profileId: string }) => void
) => {
  if (data) {
    const profile = data.objectId;
    request({ profileId: profile });
  }
};
