// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/demand.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  GetDemandRequest,
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
  SetDemandStatusResponse,
} from './demand_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.demand.v1.DemandService
 */
export const DemandService = {
  typeName: 'marketplace.demand.v1.DemandService',
  methods: {
    /**
     * @generated from rpc marketplace.demand.v1.DemandService.GetDemand
     */
    getDemand: {
      name: 'GetDemand',
      I: GetDemandRequest,
      O: GetDemandResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.DemandService.SetDemandStatus
     */
    setDemandStatus: {
      name: 'SetDemandStatus',
      I: SetDemandStatusRequest,
      O: SetDemandStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.DemandService.ListAppliedDemands
     */
    listAppliedDemands: {
      name: 'ListAppliedDemands',
      I: ListAppliedDemandsRequest,
      O: ListAppliedDemandsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
