// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/squad.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateSquadRequest,
  CreateSquadResponse,
  GetSquadRequest,
  GetSquadResponse,
  ListSquadsRequest,
  ListSquadsResponse,
  SearchSquadsRequest,
  SearchSquadsResponse,
  SetSquadStatusRequest,
  SetSquadStatusResponse,
  UpdateSquadInfoRequest,
  UpdateSquadInfoResponse,
} from './squad_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.biz.v1.SquadService
 */
export const SquadService = {
  typeName: 'profiler.biz.v1.SquadService',
  methods: {
    /**
     * @generated from rpc profiler.biz.v1.SquadService.GetSquad
     */
    getSquad: {
      name: 'GetSquad',
      I: GetSquadRequest,
      O: GetSquadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.SquadService.ListSquads
     */
    listSquads: {
      name: 'ListSquads',
      I: ListSquadsRequest,
      O: ListSquadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.SquadService.CreateSquad
     */
    createSquad: {
      name: 'CreateSquad',
      I: CreateSquadRequest,
      O: CreateSquadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.SquadService.SetSquadStatus
     */
    setSquadStatus: {
      name: 'SetSquadStatus',
      I: SetSquadStatusRequest,
      O: SetSquadStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.SquadService.SearchSquads
     */
    searchSquads: {
      name: 'SearchSquads',
      I: SearchSquadsRequest,
      O: SearchSquadsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.SquadService.UpdateSquadInfo
     */
    updateSquadInfo: {
      name: 'UpdateSquadInfo',
      I: UpdateSquadInfoRequest,
      O: UpdateSquadInfoResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
