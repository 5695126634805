import { combineReducers } from '@reduxjs/toolkit';

import { productSlice } from '@store/product/slices';
import {
  CreateProductServiceRequest,
  CreateProductServiceResponse,
  DeleteProductServiceRequest,
  DeleteProductServiceResponse,
  GetProductServiceRequest,
  GetProductServiceResponse,
  ListProductsServiceRequest,
  ListProductsServiceResponse,
  productService,
} from '@services/api/marketplace/product';
import { createServiceCall, useAppDispatch } from '../helpers';

export const productReducer = combineReducers({
  product: productSlice.reducer,
});

const createProduct = createServiceCall<CreateProductServiceResponse, CreateProductServiceRequest>(
  productSlice.name,
  productService.createProduct,
  ({ payload, response, dispatch }) => {}
);
const deleteProduct = createServiceCall<DeleteProductServiceResponse, DeleteProductServiceRequest>(
  productSlice.name,
  productService.deleteProduct,
  ({ payload, response, dispatch }) => {
    console.log(response);
  }
);
const getProduct = createServiceCall<GetProductServiceResponse, GetProductServiceRequest>(
  productSlice.name,
  productService.getProduct,
  ({ payload, response, dispatch }) => {
    console.log(response);
  }
);
const listProducts = createServiceCall<ListProductsServiceResponse, ListProductsServiceRequest>(
  productSlice.name,
  productService.listProducts,
  ({ payload, response, dispatch }) => {
    console.log(response);
  }
);

export const useDemand = (): {
  createProduct: (request: CreateProductServiceRequest) => void;
} => {
  const dispatch = useAppDispatch();

  return {
    createProduct: (request: CreateProductServiceRequest) => {
      dispatch(createProduct(request));
    },
  };
};

export {};
