import { useListProducts } from '@services/api/marketplace/products';
import { ListProductsRequest } from '@proto/marketplace/product/v1/product_pb';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import Box from '@mui/material/Box';
import { ROUTES_PREFIXES } from '@common/routes';
import { Link } from 'react-router-dom';
import React from 'react';
import Typography from '@mui/material/Typography';

type ProductsListProps = {
  requestType: 'owner';
  value: Entity;
};

const ProductsList = (props: ProductsListProps) => {
  const { requestType, value } = props;
  const { data, isLoading, error } = useListProducts(
    new ListProductsRequest({
      by: {
        case: requestType,
        value,
      },
    })
  );

  return (
    <Box>
      {data?.products?.map((product, index) => {
        return (
          <Box key={product?.productId}>
            <Link
              key={product?.productId}
              to={`${ROUTES_PREFIXES.PRODUCT}/${product?.productId}`}
              className="NavLink"
            >
              <Typography fontSize="13px" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {product?.title}
              </Typography>
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductsList;
