import React, { useEffect, useState } from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';
import { TagTypeEnum, TTag } from '@services/api/profiler/tags';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import Card from '@components/Card/Card';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { Link, useParams } from 'react-router-dom';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { useSetDemandstatus } from '@services/api/marketplace/calls';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

type KanbanItemProps = {
  demand: any;
};

const KanbanDemandCard = (props: KanbanItemProps) => {
  const { dashboardId } = useParams();
  const { demand } = props;
  const [tagByType, setTagByType] = useState<Array<[TagTypeEnum, TTag[]]>>([]);
  const { mutate, error } = useSetDemandstatus();

  useEffect(() => {
    setTagByType(transformTagsToTagsByType(demand?.entity?.value?.tags.tags || []));
  }, [demand?.entity?.value?.tags.tags]);

  const handleСlick = () => {
    if (demand?.status?.status >= 5) return;
    mutate({
      demandId: demand?.entity?.value?.contractId || demand?.entity?.value?.interviewId,
      status: Number(demand?.status?.status) + 1,
    });
  };

  return (
    <Box>
      <Card>
        <Box display="flex" gap={1} flexDirection="column">
          <Link
            className="NavLink"
            to={`/${dashboardId}/${demand?.entity?.case}-manage/${
              demand.entity.value.contractId || demand.entity.value.interviewId
            }`}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <Typography fontSize="13px" fontWeight="bold">
                {demand?.entity?.case.toUpperCase()}
              </Typography>
              {tagByType
                // @ts-ignore
                .filter(([type]) => type === TagType.ROLE)
                .map(([type, tags]) => (
                  <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                    {tags.map((tag) => (
                      <Chip key={tag.value} label={tag.alias || tag.value} variant="outlined" />
                    ))}
                  </Box>
                ))}
            </Box>
          </Link>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DemandTraits gap={0.5} fontSize="13px" demand={demand.entity.value} />
            <Box sx={{ cursor: 'pointer' }} onClick={handleСlick}>
              <KeyboardDoubleArrowRightIcon />
            </Box>
          </Box>

          <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
            {tagByType
              .filter(
                ([type]) =>
                  type.toString() !== 'TAG_TYPE_ROLE' && type?.toString() !== 'TAG_TYPE_COUNTRY'
              )
              .map(([type, tags]) => (
                <Box display="flex" alignItems="center" gap={0.5} flexWrap="wrap">
                  {tags.map((tag) => (
                    <Chip key={tag.value} label={tag.value} variant="outlined" />
                  ))}
                </Box>
              ))}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default KanbanDemandCard;
