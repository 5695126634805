import { Provider } from 'react-redux';
import ScrollToTop from '@components/ScrollToTop/ScrollToTop';
import { CookiesBanner } from '@components/CookiesBanner/CookiesBanner';
import { AlertManager } from '@components/AlertManager/AlertManager';
import { ModalManager } from '@components/ModalManager/ModalManager';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from '../../store';
import { Router } from './Router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// Оновіть ваш компонент App, щоб використовувати PersistGate
export const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ScrollToTop />
        <Router />
        <CookiesBanner />
        <AlertManager />
        <ModalManager />
      </Provider>
    </QueryClientProvider>
  );
};
