import { EventsService } from '@proto/notificator/events/v1/events_connect';

import { SubscribeRequest, SubscribeResponse } from '@proto/notificator/events/v1/events_pb';

import { getClient, makeConnect, PlainMsg } from '@services/api/helpers';

export type SubscribeServiceResponse = PlainMsg<SubscribeResponse>;

const notificatorClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_NOTIFICATION_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  EventsService
);

export const notificatorService = {
  subscribe(): Promise<SubscribeServiceResponse> {
    return makeConnect(notificatorClient.subscribe, new SubscribeRequest());
  },
};
