import { AuthService } from '@proto/iam/auth/v1/auth_connect';
import {
  PreSignRequest,
  PreSignResponse,
  SignInRequest,
  SignInResponse,
  SignOutRequest,
  SignOutResponse,
} from '@proto/iam/auth/v1/auth_pb';
import {
  GenerateTotpRequest,
  GenerateTotpResponse,
  ValidateTotpRequest,
  ValidateTotpResponse,
} from '@proto/iam/auth/v1/mfa_pb';
import { getClient, makeRequest, PlainMsg } from '@services/api/helpers';
import { MFAService } from '@proto/iam/auth/v1/mfa_connect';

export type SignInServiceRequest = PlainMsg<SignInRequest>;
export type SignInServiceResponse = PlainMsg<SignInResponse>;

export type GenerateTotpServiceResponse = PlainMsg<GenerateTotpResponse>;
export type ValidateTotpServiceRequest = PlainMsg<ValidateTotpRequest>;
export type ValidateTotpServiceResponse = PlainMsg<ValidateTotpResponse>;

export type PreSignServiceRequest = PreSignRequest;
export type PreSignServiceResponse = PreSignResponse;

// export type UserSiegnOutServiceRequest = UserSignOutRequest;
export type SignOutServiceResponse = PlainMsg<SignOutResponse>;
export type SignOutServiceRequest = PlainMsg<SignOutRequest>;
const authClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_AUTH_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AuthService
);
const mfaClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_AUTH_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  MFAService
);

export const authService = {
  emailSignIn(request: SignInServiceRequest): Promise<SignInServiceResponse> {
    return makeRequest(authClient.signIn, request);
  },

  getEmailCode(request: PreSignServiceRequest): Promise<PreSignServiceResponse> {
    return makeRequest(authClient.preSign, request);
  },
  signOut(request: SignOutServiceRequest): Promise<SignOutServiceResponse> {
    return makeRequest(authClient.signOut, request);
  },
  generateOtp(): Promise<GenerateTotpServiceResponse> {
    return makeRequest(mfaClient.generateTotp, new GenerateTotpRequest());
  },
  validateOtp(request: ValidateTotpServiceRequest): Promise<ValidateTotpServiceResponse> {
    return makeRequest(mfaClient.validateTotp, request);
  },
};
