import { createServiceSlice } from '@store/helpers';
import { TProductCaseReducers, TProductState } from '@store/product/product';

export const productSlice = createServiceSlice<TProductState, TProductCaseReducers>({
  name: 'product',
  initialState: {
    product: null,
  },
  reducers: {
    createProduct: (state, action) => {
      state.product = action.payload;
    },
  },
});
