import { Page } from '@components/Page/Page';
import { useParams } from 'react-router-dom';
import { useMarketplace } from '@store/marketplace';
import { Suspense, useEffect } from 'react';
import { useDialog } from '@components/hooks/useDialog';
import SendEvaluationFeedBackForm from '@pages/Evaluation/SendEvaluationFeedBackForm';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CalendarComponent from '@pages/Evaluation/EvaluationSchedulesCalendar/CalendarComponent';
import { useGetEvaluation } from '@services/api/marketplace/calls';
import SchedulesList from '@pages/Evaluation/SchedulesList';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';

const Evaluation = () => {
  const { evaluationId } = useParams();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { foundDemands, getDemand } = useMarketplace();
  const { data: evaluation } = useGetEvaluation({ evaluationId });

  const isInterviewer = evaluation?.evaluation?.currentUser.case === 'interviewer';
  const isContractor = evaluation?.evaluation?.currentUser.case === 'candidate';

  useEffect(() => {
    if (evaluation?.evaluation?.interviewId)
      getDemand({
        id: evaluation?.evaluation?.interviewId,
        type: 'interview',
      });
  }, [evaluation, getDemand]);

  return evaluationId ? (
    <Page description="evaluation" title="evaluation">
      <Container>
        {evaluation?.evaluation ? (
          <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
            <Box width="50%">
              {isContractor && <SchedulesList evaluationId={evaluationId} />}

              {isInterviewer && evaluation?.evaluation?.createdAt && (
                <Box display="flex">
                  <CalendarComponent
                    evaluationdCreatedAt={evaluation?.evaluation?.createdAt}
                    evaluationId={evaluationId}
                  />
                </Box>
              )}
            </Box>

            {/* {evaluation?.evaluation?.interviewId && <Box> */}
            {/*    <DemandAppliedCard demand={foundDemands[evaluation?.evaluation?.interviewId]}/> */}
            {/* </Box>} */}

            {isInterviewer && (
              <Button variant="contained" onClick={handleOpen}>
                {evaluation.evaluation.feedback ? 'Update feedback' : 'Send feedback'}
              </Button>
            )}

            {evaluation.evaluation.feedback && (
              <Box>
                {HTMLReactParser(
                  stateToHTML(convertFromRaw(JSON.parse(evaluation.evaluation.feedback)))
                )}
              </Box>
            )}

            <Suspense fallback={null}>
              <SendEvaluationFeedBackForm
                evaluation={evaluation.evaluation}
                evaluationId={evaluation.evaluation.evaluationId}
                isOpen={isOpen}
                handleClose={handleClose}
              />
            </Suspense>
          </Box>
        ) : null}
      </Container>
    </Page>
  ) : null;
};

export default Evaluation;
