import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SearchProfile from '@components/SearchProfiles/SearchProfile';
import React from 'react';
import UserCard from '@components/AddMemberDialog/UserCard';
import { useProfiler } from '@store/profiler';
import SendInvitesButton from '@components/Invites/SendInvites';
import { useSnackbar } from '@components/hooks/useSnackbar';
import Box from '@mui/material/Box';
import AppSnackbar from '@components/Snackbar/Snackbar';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';

type AddMemberProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId: string;
  entity: TypeOfEntity;
  objectName?: string;
};

const AddMemberDialog = (props: AddMemberProps) => {
  const { isOpen, handleClose, entityId, entity, objectName } = props;
  const { isSnackbarOpen, handleSnackbarOpen, handleCloseSnackbar } = useSnackbar();
  const { sendInvites, selectedProfile } = useProfiler();

  const sendInvite = () => {
    if (selectedProfile && entity && entityId) {
      sendInvites({
        id: entityId,
        type: entity,
        profileIds: [selectedProfile.profileId],
      });
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleSnackbarOpen();
    handleClose();
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={isOpen}>
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          item
          sx={{ width: '600px', backgroundColor: '#3f3d50' }}
          p={5}
        >
          <Typography textAlign="center" mb={4} variant="subtitle1">
            Add member to {objectName}
          </Typography>
          <SearchProfile />
          {selectedProfile && <UserCard userProfile={selectedProfile} />}
          {selectedProfile ? <SendInvitesButton handleSubmit={handleSubmit} /> : null}
        </Grid>
      </Dialog>
      <AppSnackbar
        label="Invite send successfully!"
        severity="success"
        isOpen={isSnackbarOpen}
        handleClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default AddMemberDialog;
