import React, { Suspense } from 'react';
import { useProfiler } from '@store/profiler';
import { Link, useParams } from 'react-router-dom';
import { useOrgs } from '@store/orgs';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ROUTES_PREFIXES } from '@common/routes';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import OrgMemberPopover from '@pages/Orgs/OrgMemberPopover';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ConfirmLeaveOrgDialog from '@pages/Orgs/ConfirmLeaveOrgDialog';
import { useDialog } from '@components/hooks/useDialog';

const requiredRoles = [
  IAMRoleStrings.IAM_ROLE_ADMIN,
  IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
  IAMRoleStrings.IAM_ROLE_MANAGER,
  IAMRoleStrings.IAM_ROLE_BILLING_MANAGER,
  IAMRoleStrings.IAM_ROLE_MEMBER,
];

const OrgMembers = () => {
  const { listMembers, listRoles, userProfile } = useProfiler();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { entityId } = useParams();
  const { currentOrg } = useOrgs();
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  return hasAccess ? (
    <Box display="flex" flexDirection="column" gap={1} width={{ xs: '100%', md: '30%' }}>
      <Box display="flex" justifyContent="space-between">
        <Link className="NavLink" to={`${ROUTES_PREFIXES.OWNER_ORGS}/${entityId}/members`}>
          <Box sx={{ cursor: 'pointer' }} display="flex" gap={1} alignItems="center">
            <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
              Members
            </Typography>
          </Box>
        </Link>
        {userProfile?.profileId !== currentOrg?.ownerId && (
          <ExitToAppIcon cursor="pointer" onClick={handleOpen} />
        )}
      </Box>
      <Divider />
      <Box display="flex" gap={1} flexWrap="wrap">
        {currentOrg &&
          listMembers[entityId as keyof typeof listMembers]?.profiles?.profiles?.map((member) => {
            return <OrgMemberPopover member={member} />;
          })}
      </Box>
      <Suspense fallback={null}>
        <ConfirmLeaveOrgDialog
          isOpen={isOpen}
          handleClose={handleClose}
          entityId={entityId || ''}
        />
      </Suspense>
    </Box>
  ) : null;
};

export default OrgMembers;
