import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MembeshipSquadCard from '@pages/Main/MainPage/MembershipSquadCard';
import MembershipOrgCard from '@pages/Main/MainPage/MembershipOrgCard';
import Divider from '@mui/material/Divider';
import { useMembershipsAndSquads } from '@services/api/profiler/MembershipsQueries';
import { Squads } from '@proto/profiler/biz/v1/squad_pb';
import { Orgs } from '@proto/profiler/biz/v1/org_pb';

export const ListMemberships = (): JSX.Element => {
  const { data: membershipsData } = useMembershipsAndSquads();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {membershipsData?.squads && (
          <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
            Squads
          </Typography>
        )}
        {membershipsData?.squads && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            {(membershipsData?.squads as Squads)?.squads?.map((squad, index) => {
              const squadsLength = (membershipsData?.squads as Squads)?.squads?.length ?? 0;
              return (
                <Box key={squad.squadId}>
                  <MembeshipSquadCard squad={squad} />
                  {index < squadsLength - 1 && <Divider />}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" gap={0.5}>
        {membershipsData?.squads && (
          <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
            Organizations
          </Typography>
        )}
        {membershipsData?.orgs && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            {(membershipsData?.orgs as Orgs)?.orgs?.map((org, index) => {
              const orgsLength = (membershipsData?.orgs as Orgs)?.orgs?.length ?? 0;
              return (
                <Box key={org.orgId}>
                  <MembershipOrgCard org={org} />
                  {index < orgsLength - 1 && <Divider />}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ListMemberships;
