import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import * as React from 'react';
import { Box, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import EventIcon from '@mui/icons-material/Event';
import Divider from '@mui/material/Divider';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import InterviewInviteCard from '@pages/Marketplace/Dashboards/InterviewInviteCard';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import { useGetDemand } from '@services/api/marketplace/calls';
import { Interview } from '@proto/marketplace/demand/v1/interview_pb';
import Tags from '@components/Tags/Tags';

const InterviewPage = () => {
  const { interviewId } = useParams();
  const {
    data: demand,
    error,
    isLoading,
  } = useGetDemand({ demandId: interviewId || '', type: 'interview' });
  const interview = demand?.demand?.entity?.value as Interview;

  return (
    <Page description="Interview" title="Interview">
      <Container>
        <Box display="flex" flexDirection="column" gap={2}>
          <InterviewInviteCard />
          {/* <ListInvitedCandidates entityId={interviewId}/> */}

          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="subtitle1">Interview</Typography>
            <Tooltip title="Created at">
              <Box display="flex" gap={2} alignItems="center">
                <EventIcon />
                {/* <Typography variant="subtitle2"> */}
                {/*    {interview?.createdAt && format(new Date(interview?.createdAt), 'dd/MM/yyyy')} */}
                {/* </Typography> */}
              </Box>
            </Tooltip>
          </Box>
          <Divider />
          {demand && <DemandTraits gap={1} demand={demand} />}
          {interview?.tags?.tags && <Tags tags={interview.tags.tags} />}
          {demand && (
            <Box display="flex" flexDirection="column" gap={2}>
              {interview?.requirements &&
                HTMLReactParser(stateToHTML(convertFromRaw(JSON.parse(interview?.requirements))))}
            </Box>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default InterviewPage;
