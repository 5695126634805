import { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { useAuth } from '@store/auth';
import { ROUTES, TRoute } from '@common/routes';
import { useProfiler } from '@store/profiler';
import { InputController } from '@components/Form/controllers/InputController';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { PreSignServiceRequest } from '@services/api/auth';
import { OAuthButton } from './OAuthButton/OAuthButton';
import { OAuthAppTypes } from './OAuthButton/oAuthConfig';

const StyledLink = styled(Link)(() => ({
  color: 'white',
  display: 'inline-flex',
}));

export const AuthForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, emailSignIn } = useAuth();
  const { userProfile } = useProfiler();

  // @ts-ignore
  const navigateTo: TRoute = useMemo(() => {
    // @ts-ignore
    return location.state?.from?.pathname || ROUTES.WELCOME;
  }, [location]);

  useEffect(() => {
    if (userProfile) {
      navigate('/main');
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack maxWidth={{ xs: 340, md: 280, xl: 320 }}>
      <OAuthButton type={OAuthAppTypes.google} />
      <Typography variant="body1" mt={3.5} textAlign="center">
        or
      </Typography>
      <Form<PreSignServiceRequest>
        formProps={{ defaultValues: { email: '' } }}
        submitHandler={emailSignIn}
      >
        <InputController
          name="email"
          label="Email"
          variant="standard"
          type="email"
          autoComplete="email"
          fullWidth
          sx={{ mb: 3 }}
        />
        <LoadingButton type="submit" variant="outlined" loading={isLoading} fullWidth>
          Continue with Email
        </LoadingButton>
      </Form>

      <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }} my={3}>
        By continuing I agree to <StyledLink to={ROUTES.LEGAL_PRIVACY}>Privacy Policy</StyledLink>
        <br />
        and <StyledLink to={ROUTES.LEGAL_TERMS}> Terms of Service</StyledLink>
      </Typography>
    </Stack>
  );
};
