import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Chip, Tooltip } from '@mui/material';
import { ProfileTags } from '@pages/ProfileSettings/ProfileTags/ProfileTags';
import { useProfiler } from '@store/profiler';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { motion } from 'framer-motion';

const SidebarTags = () => {
  const [isEdited, setIsEdited] = useState(true);
  const { userProfile } = useProfiler();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        {!isEdited && (
          <LoadingButton onClick={() => setIsEdited(!isEdited)}>
            <CheckIcon sx={{ width: '18px', height: '18px' }} />
          </LoadingButton>
        )}
      </Box>
      {isEdited ? (
        <motion.div
          key={!isEdited ? 'show' : 'hide'}
          initial={{
            opacity: isEdited ? 0 : 1,
            x: isEdited ? -10 : 0,
          }}
          animate={{
            opacity: isEdited ? 1 : 0,
            x: isEdited ? 0 : -10,
          }}
          transition={{ duration: 0.3 }}
        >
          <Box display="flex">
            <Box display="flex" gap={1} flexWrap="wrap">
              {userProfile?.tags?.tags.map((tag) => {
                return (
                  <Chip
                    sx={{ fontSize: '12px' }}
                    variant={tag.active ? 'outlined' : 'filled'}
                    key={tag.value}
                    label={tag.value}
                  />
                );
              })}
            </Box>
            <Box>
              <Tooltip title="Edit tags" placement="top-start">
                <LoadingButton onClick={() => setIsEdited(!isEdited)}>
                  <EditIcon sx={{ width: '18px', height: '18px' }} />
                </LoadingButton>
              </Tooltip>
            </Box>
          </Box>
        </motion.div>
      ) : (
        <motion.div
          key={!isEdited ? 'show' : 'hide'}
          initial={{
            opacity: !isEdited ? 0 : 1,
            x: !isEdited ? -10 : 0,
          }}
          animate={{
            opacity: !isEdited ? 1 : 0,
            x: !isEdited ? 0 : -10,
          }}
          transition={{ duration: 0.3 }}
        >
          <ProfileTags />
        </motion.div>
      )}
    </Box>
  );
};

export default SidebarTags;
