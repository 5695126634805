import { Box, Grid, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import { InputController } from '@components/Form/controllers/InputController';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import { Form } from '@components/Form';
import * as React from 'react';
import { useState } from 'react';
import { useMarketplace } from '@store/marketplace';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { ContractTraits } from '@proto/marketplace/demand/v1/contract_pb';
import { InterviewTraits } from '@proto/marketplace/demand/v1/interview_pb';

type UpdateDemandTraitsProps = {
  demand: Demand;
};

const UpdateDemandTraits = (props: UpdateDemandTraitsProps) => {
  const { demand } = props;
  const [isEdited, setIsEdited] = useState(true);
  const { updateContractTraits, updateInterviewInfo } = useMarketplace();

  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
      return;
    }
    if (demand?.entity?.case === 'contract') {
      updateContractTraits(data);
    }
    if (demand?.entity?.case === 'interview') {
      updateInterviewInfo(data);
    }
    setIsEdited(!isEdited);
  };
  if (!demand) return null;

  const traits = demand.entity.value?.traits;

  return demand ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          rate: demand.entity.case === 'contract' ? (traits as ContractTraits).rate : undefined,
          price: demand.entity.case === 'interview' ? (traits as InterviewTraits).price : undefined,
          capacity:
            demand.entity.case === 'contract' ? (traits as ContractTraits).capacity : undefined,
          experience: traits?.experience,
          contractId:
            demand.entity.case === 'contract' ? demand.entity.value.contractId : undefined,
          interviewId:
            demand.entity.case === 'interview' ? demand.entity.value.interviewId : undefined,
        },
      }}
      submitHandler={handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        {isEdited ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <DemandTraits gap={1} demand={demand.entity.value} />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2} width="80%">
            {demand?.entity?.case === 'contract' && (
              <Grid container flexDirection="column" gap={2}>
                <InputController
                  fullWidth
                  type="number"
                  name={`${FieldNames.EXPERIENCE}`}
                  label="Experience"
                />

                <InputController
                  fullWidth
                  type="number"
                  name={`${FieldNames.CAPACITY}`}
                  label="Capacity"
                />

                <InputController fullWidth type="number" name={`${FieldNames.RATE}`} label="Rate" />
              </Grid>
            )}

            {demand?.entity?.case === 'interview' && (
              <Grid container flexDirection="column" gap={2}>
                <Grid item xs={5}>
                  <InputController
                    fullWidth
                    type="number"
                    name={`${FieldNames.EXPERIENCE}`}
                    label="Experience"
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputController
                    fullWidth
                    type="number"
                    name={`${FieldNames.PRICE}`}
                    label="Price"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <Box>
          <Tooltip placement="left" title="Update traits">
            <LoadingButton type="submit">{isEdited ? <EditIcon /> : <CheckIcon />}</LoadingButton>
          </Tooltip>
        </Box>
      </Box>
    </Form>
  ) : null;
};

export default UpdateDemandTraits;
