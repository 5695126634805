import { TextFieldProps } from '@mui/material/TextField';
import { useController, useFormContext } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

type TInputControllerProps = Omit<TextFieldProps, 'name'> & { name: string };

export const DatePickerController = (props: TInputControllerProps): JSX.Element => {
  const { name } = props;
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ control, name });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Birthdate"
        value={dayjs(value)}
        format="DD.MM.YYYY"
        disableFuture
        onChange={(data) => {
          onChange(data ? data.toDate() : null);
        }}
      />
    </LocalizationProvider>
  );
};
