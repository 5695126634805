import React from 'react';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { useProfiler } from '@store/profiler';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';

interface IPinUnpinButtonProps {
  id: string;
  type: TypeOfEntity;
}

const PinUnpinButton: React.FC<IPinUnpinButtonProps> = ({ id, type }) => {
  const { pinEntity, unpinEntity, userProfile } = useProfiler();

  const isPinned = userProfile?.pinnedItems?.some((entity) => entity?.entity?.id === id);

  const handleChange = () => {
    const action = isPinned ? unpinEntity : pinEntity;
    action({ entityId: id, entityType: type });
  };

  return (
    <Stack>
      <Tooltip title={isPinned ? 'Unpin' : 'Pin'} placement="left">
        <Checkbox
          checked={isPinned}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
    </Stack>
  );
};

export default PinUnpinButton;
