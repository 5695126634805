// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/contractor.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';

/**
 * @generated from message marketplace.demand.v1.MatchedContracts
 */
export class MatchedContracts extends Message<MatchedContracts> {
  /**
   * @generated from field: string profile_id = 1;
   */
  profileId = '';

  /**
   * @generated from field: string contract_id = 2;
   */
  contractId = '';

  constructor(data?: PartialMessage<MatchedContracts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.MatchedContracts';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'contract_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MatchedContracts {
    return new MatchedContracts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MatchedContracts {
    return new MatchedContracts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MatchedContracts {
    return new MatchedContracts().fromJsonString(jsonString, options);
  }

  static equals(
    a: MatchedContracts | PlainMessage<MatchedContracts> | undefined,
    b: MatchedContracts | PlainMessage<MatchedContracts> | undefined
  ): boolean {
    return proto3.util.equals(MatchedContracts, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Contractors
 */
export class Contractors extends Message<Contractors> {
  /**
   * @generated from field: repeated marketplace.demand.v1.MatchedContracts contracts = 1;
   */
  contracts: MatchedContracts[] = [];

  /**
   * @generated from oneof marketplace.demand.v1.Contractors.contractor
   */
  contractor:
    | {
        /**
         * @generated from field: string squad_id = 2;
         */
        value: string;
        case: 'squadId';
      }
    | {
        /**
         * @generated from field: string org_id = 3;
         */
        value: string;
        case: 'orgId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Contractors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Contractors';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contracts', kind: 'message', T: MatchedContracts, repeated: true },
    { no: 2, name: 'squad_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'contractor' },
    { no: 3, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'contractor' },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Contractors {
    return new Contractors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Contractors {
    return new Contractors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Contractors {
    return new Contractors().fromJsonString(jsonString, options);
  }

  static equals(
    a: Contractors | PlainMessage<Contractors> | undefined,
    b: Contractors | PlainMessage<Contractors> | undefined
  ): boolean {
    return proto3.util.equals(Contractors, a, b);
  }
}

/**
 * TODO: add cache endpoint for contracts busket
 *
 * @generated from message marketplace.demand.v1.ApplyOnContractsRequest
 */
export class ApplyOnContractsRequest extends Message<ApplyOnContractsRequest> {
  /**
   * @generated from oneof marketplace.demand.v1.ApplyOnContractsRequest.apply_as
   */
  applyAs:
    | {
        /**
         * @generated from field: marketplace.demand.v1.Contractors contractors = 1;
         */
        value: Contractors;
        case: 'contractors';
      }
    | {
        /**
         * Single user
         *
         * @generated from field: string contract_id = 2;
         */
        value: string;
        case: 'contractId';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ApplyOnContractsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApplyOnContractsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'contractors', kind: 'message', T: Contractors, oneof: 'apply_as' },
    { no: 2, name: 'contract_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, oneof: 'apply_as' },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApplyOnContractsRequest {
    return new ApplyOnContractsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApplyOnContractsRequest {
    return new ApplyOnContractsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApplyOnContractsRequest {
    return new ApplyOnContractsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApplyOnContractsRequest | PlainMessage<ApplyOnContractsRequest> | undefined,
    b: ApplyOnContractsRequest | PlainMessage<ApplyOnContractsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ApplyOnContractsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApplyOnContractsResponse
 */
export class ApplyOnContractsResponse extends Message<ApplyOnContractsResponse> {
  constructor(data?: PartialMessage<ApplyOnContractsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApplyOnContractsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApplyOnContractsResponse {
    return new ApplyOnContractsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApplyOnContractsResponse {
    return new ApplyOnContractsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApplyOnContractsResponse {
    return new ApplyOnContractsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApplyOnContractsResponse | PlainMessage<ApplyOnContractsResponse> | undefined,
    b: ApplyOnContractsResponse | PlainMessage<ApplyOnContractsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ApplyOnContractsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RevokeContractorApplyRequest
 */
export class RevokeContractorApplyRequest extends Message<RevokeContractorApplyRequest> {
  /**
   * @generated from field: string supply_id = 1;
   */
  supplyId = '';

  constructor(data?: PartialMessage<RevokeContractorApplyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RevokeContractorApplyRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RevokeContractorApplyRequest {
    return new RevokeContractorApplyRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RevokeContractorApplyRequest {
    return new RevokeContractorApplyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeContractorApplyRequest {
    return new RevokeContractorApplyRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeContractorApplyRequest | PlainMessage<RevokeContractorApplyRequest> | undefined,
    b: RevokeContractorApplyRequest | PlainMessage<RevokeContractorApplyRequest> | undefined
  ): boolean {
    return proto3.util.equals(RevokeContractorApplyRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RevokeContractorApplyResponse
 */
export class RevokeContractorApplyResponse extends Message<RevokeContractorApplyResponse> {
  constructor(data?: PartialMessage<RevokeContractorApplyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RevokeContractorApplyResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RevokeContractorApplyResponse {
    return new RevokeContractorApplyResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RevokeContractorApplyResponse {
    return new RevokeContractorApplyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RevokeContractorApplyResponse {
    return new RevokeContractorApplyResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RevokeContractorApplyResponse | PlainMessage<RevokeContractorApplyResponse> | undefined,
    b: RevokeContractorApplyResponse | PlainMessage<RevokeContractorApplyResponse> | undefined
  ): boolean {
    return proto3.util.equals(RevokeContractorApplyResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveContractorRequest
 */
export class ApproveContractorRequest extends Message<ApproveContractorRequest> {
  /**
   * @generated from field: string supply_id = 1;
   */
  supplyId = '';

  /**
   * @generated from field: string contract_id = 2;
   */
  contractId = '';

  constructor(data?: PartialMessage<ApproveContractorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveContractorRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'contract_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveContractorRequest {
    return new ApproveContractorRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveContractorRequest {
    return new ApproveContractorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveContractorRequest {
    return new ApproveContractorRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveContractorRequest | PlainMessage<ApproveContractorRequest> | undefined,
    b: ApproveContractorRequest | PlainMessage<ApproveContractorRequest> | undefined
  ): boolean {
    return proto3.util.equals(ApproveContractorRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ApproveContractorResponse
 */
export class ApproveContractorResponse extends Message<ApproveContractorResponse> {
  constructor(data?: PartialMessage<ApproveContractorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ApproveContractorResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ApproveContractorResponse {
    return new ApproveContractorResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ApproveContractorResponse {
    return new ApproveContractorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ApproveContractorResponse {
    return new ApproveContractorResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ApproveContractorResponse | PlainMessage<ApproveContractorResponse> | undefined,
    b: ApproveContractorResponse | PlainMessage<ApproveContractorResponse> | undefined
  ): boolean {
    return proto3.util.equals(ApproveContractorResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RejectContractorRequest
 */
export class RejectContractorRequest extends Message<RejectContractorRequest> {
  /**
   * @generated from field: string supply_id = 1;
   */
  supplyId = '';

  /**
   * @generated from field: string contract_id = 2;
   */
  contractId = '';

  constructor(data?: PartialMessage<RejectContractorRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RejectContractorRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'supply_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'contract_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RejectContractorRequest {
    return new RejectContractorRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RejectContractorRequest {
    return new RejectContractorRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectContractorRequest {
    return new RejectContractorRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectContractorRequest | PlainMessage<RejectContractorRequest> | undefined,
    b: RejectContractorRequest | PlainMessage<RejectContractorRequest> | undefined
  ): boolean {
    return proto3.util.equals(RejectContractorRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RejectContractorResponse
 */
export class RejectContractorResponse extends Message<RejectContractorResponse> {
  constructor(data?: PartialMessage<RejectContractorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RejectContractorResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RejectContractorResponse {
    return new RejectContractorResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RejectContractorResponse {
    return new RejectContractorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RejectContractorResponse {
    return new RejectContractorResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RejectContractorResponse | PlainMessage<RejectContractorResponse> | undefined,
    b: RejectContractorResponse | PlainMessage<RejectContractorResponse> | undefined
  ): boolean {
    return proto3.util.equals(RejectContractorResponse, a, b);
  }
}
