import { Box, Grid, Tooltip, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { FieldNames } from '@pages/Marketplace/ManageDemands/settings';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmDeleteDialog from '@pages/Marketplace/Dashboards/ConfirmDeleteDialog';
import { Form } from '@components/Form';
import * as React from 'react';
import { useState } from 'react';
import { useDialog } from '@components/hooks/useDialog';
import { useMarketplace } from '@store/marketplace';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import HTMLReactParser from 'html-react-parser';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';

type UpdateDemandTraitsProps = {
  demand: Demand;
};

const UpdateContractDescription = (props: UpdateDemandTraitsProps) => {
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { demand } = props;
  const [isEdited, setIsEdited] = useState(true);
  const { updateContractDescription, deleteContract } = useMarketplace();

  const description = (demand?.entity?.value as Contract)?.description;

  const handleSubmit = (data: any) => {
    if (isEdited) {
      setIsEdited(!isEdited);
      return;
    }
    updateContractDescription(data);
    setIsEdited(!isEdited);
  };

  return demand ? (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          description,
          contractId: (demand?.entity?.value as Contract).contractId,
        },
      }}
      submitHandler={handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        {isEdited ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {description && HTMLReactParser(stateToHTML(convertFromRaw(JSON.parse(description))))}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap={2} width="80%">
            <Grid container flexDirection="column" gap={2}>
              <Grid item display="flex" flexDirection="column" gap={0.5}>
                <Typography>Description:</Typography>
                <WysiwygController name={FieldNames.DESCRIPTION} />
              </Grid>
            </Grid>
            <Box display="flex">
              <Box>
                <Tooltip placement="top-start" title="Delete contract">
                  <LoadingButton variant="contained" onClick={handleOpen}>
                    <DeleteForeverIcon />
                  </LoadingButton>
                </Tooltip>
                <ConfirmDeleteDialog
                  setIsEdited={setIsEdited}
                  handleClose={handleClose}
                  isOpen={isOpen}
                  deleteMethod={deleteContract}
                  demandId={(demand?.entity?.value as Contract).contractId || ''}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box display="flex" flexDirection="column" justifyContent={isEdited ? 'center' : 'start'}>
          <Tooltip placement="left" title="Update description">
            <LoadingButton type="submit">{isEdited ? <EditIcon /> : <CheckIcon />}</LoadingButton>
          </Tooltip>
        </Box>
      </Box>
    </Form>
  ) : null;
};

export default UpdateContractDescription;
