// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file notificator/events/v1/events.proto (package notificator.events.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateRequest, CreateResponse, SubscribeRequest, SubscribeResponse } from './events_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service notificator.events.v1.EventsService
 */
export const EventsService = {
  typeName: 'notificator.events.v1.EventsService',
  methods: {
    /**
     * @generated from rpc notificator.events.v1.EventsService.Subscribe
     */
    subscribe: {
      name: 'Subscribe',
      I: SubscribeRequest,
      O: SubscribeResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc notificator.events.v1.EventsService.Create
     */
    create: {
      name: 'Create',
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
