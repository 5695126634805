import {
  Action,
  combineReducers,
  configureStore,
  ReducersMapObject,
  Slice,
} from '@reduxjs/toolkit';
import {
  combineEpics,
  createEpicMiddleware,
  EpicMiddleware,
  StateObservable,
} from 'redux-observable';
import { catchError, Observable } from 'rxjs';
import { profilerReducer } from '@store/profiler';
import { authSlice } from '@store/auth/slices';
import { tagReducer } from 'store/tags';
import { marketplaceReducer } from 'store/marketplace';
import { notificatorReducer } from '@store/notificator';
import { squadsReducer } from '@store/squads';
import { orgsReducer } from '@store/orgs';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { productReducer } from '@store/product';
import { snackbarsReducer } from '@store/snackbars';

// https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html#adding-global-error-handler
const rootEpic = (
  action$: Observable<Action>,
  store$: StateObservable<void>,
  dependencies: unknown
) =>
  combineEpics()(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      // eslint-disable-next-line no-console
      console.error(error);
      return source;
    })
  );
const epicMiddleware: EpicMiddleware<Action> = createEpicMiddleware();

// static reducers
const reducers: ReducersMapObject = {
  auth: authSlice.reducer,
  profiler: profilerReducer,
  tags: tagReducer,
  marketplaceReducer,
  notificator: notificatorReducer,
  squadsReducer,
  orgsReducer,
  productReducer,
  snackbarsReducer,
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development' ? { trace: true } : false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
  reducer: persistedReducer,
});

type TRegisterSlice = (slice: Slice) => void;
export const registerSlice: TRegisterSlice = (slice) => {
  reducers[slice.name] = slice.reducer;
  const newReducer = combineReducers(reducers);
  store.replaceReducer(newReducer);
};

epicMiddleware.run(rootEpic);
