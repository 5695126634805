import { ProfileService } from '@proto/profiler/user/v1/profile_connect';

import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  PinItemRequest,
  PinItemResponse,
  Profile,
  ProfileName,
  SelectProfilesRequest,
  SelectProfilesResponse,
  Traits,
  UnpinItemRequest,
  UnpinItemResponse,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from '@proto/profiler/user/v1/profile_pb';

import { getClient, makeRequest, PlainMsg } from '@services/api/helpers';
import { ObjectInvite } from '@proto/profiler/invite/v1/invite_pb';
import {
  FinishToolTipsRequest,
  FinishToolTipsResponse,
  ListToolTipsRequest,
  ListToolTipsResponse,
} from '@proto/profiler/user/v1/tool_tips_pb';
import { ToolTipsService } from '@proto/profiler/user/v1/tool_tips_connect';
import {
  ActivateAvatarRequest,
  ActivateAvatarResponse,
  DeleteAvatarRequest,
  DeleteAvatarResponse,
  GetAvatarUploadUrlRequest,
  GetAvatarUploadUrlResponse,
  ListAvatarsRequest,
  ListAvatarsResponse,
} from '@proto/profiler/avatars/v1/avatar_pb';
import { AvatarService } from '@proto/profiler/avatars/v1/avatar_connect';
import {
  LeaveMembershipRequest,
  LeaveMembershipResponse,
  ListMembersRequest,
  ListMembersResponse,
  ListProfileMembershipsRequest,
  ListProfileMembershipsResponse,
  Member,
  RemoveMemberRequest,
  RemoveMemberResponse,
} from '@proto/profiler/biz/v1/membership_pb';
import { MembershipService } from '@proto/profiler/biz/v1/membership_connect';
import {
  AttachRoleRequest,
  AttachRoleResponse,
  DetachRolesRequest,
  DetachRolesResponse,
  ListMembersRolesRequest,
  ListMembersRolesResponse,
} from '@proto/iam/auth/v1/iam_pb';
import { IAMService } from '@proto/iam/auth/v1/iam_connect';

export type GetMyProfileServiceRequest = PlainMsg<GetMyProfileRequest>;
export type GetMyProfileServiceResponse = PlainMsg<GetMyProfileResponse>;
export type UpdateProfilePersonalInfoServiceResponse = PlainMsg<UpdateProfilePersonalInfoResponse>;
export type UpdateProfilePersonalInfoServiceRequest = PlainMsg<UpdateProfilePersonalInfoRequest>;
export type ChangeProfileStatusServiceResponse = PlainMsg<ChangeProfileStatusResponse>;
export type ChangeProfileStatusServiceRequest = PlainMsg<ChangeProfileStatusRequest>;
export type UpdateProfileTraitsServiceResponse = PlainMsg<UpdateProfileTraitsResponse>;
export type UpdateProfileTraitsServiceRequest = PlainMsg<UpdateProfileTraitsRequest>;
export type AttachRoleServiceResponse = PlainMsg<AttachRoleResponse>;
export type AttachRoleServiceRequest = PlainMsg<AttachRoleRequest>;
export type DetachRolesServiceResponse = PlainMsg<DetachRolesResponse>;
export type DetachRolesServiceRequest = PlainMsg<DetachRolesRequest>;
export type ListProfilesServiceRequest = PlainMsg<ListProfilesRequest>;
export type ListProfilesServiceResponse = PlainMsg<ListProfilesResponse>;
export type ListMembersServiceRequest = PlainMsg<ListMembersRequest>;
export type ListMembersServiceResponse = PlainMsg<ListMembersResponse>;
export type GetProfileServiceRequest = PlainMsg<GetProfileRequest>;
export type GetProfileServiceResponse = PlainMsg<GetProfileResponse>;
export type SelectProfilesServiceRequest = PlainMsg<SelectProfilesRequest>;
export type SelectProfilesServiceResponse = PlainMsg<SelectProfilesResponse>;
export type ListProfileMembershipsServiceRequest = PlainMsg<ListProfileMembershipsRequest>;
export type ListProfileMembershipsServiceResponse = PlainMsg<ListProfileMembershipsResponse>;
export type RemoveMemberServiceRequest = PlainMsg<RemoveMemberRequest>;
export type RemoveMemberServiceResponse = PlainMsg<RemoveMemberResponse>;
export type LeaveMembershipServiceRequest = PlainMsg<LeaveMembershipRequest>;
export type LeaveMembershipServiceResponse = PlainMsg<LeaveMembershipResponse>;
export type GetAvatarUploadUrlServiceRequest = PlainMsg<GetAvatarUploadUrlRequest>;
export type GetAvatarUploadUrlServiceResponse = PlainMsg<GetAvatarUploadUrlResponse>;
export type DeleteAvatarServiceRequest = PlainMsg<DeleteAvatarRequest>;
export type DeleteAvatarServiceResponse = PlainMsg<DeleteAvatarResponse>;
export type ListAvatarsServiceRequest = PlainMsg<ListAvatarsRequest>;
export type ListAvatarsServiceResponse = PlainMsg<ListAvatarsResponse>;
export type ActivateAvatarServiceRequest = PlainMsg<ActivateAvatarRequest>;
export type ActivateAvatarServiceResponse = PlainMsg<ActivateAvatarResponse>;
export type ListMembersRolesServiceRequest = PlainMsg<ListMembersRolesRequest>;
export type ListMembersRolesServiceResponse = PlainMsg<ListMembersRolesResponse>;
export type PinItemServiceRequest = PlainMsg<PinItemRequest>;
export type PinItemServiceResponse = PlainMsg<PinItemResponse>;
export type UnPinItemServiceRequest = PlainMsg<UnpinItemRequest>;
export type UnPinItemServiceResponse = PlainMsg<UnpinItemResponse>;

export type ListToolTipsServiceRequest = PlainMsg<ListToolTipsRequest>;
export type ListToolTipsServiceResponse = PlainMsg<ListToolTipsResponse>;
export type FinishToolTipsServiceRequest = PlainMsg<FinishToolTipsRequest>;
export type FinishToolTipsServiceResponse = PlainMsg<FinishToolTipsResponse>;

export type TProfile = PlainMsg<Profile>;
export type TProfileName = PlainMsg<ProfileName>;
export type TProfileTraits = PlainMsg<Traits>;
// export type TProfileSocialUrls = PlainMsg<SocialUrls>;
export type TInvite = PlainMsg<ObjectInvite>;
export type TMember = PlainMsg<Member> & {
  squad: string;
  org: string;
};

export const profileClient = getClient<typeof ProfileService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProfileService
);

export const membershipClient = getClient<typeof MembershipService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  MembershipService
);
const avatarClient = getClient<typeof AvatarService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  AvatarService
);

const toolTipsClient = getClient<typeof ToolTipsService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_PROFILER_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ToolTipsService
);
const iAMClient = getClient<typeof IAMService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_AUTH_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  IAMService
);

export const profileService = {
  getMyProfile(): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, new GetMyProfileRequest());
  },
  getAvatarUpload(
    request: GetAvatarUploadUrlServiceRequest
  ): Promise<GetAvatarUploadUrlServiceResponse> {
    return makeRequest(avatarClient.getAvatarUploadUrl, request);
  },
  pinItem(request: PinItemServiceRequest): Promise<PinItemServiceResponse> {
    return makeRequest(profileClient.pinItem, request);
  },
  unPinItem(request: UnPinItemServiceRequest): Promise<UnPinItemServiceResponse> {
    return makeRequest(profileClient.unpinItem, request);
  },
  listMemberShips(
    request: ListProfileMembershipsServiceRequest
  ): Promise<ListProfileMembershipsServiceResponse> {
    return makeRequest(membershipClient.listProfileMemberships, request);
  },
  deleteAvatar(request: DeleteAvatarServiceRequest): Promise<DeleteAvatarServiceResponse> {
    return makeRequest(avatarClient.deleteAvatar, request);
  },
  listAvatars(request: ListAvatarsServiceRequest): Promise<ListAvatarsServiceResponse> {
    return makeRequest(avatarClient.listAvatars, request);
  },
  getListMembers(request: ListMembersServiceRequest): Promise<ListMembersServiceResponse> {
    return makeRequest(membershipClient.listMembers, request);
  },
  activateAvatar(request: ActivateAvatarServiceRequest): Promise<ActivateAvatarServiceResponse> {
    return makeRequest(avatarClient.activateAvatar, request);
  },
  getWelcomeProfile(request: GetMyProfileServiceRequest): Promise<GetMyProfileServiceResponse> {
    return makeRequest(profileClient.getMyProfile, request);
  },
  getListMembersRoles(
    request: ListMembersRolesServiceRequest
  ): Promise<ListMembersRolesServiceResponse> {
    return makeRequest(iAMClient.listMembersRoles, request);
  },
  getProfile(request: GetProfileServiceRequest): Promise<GetProfileServiceResponse> {
    return makeRequest<GetProfileRequest, GetProfileResponse>(profileClient.getProfile, request);
  },

  listProfiles(request: ListProfilesServiceRequest): Promise<ListProfilesServiceResponse> {
    return makeRequest(profileClient.listProfiles, request);
  },

  attachRole(request: AttachRoleServiceRequest): Promise<AttachRoleServiceResponse> {
    return makeRequest<AttachRoleRequest, AttachRoleResponse>(iAMClient.attachRole, request);
  },
  detachRole(request: DetachRolesServiceRequest): Promise<DetachRolesServiceResponse> {
    return makeRequest<DetachRolesRequest, DetachRolesResponse>(iAMClient.detachRoles, request);
  },
  removeMember(request: RemoveMemberServiceRequest): Promise<RemoveMemberServiceResponse> {
    return makeRequest<RemoveMemberRequest, RemoveMemberResponse>(
      membershipClient.removeMember,
      request
    );
  },
  leaveMembership(request: LeaveMembershipServiceRequest): Promise<LeaveMembershipServiceResponse> {
    return makeRequest<LeaveMembershipRequest, LeaveMembershipResponse>(
      membershipClient.leaveMembership,
      request
    );
  },
  updateProfileName(
    request: UpdateProfilePersonalInfoServiceRequest
  ): Promise<UpdateProfilePersonalInfoServiceResponse> {
    return makeRequest<UpdateProfilePersonalInfoRequest, UpdateProfilePersonalInfoResponse>(
      profileClient.updateProfilePersonalInfo,
      request
    );
  },

  changeProfileStatus(
    request: ChangeProfileStatusServiceRequest
  ): Promise<ChangeProfileStatusServiceResponse> {
    return makeRequest<ChangeProfileStatusRequest, ChangeProfileStatusResponse>(
      profileClient.changeProfileStatus,
      request
    );
  },
  selectProfiles(request: SelectProfilesServiceRequest): Promise<SelectProfilesServiceResponse> {
    return makeRequest<SelectProfilesRequest, SelectProfilesResponse>(
      profileClient.selectProfiles,
      request
    );
  },

  updateTraits(
    request: UpdateProfileTraitsServiceRequest
  ): Promise<UpdateProfileTraitsServiceResponse> {
    return makeRequest<UpdateProfileTraitsRequest, UpdateProfileTraitsResponse>(
      profileClient.updateProfileTraits,
      request
    );
  },
  listToolTips(): Promise<ListToolTipsServiceResponse> {
    return makeRequest<ListToolTipsRequest, ListToolTipsResponse>(
      toolTipsClient.listToolTips,
      new ListToolTipsRequest()
    );
  },
  finishTooltips(request: FinishToolTipsServiceRequest): Promise<FinishToolTipsServiceResponse> {
    return makeRequest<FinishToolTipsRequest, FinishToolTipsResponse>(
      toolTipsClient.finishToolTips,
      request
    );
  },
};
