import { Link, useNavigate, useParams } from 'react-router-dom';
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import SelectDemandStatus from '@pages/Marketplace/Dashboards/SelectDemandStatus';
import UpdateInterviewRequirements from '@pages/Marketplace/Dashboards/UpdateInterviewRequirements';
import UpdateDemandTraits from '@pages/Marketplace/Dashboards/UpdateDemandTraits';
import Container from '@mui/material/Container';
import Tags from '@components/Tags/Tags';
import { useGetDemand } from '@services/api/marketplace/calls';

const ManageInterviewPage = () => {
  const { demandId, dashboardId } = useParams();
  const navigate = useNavigate();
  const { data: demand } = useGetDemand({ demandId: demandId || '', type: 'interview' });

  const interview = demand?.demand?.entity?.value;

  return (
    <Box px={3}>
      <Container>
        <Box display="flex" flexDirection="column" py={2} gap={2}>
          <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">
              {demand?.demand?.entity.case?.toUpperCase()}
            </Typography>
            <Box display="flex" gap={1} alignItems="center">
              <EventIcon />
              {
                // TODO fix date
                /* <Typography variant="subtitle2"> */
              }
              {/*    {interview?.createdAt && format(new Date(interview?.createdAt), 'dd/MM/yyyy')} */}
              {/* </Typography> */}
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <SelectDemandStatus status={demand?.demand?.status?.status?.toString() || ''} />
          </Box>
          <Link className="NavLink" to={`/${dashboardId}/interview-manage/${demandId}/evaluations`}>
            <Typography variant="subtitle2">Manage evaluations</Typography>
          </Link>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={1}>
              {demand?.demand && <UpdateDemandTraits demand={demand?.demand} />}
              {interview?.tags?.tags && <Tags tags={interview.tags.tags} />}
              {demand?.demand && <UpdateInterviewRequirements demand={demand?.demand} />}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ManageInterviewPage;
