import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductRequest,
  DeleteProductResponse,
  GetProductRequest,
  GetProductResponse,
  ListProductsRequest,
  ListProductsResponse,
} from '@proto/marketplace/product/v1/product_pb';

import { getClient, makeRequest, PlainMsg } from '@services/api/helpers';
import { ProductService } from '@proto/marketplace/product/v1/product_connect';

export type CreateProductServiceRequest = PlainMsg<CreateProductRequest>;
export type CreateProductServiceResponse = PlainMsg<CreateProductResponse>;
export type DeleteProductServiceRequest = PlainMsg<DeleteProductRequest>;
export type DeleteProductServiceResponse = PlainMsg<DeleteProductResponse>;
export type GetProductServiceRequest = PlainMsg<GetProductRequest>;
export type GetProductServiceResponse = PlainMsg<GetProductResponse>;
export type ListProductsServiceRequest = PlainMsg<ListProductsRequest>;
export type ListProductsServiceResponse = PlainMsg<ListProductsResponse>;
const productClient = getClient<typeof ProductService>(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProductService
);

export const productService = {
  createProduct(request: CreateProductServiceRequest): Promise<CreateProductServiceResponse> {
    return makeRequest<CreateProductRequest, CreateProductResponse>(
      productClient.createProduct,
      request
    );
  },
  deleteProduct(request: DeleteProductServiceRequest): Promise<DeleteProductServiceResponse> {
    return makeRequest<DeleteProductRequest, DeleteProductResponse>(
      productClient.deleteProduct,
      request
    );
  },
  getProduct(request: GetProductServiceRequest): Promise<GetProductServiceResponse> {
    return makeRequest<GetProductRequest, GetProductResponse>(productClient.getProduct, request);
  },
  listProducts(request: ListProductsServiceRequest): Promise<ListProductsServiceResponse> {
    return makeRequest<ListProductsRequest, ListProductsResponse>(
      productClient.listProducts,
      request
    );
  },
};
