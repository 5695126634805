import Joi from 'joi';
import { Entity } from '@proto/grpc/type/v1/entity_pb';

export enum FieldNames {
  TITLE = 'title',
  DESCRIPTION = 'description',
  SPECIFICATION = 'specification',
  VERSION = 'version',
  URL = 'demoUrl',
  OWNER = 'owner',
  COUNTRIES = 'countries',
  LANGUAGES = 'languages',
  INDUSTRY_DOMAIN = 'industryDomain',
  ROLES = 'roles',
  PROGRAMMING_LANGUAGES = 'programmingLanguages',
}

export const getDefaultValues = (owner: Entity) => ({
  [FieldNames.TITLE]: '',
  [FieldNames.DESCRIPTION]: '',
  [FieldNames.SPECIFICATION]: '',
  [FieldNames.VERSION]: '',
  [FieldNames.URL]: '',
  [FieldNames.OWNER]: owner,
});

export const createOrgResolverSchema = {
  [FieldNames.TITLE]: Joi.string().required().min(2).max(30).messages({
    'string.empty': 'enter the name of the organization',
    'string.min': 'organization name must be at least 2 characters long',
    'string.max': 'organization name must be less than or equal to 30 characters long',
  }),
};
