import * as React from 'react';
import { Box, Container } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import { useListFeedItems, useListFeedItemsByDemand } from '@services/api/marketplace/calls';
import { Entity, TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import SidebarProfileSettings from '@pages/Main/SidebarProfileSettings/SidebarProfileSettings';
import FeedList from '@pages/Marketplace/Feed/FeedList';
import FeedByDemandsDialog from '@pages/Marketplace/Feed/FeedByDemandsDialog';
import Grid from '@mui/material/Grid';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';
import FilterFeedItems from '@pages/Marketplace/Feed/FilterFeedItems';

export const demandsTypes = {
  contract: TypeOfEntity.CONTRACT,
  interview: TypeOfEntity.INTERVIEW,
};

const FeedPage = () => {
  const { demandIdFromUrl } = useMarketplace();
  const { data, isLoading, error: lisftFeedError } = useListFeedItems();
  const {
    data: feedByContract,
    error,
    isPending,
    isLoading: feedByContractLoading,
  } = useListFeedItemsByDemand(
    demandIdFromUrl
      ? new Entity({
          id: demandIdFromUrl.demandId,
          type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
        })
      : undefined
  );

  console.log('feedItems', data);
  console.log('feedByDemands', feedByContract, error);

  return (
    <Container>
      {feedByContractLoading && <LoaderWithDelay />}
      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'fixed',
              left: 'auto',
              right: 'auto',
              width: '320px',
              height: '100vh',
              overflowY: 'auto',
            }}
          >
            <SidebarProfileSettings />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ width: '100%' }} display="flex" gap={1}>
            {data?.feedItems && (
              <Box width="800px">
                <FeedList recommendations={data.recomendations} feedItems={data?.feedItems} />
              </Box>
            )}
            <FilterFeedItems />
          </Box>
        </Grid>
      </Grid>
      {!isPending && (
        <FeedByDemandsDialog
          feedOwner={feedByContract?.feedOwner}
          feedItems={feedByContract?.feedItems}
        />
      )}
    </Container>
  );
};

export default FeedPage;
