// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/user/v1/profile.proto (package profiler.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ChangeProfileStatusRequest,
  ChangeProfileStatusResponse,
  CreateProfileRequest,
  CreateProfileResponse,
  GetMyProfileRequest,
  GetMyProfileResponse,
  GetProfileRequest,
  GetProfileResponse,
  GetTermsOfUseRequest,
  GetTermsOfUseResponse,
  ListInterviewersRequest,
  ListInterviewersResponse,
  ListProfilesRequest,
  ListProfilesResponse,
  PinItemRequest,
  PinItemResponse,
  SearchSupplyProfilesRequest,
  SearchSupplyProfilesResponse,
  SelectProfilesRequest,
  SelectProfilesResponse,
  UnpinItemForEveryoneRequest,
  UnpinItemForEveryoneResponse,
  UnpinItemRequest,
  UnpinItemResponse,
  UpdateProfilePersonalInfoRequest,
  UpdateProfilePersonalInfoResponse,
  UpdateProfileTraitsRequest,
  UpdateProfileTraitsResponse,
} from './profile_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.user.v1.ProfileService
 */
export const ProfileService = {
  typeName: 'profiler.user.v1.ProfileService',
  methods: {
    /**
     * @generated from rpc profiler.user.v1.ProfileService.GetProfile
     */
    getProfile: {
      name: 'GetProfile',
      I: GetProfileRequest,
      O: GetProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.GetMyProfile
     */
    getMyProfile: {
      name: 'GetMyProfile',
      I: GetMyProfileRequest,
      O: GetMyProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.CreateProfile
     */
    createProfile: {
      name: 'CreateProfile',
      I: CreateProfileRequest,
      O: CreateProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.ChangeProfileStatus
     */
    changeProfileStatus: {
      name: 'ChangeProfileStatus',
      I: ChangeProfileStatusRequest,
      O: ChangeProfileStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.UpdateProfilePersonalInfo
     */
    updateProfilePersonalInfo: {
      name: 'UpdateProfilePersonalInfo',
      I: UpdateProfilePersonalInfoRequest,
      O: UpdateProfilePersonalInfoResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.ListProfiles
     */
    listProfiles: {
      name: 'ListProfiles',
      I: ListProfilesRequest,
      O: ListProfilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.ListInterviewers
     */
    listInterviewers: {
      name: 'ListInterviewers',
      I: ListInterviewersRequest,
      O: ListInterviewersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.SearchSupplyProfiles
     */
    searchSupplyProfiles: {
      name: 'SearchSupplyProfiles',
      I: SearchSupplyProfilesRequest,
      O: SearchSupplyProfilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.SelectProfiles
     */
    selectProfiles: {
      name: 'SelectProfiles',
      I: SelectProfilesRequest,
      O: SelectProfilesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.UpdateProfileTraits
     */
    updateProfileTraits: {
      name: 'UpdateProfileTraits',
      I: UpdateProfileTraitsRequest,
      O: UpdateProfileTraitsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.GetTermsOfUse
     */
    getTermsOfUse: {
      name: 'GetTermsOfUse',
      I: GetTermsOfUseRequest,
      O: GetTermsOfUseResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.PinItem
     */
    pinItem: {
      name: 'PinItem',
      I: PinItemRequest,
      O: PinItemResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.UnpinItem
     */
    unpinItem: {
      name: 'UnpinItem',
      I: UnpinItemRequest,
      O: UnpinItemResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.user.v1.ProfileService.UnpinItemForEveryone
     */
    unpinItemForEveryone: {
      name: 'UnpinItemForEveryone',
      I: UnpinItemForEveryoneRequest,
      O: UnpinItemForEveryoneResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
