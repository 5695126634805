// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/invite/v1/invite.proto (package profiler.invite.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  InviteActionRequest,
  InviteActionResponse,
  ListInvitesRequest,
  ListInvitesResponse,
  ListUserInvitesRequest,
  ListUserInvitesResponse,
  SendInvitesRequest,
  SendInvitesResponse,
} from './invite_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.invite.v1.InviteService
 */
export const InviteService = {
  typeName: 'profiler.invite.v1.InviteService',
  methods: {
    /**
     * @generated from rpc profiler.invite.v1.InviteService.SendInvites
     */
    sendInvites: {
      name: 'SendInvites',
      I: SendInvitesRequest,
      O: SendInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.invite.v1.InviteService.InviteAction
     */
    inviteAction: {
      name: 'InviteAction',
      I: InviteActionRequest,
      O: InviteActionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.invite.v1.InviteService.ListUserInvites
     */
    listUserInvites: {
      name: 'ListUserInvites',
      I: ListUserInvitesRequest,
      O: ListUserInvitesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.invite.v1.InviteService.ListInvites
     */
    listInvites: {
      name: 'ListInvites',
      I: ListInvitesRequest,
      O: ListInvitesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
