import Box from '@mui/material/Box';
import { useProfiler } from '@store/profiler';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { InviteAction, InviteActionRequest } from '@proto/profiler/invite/v1/invite_pb';
import React from 'react';
import { useParams } from 'react-router-dom';

const InterviewInviteCard = () => {
  const { interviewId } = useParams();
  const { listUserInvites } = useProfiler();
  const { closeUserInvite, acceptUserInvite } = useProfiler();

  const handleAccept = () => {
    acceptUserInvite({
      inviteId: listUserInvites[interviewId as keyof typeof listUserInvites].inviteId,
      action: InviteAction.ACCEPT,
    });
  };

  const handleDecline = () => {
    closeUserInvite(
      new InviteActionRequest({
        inviteId: listUserInvites[interviewId as keyof typeof listUserInvites].inviteId,
        action: InviteAction.REJECT,
      })
    );
  };

  return listUserInvites[interviewId as keyof typeof listUserInvites] ? (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={2} alignItems="center">
      <Typography variant="subtitle1">You were invited</Typography>
      <Box display="flex" gap={1}>
        <Button variant="outlined" color="success" onClick={handleAccept}>
          Accept
        </Button>
        <Button onClick={handleDecline} variant="outlined" color="error">
          Decline
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default InterviewInviteCard;
