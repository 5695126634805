import React, { Suspense } from 'react';
import { Member } from '@components/MembesrList/Member';
import Stack from '@mui/material/Stack';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { useProfiler } from '@store/profiler';
import { TProfile } from '@services/api/profiler/profile';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useDialog } from '@components/hooks/useDialog';
import AddMemberDialog from '@components/AddMemberDialog/AddMemberDialog';
import { userSlice } from '@store/profiler/slices';
import { useAppDispatch } from '@store/helpers';
import ManageSquadInvitesList from '@pages/Squads/ManageSquads/ManageSquadInvitesList';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import { useParams } from 'react-router-dom';

const ManageSquadMembersList = ({ entityId }: { entityId: string }) => {
  const dispatch = useAppDispatch();
  const { name } = useParams();
  const { listMembers } = useProfiler();
  const { isOpen, handleOpen, handleClose } = useDialog();
  const { listRoles, userProfile } = useProfiler();
  const { data: squad, error } = useGetSquad({ case: 'squadId', value: entityId || '' });

  const {
    actions: { deleteSelectedProfile },
  } = userSlice;
  console.log('vdabdasbadsbdsabdasb', listMembers[entityId as keyof typeof listMembers]);

  const requiredRolesToLook = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_MANAGER,
    IAMRoleStrings.IAM_ROLE_BILLING_MANAGER,
    IAMRoleStrings.IAM_ROLE_MEMBER,
  ];
  const hasAccessToLook = hasCommonRoles(
    requiredRolesToLook,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  const requiredRolesToAddMember = [
    IAMRoleStrings.IAM_ROLE_ADMIN,
    IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER,
  ];

  const hasAccessToAddMember = hasCommonRoles(
    requiredRolesToAddMember,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  const closeDialog = () => {
    dispatch(deleteSelectedProfile({ searchProfiles: [] }));
    handleClose();
  };

  return hasAccessToLook ? (
    <Stack gap={0.5}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Members
        </Typography>
        {hasAccessToAddMember && (
          <AddIcon
            sx={{ width: '20px', cursor: 'pointer' }}
            onClick={(event) => {
              handleOpen();
            }}
            color="success"
          />
        )}
      </Stack>
      <ManageSquadInvitesList />
      <Stack gap={1.5}>
        {squad?.squad &&
          listMembers[entityId as keyof typeof listMembers]?.profiles?.profiles?.map(
            (member: TProfile) => {
              return (
                squad?.squad && (
                  <Member
                    key={member.profileId}
                    currentEntity={squad?.squad}
                    entityId={entityId}
                    member={member}
                  />
                )
              );
            }
          )}
      </Stack>
      <Suspense fallback={null}>
        <AddMemberDialog
          objectName={squad?.squad?.name}
          isOpen={isOpen}
          handleClose={closeDialog}
          entity={TypeOfEntity.SQUAD}
          entityId={entityId || ''}
        />
      </Suspense>
    </Stack>
  ) : null;
};
export default ManageSquadMembersList;
