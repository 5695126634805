// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file notificator/events/v1/events.proto (package notificator.events.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from enum notificator.events.v1.EventMessageType
 */
export enum EventMessageType {
  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_INVITE = 1;
   */
  INVITE = 1,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_EVALUATION = 2;
   */
  EVALUATION = 2,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_RECOMMENDATION = 3;
   */
  RECOMMENDATION = 3,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_ACHIEVEMENT = 4;
   */
  ACHIEVEMENT = 4,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_SUPPLY_APPLY = 5;
   */
  SUPPLY_APPLY = 5,

  /**
   * @generated from enum value: EVENT_MESSAGE_TYPE_SUPPLY_APPROVE = 6;
   */
  SUPPLY_APPROVE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(EventMessageType)
proto3.util.setEnumType(EventMessageType, 'notificator.events.v1.EventMessageType', [
  { no: 0, name: 'EVENT_MESSAGE_TYPE_UNSPECIFIED' },
  { no: 1, name: 'EVENT_MESSAGE_TYPE_INVITE' },
  { no: 2, name: 'EVENT_MESSAGE_TYPE_EVALUATION' },
  { no: 3, name: 'EVENT_MESSAGE_TYPE_RECOMMENDATION' },
  { no: 4, name: 'EVENT_MESSAGE_TYPE_ACHIEVEMENT' },
  { no: 5, name: 'EVENT_MESSAGE_TYPE_SUPPLY_APPLY' },
  { no: 6, name: 'EVENT_MESSAGE_TYPE_SUPPLY_APPROVE' },
]);

/**
 * @generated from message notificator.events.v1.Event
 */
export class Event extends Message<Event> {
  /**
   * @generated from field: string message = 1;
   */
  message = '';

  /**
   * @generated from field: notificator.events.v1.EventMessageType message_type = 2;
   */
  messageType = EventMessageType.UNSPECIFIED;

  /**
   * @generated from field: grpc.type.v1.UUIDS recipients = 3;
   */
  recipients?: UUIDS;

  constructor(data?: PartialMessage<Event>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.events.v1.Event';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'message_type', kind: 'enum', T: proto3.getEnumType(EventMessageType) },
    { no: 3, name: 'recipients', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Event {
    return new Event().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Event {
    return new Event().fromJsonString(jsonString, options);
  }

  static equals(
    a: Event | PlainMessage<Event> | undefined,
    b: Event | PlainMessage<Event> | undefined
  ): boolean {
    return proto3.util.equals(Event, a, b);
  }
}

/**
 * @generated from message notificator.events.v1.SubscribeRequest
 */
export class SubscribeRequest extends Message<SubscribeRequest> {
  constructor(data?: PartialMessage<SubscribeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.events.v1.SubscribeRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeRequest {
    return new SubscribeRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined,
    b: SubscribeRequest | PlainMessage<SubscribeRequest> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeRequest, a, b);
  }
}

/**
 * @generated from message notificator.events.v1.SubscribeResponse
 */
export class SubscribeResponse extends Message<SubscribeResponse> {
  /**
   * @generated from field: string subject = 1;
   */
  subject = '';

  /**
   * @generated from field: string message = 2;
   */
  message = '';

  /**
   * @generated from field: notificator.events.v1.EventMessageType message_type = 3;
   */
  messageType = EventMessageType.UNSPECIFIED;

  constructor(data?: PartialMessage<SubscribeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.events.v1.SubscribeResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'message', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'message_type', kind: 'enum', T: proto3.getEnumType(EventMessageType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeResponse {
    return new SubscribeResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined,
    b: SubscribeResponse | PlainMessage<SubscribeResponse> | undefined
  ): boolean {
    return proto3.util.equals(SubscribeResponse, a, b);
  }
}

/**
 * @generated from message notificator.events.v1.CreateRequest
 */
export class CreateRequest extends Message<CreateRequest> {
  /**
   * @generated from field: string subject = 1;
   */
  subject = '';

  /**
   * @generated from field: repeated notificator.events.v1.Event events = 2;
   */
  events: Event[] = [];

  constructor(data?: PartialMessage<CreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.events.v1.CreateRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'subject', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'events', kind: 'message', T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateRequest {
    return new CreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateRequest {
    return new CreateRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateRequest | PlainMessage<CreateRequest> | undefined,
    b: CreateRequest | PlainMessage<CreateRequest> | undefined
  ): boolean {
    return proto3.util.equals(CreateRequest, a, b);
  }
}

/**
 * @generated from message notificator.events.v1.CreateResponse
 */
export class CreateResponse extends Message<CreateResponse> {
  constructor(data?: PartialMessage<CreateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'notificator.events.v1.CreateResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateResponse {
    return new CreateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateResponse {
    return new CreateResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: CreateResponse | PlainMessage<CreateResponse> | undefined,
    b: CreateResponse | PlainMessage<CreateResponse> | undefined
  ): boolean {
    return proto3.util.equals(CreateResponse, a, b);
  }
}
