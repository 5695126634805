import { Form } from '@components/Form';
import Grid from '@mui/material/Grid';
import { InputController } from '@components/Form/controllers/InputController';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useOrgs } from '@store/orgs';
import SearchIcon from '@mui/icons-material/Search';

const ListOrgsForm = () => {
  const { searchOrgs } = useOrgs();

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: { name: '', industry_domain: [] },
      }}
      submitHandler={(data) => {
        searchOrgs(data);
      }}
    >
      <Grid container spacing={2} display="flex" justifyContent="space-between" mb={2}>
        <Grid item xs={8.9}>
          <Stack spacing={2}>
            <InputController name="name" label="Name" />
          </Stack>
        </Grid>
        <Grid item xs={2.5} display="flex" justifyContent="flex-end">
          <Button type="submit" variant="outlined">
            <SearchIcon />
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
export default ListOrgsForm;
