import { Page } from '@components/Page/Page';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useMarketplace } from '@store/marketplace';
import * as React from 'react';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Divider from '@mui/material/Divider';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import HTMLReactParser from 'html-react-parser';
import Tags from '@components/Tags/Tags';
import { useGetDemand } from '@services/api/marketplace/calls';
import { Contract } from '@proto/marketplace/demand/v1/contract_pb';

const ContractPage = () => {
  const { contractId } = useParams();
  const { getDemand, foundDemands } = useMarketplace();
  const { data: demand } = useGetDemand({ demandId: contractId || '', type: 'contract' });

  useEffect(() => {
    if (contractId) {
      getDemand({ type: 'contract', id: contractId });
    }
  }, [contractId, getDemand]);

  return (
    <Page description="Contract" title="Contract">
      <Container>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="subtitle1">Contract</Typography>
            {/* <Tooltip title="Created at"> */}
            {/*    <Box display="flex" gap={1} alignItems="center"> */}
            {/*        <EventIcon/> */}
            {/*        <Typography variant="subtitle2"> */}
            {/*            {demand?.createdAt && format(new Date(demand?.createdAt), 'dd/MM/yyyy')} */}
            {/*        </Typography> */}
            {/*    </Box> */}
            {/* </Tooltip> */}
          </Box>
          <Divider />
          {demand?.demand?.entity.value && (
            <DemandTraits gap={1} demand={demand?.demand?.entity.value} />
          )}
          {demand?.demand?.entity?.value?.tags?.tags && (
            <Tags tags={demand?.demand?.entity.value.tags.tags} />
          )}
          {(demand?.demand?.entity?.value as Contract)?.description &&
            HTMLReactParser(
              stateToHTML(
                convertFromRaw(JSON.parse((demand?.demand?.entity?.value as Contract)?.description))
              )
            )}
        </Box>
      </Container>
    </Page>
  );
};

export default ContractPage;
