import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useProfiler } from '@store/profiler';
import { IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';

type RolesAutocompleteProps = {
  defaultRoles: IAMRoleStrings[] | undefined;
  entityId: string;
  subjectId: string;
};

export enum IAMRole {
  IAM_ROLE_UNSPECIFIED = 0,
  IAM_ROLE_ADMIN = 1,
  IAM_ROLE_MANAGER = 2,
  IAM_ROLE_MEMBER = 3,
  IAM_ROLE_BILLING_MANAGER = 4,
  IAM_ROLE_PEOPLE_MANAGER = 5,
}

const rolesOptions = [
  { label: 'IAM_ROLE_ADMIN', value: 'IAM_ROLE_ADMIN' },
  { label: 'IAM_ROLE_MEMBER', value: 'IAM_ROLE_MEMBER' },
  { label: 'IAM_ROLE_MANAGER', value: 'IAM_ROLE_MANAGER' },
  { label: 'IAM_ROLE_BILLING_MANAGER', value: 'IAM_ROLE_BILLING_MANAGER' },
  { label: 'IAM_ROLE_PEOPLE_MANAGER', value: 'IAM_ROLE_PEOPLE_MANAGER' },
];

const RolesAutocomplete = (props: RolesAutocompleteProps) => {
  const { defaultRoles, subjectId, entityId } = props;
  const { attachRole, detachRole } = useProfiler();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: any,
    reaeson: string,
    details: any
  ) => {
    if (reaeson === 'selectOption') {
      return attachRole({
        subjectId,
        domainId: entityId,
        role: details.option.value,
      });
    }
    if (reaeson === 'removeOption') {
      return detachRole({
        subjectId,
        domainId: entityId,
        role: details.option.value,
      });
    }
    return 'success';
  };

  return defaultRoles !== undefined ? (
    <Autocomplete
      onChange={handleChange}
      defaultValue={defaultRoles?.map((role) => {
        return { label: role?.toString(), value: role?.toString() };
      })}
      options={rolesOptions}
      getOptionLabel={(option) => option.label}
      multiple
      isOptionEqualToValue={(option, _value) => option.label === _value.label}
      renderInput={(params) => <TextField {...params} label="Choose role" />}
    />
  ) : null;
};
export default RolesAutocomplete;
