import { getClient, makeRequest, PlainMsg } from '@services/api/helpers';

import {
  CreateContractRequest,
  CreateContractResponse,
  DeleteContractRequest,
  DeleteContractResponse,
  GetContractRequest,
  GetContractResponse,
  UpdateContractDescriptionRequest,
  UpdateContractDescriptionResponse,
  UpdateContractTraitsRequest,
  UpdateContractTraitsResponse,
} from '@proto/marketplace/demand/v1/contract_pb';
import {
  CreateInterviewRequest,
  CreateInterviewResponse,
  DeleteInterviewRequest,
  DeleteInterviewResponse,
  GetInterviewRequest,
  GetInterviewResponse,
  UpdateInterviewRequest,
  UpdateInterviewRequirementsRequest,
  UpdateInterviewRequirementsResponse,
  UpdateInterviewResponse,
} from '@proto/marketplace/demand/v1/interview_pb';
import { ContractService } from '@proto/marketplace/demand/v1/contract_connect';
import { InterviewService } from '@proto/marketplace/demand/v1/interview_connect';
import { DemandService } from '@proto/marketplace/demand/v1/demand_connect';
import {
  GetDemandRequest,
  GetDemandResponse,
  ListAppliedDemandsRequest,
  ListAppliedDemandsResponse,
  SetDemandStatusRequest,
  SetDemandStatusResponse,
} from '@proto/marketplace/demand/v1/demand_pb';
import { InterviewerService } from '@proto/marketplace/demand/v1/interviewer_connect';
import { ContractorService } from '@proto/marketplace/demand/v1/contractor_connect';
import {
  ApplyAsInterviewerRequest,
  ApplyAsInterviewerResponse,
  ApproveInterviewerRequest,
  ApproveInterviewerResponse,
  RejectInterviewerRequest,
  RejectInterviewerResponse,
  RevokeInterviewerApplyRequest,
  RevokeInterviewerApplyResponse,
} from '@proto/marketplace/demand/v1/interviewer_pb';
import {
  ApproveContractorRequest,
  ApproveContractorResponse,
  RejectContractorRequest,
  RejectContractorResponse,
  RevokeContractorApplyRequest,
  RevokeContractorApplyResponse,
} from '@proto/marketplace/demand/v1/contractor_pb';
import { CandidateService } from '@proto/marketplace/demand/v1/candidate_connect';
import {
  AddCandidateRequest,
  AddCandidateResponse,
  InviteCandidatesRequest,
  InviteCandidatesResponse,
  ListCandidatesRequest,
  ListCandidatesResponse,
  RemoveCandidateRequest,
  RemoveCandidateResponse,
} from '@proto/marketplace/demand/v1/candidate_pb';
import { EvaluationService } from '@proto/marketplace/demand/v1/evaluation_connect';
import {
  GetEvaluationRequest,
  GetEvaluationResponse,
  ListEvaluationsRequest,
  ListEvaluationsResponse,
  SaveEvaluationFeedbackDraftRequest,
  SaveEvaluationFeedbackDraftResponse,
  SendEvaluationFeedbackRequest,
  SendEvaluationFeedbackResponse,
} from '@proto/marketplace/demand/v1/evaluation_pb';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export type CreateContractServiceRequest = PlainMsg<CreateContractRequest>;
export type CreateContractServiceResponse = PlainMsg<CreateContractResponse>;
export type GetContractServiceRequest = PlainMsg<GetContractRequest>;
export type GetContractServiceResponse = PlainMsg<GetContractResponse>;
export type UpdateContractTraitsServiceRequest = PlainMsg<UpdateContractTraitsRequest>;
export type UpdateContractTraitsServiceResponse = PlainMsg<UpdateContractTraitsResponse>;
export type UpdateContractDescriptionServiceRequest = PlainMsg<UpdateContractDescriptionRequest>;
export type UpdateContractDescriptionServiceResponse = PlainMsg<UpdateContractDescriptionResponse>;
export type DeleteContractServiceRequest = PlainMsg<DeleteContractRequest>;
export type DeleteContractServiceResponse = PlainMsg<DeleteContractResponse>;
export type CreateInterviewServiceRequest = PlainMsg<CreateInterviewRequest>;
export type CreateInterviewServiceResponse = PlainMsg<CreateInterviewResponse>;
export type GetInterviewServiceRequest = PlainMsg<GetInterviewRequest>;
export type GetInterviewServiceResponse = PlainMsg<GetInterviewResponse>;
export type UpdateInterviewServiceRequest = PlainMsg<UpdateInterviewRequest>;
export type UpdateInterviewServiceResponse = PlainMsg<UpdateInterviewResponse>;
export type UpdateInterviewRequirementsServiceRequest =
  PlainMsg<UpdateInterviewRequirementsRequest>;
export type UpdateInterviewRequirementsServiceResponse =
  PlainMsg<UpdateInterviewRequirementsResponse>;
export type DeleteInterviewServiceRequest = PlainMsg<DeleteInterviewRequest>;
export type DeleteInterviewServiceResponse = PlainMsg<DeleteInterviewResponse>;
export type SetDemandStatusServiceRequest = PlainMsg<SetDemandStatusRequest>;
export type SetDemandStatusServiceResponse = PlainMsg<SetDemandStatusResponse>;
export type GetDemandServiceRequest = PlainMsg<GetDemandRequest>;
export type GetDemandServiceResponse = PlainMsg<GetDemandResponse>;
export type ApplyAsInterviewerServiceRequest = PlainMsg<ApplyAsInterviewerRequest>;
export type ApplyAsInterviewerServiceResponse = PlainMsg<ApplyAsInterviewerResponse>;
export type ApproveInterviewerServiceRequest = PlainMsg<ApproveInterviewerRequest>;
export type ApproveInterviewerServiceResponse = PlainMsg<ApproveInterviewerResponse>;
export type RejectInterviewerServiceRequest = PlainMsg<RejectInterviewerRequest>;
export type RejectInterviewerServiceResponse = PlainMsg<RejectInterviewerResponse>;
export type RevokeInterviewerApplyServiceRequest = PlainMsg<RevokeInterviewerApplyRequest>;
export type RevokeInterviewerApplyServiceResponse = PlainMsg<RevokeInterviewerApplyResponse>;
export type ListAppliedDemandsServiceRequest = PlainMsg<ListAppliedDemandsRequest>;
export type ListAppliedDemandsServiceResponse = PlainMsg<ListAppliedDemandsResponse>;
export type ApproveContractorServiceResponse = PlainMsg<ApproveContractorResponse>;
export type ApproveContractorServiceRequest = PlainMsg<ApproveContractorRequest>;
export type RejectContractorServiceRequest = PlainMsg<RejectContractorRequest>;
export type RejectContractorServiceResponse = PlainMsg<RejectContractorResponse>;
export type RevokeContractorApplyServiceRequest = PlainMsg<RevokeContractorApplyRequest>;
export type RevokeContractorApplyServiceResponse = PlainMsg<RevokeContractorApplyResponse>;
export type InviteCandidatesServiceRequest = PlainMsg<InviteCandidatesRequest>;
export type InviteCandidatesServiceResponse = PlainMsg<InviteCandidatesResponse>;
export type ListCandidatesServiceRequest = PlainMsg<ListCandidatesRequest>;
export type ListCandidatesServiceResponse = PlainMsg<ListCandidatesResponse>;
export type RemoveCandidateServiceRequest = PlainMsg<RemoveCandidateRequest>;
export type RemoveCandidateServiceResponse = PlainMsg<RemoveCandidateResponse>;
export type AddCandidateServiceRequest = PlainMsg<AddCandidateRequest>;
export type AddCandidateServiceResponse = PlainMsg<AddCandidateResponse>;
export type ListEvaluationServiceRequest = PlainMsg<ListEvaluationsRequest>;
export type ListEvaluationServiceResponse = PlainMsg<ListEvaluationsResponse>;
export type GetEvaluationServiceRequest = PlainMsg<GetEvaluationRequest>;
export type GetEvaluationServiceResponse = PlainMsg<GetEvaluationResponse>;
export type SendEvaluationFeedbackServiceRequest = PlainMsg<SendEvaluationFeedbackRequest>;
export type SendEvaluationFeedbackServiceResponse = PlainMsg<SendEvaluationFeedbackResponse>;
export type SaveEvaluationFeedbackDraftServiceRequest =
  PlainMsg<SaveEvaluationFeedbackDraftRequest>;
export type SaveEvaluationFeedbackDraftServiceResponse =
  PlainMsg<SaveEvaluationFeedbackDraftResponse>;

export const demandClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  DemandService
);

export const contractClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ContractService
);

export const contractorClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ContractorService
);

export const interviewClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InterviewService
);

const interviewerClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  InterviewerService
);

const candidateClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  CandidateService
);

export const evaluationClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  EvaluationService
);

export const demandService = {
  createContract(request: CreateContractServiceRequest): Promise<CreateContractServiceResponse> {
    return makeRequest(contractClient.createContract, request);
  },
  getContract(request: GetContractServiceRequest): Promise<GetContractServiceResponse> {
    return makeRequest(contractClient.getContract, request);
  },
  updateContractTraits(
    request: UpdateContractTraitsServiceRequest
  ): Promise<UpdateContractTraitsServiceResponse> {
    return makeRequest(contractClient.updateContractTraits, request);
  },
  updateContractDescription(
    request: UpdateContractDescriptionServiceRequest
  ): Promise<UpdateContractDescriptionServiceResponse> {
    return makeRequest(contractClient.updateContractDescription, request);
  },
  deleteContract(request: DeleteContractServiceRequest): Promise<DeleteContractServiceResponse> {
    return makeRequest(contractClient.deleteContract, request);
  },

  createInterview(request: CreateInterviewServiceRequest): Promise<CreateInterviewServiceResponse> {
    return makeRequest(interviewClient.createInterview, request);
  },
  getInterview(request: GetInterviewServiceRequest): Promise<GetInterviewServiceResponse> {
    return makeRequest(interviewClient.getInterview, request);
  },
  updateInterview(request: UpdateInterviewServiceRequest): Promise<UpdateInterviewServiceResponse> {
    return makeRequest(interviewClient.updateInterview, request);
  },
  deleteInterview(request: DeleteInterviewServiceRequest): Promise<DeleteInterviewServiceResponse> {
    return makeRequest(interviewClient.deleteInterview, request);
  },
  setDemandStatus(request: SetDemandStatusServiceRequest): Promise<SetDemandStatusServiceResponse> {
    return makeRequest(demandClient.setDemandStatus, request);
  },
  applyAsInterviewer(
    request: ApplyAsInterviewerServiceRequest
  ): Promise<ApplyAsInterviewerServiceResponse> {
    return makeRequest(interviewerClient.applyAsInterviewer, request);
  },
  listAppliedDemands(): Promise<ListAppliedDemandsServiceResponse> {
    return makeRequest(demandClient.listAppliedDemands, new ListAppliedDemandsRequest());
  },
  getDemand(request: GetDemandServiceRequest): Promise<GetDemandServiceResponse> {
    return makeRequest(demandClient.getDemand, request);
  },
  approveContractor(
    request: ApproveContractorServiceRequest
  ): Promise<ApproveContractorServiceResponse> {
    return makeRequest(contractorClient.approveContractor, request);
  },
  approveInterviewer(
    request: ApproveInterviewerServiceRequest
  ): Promise<ApproveInterviewerServiceResponse> {
    return makeRequest(interviewerClient.approveInterviewer, request);
  },
  rejectContractor(
    request: RejectContractorServiceRequest
  ): Promise<RejectContractorServiceResponse> {
    return makeRequest(contractorClient.rejectContractor, request);
  },
  rejectInterviewer(
    request: RejectInterviewerServiceRequest
  ): Promise<RejectInterviewerServiceResponse> {
    return makeRequest(interviewerClient.rejectInterviewer, request);
  },
  revokeContractorApply(
    request: RevokeContractorApplyServiceRequest
  ): Promise<RevokeContractorApplyServiceResponse> {
    return makeRequest(contractorClient.revokeContractorApply, request);
  },
  revokeInterviewerApply(
    request: RevokeInterviewerApplyServiceRequest
  ): Promise<RevokeInterviewerApplyServiceResponse> {
    return makeRequest(interviewerClient.revokeInterviewerApply, request);
  },
  inviteCandidates(
    request: InviteCandidatesServiceRequest
  ): Promise<InviteCandidatesServiceResponse> {
    return makeRequest(candidateClient.inviteCandidates, request);
  },
  listCandidates(request: ListCandidatesServiceRequest): Promise<ListCandidatesServiceResponse> {
    return makeRequest(candidateClient.listCandidates, request);
  },
  removeCandidate(request: RemoveCandidateServiceRequest): Promise<RemoveCandidateServiceResponse> {
    return makeRequest(candidateClient.removeCandidate, request);
  },
  addCandidate(request: AddCandidateServiceRequest): Promise<AddCandidateServiceResponse> {
    return makeRequest(candidateClient.addCandidate, request);
  },
  listEvaluations(): Promise<ListEvaluationServiceResponse> {
    return makeRequest(evaluationClient.listEvaluations, new ListEvaluationsRequest());
  },
  getEvaluation(request: GetEvaluationServiceRequest): Promise<GetEvaluationServiceResponse> {
    return makeRequest(evaluationClient.getEvaluation, request);
  },
  sendEvaluationFeedback(
    request: SendEvaluationFeedbackServiceRequest
  ): Promise<SendEvaluationFeedbackServiceResponse> {
    return makeRequest(evaluationClient.sendEvaluationFeedback, request);
  },
  saveEvaluationFeedbackDraft(
    request: SaveEvaluationFeedbackDraftServiceRequest
  ): Promise<SaveEvaluationFeedbackDraftServiceResponse> {
    return makeRequest(evaluationClient.saveEvaluationFeedbackDraft, request);
  },
  updateInterviewRequirements(
    request: UpdateInterviewRequirementsServiceRequest
  ): Promise<UpdateInterviewRequirementsServiceResponse> {
    return makeRequest(interviewClient.updateInterviewRequirements, request);
  },
};

// querry calls
const listAppliedDemands = async (): Promise<ListAppliedDemandsServiceResponse> => {
  const request = new ListAppliedDemandsRequest();

  return demandClient.listAppliedDemands(request);
};

const getDemand = async (payload: {
  demandId: string;
  type: 'contract' | 'interview';
}): Promise<GetDemandResponse> => {
  return demandClient.getDemand(
    new GetDemandRequest({
      id: {
        case: payload.type,
        value: payload.demandId,
      },
    })
  );
};

export const useDemand = (): {
  listAppliedDemands: UseQueryResult<any, Error>;
  tgetDemand: (payload: {
    demandId: string;
    type: 'contract' | 'interview';
  }) => UseQueryResult<GetDemandResponse, Error>;
} => {
  const useGetDemandQuery = (payload: { demandId: string; type: 'contract' | 'interview' }) => {
    return useQuery({
      queryKey: ['getDashboard', payload.demandId],
      queryFn: () => getDemand({ demandId: payload.demandId, type: payload.type }),
      staleTime: 5 * 60 * 1000,
    });
  };

  return {
    listAppliedDemands: useQuery({
      queryKey: ['listAppliedDemands'],
      queryFn: listAppliedDemands,
    }),
    tgetDemand: useGetDemandQuery,
  };
};
