import { Chip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { useTags } from '@store/tags';
import { useProfiler } from '@store/profiler';
import { transformTagsToTagsByType } from '@utils/transformTagsToTagsByType';
import { useParams } from 'react-router-dom';
import TagAutocomplete, { TAutocompleteItem } from '@components/TagAutocomplete/TagAutocomplete';
import {
  getDefaultTags,
  removeHandler,
  selectHandler,
  statusChangeHandler,
} from '@components/TagAutocomplete/config';
import Box from '@mui/material/Box';
import { useOrgs } from '@store/orgs';
import Divider from '@mui/material/Divider';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import { TagType } from '@proto/profiler/tags/v1/tags_pb';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { TTag } from '@services/api/profiler/tags';
import SettingsIcon from '@mui/icons-material/Settings';

const OrgTags = () => {
  const { entityId } = useParams();
  const { listRoles, userProfile } = useProfiler();
  const { currentOrg } = useOrgs();
  const [tagsMap, seTTagsMap] = useState<any>([]);
  const [isManage, setIsManage] = useState(false);
  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  const {
    getCountries,
    getLanguages,
    getIndustryDomains,
    countries,
    languages,
    industryDomains,
    addTags,
    removeTags,
    setTagstatus,
  } = useTags();

  useEffect(() => {
    if (currentOrg?.tags?.tags) {
      seTTagsMap(transformTagsToTagsByType(currentOrg?.tags?.tags || []));
    }
  }, [currentOrg?.tags?.tags]);

  return (
    <Stack gap={1} width={{ xs: '100%', md: '40%' }}>
      <Box display="flex" gap={1} alignItems="center">
        <Typography color="#bdbdbd" fontSize="13px" fontWeight="bold">
          Tags
        </Typography>
        {hasAccess && (
          <SettingsIcon onClick={() => setIsManage(!isManage)} sx={{ cursor: 'pointer' }} />
        )}
      </Box>

      <Divider />

      {tagsMap.length !== 0 &&
        (isManage ? (
          <Stack>
            <TagAutocomplete
              items={industryDomains}
              getItems={getIndustryDomains}
              defaultItems={getDefaultTags(tagsMap[3])}
              label="Industry domains"
              statusChanger={(tag, status) => {
                statusChangeHandler(
                  tag,
                  {
                    tagType: TagType.INDUSTRY_DOMAIN,
                    entity: TypeOfEntity.ORG,
                    entityId,
                    status,
                  },
                  setTagstatus
                );
              }}
              onSelect={(tag: TTag) =>
                selectHandler(
                  tag,
                  {
                    tagType: TagType.INDUSTRY_DOMAIN,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  addTags
                )
              }
              onRemove={(tag: TTag) =>
                removeHandler(
                  tag,
                  {
                    tagType: TagType.INDUSTRY_DOMAIN,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  removeTags
                )
              }
            />
            <TagAutocomplete
              items={countries}
              getItems={getCountries}
              defaultItems={getDefaultTags(tagsMap[1])}
              label="Countries"
              statusChanger={(tag: TAutocompleteItem) => {
                statusChangeHandler(
                  tag,
                  {
                    tagType: TagType.COUNTRY,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  setTagstatus
                );
              }}
              onSelect={(tag: TTag) =>
                selectHandler(
                  tag,
                  {
                    tagType: TagType.COUNTRY,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  addTags
                )
              }
              onRemove={(tag: TTag) =>
                removeHandler(
                  tag,
                  {
                    tagType: TagType.COUNTRY,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  removeTags
                )
              }
            />
            <TagAutocomplete
              items={languages}
              getItems={getLanguages}
              defaultItems={getDefaultTags(tagsMap[2])}
              label="Languages"
              statusChanger={(tag, status) => {
                statusChangeHandler(
                  tag,
                  {
                    tagType: TagType.LANGUAGE,
                    entity: TypeOfEntity.ORG,
                    entityId,
                    status,
                  },
                  setTagstatus
                );
              }}
              onSelect={(tag: TTag) =>
                selectHandler(
                  tag,
                  {
                    tagType: TagType.LANGUAGE,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  addTags
                )
              }
              onRemove={(tag: TTag) =>
                removeHandler(
                  tag,
                  {
                    tagType: TagType.LANGUAGE,
                    entity: TypeOfEntity.ORG,
                    entityId,
                  },
                  removeTags
                )
              }
            />
          </Stack>
        ) : (
          <Stack gap={2}>
            {tagsMap[3][1]?.length !== 0 && (
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Industry domain
                </Typography>
                {tagsMap[3][1].map((tag: any) => {
                  return (
                    <Chip
                      key={tag.value}
                      sx={{ marginRight: '8px' }}
                      label={tag.value}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            )}
            {tagsMap[1][1]?.length !== 0 && (
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Countries
                </Typography>
                {tagsMap[1][1].map((tag: any) => {
                  return (
                    <Chip
                      key={tag.value}
                      sx={{ marginRight: '8px' }}
                      label={tag.value}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            )}

            {tagsMap[2][1]?.length !== 0 && (
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Languages
                </Typography>
                {tagsMap[2][1].map((tag: any) => {
                  return (
                    <Chip
                      key={tag.value}
                      sx={{ marginRight: '8px' }}
                      label={tag.value}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            )}
          </Stack>
        ))}
    </Stack>
  );
};
export default OrgTags;
