import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Divider from '@mui/material/Divider';
import { useGetSquad } from '@services/api/profiler/SquadsQueries';
import { useParams } from 'react-router-dom';
import TagsComponent from '@components/Tags/Tags';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';

const SquadTags = () => {
  const { name } = useParams();
  const { data: squad } = useGetSquad({ case: 'name', value: name || '' });

  return (
    <Box py={2}>
      <Typography mb={1} variant="subtitle1">
        Tags
      </Typography>
      <Divider />
      <TagsComponent tags={(squad?.squad?.members?.value as Tags)?.tags} />
    </Box>
  );
};

export default SquadTags;
