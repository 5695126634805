import bigInt from 'big-integer';
import { Timestamp } from '@bufbuild/protobuf';
import { DateSpanApi } from '@fullcalendar/core';
import {
  ListEvaluationScheduleAcceptedSlotsResponse,
  ListEvaluationScheduleSlotsResponse,
} from '@proto/marketplace/demand/v1/evaluation_pb';

export const convertDateToTimeStamp = (dateString: string) => {
  const date = new Date(dateString);
  const seconds = bigInt(Math.floor(date.getTime() / 1000));
  const nanos = (date.getTime() % 1000) * 1000000;
  // @ts-ignore
  return new Timestamp({ seconds: seconds.value, nanos });
};

export const convertDatesToTimestamps = (dateStrings: string[]) => {
  return dateStrings.map((dateString) => {
    return convertDateToTimeStamp(dateString);
  });
};

export const validRange = (nowDate: Date) => {
  const start = new Date(nowDate.valueOf());
  const end = new Date(nowDate.valueOf());
  end.setDate(end.getDate() + 7);

  return { start, end };
};

export const selectAllow = (selectInfo: DateSpanApi) => {
  const now = new Date();
  const start = new Date(now);
  const end = new Date(now);
  end.setDate(end.getDate() + 7);

  return selectInfo.start >= start && selectInfo.end <= end;
};

export const convertTimestampToDate = (timestamp: Timestamp) => {
  const date = timestamp.toDate();

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const timezoneOffset = -date.getTimezoneOffset();
  const offsetSign = timezoneOffset >= 0 ? '+' : '-';
  const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;

  return formattedDate;
};

export const getDefaulEvents = (
  evaluationScheduleSlots: ListEvaluationScheduleSlotsResponse,
  acceptScheduleSlots: ListEvaluationScheduleAcceptedSlotsResponse | undefined
) => {
  let acceptedSlots: any = [];

  if (acceptScheduleSlots) {
    acceptedSlots = Object.entries(acceptScheduleSlots?.acceptedSlots).map(
      ([evaluationId, schedule]) => {
        return {
          id: schedule.evaluationScheduleSlotId,
          start: schedule.scheduledAt ? convertTimestampToDate(schedule.scheduledAt) : '',
          title: 'You already have an interview for this time',
          extendedProps: {
            evaluationId,
          },
          color: '#c62828',
        };
      }
    );
  }

  const evaluationSlots = evaluationScheduleSlots?.schedules.map((schedule) => {
    return {
      id: schedule.evaluationScheduleSlotId,
      start: schedule.scheduledAt ? convertTimestampToDate(schedule.scheduledAt) : '',
      title: 'Convenient time for you',
      color: '#0d47a1',
    };
  });

  return [...evaluationSlots, ...acceptedSlots];
};

export const isUUID = (str: string) => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return regex.test(str);
};
