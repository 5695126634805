import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const MobileDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const getLinkStyle = (paths: string[]) => {
    return paths.some((path) => location.pathname === path)
      ? { color: '#0087ca' }
      : { color: 'white' };
  };

  return (
    <Box>
      <MenuIcon onClick={() => setIsOpen(!isOpen)} />
      <Drawer
        open={isOpen}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '100%',
            position: 'relative',
          },
        }}
      >
        <Toolbar />
        <Box display="flex" p={5}>
          <Stack display="flex" flexDirection="column" gap={2}>
            <NavLink
              className="NavLink HoverUnderlineAnimation"
              to={ROUTES.ORGS_LIST}
              style={() => getLinkStyle([ROUTES.ORGS_LIST, ROUTES.EXPLORE_ORGS])}
              onClick={() => setIsOpen(false)}
            >
              <Typography variant="subtitle2" sx={{ transition: 'all 0.3s linear' }}>
                Organizations
              </Typography>
            </NavLink>
            <NavLink
              className="NavLink HoverUnderlineAnimation"
              to={ROUTES.SQUADS_LIST}
              onClick={() => setIsOpen(false)}
              style={() => getLinkStyle([ROUTES.SQUADS_LIST, ROUTES.EXPLORE_SQUADS])}
            >
              <Typography variant="subtitle2" sx={{ transition: 'all 0.3s linear' }}>
                Squads
              </Typography>
            </NavLink>
            <NavLink
              className="NavLink HoverUnderlineAnimation"
              to={ROUTES.FEED_LIST}
              onClick={() => setIsOpen(false)}
              style={() => getLinkStyle([ROUTES.FEED_LIST])}
            >
              <Typography variant="subtitle2" sx={{ transition: 'all 0.3s linear' }}>
                Demands
              </Typography>
            </NavLink>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileDrawer;
