import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalTypes } from '@components/ModalManager/loadableModalComponentsMap';
import { Attributes } from 'react';
import { registerSlice } from '@store/index';

export type ModalState = {
  modalType: ModalTypes | null;
  modalProps: Attributes;
};

type ShowModalAction = PayloadAction<ModalState>;
type HideModalAction = PayloadAction<Record<'modalResult', never>>;

export type ModalCaseReducer = {
  showModal: (state: ModalState, action: ShowModalAction) => void;
  hideModal: (state: ModalState, action: HideModalAction) => void;
};

const initialState = {
  modalType: null,
  modalProps: {},
};

export const modalSlice = createSlice<ModalState, ModalCaseReducer>({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action) {
      const { modalProps = {}, modalType = '' } = action.payload;

      Object.assign(state, { modalProps, modalType });
    },
    hideModal(state) {
      Object.assign(state, initialState);
    },
  },
});

registerSlice(modalSlice);
