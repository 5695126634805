import { useProfiler } from '@store/profiler';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { changeHandler, onInputChange } from '@components/SearchProfiles/config';
import { useAppDispatch } from '@store/helpers';

const SearchProfile = () => {
  const { selectProfiles, foundProfiles, getProfile } = useProfiler();
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Autocomplete
        options={foundProfiles}
        onInputChange={(e, data) => onInputChange(data, selectProfiles, dispatch)}
        onChange={(e, data) => changeHandler(data, getProfile)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props}>
              <Avatar sx={{ width: 20, height: 20 }} variant="rounded" />
              <Typography pl={1}>{option.name}</Typography>
            </Box>
          );
        }}
        renderInput={(params) => <TextField {...params} label="Enter profile name" />}
      />
    </Box>
  );
};
export default SearchProfile;
