// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file grpc/type/v1/entity.proto (package grpc.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3 } from '@bufbuild/protobuf';
import { UUIDS } from './uuid_pb.js';

/**
 * @generated from enum grpc.type.v1.TypeOfEntity
 */
export enum TypeOfEntity {
  /**
   * @generated from enum value: TYPE_OF_ENTITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_USER = 1;
   */
  USER = 1,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_ORG = 2;
   */
  ORG = 2,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_SQUAD = 3;
   */
  SQUAD = 3,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_DYNAMIC_SQUAD = 4;
   */
  DYNAMIC_SQUAD = 4,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_DEMAND = 5;
   */
  DEMAND = 5,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_CONTRACT = 6;
   */
  CONTRACT = 6,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_INTERVIEW = 7;
   */
  INTERVIEW = 7,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_DASHBOARD = 8;
   */
  DASHBOARD = 8,

  /**
   * @generated from enum value: TYPE_OF_ENTITY_PRODUCT = 9;
   */
  PRODUCT = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(TypeOfEntity)
proto3.util.setEnumType(TypeOfEntity, 'grpc.type.v1.TypeOfEntity', [
  { no: 0, name: 'TYPE_OF_ENTITY_UNSPECIFIED' },
  { no: 1, name: 'TYPE_OF_ENTITY_USER' },
  { no: 2, name: 'TYPE_OF_ENTITY_ORG' },
  { no: 3, name: 'TYPE_OF_ENTITY_SQUAD' },
  { no: 4, name: 'TYPE_OF_ENTITY_DYNAMIC_SQUAD' },
  { no: 5, name: 'TYPE_OF_ENTITY_DEMAND' },
  { no: 6, name: 'TYPE_OF_ENTITY_CONTRACT' },
  { no: 7, name: 'TYPE_OF_ENTITY_INTERVIEW' },
  { no: 8, name: 'TYPE_OF_ENTITY_DASHBOARD' },
  { no: 9, name: 'TYPE_OF_ENTITY_PRODUCT' },
]);

/**
 * @generated from message grpc.type.v1.Entity
 */
export class Entity extends Message<Entity> {
  /**
   * @generated from field: string id = 1;
   */
  id = '';

  /**
   * @generated from field: grpc.type.v1.TypeOfEntity type = 2;
   */
  type = TypeOfEntity.UNSPECIFIED;

  constructor(data?: PartialMessage<Entity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.Entity';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'type', kind: 'enum', T: proto3.getEnumType(TypeOfEntity) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Entity {
    return new Entity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Entity {
    return new Entity().fromJsonString(jsonString, options);
  }

  static equals(
    a: Entity | PlainMessage<Entity> | undefined,
    b: Entity | PlainMessage<Entity> | undefined
  ): boolean {
    return proto3.util.equals(Entity, a, b);
  }
}

/**
 * @generated from message grpc.type.v1.Entities
 */
export class Entities extends Message<Entities> {
  /**
   * @generated from field: repeated grpc.type.v1.Entity entities = 1;
   */
  entities: Entity[] = [];

  constructor(data?: PartialMessage<Entities>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.Entities';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entities', kind: 'message', T: Entity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Entities {
    return new Entities().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Entities {
    return new Entities().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Entities {
    return new Entities().fromJsonString(jsonString, options);
  }

  static equals(
    a: Entities | PlainMessage<Entities> | undefined,
    b: Entities | PlainMessage<Entities> | undefined
  ): boolean {
    return proto3.util.equals(Entities, a, b);
  }
}

/**
 * @generated from message grpc.type.v1.EntityIds
 */
export class EntityIds extends Message<EntityIds> {
  /**
   * @generated from field: grpc.type.v1.UUIDS ids = 1;
   */
  ids?: UUIDS;

  /**
   * @generated from field: grpc.type.v1.TypeOfEntity type = 2;
   */
  type = TypeOfEntity.UNSPECIFIED;

  constructor(data?: PartialMessage<EntityIds>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'grpc.type.v1.EntityIds';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'ids', kind: 'message', T: UUIDS },
    { no: 2, name: 'type', kind: 'enum', T: proto3.getEnumType(TypeOfEntity) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityIds {
    return new EntityIds().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityIds {
    return new EntityIds().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityIds {
    return new EntityIds().fromJsonString(jsonString, options);
  }

  static equals(
    a: EntityIds | PlainMessage<EntityIds> | undefined,
    b: EntityIds | PlainMessage<EntityIds> | undefined
  ): boolean {
    return proto3.util.equals(EntityIds, a, b);
  }
}
