// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/price.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';

/**
 * @generated from message marketplace.product.v1.SetPriceResponse
 */
export class SetPriceResponse extends Message<SetPriceResponse> {
  constructor(data?: PartialMessage<SetPriceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SetPriceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetPriceResponse {
    return new SetPriceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetPriceResponse {
    return new SetPriceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetPriceResponse {
    return new SetPriceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetPriceResponse | PlainMessage<SetPriceResponse> | undefined,
    b: SetPriceResponse | PlainMessage<SetPriceResponse> | undefined
  ): boolean {
    return proto3.util.equals(SetPriceResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.Price
 */
export class Price extends Message<Price> {
  /**
   * @generated from field: double fix = 1;
   */
  fix = 0;

  /**
   * @generated from field: double customization = 2;
   */
  customization = 0;

  /**
   * @generated from field: double rate = 3;
   */
  rate = 0;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<Price>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.Price';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'fix', kind: 'scalar', T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: 'customization', kind: 'scalar', T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: 'rate', kind: 'scalar', T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: 'created_at', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Price {
    return new Price().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Price {
    return new Price().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Price {
    return new Price().fromJsonString(jsonString, options);
  }

  static equals(
    a: Price | PlainMessage<Price> | undefined,
    b: Price | PlainMessage<Price> | undefined
  ): boolean {
    return proto3.util.equals(Price, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.SetPriceRequest
 */
export class SetPriceRequest extends Message<SetPriceRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  /**
   * @generated from field: marketplace.product.v1.Price price = 2;
   */
  price?: Price;

  constructor(data?: PartialMessage<SetPriceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.SetPriceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'price', kind: 'message', T: Price },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetPriceRequest {
    return new SetPriceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetPriceRequest {
    return new SetPriceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetPriceRequest {
    return new SetPriceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SetPriceRequest | PlainMessage<SetPriceRequest> | undefined,
    b: SetPriceRequest | PlainMessage<SetPriceRequest> | undefined
  ): boolean {
    return proto3.util.equals(SetPriceRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetPriceRequest
 */
export class GetPriceRequest extends Message<GetPriceRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  constructor(data?: PartialMessage<GetPriceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetPriceRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPriceRequest {
    return new GetPriceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPriceRequest {
    return new GetPriceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPriceRequest {
    return new GetPriceRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetPriceRequest | PlainMessage<GetPriceRequest> | undefined,
    b: GetPriceRequest | PlainMessage<GetPriceRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetPriceRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.GetPriceResponse
 */
export class GetPriceResponse extends Message<GetPriceResponse> {
  /**
   * @generated from field: marketplace.product.v1.Price price = 1;
   */
  price?: Price;

  constructor(data?: PartialMessage<GetPriceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.GetPriceResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'price', kind: 'message', T: Price },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPriceResponse {
    return new GetPriceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPriceResponse {
    return new GetPriceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPriceResponse {
    return new GetPriceResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetPriceResponse | PlainMessage<GetPriceResponse> | undefined,
    b: GetPriceResponse | PlainMessage<GetPriceResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetPriceResponse, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListPriceHistoryRequest
 */
export class ListPriceHistoryRequest extends Message<ListPriceHistoryRequest> {
  /**
   * @generated from field: string product_id = 1;
   */
  productId = '';

  constructor(data?: PartialMessage<ListPriceHistoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListPriceHistoryRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPriceHistoryRequest {
    return new ListPriceHistoryRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPriceHistoryRequest {
    return new ListPriceHistoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPriceHistoryRequest {
    return new ListPriceHistoryRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListPriceHistoryRequest | PlainMessage<ListPriceHistoryRequest> | undefined,
    b: ListPriceHistoryRequest | PlainMessage<ListPriceHistoryRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListPriceHistoryRequest, a, b);
  }
}

/**
 * @generated from message marketplace.product.v1.ListPriceHistoryResponse
 */
export class ListPriceHistoryResponse extends Message<ListPriceHistoryResponse> {
  /**
   * @generated from field: repeated marketplace.product.v1.Price prices = 1;
   */
  prices: Price[] = [];

  constructor(data?: PartialMessage<ListPriceHistoryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.product.v1.ListPriceHistoryResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'prices', kind: 'message', T: Price, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListPriceHistoryResponse {
    return new ListPriceHistoryResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListPriceHistoryResponse {
    return new ListPriceHistoryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListPriceHistoryResponse {
    return new ListPriceHistoryResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListPriceHistoryResponse | PlainMessage<ListPriceHistoryResponse> | undefined,
    b: ListPriceHistoryResponse | PlainMessage<ListPriceHistoryResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListPriceHistoryResponse, a, b);
  }
}
