import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import SearchProfile from '@components/SearchProfiles/SearchProfile';
import React from 'react';
import UserCard from '@components/AddMemberDialog/UserCard';
import { useProfiler } from '@store/profiler';
import SendInvitesButton from '@components/Invites/SendInvites';
import Box from '@mui/material/Box';
import { useMarketplace } from '@store/marketplace';

type AddCandidateProps = {
  isOpen: boolean;
  handleClose: () => void;
  entityId?: string;
  objectName?: string;
};

export const AddCandidateDialog = (props: AddCandidateProps) => {
  const { isOpen, handleClose, entityId, objectName } = props;
  const { selectedProfile } = useProfiler();
  const { inviteCandidates } = useMarketplace();
  const sendInvite = () => {
    if (selectedProfile && entityId) {
      inviteCandidates({
        interviewId: entityId,
        profileIds: [selectedProfile.profileId],
      });
    }
  };

  const handleSubmit = () => {
    sendInvite();
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        sx={{ width: '600px', backgroundColor: '#3f3d50' }}
        p={5}
        gap={2}
      >
        <Typography textAlign="center" variant="subtitle1">
          Add Candidate To {objectName}
        </Typography>
        <SearchProfile />
        {selectedProfile && <UserCard userProfile={selectedProfile} />}
        {selectedProfile ? <SendInvitesButton handleSubmit={handleSubmit} /> : null}
      </Box>
    </Dialog>
  );
};
