import { createServiceSlice } from '@store/helpers';
import { userSlice } from '@store/profiler/slices';

import { AuthCaseReducers, AuthState } from './auth';

type ExtendedAuthCaseReducers = AuthCaseReducers & {
  [key: string]: (state: AuthState, action: any) => void;
};

export const authSlice = createServiceSlice<AuthState, ExtendedAuthCaseReducers>({
  name: 'auth',
  initialState: {
    device: null,
    verifyToken: null,
    externalAuthState: null,
    isInitialized: false,
    sessionId: '',
    otp: null,
  },
  reducers: {
    setExternalAuthState: (state, action) => {
      const { externalAuthState } = action.payload;

      state.externalAuthState = externalAuthState;
    },
    setSessionId: (state, action) => {
      const { sessionId } = action.payload;

      state.sessionId = sessionId;
    },
    setOtp: (state, action) => {
      const { response } = action.payload;

      state.otp = response;
    },
    setDevice: (state, action) => {
      const { device } = action.payload;

      state.device = device;
    },
    setVerifyToken: (state, action) => {
      const { verifyToken } = action.payload;

      state.verifyToken = verifyToken;
    },
  },
  // Need to handle the first state of logged user
  extraReducersCb: (builder) => {
    builder.addMatcher(
      (action) => action.type.match(userSlice.actions.setProfile.type),
      (state) => {
        state.isInitialized = true;
      }
    );
  },
});
