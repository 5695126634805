import { getClient } from '@services/api/helpers';
import { ProductService } from '@proto/marketplace/product/v1/product_connect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductRequest,
  DeleteProductResponse,
  GetProductRequest,
  ListProductsRequest,
  SearchProductsRequest,
  SetProductStatusRequest,
  SetProductStatusResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from '@proto/marketplace/product/v1/product_pb';
import { Tags } from '@proto/profiler/tags/v1/tags_pb';
import { getTagsFromPayload } from '@store/marketplace/helpers';
import { TCreateProductForm } from '@components/CreateProductForm/CreateProductDialog';

const productClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  ProductService
);

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  const createProduct = async (payload: TCreateProductForm): Promise<CreateProductResponse> => {
    return productClient.createProduct(
      new CreateProductRequest({
        owner: payload.owner,
        title: payload.title,
        description: payload.description,
        specification: payload.specification,
        version: payload.version,
        demoUrl: payload.demoUrl,
        tags: new Tags({
          tags: getTagsFromPayload(payload),
        }),
      })
    );
  };

  return useMutation({
    mutationFn: createProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  const updateProduct = async (payload: UpdateProductRequest): Promise<UpdateProductResponse> => {
    return productClient.updateProduct(payload);
  };

  return useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listDashboards']});
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  const deleteProduct = async (payload: DeleteProductRequest): Promise<DeleteProductResponse> => {
    return productClient.deleteProduct(payload);
  };

  return useMutation({
    mutationFn: deleteProduct,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listDashboards']});
    },
  });
};

export const useGetProduct = (productId: string) => {
  const getProduct = async () => {
    return productClient.getProduct(new GetProductRequest({ productId }));
  };

  return useQuery({
    queryKey: ['getProduct', productId],
    queryFn: getProduct,
  });
};

const useSetProductStatus = async (payload: SetProductStatusRequest) => {
  const setProductStatus = async (): Promise<SetProductStatusResponse> => {
    return productClient.setProductStatus(payload);
  };

  return useMutation({
    mutationFn: setProductStatus,
    onSuccess: () => {
      // queryClient.invalidateQueries({queryKey: ['listDashboards']});
    },
  });
};

const useSearchProducts = (payload: SearchProductsRequest) => {
  const searchProducts = async () => {
    return productClient.searchProducts(payload);
  };

  return useQuery({
    queryKey: ['searchProducts', payload],
    queryFn: searchProducts,
  });
};

export const useListProducts = (payload: ListProductsRequest) => {
  const listProducts = async () => {
    return productClient.listProducts(payload);
  };

  return useQuery({
    queryKey: ['listProducts', payload],
    queryFn: listProducts,
  });
};
