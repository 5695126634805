import { Page } from '@components/Page/Page';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useOrgs } from '@store/orgs';
import OrgTags from '@pages/Orgs/OrgTags';
import { useProfiler } from '@store/profiler';
import Container from '@mui/material/Container';
import EntityInviteCard from '@components/EntityInviteCard/EntityInviteCard';
import ManageOrgKanbanboards from '@pages/Orgs/ManageOrgs/ManageOrgKanbanboards';
import Divider from '@mui/material/Divider';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import OrgMembers from '@pages/Orgs/OrgMembers';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import UpdateOrgAboutForm from '@pages/Orgs/ManageOrgs/UpdateOrgInfoForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Products from '@components/Products/Products';
import { useGetOrg } from '@services/api/profiler/OrgsQueries';
import Avatars from '@components/Avatars/Avatars';

export const Org = () => {
  const { entityId } = useParams();
  const navigate = useNavigate();
  const { userProfile, listRoles, getListMembers } = useProfiler();
  const { getCurrentOrg, currentOrg } = useOrgs();
  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  const { data: org, isLoading, error } = useGetOrg({ case: 'orgId', value: entityId || '' });

  useEffect(() => {
    if (entityId) {
      getCurrentOrg([entityId]);
      getListMembers({ id: entityId, type: TypeOfEntity.ORG });
    }
  }, [getCurrentOrg, getListMembers, entityId]);

  return currentOrg?.orgId === entityId ? (
    <Page description="Orgs" title="Orgs">
      <EntityInviteCard entityId={currentOrg?.orgId} entity={currentOrg} />
      <Container>
        <ArrowBackIcon cursor="pointer" onClick={() => navigate(-1)} />
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
            <Box display="flex" flexDirection="column" gap={1} alignItems="center">
              {hasAccess && currentOrg && (
                <Avatars
                  link={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${currentOrg?.orgId}/${currentOrg?.avatar}`}
                  entityId={currentOrg?.orgId}
                />
              )}
            </Box>
            <Box width="100%">
              <Typography variant="subtitle1">{currentOrg?.name}</Typography>
              {hasAccess ? (
                currentOrg && <UpdateOrgAboutForm org={currentOrg} />
              ) : (
                <Typography flexGrow="grow" sx={{ color: '#bbb' }} variant="subtitle2">
                  {currentOrg?.about || 'No title'}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider />
          <ManageOrgKanbanboards />
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <OrgTags />
            <OrgMembers />
          </Box>
        </Box>
        {entityId && <Products id={entityId} type={TypeOfEntity.ORG} />}
      </Container>
    </Page>
  ) : null;
};
