import { useAuth } from '@store/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import { LoaderWithDelay } from '@components/LoaderWithDelay/LoaderWithDelay';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isInitialized } = useAuth();
  const location = useLocation();
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  return <div>{!isInitialized ? <LoaderWithDelay /> : children}</div>;
};
