// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/tags/v1/tags.proto (package profiler.tags.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AddTagsRequest,
  AddTagsResponse,
  GetTagRequest,
  GetTagResponse,
  ListObjectsTagsRequest,
  ListObjectsTagsResponse,
  ListTagsRequest,
  ListTagsResponse,
  RemoveTagsRequest,
  RemoveTagsResponse,
  SearchEntitiesRequest,
  SearchEntitiesResponse,
  SetObjectsTagsStatusRequest,
  SetObjectsTagsStatusResponse,
  SetObjectTagsStatusRequest,
  SetObjectTagsStatusResponse,
} from './tags_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.tags.v1.TagsService
 */
export const TagsService = {
  typeName: 'profiler.tags.v1.TagsService',
  methods: {
    /**
     * @generated from rpc profiler.tags.v1.TagsService.ListObjectsTags
     */
    listObjectsTags: {
      name: 'ListObjectsTags',
      I: ListObjectsTagsRequest,
      O: ListObjectsTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.AddTags
     */
    addTags: {
      name: 'AddTags',
      I: AddTagsRequest,
      O: AddTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.RemoveTags
     */
    removeTags: {
      name: 'RemoveTags',
      I: RemoveTagsRequest,
      O: RemoveTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.SetObjectTagsStatus
     */
    setObjectTagsStatus: {
      name: 'SetObjectTagsStatus',
      I: SetObjectTagsStatusRequest,
      O: SetObjectTagsStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.SetObjectsTagsStatus
     */
    setObjectsTagsStatus: {
      name: 'SetObjectsTagsStatus',
      I: SetObjectsTagsStatusRequest,
      O: SetObjectsTagsStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.ListTags
     */
    listTags: {
      name: 'ListTags',
      I: ListTagsRequest,
      O: ListTagsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.GetTag
     */
    getTag: {
      name: 'GetTag',
      I: GetTagRequest,
      O: GetTagResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.tags.v1.TagsService.SearchEntities
     */
    searchEntities: {
      name: 'SearchEntities',
      I: SearchEntitiesRequest,
      O: SearchEntitiesResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
