import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '@components/Form';
import CreateContractInputs from '@pages/Marketplace/Dashboards/CreateContractInputs';
import { createContractResolverSchema } from '@pages/Marketplace/ManageDemands/settings';
import { useCreateContract } from '@services/api/marketplace/calls';

type CreateContractFormProps = {
  handleCloseModal: () => void;
  dashboardId: string;
};

export type TCreateContractForm = {
  dashboardId: string;
  rate: number;
  capacity: number;
  experience: number;
  description: string;
  createInterview: boolean;
};

const CreateContractForm = (props: CreateContractFormProps) => {
  const { handleCloseModal, dashboardId } = props;
  const { mutate, isError } = useCreateContract();

  return (
    <Form<TCreateContractForm>
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{
        defaultValues: {
          dashboardId,
        },
      }}
      submitHandler={(data) => {
        mutate(data);
        handleCloseModal();
      }}
      resolverSchema={createContractResolverSchema}
    >
      <CreateContractInputs />
      <LoadingButton type="submit" variant="outlined">
        Create
      </LoadingButton>
    </Form>
  );
};

export default CreateContractForm;
