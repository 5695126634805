// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/evaluation.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  AcceptEvaluationScheduleSlotRequest,
  AcceptEvaluationScheduleSlotResponse,
  AddEvaluationScheduleSlotsRequest,
  AddEvaluationScheduleSlotsResponse,
  DeleteEvaluationScheduleSlotRequest,
  DeleteEvaluationScheduleSlotResponse,
  GetEvaluationFeedbackDraftRequest,
  GetEvaluationFeedbackDraftResponse,
  GetEvaluationRequest,
  GetEvaluationResponse,
  ListEvaluationScheduleAcceptedSlotsRequest,
  ListEvaluationScheduleAcceptedSlotsResponse,
  ListEvaluationScheduleSlotsRequest,
  ListEvaluationScheduleSlotsResponse,
  ListEvaluationsRequest,
  ListEvaluationsResponse,
  RescheduleEvaluationRequest,
  RescheduleEvaluationResponse,
  SaveEvaluationFeedbackDraftRequest,
  SaveEvaluationFeedbackDraftResponse,
  SendEvaluationFeedbackRequest,
  SendEvaluationFeedbackResponse,
} from './evaluation_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.demand.v1.EvaluationService
 */
export const EvaluationService = {
  typeName: 'marketplace.demand.v1.EvaluationService',
  methods: {
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.SaveEvaluationFeedbackDraft
     */
    saveEvaluationFeedbackDraft: {
      name: 'SaveEvaluationFeedbackDraft',
      I: SaveEvaluationFeedbackDraftRequest,
      O: SaveEvaluationFeedbackDraftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.GetEvaluationFeedbackDraft
     */
    getEvaluationFeedbackDraft: {
      name: 'GetEvaluationFeedbackDraft',
      I: GetEvaluationFeedbackDraftRequest,
      O: GetEvaluationFeedbackDraftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.SendEvaluationFeedback
     */
    sendEvaluationFeedback: {
      name: 'SendEvaluationFeedback',
      I: SendEvaluationFeedbackRequest,
      O: SendEvaluationFeedbackResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.GetEvaluation
     */
    getEvaluation: {
      name: 'GetEvaluation',
      I: GetEvaluationRequest,
      O: GetEvaluationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.ListEvaluations
     */
    listEvaluations: {
      name: 'ListEvaluations',
      I: ListEvaluationsRequest,
      O: ListEvaluationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.AddEvaluationScheduleSlots
     */
    addEvaluationScheduleSlots: {
      name: 'AddEvaluationScheduleSlots',
      I: AddEvaluationScheduleSlotsRequest,
      O: AddEvaluationScheduleSlotsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.ListEvaluationScheduleSlots
     */
    listEvaluationScheduleSlots: {
      name: 'ListEvaluationScheduleSlots',
      I: ListEvaluationScheduleSlotsRequest,
      O: ListEvaluationScheduleSlotsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.ListEvaluationScheduleAcceptedSlots
     */
    listEvaluationScheduleAcceptedSlots: {
      name: 'ListEvaluationScheduleAcceptedSlots',
      I: ListEvaluationScheduleAcceptedSlotsRequest,
      O: ListEvaluationScheduleAcceptedSlotsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.AcceptEvaluationScheduleSlot
     */
    acceptEvaluationScheduleSlot: {
      name: 'AcceptEvaluationScheduleSlot',
      I: AcceptEvaluationScheduleSlotRequest,
      O: AcceptEvaluationScheduleSlotResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.RescheduleEvaluation
     */
    rescheduleEvaluation: {
      name: 'RescheduleEvaluation',
      I: RescheduleEvaluationRequest,
      O: RescheduleEvaluationResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.EvaluationService.DeleteEvaluationScheduleSlot
     */
    deleteEvaluationScheduleSlot: {
      name: 'DeleteEvaluationScheduleSlot',
      I: DeleteEvaluationScheduleSlotRequest,
      O: DeleteEvaluationScheduleSlotResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
