import { useProfiler } from '@store/profiler';
import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip, Toolbar, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Page } from '@components/Page/Page';

const User = () => {
  const { profileId } = useParams();
  const { userProfiles } = useProfiler();
  const { getCurrentProfile } = useProfiler();

  const currentProfile = userProfiles[profileId || ''];

  useEffect(() => {
    if (!profileId || profileId === currentProfile?.profileId) return;
    getCurrentProfile(profileId);
  }, [getCurrentProfile, profileId, currentProfile]);

  return (
    <Page
      title={currentProfile?.name?.nickName || 'User page'}
      description={currentProfile?.name?.nickName || 'User page'}
    >
      <Container>
        <Box>
          <Box px={3} sx={{ width: '800px' }} display="flex" flexDirection="column" py={2}>
            <Toolbar />
            <Box display="flex" gap={2}>
              <Avatar
                sx={{ width: 200, height: 200, marginBottom: 2.5 }}
                variant="rounded"
                src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${currentProfile?.profileId}/${currentProfile?.avatar}`}
              />
              <Box display="flex" flexDirection="column" gap={4}>
                <Box>
                  <Typography color="secondary" textTransform="capitalize" variant="subtitle2">
                    {currentProfile?.name?.firstName} {currentProfile?.name?.lastName}
                  </Typography>
                  <Typography fontWeight="bold">@{currentProfile?.name?.nickName}</Typography>
                </Box>

                <Box>
                  {currentProfile?.traits?.experience && (
                    <Box display="flex" alignItems="center">
                      <Tooltip placement="right" title="years">
                        <Typography>Experience: {currentProfile.traits.experience}+</Typography>
                      </Tooltip>
                    </Box>
                  )}
                  {currentProfile?.traits?.capacity && (
                    <Box display="flex" alignItems="center">
                      <Tooltip placement="right" title="hours per week">
                        <Typography>Capacity: {currentProfile.traits.capacity} </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  {currentProfile?.traits?.rate && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography>Rate: ${currentProfile.traits.rate}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display="flex" gap={1} flexWrap="wrap">
              {currentProfile?.tags?.tags.map((tag) => {
                return <Chip variant="outlined" key={tag.value} label={tag.value} />;
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default User;
