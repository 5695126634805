// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/membership.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Entity, TypeOfEntity } from '../../../grpc/type/v1/entity_pb.js';
import { Profiles } from '../../user/v1/profile_pb.js';
import { UUIDS } from '../../../grpc/type/v1/uuid_pb.js';

/**
 * @generated from message profiler.biz.v1.Member
 */
export class Member extends Message<Member> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: google.protobuf.Timestamp joined = 2;
   */
  joined?: Timestamp;

  constructor(data?: PartialMessage<Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.Member';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'joined', kind: 'message', T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Member {
    return new Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJsonString(jsonString, options);
  }

  static equals(
    a: Member | PlainMessage<Member> | undefined,
    b: Member | PlainMessage<Member> | undefined
  ): boolean {
    return proto3.util.equals(Member, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListProfileMembershipMembersRequest
 */
export class ListProfileMembershipMembersRequest extends Message<ListProfileMembershipMembersRequest> {
  constructor(data?: PartialMessage<ListProfileMembershipMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListProfileMembershipMembersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileMembershipMembersRequest {
    return new ListProfileMembershipMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipMembersRequest {
    return new ListProfileMembershipMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipMembersRequest {
    return new ListProfileMembershipMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListProfileMembershipMembersRequest
      | PlainMessage<ListProfileMembershipMembersRequest>
      | undefined,
    b:
      | ListProfileMembershipMembersRequest
      | PlainMessage<ListProfileMembershipMembersRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(ListProfileMembershipMembersRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListProfileMembershipMembersResponse
 */
export class ListProfileMembershipMembersResponse extends Message<ListProfileMembershipMembersResponse> {
  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 1;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListProfileMembershipMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListProfileMembershipMembersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileMembershipMembersResponse {
    return new ListProfileMembershipMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipMembersResponse {
    return new ListProfileMembershipMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipMembersResponse {
    return new ListProfileMembershipMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListProfileMembershipMembersResponse
      | PlainMessage<ListProfileMembershipMembersResponse>
      | undefined,
    b:
      | ListProfileMembershipMembersResponse
      | PlainMessage<ListProfileMembershipMembersResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(ListProfileMembershipMembersResponse, a, b);
  }
}

/**
 * returns memberships of all types when entity_type is unspecified
 *
 * @generated from message profiler.biz.v1.ListProfileMembershipsRequest
 */
export class ListProfileMembershipsRequest extends Message<ListProfileMembershipsRequest> {
  /**
   * @generated from field: grpc.type.v1.TypeOfEntity entity_type = 1;
   */
  entityType = TypeOfEntity.UNSPECIFIED;

  constructor(data?: PartialMessage<ListProfileMembershipsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListProfileMembershipsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_type', kind: 'enum', T: proto3.getEnumType(TypeOfEntity) },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileMembershipsRequest {
    return new ListProfileMembershipsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipsRequest {
    return new ListProfileMembershipsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipsRequest {
    return new ListProfileMembershipsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfileMembershipsRequest | PlainMessage<ListProfileMembershipsRequest> | undefined,
    b: ListProfileMembershipsRequest | PlainMessage<ListProfileMembershipsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileMembershipsRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListProfileMembershipsResponse
 */
export class ListProfileMembershipsResponse extends Message<ListProfileMembershipsResponse> {
  /**
   * @generated from field: repeated profiler.biz.v1.Member memberships = 1;
   */
  memberships: Member[] = [];

  constructor(data?: PartialMessage<ListProfileMembershipsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListProfileMembershipsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'memberships', kind: 'message', T: Member, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListProfileMembershipsResponse {
    return new ListProfileMembershipsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipsResponse {
    return new ListProfileMembershipsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListProfileMembershipsResponse {
    return new ListProfileMembershipsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListProfileMembershipsResponse | PlainMessage<ListProfileMembershipsResponse> | undefined,
    b: ListProfileMembershipsResponse | PlainMessage<ListProfileMembershipsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListProfileMembershipsResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListMembersRequest
 */
export class ListMembersRequest extends Message<ListMembersRequest> {
  /**
   * @generated from field: grpc.type.v1.UUIDS entity_ids = 1;
   */
  entityIds?: UUIDS;

  constructor(data?: PartialMessage<ListMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListMembersRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_ids', kind: 'message', T: UUIDS },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersRequest {
    return new ListMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersRequest {
    return new ListMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined,
    b: ListMembersRequest | PlainMessage<ListMembersRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersRequest, a, b);
  }
}

/**
 * map[entity_id]{entity, profiles}
 *
 * @generated from message profiler.biz.v1.ListMembersResponse
 */
export class ListMembersResponse extends Message<ListMembersResponse> {
  /**
   * @generated from field: map<string, profiler.biz.v1.ListMembersResponse.Membersip> members = 1;
   */
  members: { [key: string]: ListMembersResponse_Membersip } = {};

  constructor(data?: PartialMessage<ListMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListMembersResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'members',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: ListMembersResponse_Membersip },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListMembersResponse {
    return new ListMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse {
    return new ListMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined,
    b: ListMembersResponse | PlainMessage<ListMembersResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.ListMembersResponse.Membersip
 */
export class ListMembersResponse_Membersip extends Message<ListMembersResponse_Membersip> {
  /**
   * @generated from field: grpc.type.v1.Entity entity = 1;
   */
  entity?: Entity;

  /**
   * @generated from field: profiler.user.v1.Profiles profiles = 2;
   */
  profiles?: Profiles;

  constructor(data?: PartialMessage<ListMembersResponse_Membersip>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.ListMembersResponse.Membersip';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity', kind: 'message', T: Entity },
    { no: 2, name: 'profiles', kind: 'message', T: Profiles },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListMembersResponse_Membersip {
    return new ListMembersResponse_Membersip().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membersip {
    return new ListMembersResponse_Membersip().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListMembersResponse_Membersip {
    return new ListMembersResponse_Membersip().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListMembersResponse_Membersip | PlainMessage<ListMembersResponse_Membersip> | undefined,
    b: ListMembersResponse_Membersip | PlainMessage<ListMembersResponse_Membersip> | undefined
  ): boolean {
    return proto3.util.equals(ListMembersResponse_Membersip, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.RemoveMemberRequest
 */
export class RemoveMemberRequest extends Message<RemoveMemberRequest> {
  /**
   * @generated from field: string entity_id = 1;
   */
  entityId = '';

  /**
   * @generated from field: string profile_id = 2;
   */
  profileId = '';

  constructor(data?: PartialMessage<RemoveMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.RemoveMemberRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'profile_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberRequest {
    return new RemoveMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined,
    b: RemoveMemberRequest | PlainMessage<RemoveMemberRequest> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.RemoveMemberResponse
 */
export class RemoveMemberResponse extends Message<RemoveMemberResponse> {
  constructor(data?: PartialMessage<RemoveMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.RemoveMemberResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RemoveMemberResponse {
    return new RemoveMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined,
    b: RemoveMemberResponse | PlainMessage<RemoveMemberResponse> | undefined
  ): boolean {
    return proto3.util.equals(RemoveMemberResponse, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.LeaveMembershipRequest
 */
export class LeaveMembershipRequest extends Message<LeaveMembershipRequest> {
  /**
   * @generated from field: string entity_id = 1;
   */
  entityId = '';

  constructor(data?: PartialMessage<LeaveMembershipRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.LeaveMembershipRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'entity_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipRequest {
    return new LeaveMembershipRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveMembershipRequest | PlainMessage<LeaveMembershipRequest> | undefined,
    b: LeaveMembershipRequest | PlainMessage<LeaveMembershipRequest> | undefined
  ): boolean {
    return proto3.util.equals(LeaveMembershipRequest, a, b);
  }
}

/**
 * @generated from message profiler.biz.v1.LeaveMembershipResponse
 */
export class LeaveMembershipResponse extends Message<LeaveMembershipResponse> {
  constructor(data?: PartialMessage<LeaveMembershipResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'profiler.biz.v1.LeaveMembershipResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): LeaveMembershipResponse {
    return new LeaveMembershipResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: LeaveMembershipResponse | PlainMessage<LeaveMembershipResponse> | undefined,
    b: LeaveMembershipResponse | PlainMessage<LeaveMembershipResponse> | undefined
  ): boolean {
    return proto3.util.equals(LeaveMembershipResponse, a, b);
  }
}
