import { useProfiler } from '@store/profiler';
import { Form } from '@components/Form';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputController } from '@components/Form/controllers/InputController';
import { CheckboxController } from '@components/Form/controllers/CheckboxController/CheckboxController';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FIELD_LABELS, FieldNames, getDefaultValues, myProfileTraitsFormSchema } from './config';

type ProfileTraitsFormProps = {
  isOnboarding?: () => void;
};

export const ProfileTraitsForm = (props: ProfileTraitsFormProps) => {
  const { isOnboarding } = props;
  const { userProfile, updateTraits } = useProfiler();

  const handleTraits = (traits: object) => {
    if (isOnboarding) {
      isOnboarding();
    }
    updateTraits(traits);
  };

  return (
    <Form
      width="100%"
      display="flex"
      alignSelf="center"
      flexDirection="column"
      formProps={{ defaultValues: getDefaultValues(userProfile?.traits) }}
      resolverSchema={myProfileTraitsFormSchema}
      submitHandler={handleTraits}
    >
      <Grid container spacing={3} mb={2} className="fifth-step">
        <Grid item xs={6}>
          <InputController
            fullWidth
            type="number"
            name={`traits.${FieldNames.RATE}`}
            label={FIELD_LABELS[FieldNames.RATE]}
          />
        </Grid>
        <Grid item xs={6}>
          <InputController
            fullWidth
            type="number"
            name={`traits.${FieldNames.CAPACITY}`}
            label={FIELD_LABELS[FieldNames.CAPACITY]}
          />
        </Grid>
        <Grid item xs={6}>
          <InputController
            fullWidth
            type="number"
            name={`traits.${FieldNames.EXPIRIENCE}`}
            label={FIELD_LABELS[FieldNames.EXPIRIENCE]}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxController
            labelProps={{ sx: { margin: 0 } }}
            name={`traits.${FieldNames.INTERVIEWER}`}
            label={FIELD_LABELS[FieldNames.INTERVIEWER]}
          />
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="column" gap={1}>
        <LoadingButton type="submit" variant="contained">
          {isOnboarding ? 'Update traits' : 'Update traits'}
        </LoadingButton>
        {isOnboarding && (
          <Button onClick={isOnboarding} variant="contained">
            Skip
          </Button>
        )}
      </Box>
    </Form>
  );
};
