import Box from '@mui/material/Box';
import { useProfiler } from '@store/profiler';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import SidebarProfileNameForm from '@pages/Main/SidebarProfileSettings/SidebarProfileNameForm';
import SidebarProfileTraitsForm from '@pages/Main/SidebarProfileSettings/SidebarProfileTraitsForm';
import SidebarTags from '@pages/Main/SidebarProfileSettings/SidebarTags';
import UserInvitesList from '@components/Invites/UserInvitesList';
import Divider from '@mui/material/Divider';
import ListMemberships from '@pages/Main/MainPage/ListMemberships';
import Products from '@components/Products/Products';
import ListUserAchievements from '@pages/Main/MainPage/ListUserAchievements';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { useGetMyProfile } from '@services/api/profiler/ProfilesQueries';

const SidebarProfileSettings = () => {
  const { userProfile } = useProfiler();
  const { data: myProfile, error } = useGetMyProfile();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" flexDirection="column" gap={1}>
        <UserInvitesList />
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" gap={2}>
            <Avatar
              sx={{
                width: 50,
                height: 50,
                color: 'white',
              }}
              alt={userProfile?.name?.nickName.toUpperCase() || 'User'}
              src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${myProfile?.profile?.profileId}/${myProfile?.profile?.avatar}`}
            />
            <SidebarProfileNameForm />
          </Box>
          <Divider />
          <SidebarProfileTraitsForm />
          <Divider />
          <SidebarTags />
          <Divider />
          <ListMemberships />
          <Divider />
          {userProfile?.profileId && (
            <Products id={userProfile?.profileId} type={TypeOfEntity.USER} />
          )}
          <Divider />
          <ListUserAchievements />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarProfileSettings;
