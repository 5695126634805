import { useProfiler } from '@store/profiler';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const profileActiveStatusSwitchLabel = 'Active profile';

export const ProfileActiveStatusSwitch = () => {
  const { userProfile, changeProfileStatus } = useProfiler();

  const handleChange = () => {
    changeProfileStatus({ active: !userProfile?.active });
  };

  return (
    <FormControlLabel
      label={profileActiveStatusSwitchLabel}
      control={<Switch onChange={handleChange} checked={userProfile?.active} />}
    />
  );
};
