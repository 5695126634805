import { CallBackProps, Step } from 'react-joyride';
import React from 'react';
import { ToolTips } from '@proto/profiler/user/v1/tool_tips_pb';

type TSetState = (patch: Partial<State> | ((prevState: State) => Partial<State>)) => void;

export interface State {
  run: boolean;
  steps: Step[];
}

export const findToolTips = (tooltipsList: string[], tooltip: string) => {
  if (!tooltipsList) return false;
  return tooltipsList.includes(tooltip);
};

export const endTooltips = (
  data: CallBackProps,
  finishTooltips: (payload: ToolTips) => void,
  payload: ToolTips
) => {
  if (data.status === 'finished' && data.lifecycle === 'complete') finishTooltips(payload);
};

export const handleRunStatus = (
  event: React.MouseEvent<HTMLElement>,
  runStatus: boolean,
  setState: TSetState
) => {
  event.preventDefault();

  setState({
    run: !runStatus,
  });
};

export const handleClickStart = (event: React.MouseEvent<HTMLElement>, setState: TSetState) => {
  event.preventDefault();

  setState({
    run: true,
  });
};

export const handleClickFinish = (event: React.MouseEvent<HTMLElement>, setState: TSetState) => {
  event.preventDefault();

  setState({
    run: false,
  });
};
