import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { useMarketplace } from '@store/marketplace';
import Typography from '@mui/material/Typography';
import { TOrg } from '@services/api/profiler/biz';
import { useEffect } from 'react';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import { useProfiler } from '@store/profiler';
import { useMembershipsAndSquads } from '@services/api/profiler/MembershipsQueries';
import { Squad, Squads } from '@proto/profiler/biz/v1/squad_pb';
import { Orgs } from '@proto/profiler/biz/v1/org_pb';

type ResultType = { entity: string; name: string };

const mergeLists = (squads: Squad[] = [], orgs: TOrg[] = []): ResultType[] => [
  ...squads.map(({ squadId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'squadId' }),
    name,
  })),
  ...orgs.map(({ orgId: id, name }) => ({
    entity: JSON.stringify({ id, case: 'orgId' }),
    name,
  })),
];

const PreAppliedEntitySelect = () => {
  const { currentContractorId, setCurrentContractorId } = useMarketplace();
  const { data: membershipsData } = useMembershipsAndSquads();

  const mergedList = mergeLists(
    (membershipsData?.squads as Squads)?.squads,
    (membershipsData?.orgs as Orgs)?.orgs
  );
  const { getListMembers } = useProfiler();

  const handleChange = (event: SelectChangeEvent) => {
    const entity = JSON.parse(event.target.value as string);
    setCurrentContractorId({
      id: entity.id,
      case: entity.case,
    });
  };

  useEffect(() => {
    if (currentContractorId?.case === 'orgId') {
      getListMembers({ id: currentContractorId.id, type: TypeOfEntity.ORG });
    }
  }, [getListMembers, currentContractorId]);

  return (
    <div>
      <Typography variant="subtitle2" mb={1}>
        Select entity:
      </Typography>
      <Select value={JSON.stringify(currentContractorId)} onChange={handleChange} fullWidth>
        {mergedList?.map((entity, index) => (
          <MenuItem value={entity.entity} key={entity.entity}>
            {entity.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PreAppliedEntitySelect;
