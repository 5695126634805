import { Demand } from '@proto/marketplace/demand/v1/demand_pb';

export function sortItemsByDate(items: Demand[]) {
  return items?.sort((a, b) => {
    const dateA = new Date(a?.entity?.value?.createdAt?.toDate() || '');
    const dateB = new Date(b?.entity?.value?.createdAt?.toDate() || '');

    return dateB.getTime() - dateA.getTime();
  });
}
