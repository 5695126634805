import { useProfiler } from '@store/profiler';
import Box from '@mui/material/Box';
import React, { useEffect } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TypeOfEntity } from '@proto/grpc/type/v1/entity_pb';
import Avatar from '@mui/material/Avatar';
import { InviteAction } from '@proto/profiler/invite/v1/invite_pb';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasCommonRoles, IAMRoleStrings } from '@components/WithRoleBasedDisplayProps';
import Card from '@components/Card/Card';

const ManageOrgInvitesList = () => {
  const { entityId } = useParams();
  const { invitesLists, getEntityInvitesList, closeGroupingInvite } = useProfiler();
  const { listRoles, userProfile } = useProfiler();
  const requiredRoles = [IAMRoleStrings.IAM_ROLE_ADMIN, IAMRoleStrings.IAM_ROLE_PEOPLE_MANAGER];
  const hasAccess = hasCommonRoles(
    requiredRoles,
    listRoles[entityId as keyof typeof listRoles]?.find((el) => el.id === userProfile?.profileId)
      ?.roles.roles
  );

  useEffect(() => {
    if (entityId) getEntityInvitesList({ id: entityId, type: TypeOfEntity.ORG });
  }, [getEntityInvitesList, entityId]);

  return hasAccess && invitesLists[entityId as keyof typeof invitesLists] ? (
    <Box display="flex" flexDirection="column" gap={2} mb={2}>
      <Typography variant="subtitle2">Need confirmation</Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        {invitesLists[entityId as keyof typeof invitesLists]?.map((inviteItem) => {
          return (
            <Card key={inviteItem.inviteId}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex">
                  <Avatar
                    sx={{ width: 50, height: 50, marginRight: '10px' }}
                    src="/"
                    alt={inviteItem?.name?.nickName.toUpperCase()}
                  />
                  {inviteItem?.name?.nickName}
                </Box>
                <Tooltip
                  placement="left"
                  onClick={async () =>
                    closeGroupingInvite({
                      inviteId: inviteItem.inviteId,
                      action: InviteAction.CANCEL,
                    })
                  }
                  title="Cancel invite"
                >
                  <IconButton>
                    <DeleteIcon color="error" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          );
        })}
      </Box>
    </Box>
  ) : null;
};
export default ManageOrgInvitesList;
