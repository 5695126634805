import Dialog from '@mui/material/Dialog';
import {
  CreateGroupingForm,
  TCreateGroupingFormPayload,
} from '@components/CreateGroupingForm/CreateGroupingForm';
import Stack from '@mui/material/Stack';
import { InputController } from '@components/Form/controllers/InputController';
import { FieldNames } from '@components/CreateGroupingForm/config';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import { useTags } from 'store/tags';
import Box from '@mui/material/Box';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import React from 'react';
import Divider from '@mui/material/Divider';

type DialogLabels = {
  title: string;
  name: string;
  about: string;
  button: string;
  description: string;
  footer: string;
};

type CreateGroupingDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleCreate: any;
  labels: DialogLabels;
  resolverSchema?: any;
  org?: boolean;
};

const CreateGroupingDialog = (props: CreateGroupingDialogProps) => {
  const { isOpen, handleClose, handleCreate, labels, org, resolverSchema } = props;
  const { getCountries, getIndustryDomains, getLanguages, countries, industryDomains, languages } =
    useTags();

  const onSubmit = (data: TCreateGroupingFormPayload) => {
    handleCreate(data);
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        sx={{ width: { xs: '300px', sm: '400px', md: '600px' }, backgroundColor: '#3f3d50' }}
        display="flex"
        flexDirection="column"
        gap={1}
        py={5}
        px={{ xs: 3 }}
      >
        <Box display="flex" gap={2}>
          <Diversity3Icon />
          <Typography variant="subtitle1">{labels.title}</Typography>
        </Box>
        <CreateGroupingForm
          onSubmit={(data) => {
            onSubmit(data);
            handleClose();
          }}
          resolverSchema={resolverSchema}
        >
          <Stack direction="column" gap={2}>
            <Typography>{labels.description}</Typography>
            <InputController name={FieldNames.NAME} label={labels.name} />
            <InputController multiline rows={5} name={FieldNames.ABOUT} label={labels.about} />
            {org && (
              <Stack spacing={2}>
                <AutocompleteController
                  getItems={getCountries}
                  multiple
                  name={FieldNames.COUNTRIES}
                  label="Specify the countries where your organization operates"
                  options={countries}
                />
                <AutocompleteController
                  getItems={getLanguages}
                  multiple
                  name={FieldNames.LANGUAGES}
                  label="List the languages spoken within your organization"
                  options={languages}
                />
                <AutocompleteController
                  getItems={getIndustryDomains}
                  multiple
                  name={FieldNames.INDUSTRY_DOMAIN}
                  label="Select the industry domains relevant to your organization's work"
                  options={industryDomains}
                />
              </Stack>
            )}
            <Typography>{labels.footer}</Typography>
            <Divider />
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton type="submit" color="success" variant="outlined">
                {labels.button}
              </LoadingButton>
            </Box>
          </Stack>
        </CreateGroupingForm>
      </Box>
    </Dialog>
  );
};

export default CreateGroupingDialog;
