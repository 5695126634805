// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file profiler/biz/v1/membership.proto (package profiler.biz.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  LeaveMembershipRequest,
  LeaveMembershipResponse,
  ListMembersRequest,
  ListMembersResponse,
  ListProfileMembershipMembersRequest,
  ListProfileMembershipMembersResponse,
  ListProfileMembershipsRequest,
  ListProfileMembershipsResponse,
  RemoveMemberRequest,
  RemoveMemberResponse,
} from './membership_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service profiler.biz.v1.MembershipService
 */
export const MembershipService = {
  typeName: 'profiler.biz.v1.MembershipService',
  methods: {
    /**
     * @generated from rpc profiler.biz.v1.MembershipService.ListProfileMembershipMembers
     */
    listProfileMembershipMembers: {
      name: 'ListProfileMembershipMembers',
      I: ListProfileMembershipMembersRequest,
      O: ListProfileMembershipMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.MembershipService.ListProfileMemberships
     */
    listProfileMemberships: {
      name: 'ListProfileMemberships',
      I: ListProfileMembershipsRequest,
      O: ListProfileMembershipsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.MembershipService.ListMembers
     */
    listMembers: {
      name: 'ListMembers',
      I: ListMembersRequest,
      O: ListMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.MembershipService.RemoveMember
     */
    removeMember: {
      name: 'RemoveMember',
      I: RemoveMemberRequest,
      O: RemoveMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc profiler.biz.v1.MembershipService.LeaveMembership
     */
    leaveMembership: {
      name: 'LeaveMembership',
      I: LeaveMembershipRequest,
      O: LeaveMembershipResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
