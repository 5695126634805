import { getClient, makeRequest, PlainMsg } from '@services/api/helpers';
import { DashboardService } from '@proto/marketplace/dashboard/v1/dashboad_connect';
import {
  CreateDashboardRequest,
  CreateDashboardResponse,
  GetDashboardRequest,
  GetDashboardResponse,
  KanbanBoard,
  ListDashboardsRequest,
  ListDashboardsResponse,
  ListFeedItemsRequest,
  ListFeedItemsResponse,
  UpdateDashboardRequest,
  UpdateDashboardResponse,
} from '@proto/marketplace/dashboard/v1/dashboad_pb';

export type TKanbanBoard = PlainMsg<KanbanBoard>;

export type CreateDashboardServiceRequest = PlainMsg<CreateDashboardRequest>;
export type CreateDashboardServiceResponse = PlainMsg<CreateDashboardResponse>;
export type GetDashboardServiceRequest = PlainMsg<GetDashboardRequest>;
export type GetDashboardServiceResponse = PlainMsg<GetDashboardResponse> & { kanban: TKanbanBoard };
export type ListDashboardsServiceResponse = PlainMsg<ListDashboardsResponse>;
export type UpdateDashboardServiceRequest = PlainMsg<UpdateDashboardRequest>;
export type UpdateDashboardServiceResponse = PlainMsg<UpdateDashboardResponse>;
export type ListFeedItemsServiceRequest = PlainMsg<ListFeedItemsRequest>;
export type ListFeedItemsServiceResponse = PlainMsg<ListFeedItemsResponse>;

export const dashboardClient = getClient(
  `${process.env.REACT_APP_HTTP_SCHEMA}://${process.env.REACT_APP_MARKETPLACE_SERVICE}.${process.env.REACT_APP_API_DOMAIN}`,
  DashboardService
);

export const dashboardService = {
  createDashboard(request: CreateDashboardServiceRequest): Promise<CreateDashboardServiceResponse> {
    return makeRequest<CreateDashboardRequest, CreateDashboardResponse>(
      dashboardClient.createDashboard,
      request
    );
  },
  getDashboard(request: GetDashboardServiceRequest): Promise<GetDashboardServiceResponse> {
    return makeRequest<GetDashboardRequest, GetDashboardResponse>(
      dashboardClient.getDashboard,
      request
    );
  },
  listDashboards(): Promise<ListDashboardsServiceResponse> {
    return makeRequest<ListDashboardsRequest, ListDashboardsResponse>(
      dashboardClient.listDashboards,
      new ListDashboardsRequest()
    );
  },
  updateDashboard(request: UpdateDashboardServiceRequest): Promise<UpdateDashboardServiceResponse> {
    return makeRequest<UpdateDashboardRequest, UpdateDashboardResponse>(
      dashboardClient.updateDashboard,
      request
    );
  },
  listFeedItems(request: ListFeedItemsServiceRequest): Promise<ListFeedItemsServiceResponse> {
    return makeRequest<ListFeedItemsRequest, ListFeedItemsResponse>(
      dashboardClient.listFeedItems,
      request
    );
  },
};
