import { createServiceSlice } from '@store/helpers';
import {
  TKanbanBoards,
  TMarketplaceCaseReducers,
  TMarketplaceState,
  TRecommendations,
} from '@store/marketplace/marketplace';
import { TCustomDemands } from '@store/marketplace/helpers';

export const marketplaceSlice = createServiceSlice<TMarketplaceState, TMarketplaceCaseReducers>({
  name: 'marketplace',
  initialState: {
    myDashboardsList: {} as TKanbanBoards,
    dashboardsMap: {},
    feedItems: {} as TCustomDemands,
    appliedDemands: undefined,
    feedItemsByContract: {} as TCustomDemands,
    feedRecommendations: {} as TRecommendations,
    displayListType: 'all',
    preAppliesContracts: [],
    chosenContract: undefined,
    feedOwner: null,
    foundDemands: {},
    candidatesList: {},
    listEvaluations: {},
    listInterviewEvaluations: {},
    listEvaluationsByManager: {},
    currentContractorId: undefined,
    contractors: undefined,
  },

  reducers: {
    setDashboards: (state, action) => {
      state.myDashboardsList = action.payload;
    },
    setDashboardsMap: (state, action) => {
      Object.assign(state.dashboardsMap, action.payload);
    },
    setFeedItems: (state, action) => {
      state.feedItems = action.payload;
    },
    setDisplayListType: (state, action) => {
      state.displayListType = action.payload;
    },
    setFeedRecommendations(state, action) {
      state.feedRecommendations = action.payload;
    },
    addPreAppliesContracts(state, action) {
      if (!action.payload.length) {
        state.preAppliesContracts = [];
        return;
      }
      state.preAppliesContracts = state.preAppliesContracts.concat([action.payload]);
    },
    deletePreAppliesContracts(state, action) {
      state.preAppliesContracts = state.preAppliesContracts.filter(
        (item: string) => item !== action.payload
      );
    },
    setChosenContract(state, action) {
      state.chosenContract = action.payload;
    },
    setAppliedDemands(state, action) {
      state.appliedDemands = action.payload;
    },
    setFeedItemsByContract(state, action) {
      state.feedItemsByContract = action.payload;
    },
    setFeedOwner(state, action) {
      state.feedOwner = action.payload;
    },
    setFoundDemand(state, action) {
      state.foundDemands = Object.assign(state.foundDemands, action.payload);
    },
    setCandidatesList(state, action) {
      state.candidatesList = Object.assign(state.candidatesList, action.payload);
    },
    setListEvaluations(state, action) {
      state.listEvaluations = Object.assign(state.listEvaluations, action.payload);
    },
    setListInterviewEvaluations(state, action) {
      state.listInterviewEvaluations = action.payload;
    },
    setListEvaluationsByManager(state, action) {
      state.listEvaluationsByManager = Object.assign(
        state.listEvaluationsByManager,
        action.payload
      );
    },
    setCurrentContractorId(state, action) {
      state.currentContractorId = action.payload;
    },
    setContractors(state, action) {
      state.contractors = action.payload;
    },
  },
});
