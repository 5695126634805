import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMarketplace } from '@store/marketplace';
import DemandTraits from '@pages/Marketplace/Dashboards/DemandTraits';
import Card from '@components/Card/Card';
import { Demand } from '@proto/marketplace/demand/v1/demand_pb';
import { getDemandId } from '@pages/Marketplace/Feed/config';
import Tags from '@components/Tags/Tags';
import ContractorSelect from '@pages/Marketplace/Feed/ContractorSelect';
import HTMLReactParser from 'html-react-parser';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import Button from '@mui/material/Button';
import { useListFeedItemsByDemand } from '@services/api/marketplace/calls';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import { demandsTypes } from '@pages/Marketplace/Feed/FeedPage';
import Avatar from '@mui/material/Avatar';

const PreAppliedDemandCard = ({
  demand,
  isPreApplied,
  showActiveState,
}: {
  demand: Demand;
  isPreApplied?: boolean;
  showActiveState?: boolean;
}) => {
  const { demandIdFromUrl, applyAsInterviewer } = useMarketplace();
  const demandId = getDemandId(demand);
  const { data: feedByContract } = useListFeedItemsByDemand(
    demandIdFromUrl
      ? new Entity({
          id: demandIdFromUrl.demandId,
          type: demandsTypes[demandIdFromUrl.demandType as keyof typeof demandsTypes],
        })
      : undefined
  );
  const [matchedTags, setMatchedTags] = React.useState<any>(null);

  const recommendations =
    feedByContract?.recommendations?.recommendations[demandId as string]?.recommendations;

  const suppliers =
    recommendations &&
    Object.values(recommendations).map((supplier) => {
      return (
        <Box>
          {supplier?.suppliers?.map((sup) => {
            return (
              <Box
                onClick={() =>
                  setMatchedTags({
                    tags: sup.matchedTags?.tags,
                    traits: sup?.traits,
                  })
                }
                display="flex"
                gap={1}
                sx={{ cursor: 'pointer' }}
              >
                <Avatar
                  sx={{ cursor: 'pointer', color: 'white', width: '35px', height: '35px' }}
                  alt={sup?.profile?.name?.nickName.toUpperCase() || 'User'}
                  src={`${process.env.REACT_APP_AVATARS_ENDPOINT}/${sup?.profile?.profileId}/${sup?.profile?.avatar}`}
                />
                <Typography>{sup?.profile?.name?.nickName || '?rec supplier name'}</Typography>
              </Box>
            );
          })}
        </Box>
      );
    });

  return (
    <Box
      // sx={{cursor: 'pointer'}}
      className={demandIdFromUrl === demandId && showActiveState ? 'matchedCard' : ''}
    >
      <Card>
        <Box display="flex" gap={1} flexDirection="column" justifyContent="center" mb={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="14px" fontWeight="bold">
              {demand?.entity?.case?.toUpperCase()}
            </Typography>
            <Typography fontSize="14px">
              {format(new Date(demand.entity.value?.createdAt?.toDate() || ''), 'dd/MM/yyyy')}
            </Typography>
          </Box>
          <Divider />
          {suppliers && (
            <Box display="flex" gap={1} flexDirection="column">
              <Typography fontWeight="bold">This user has eligible to this demand</Typography>
              {suppliers}
              <Divider />
            </Box>
          )}

          <Box display="flex" gap={1} justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap={1}>
              <DemandTraits
                matches={matchedTags?.traits}
                fontSize="13px"
                gap={2}
                demand={demand.entity.value}
              />
              {demand.entity.value?.tags?.tags && (
                <Tags
                  matchedTags={matchedTags?.tags}
                  showType
                  tags={demand.entity.value?.tags?.tags}
                />
              )}
            </Box>
          </Box>
          {demand?.entity?.case === 'contract' &&
            demand?.entity?.value.description &&
            HTMLReactParser(
              stateToHTML(convertFromRaw(JSON.parse(demand?.entity.value.description)))
            )}
          {demand?.entity?.case === 'interview' &&
            demand?.entity.value.requirements &&
            HTMLReactParser(
              stateToHTML(convertFromRaw(JSON.parse(demand?.entity.value.requirements)))
            )}
        </Box>
        {demand?.entity?.case === 'interview' && (
          <Button
            fullWidth
            variant="contained"
            onClick={() => applyAsInterviewer(demand?.entity?.value?.interviewId || '')}
          >
            Apply
          </Button>
        )}
        {isPreApplied && demand?.entity?.case === 'contract' && (
          <ContractorSelect contractId={demandId} />
        )}
      </Card>
    </Box>
  );
};

export default PreAppliedDemandCard;
