import { useDialog } from '@components/hooks/useDialog';
import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import CreateProductDialog from '@components/CreateProductForm/CreateProductDialog';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import CategoryIcon from '@mui/icons-material/Category';
import { MenuItem } from '@mui/material';

type CreateProductButtonProps = {
  owner: Entity;
};

const CreateProductButton = (props: CreateProductButtonProps) => {
  const { owner } = props;
  const { isOpen, handleOpen, handleClose } = useDialog();

  return (
    <Box display="flex" alignItems="center">
      <MenuItem>
        <Box onClick={handleOpen} display="flex" gap={1} sx={{ cursor: 'pointer' }}>
          <CategoryIcon />
          <Typography fontSize="13px">New product</Typography>
        </Box>
      </MenuItem>
      <CreateProductDialog owner={owner} isOpen={isOpen} handleClose={handleClose} />
    </Box>
  );
};

export default CreateProductButton;
