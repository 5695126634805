// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/contractor.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  ApplyOnContractsRequest,
  ApplyOnContractsResponse,
  ApproveContractorRequest,
  ApproveContractorResponse,
  RejectContractorRequest,
  RejectContractorResponse,
  RevokeContractorApplyRequest,
  RevokeContractorApplyResponse,
} from './contractor_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.demand.v1.ContractorService
 */
export const ContractorService = {
  typeName: 'marketplace.demand.v1.ContractorService',
  methods: {
    /**
     * @generated from rpc marketplace.demand.v1.ContractorService.ApplyOnContracts
     */
    applyOnContracts: {
      name: 'ApplyOnContracts',
      I: ApplyOnContractsRequest,
      O: ApplyOnContractsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.ContractorService.RevokeContractorApply
     */
    revokeContractorApply: {
      name: 'RevokeContractorApply',
      I: RevokeContractorApplyRequest,
      O: RevokeContractorApplyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.ContractorService.ApproveContractor
     */
    approveContractor: {
      name: 'ApproveContractor',
      I: ApproveContractorRequest,
      O: ApproveContractorResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.demand.v1.ContractorService.RejectContractor
     */
    rejectContractor: {
      name: 'RejectContractor',
      I: RejectContractorRequest,
      O: RejectContractorResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
