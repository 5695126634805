import { JoinSection } from '@pages/Home/JoinSection/JoinSection';
import { Header } from '@components/Header/Header';
import Box from '@mui/material/Box';

export const Login = () => {
  return (
    <Box py={{ xs: 15, md: 0 }}>
      <Header />
      <JoinSection />
    </Box>
  );
};
