import { useMarketplace } from '@store/marketplace';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import { useState } from 'react';
import { MatchedContracts } from '@proto/marketplace/demand/v1/contractor_pb';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import { useProfiler } from '@store/profiler';
import { useMembershipsAndSquads } from '@services/api/profiler/MembershipsQueries';
import { Squads } from '@proto/profiler/biz/v1/squad_pb';
import { Profiles } from '@proto/profiler/user/v1/profile_pb';

const ContractorSelect = ({ contractId }: { contractId?: string }) => {
  const { currentContractorId, setContractors, contractors } = useMarketplace();
  const { data: membershipsData } = useMembershipsAndSquads();

  const currentSquad = (membershipsData?.squads as Squads)?.squads?.find(
    (squad) => squad.squadId === currentContractorId?.id
  );
  const [userContractorrId, setUserContractorId] = useState<string | undefined>(undefined);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const { listMembers } = useProfiler();
  const handleToggle = () => {
    setShowFirstButton(!showFirstButton);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setContractors([
      new MatchedContracts({
        profileId: event.target.value as string,
        contractId,
      }),
      ...(contractors || []),
    ]);
    setUserContractorId(event.target.value as string);
  };
  return currentContractorId ? (
    <Box>
      <Box>
        {showFirstButton ? (
          <Button fullWidth onClick={handleToggle} variant="contained">
            <Typography variant="subtitle2">Choose contractor for this demand</Typography>
          </Button>
        ) : (
          <Box display="flex" gap={1} alignItems="center">
            <Select value={userContractorrId} onChange={handleChange} fullWidth>
              {listMembers[
                currentContractorId?.id as keyof typeof listMembers
              ]?.profiles?.profiles?.map((profile) => {
                return (
                  <MenuItem value={profile.profileId} key={profile.profileId}>
                    {profile?.name?.nickName}
                  </MenuItem>
                );
              })}
              {(currentSquad?.members?.value as Profiles)?.profiles?.map((profile) => {
                return (
                  <MenuItem value={profile.profileId} key={profile.profileId}>
                    {profile?.name?.nickName}
                  </MenuItem>
                );
              })}
            </Select>
            <HighlightOffIcon onClick={handleToggle} />
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export default ContractorSelect;
