export function generateEnumDescriptions<T>(enumObject: T): { [K in keyof T]?: string } {
  return Object.keys(enumObject)
    .filter((key) => typeof enumObject[key as keyof T] === 'number')
    .reduce((acc, key) => {
      const value = enumObject[key as keyof T] as unknown as keyof T;
      acc[value] = key
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (match) => match.toUpperCase())
        .replace(/\w+/g, (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());
      return acc;
    }, {} as { [K in keyof T]?: string });
}
