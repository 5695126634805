import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import React from 'react';
import Stack from '@mui/material/Stack';
import { NavLink, useLocation } from 'react-router-dom';
import { ROUTES } from '@common/routes';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const Sidebar = ({ isOpen, toggleDrawer }: { isOpen: boolean; toggleDrawer: any }) => {
  const location = useLocation();
  const getLinkStyle = (paths: string[]) => {
    return paths.some((path) => location.pathname === path)
      ? { color: '#0087ca' }
      : { color: 'white' };
  };

  return (
    <Drawer open={isOpen} onClose={toggleDrawer(false)}>
      <Toolbar />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gap={2}
        height="100vh"
        overflow="auto"
        minWidth="240px"
      >
        <Stack display="flex" flexDirection="column" gap={2}>
          <NavLink
            className="NavLink HoverUnderlineAnimation"
            to={ROUTES.ORGS_LIST}
            style={() => getLinkStyle([ROUTES.ORGS_LIST, ROUTES.EXPLORE_ORGS])}
            onClick={toggleDrawer(false)}
          >
            <Typography sx={{ transition: 'all 0.3s linear' }}>Organizations</Typography>
          </NavLink>
          <NavLink
            className="NavLink HoverUnderlineAnimation"
            to={ROUTES.SQUADS_LIST}
            style={() => getLinkStyle([ROUTES.SQUADS_LIST, ROUTES.EXPLORE_SQUADS])}
            onClick={toggleDrawer(false)}
          >
            <Typography sx={{ transition: 'all 0.3s linear' }}>Squads</Typography>
          </NavLink>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
