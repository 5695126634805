import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import React from 'react';
import { Form } from '@components/Form';
import { InputController } from '@components/Form/controllers/InputController';
import { FieldNames, getDefaultValues } from '@components/CreateProductForm/config';
import { Entity } from '@proto/grpc/type/v1/entity_pb';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCreateProduct } from '@services/api/marketplace/products';
import AutocompleteController from '@components/Form/controllers/AutocompleteController/AutocompleteController';
import { useTags } from '@store/tags';
import WysiwygController from '@components/Form/controllers/WysiwygController/WysiwygController';

type CreateProductFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  owner: Entity;
};

export type TCreateProductForm = {
  title: string;
  description: string;
  owner: Entity;
  specification: string;
  version: string;
  demoUrl: string;
};

const CreateProductDialog = (props: CreateProductFormProps) => {
  const { isOpen, handleClose, owner } = props;
  const { getProgrammingLanguages, getIndustryDomains, industryDomains, programmingLanguages } =
    useTags();
  const { mutate, isError, error } = useCreateProduct();

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <Box
        sx={{ width: { xs: '300px', sm: '400px', md: '600px' }, backgroundColor: '#3f3d50' }}
        display="flex"
        flexDirection="column"
        gap={1}
        py={5}
        px={{ xs: 3 }}
      >
        <Box display="flex" gap={2}>
          <Form
            width="100%"
            display="flex"
            alignSelf="center"
            flexDirection="column"
            formProps={{ defaultValues: getDefaultValues(owner) }}
            submitHandler={(data) => {
              handleClose();
              mutate(data);
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="subtitle1">Create product</Typography>
              <InputController
                name={FieldNames.TITLE}
                label="Enter a compelling title for your product to attract potential clients"
              />
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography fontWeight="bold">
                  Write a comprehensive description to give an overview of what your product offers
                </Typography>
                <WysiwygController name={FieldNames.DESCRIPTION} />
              </Box>
              <InputController
                name={FieldNames.SPECIFICATION}
                label="Provide detailed specifications to highlight the features and capabilities of your product"
              />
              <InputController
                name={FieldNames.VERSION}
                label="Indicate the current version of your product"
              />
              <InputController
                name={FieldNames.URL}
                label="Share a URL where potential clients can see a demonstration of your product"
              />

              <AutocompleteController
                getItems={getProgrammingLanguages}
                multiple
                name={`${FieldNames.PROGRAMMING_LANGUAGES}`}
                label="Specify the programming language you used"
                options={programmingLanguages}
              />
              <AutocompleteController
                getItems={getIndustryDomains}
                multiple
                name={`${FieldNames.INDUSTRY_DOMAIN}`}
                label="Select the industry area related to the product"
                options={industryDomains}
              />
              <Divider />
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton type="submit" color="success" variant="outlined">
                  Create
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateProductDialog;
