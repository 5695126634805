// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file marketplace/product/v1/product.proto (package marketplace.product.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CreateProductRequest,
  CreateProductResponse,
  DeleteProductRequest,
  DeleteProductResponse,
  GetProductRequest,
  GetProductResponse,
  ListProductsRequest,
  ListProductsResponse,
  SearchProductsRequest,
  SearchProductsResponse,
  SetProductStatusRequest,
  SetProductStatusResponse,
  UpdateProductRequest,
  UpdateProductResponse,
} from './product_pb.js';
import { MethodKind } from '@bufbuild/protobuf';

/**
 * @generated from service marketplace.product.v1.ProductService
 */
export const ProductService = {
  typeName: 'marketplace.product.v1.ProductService',
  methods: {
    /**
     * @generated from rpc marketplace.product.v1.ProductService.GetProduct
     */
    getProduct: {
      name: 'GetProduct',
      I: GetProductRequest,
      O: GetProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.DeleteProduct
     */
    deleteProduct: {
      name: 'DeleteProduct',
      I: DeleteProductRequest,
      O: DeleteProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.ListProducts
     */
    listProducts: {
      name: 'ListProducts',
      I: ListProductsRequest,
      O: ListProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.CreateProduct
     */
    createProduct: {
      name: 'CreateProduct',
      I: CreateProductRequest,
      O: CreateProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.UpdateProduct
     */
    updateProduct: {
      name: 'UpdateProduct',
      I: UpdateProductRequest,
      O: UpdateProductResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.SetProductStatus
     */
    setProductStatus: {
      name: 'SetProductStatus',
      I: SetProductStatusRequest,
      O: SetProductStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc marketplace.product.v1.ProductService.SearchProducts
     */
    searchProducts: {
      name: 'SearchProducts',
      I: SearchProductsRequest,
      O: SearchProductsResponse,
      kind: MethodKind.Unary,
    },
  },
} as const;
