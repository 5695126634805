// @generated by protoc-gen-es v1.9.0 with parameter "target=ts"
// @generated from file marketplace/demand/v1/evaluation.proto (package marketplace.demand.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf';
import { Message, proto3, Timestamp } from '@bufbuild/protobuf';
import { Profile } from '../../../profiler/user/v1/profile_pb.js';

/**
 * TODO: implement the schedule
 *
 * @generated from message marketplace.demand.v1.EvaluationSchedule
 */
export class EvaluationSchedule extends Message<EvaluationSchedule> {
  /**
   * @generated from field: string evaluation_schedule_slot_id = 1;
   */
  evaluationScheduleSlotId = '';

  /**
   * @generated from field: google.protobuf.Timestamp scheduled_at = 2;
   */
  scheduledAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp accepted_at = 3;
   */
  acceptedAt?: Timestamp;

  /**
   * @generated from field: string url = 4;
   */
  url = '';

  constructor(data?: PartialMessage<EvaluationSchedule>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.EvaluationSchedule';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_schedule_slot_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'scheduled_at', kind: 'message', T: Timestamp },
    { no: 3, name: 'accepted_at', kind: 'message', T: Timestamp, opt: true },
    { no: 4, name: 'url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EvaluationSchedule {
    return new EvaluationSchedule().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EvaluationSchedule {
    return new EvaluationSchedule().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): EvaluationSchedule {
    return new EvaluationSchedule().fromJsonString(jsonString, options);
  }

  static equals(
    a: EvaluationSchedule | PlainMessage<EvaluationSchedule> | undefined,
    b: EvaluationSchedule | PlainMessage<EvaluationSchedule> | undefined
  ): boolean {
    return proto3.util.equals(EvaluationSchedule, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SendEvaluationFeedbackRequest
 */
export class SendEvaluationFeedbackRequest extends Message<SendEvaluationFeedbackRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  constructor(data?: PartialMessage<SendEvaluationFeedbackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SendEvaluationFeedbackRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackRequest {
    return new SendEvaluationFeedbackRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendEvaluationFeedbackRequest | PlainMessage<SendEvaluationFeedbackRequest> | undefined,
    b: SendEvaluationFeedbackRequest | PlainMessage<SendEvaluationFeedbackRequest> | undefined
  ): boolean {
    return proto3.util.equals(SendEvaluationFeedbackRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SendEvaluationFeedbackResponse
 */
export class SendEvaluationFeedbackResponse extends Message<SendEvaluationFeedbackResponse> {
  constructor(data?: PartialMessage<SendEvaluationFeedbackResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SendEvaluationFeedbackResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SendEvaluationFeedbackResponse {
    return new SendEvaluationFeedbackResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: SendEvaluationFeedbackResponse | PlainMessage<SendEvaluationFeedbackResponse> | undefined,
    b: SendEvaluationFeedbackResponse | PlainMessage<SendEvaluationFeedbackResponse> | undefined
  ): boolean {
    return proto3.util.equals(SendEvaluationFeedbackResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationFeedbackDraftRequest
 */
export class GetEvaluationFeedbackDraftRequest extends Message<GetEvaluationFeedbackDraftRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  constructor(data?: PartialMessage<GetEvaluationFeedbackDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationFeedbackDraftRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftRequest {
    return new GetEvaluationFeedbackDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetEvaluationFeedbackDraftRequest
      | PlainMessage<GetEvaluationFeedbackDraftRequest>
      | undefined,
    b:
      | GetEvaluationFeedbackDraftRequest
      | PlainMessage<GetEvaluationFeedbackDraftRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationFeedbackDraftRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationFeedbackDraftResponse
 */
export class GetEvaluationFeedbackDraftResponse extends Message<GetEvaluationFeedbackDraftResponse> {
  /**
   * @generated from field: string feedback = 1;
   */
  feedback = '';

  constructor(data?: PartialMessage<GetEvaluationFeedbackDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationFeedbackDraftResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feedback', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationFeedbackDraftResponse {
    return new GetEvaluationFeedbackDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | GetEvaluationFeedbackDraftResponse
      | PlainMessage<GetEvaluationFeedbackDraftResponse>
      | undefined,
    b:
      | GetEvaluationFeedbackDraftResponse
      | PlainMessage<GetEvaluationFeedbackDraftResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationFeedbackDraftResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SaveEvaluationFeedbackDraftRequest
 */
export class SaveEvaluationFeedbackDraftRequest extends Message<SaveEvaluationFeedbackDraftRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  /**
   * @generated from field: string feedback = 2;
   */
  feedback = '';

  constructor(data?: PartialMessage<SaveEvaluationFeedbackDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SaveEvaluationFeedbackDraftRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'feedback', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftRequest {
    return new SaveEvaluationFeedbackDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | SaveEvaluationFeedbackDraftRequest
      | PlainMessage<SaveEvaluationFeedbackDraftRequest>
      | undefined,
    b:
      | SaveEvaluationFeedbackDraftRequest
      | PlainMessage<SaveEvaluationFeedbackDraftRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(SaveEvaluationFeedbackDraftRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.SaveEvaluationFeedbackDraftResponse
 */
export class SaveEvaluationFeedbackDraftResponse extends Message<SaveEvaluationFeedbackDraftResponse> {
  constructor(data?: PartialMessage<SaveEvaluationFeedbackDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.SaveEvaluationFeedbackDraftResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SaveEvaluationFeedbackDraftResponse {
    return new SaveEvaluationFeedbackDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | SaveEvaluationFeedbackDraftResponse
      | PlainMessage<SaveEvaluationFeedbackDraftResponse>
      | undefined,
    b:
      | SaveEvaluationFeedbackDraftResponse
      | PlainMessage<SaveEvaluationFeedbackDraftResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(SaveEvaluationFeedbackDraftResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation
 */
export class Evaluation extends Message<Evaluation> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  /**
   * @generated from field: string interview_id = 2;
   */
  interviewId = '';

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;

  /**
   * @generated from oneof marketplace.demand.v1.Evaluation.current_user
   */
  currentUser:
    | {
        /**
         * @generated from field: bool candidate = 4;
         */
        value: boolean;
        case: 'candidate';
      }
    | {
        /**
         * @generated from field: bool interviewer = 5;
         */
        value: boolean;
        case: 'interviewer';
      }
    | {
        /**
         * @generated from field: bool manager = 6;
         */
        value: boolean;
        case: 'manager';
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: optional string feedback = 7;
   */
  feedback?: string;

  /**
   * @generated from field: marketplace.demand.v1.EvaluationSchedule schedule = 8;
   */
  schedule?: EvaluationSchedule;

  /**
   * @generated from field: optional string requirements = 9;
   */
  requirements?: string;

  /**
   * @generated from field: optional marketplace.demand.v1.Evaluation.Participants participants = 10;
   */
  participants?: Evaluation_Participants;

  constructor(data?: PartialMessage<Evaluation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'interview_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 4, name: 'candidate', kind: 'scalar', T: 8 /* ScalarType.BOOL */, oneof: 'current_user' },
    {
      no: 5,
      name: 'interviewer',
      kind: 'scalar',
      T: 8 /* ScalarType.BOOL */,
      oneof: 'current_user',
    },
    { no: 6, name: 'manager', kind: 'scalar', T: 8 /* ScalarType.BOOL */, oneof: 'current_user' },
    { no: 7, name: 'feedback', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: 'schedule', kind: 'message', T: EvaluationSchedule },
    { no: 9, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: 'participants', kind: 'message', T: Evaluation_Participants, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluation {
    return new Evaluation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluation {
    return new Evaluation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Evaluation {
    return new Evaluation().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation | PlainMessage<Evaluation> | undefined,
    b: Evaluation | PlainMessage<Evaluation> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluation.Participants
 */
export class Evaluation_Participants extends Message<Evaluation_Participants> {
  /**
   * @generated from field: optional profiler.user.v1.Profile candidate = 1;
   */
  candidate?: Profile;

  /**
   * @generated from field: optional profiler.user.v1.Profile interviewer = 2;
   */
  interviewer?: Profile;

  constructor(data?: PartialMessage<Evaluation_Participants>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluation.Participants';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'candidate', kind: 'message', T: Profile, opt: true },
    { no: 2, name: 'interviewer', kind: 'message', T: Profile, opt: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): Evaluation_Participants {
    return new Evaluation_Participants().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): Evaluation_Participants {
    return new Evaluation_Participants().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): Evaluation_Participants {
    return new Evaluation_Participants().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluation_Participants | PlainMessage<Evaluation_Participants> | undefined,
    b: Evaluation_Participants | PlainMessage<Evaluation_Participants> | undefined
  ): boolean {
    return proto3.util.equals(Evaluation_Participants, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.Evaluations
 */
export class Evaluations extends Message<Evaluations> {
  /**
   * @generated from field: repeated marketplace.demand.v1.Evaluation evaluations = 1;
   */
  evaluations: Evaluation[] = [];

  constructor(data?: PartialMessage<Evaluations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.Evaluations';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluations', kind: 'message', T: Evaluation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Evaluations {
    return new Evaluations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Evaluations {
    return new Evaluations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Evaluations {
    return new Evaluations().fromJsonString(jsonString, options);
  }

  static equals(
    a: Evaluations | PlainMessage<Evaluations> | undefined,
    b: Evaluations | PlainMessage<Evaluations> | undefined
  ): boolean {
    return proto3.util.equals(Evaluations, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationRequest
 */
export class GetEvaluationRequest extends Message<GetEvaluationRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  /**
   * @generated from field: optional string requirements = 2;
   */
  requirements?: string;

  constructor(data?: PartialMessage<GetEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'requirements', kind: 'scalar', T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetEvaluationRequest {
    return new GetEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEvaluationRequest {
    return new GetEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationRequest {
    return new GetEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetEvaluationRequest | PlainMessage<GetEvaluationRequest> | undefined,
    b: GetEvaluationRequest | PlainMessage<GetEvaluationRequest> | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.GetEvaluationResponse
 */
export class GetEvaluationResponse extends Message<GetEvaluationResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluation evaluation = 1;
   */
  evaluation?: Evaluation;

  constructor(data?: PartialMessage<GetEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.GetEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation', kind: 'message', T: Evaluation },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GetEvaluationResponse {
    return new GetEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetEvaluationResponse {
    return new GetEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GetEvaluationResponse {
    return new GetEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: GetEvaluationResponse | PlainMessage<GetEvaluationResponse> | undefined,
    b: GetEvaluationResponse | PlainMessage<GetEvaluationResponse> | undefined
  ): boolean {
    return proto3.util.equals(GetEvaluationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationsRequest
 */
export class ListEvaluationsRequest extends Message<ListEvaluationsRequest> {
  constructor(data?: PartialMessage<ListEvaluationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsRequest {
    return new ListEvaluationsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEvaluationsRequest | PlainMessage<ListEvaluationsRequest> | undefined,
    b: ListEvaluationsRequest | PlainMessage<ListEvaluationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationsResponse
 */
export class ListEvaluationsResponse extends Message<ListEvaluationsResponse> {
  /**
   * @generated from field: marketplace.demand.v1.Evaluations evaluations = 1;
   */
  evaluations?: Evaluations;

  constructor(data?: PartialMessage<ListEvaluationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluations', kind: 'message', T: Evaluations },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationsResponse {
    return new ListEvaluationsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined,
    b: ListEvaluationsResponse | PlainMessage<ListEvaluationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddEvaluationScheduleSlotsRequest
 */
export class AddEvaluationScheduleSlotsRequest extends Message<AddEvaluationScheduleSlotsRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  /**
   * @generated from field: repeated google.protobuf.Timestamp slots = 2;
   */
  slots: Timestamp[] = [];

  constructor(data?: PartialMessage<AddEvaluationScheduleSlotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddEvaluationScheduleSlotsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'slots', kind: 'message', T: Timestamp, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsRequest {
    return new AddEvaluationScheduleSlotsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AddEvaluationScheduleSlotsRequest
      | PlainMessage<AddEvaluationScheduleSlotsRequest>
      | undefined,
    b:
      | AddEvaluationScheduleSlotsRequest
      | PlainMessage<AddEvaluationScheduleSlotsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(AddEvaluationScheduleSlotsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AddEvaluationScheduleSlotsResponse
 */
export class AddEvaluationScheduleSlotsResponse extends Message<AddEvaluationScheduleSlotsResponse> {
  constructor(data?: PartialMessage<AddEvaluationScheduleSlotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AddEvaluationScheduleSlotsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AddEvaluationScheduleSlotsResponse {
    return new AddEvaluationScheduleSlotsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AddEvaluationScheduleSlotsResponse
      | PlainMessage<AddEvaluationScheduleSlotsResponse>
      | undefined,
    b:
      | AddEvaluationScheduleSlotsResponse
      | PlainMessage<AddEvaluationScheduleSlotsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(AddEvaluationScheduleSlotsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationScheduleSlotsRequest
 */
export class ListEvaluationScheduleSlotsRequest extends Message<ListEvaluationScheduleSlotsRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  constructor(data?: PartialMessage<ListEvaluationScheduleSlotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationScheduleSlotsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationScheduleSlotsRequest {
    return new ListEvaluationScheduleSlotsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleSlotsRequest {
    return new ListEvaluationScheduleSlotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleSlotsRequest {
    return new ListEvaluationScheduleSlotsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListEvaluationScheduleSlotsRequest
      | PlainMessage<ListEvaluationScheduleSlotsRequest>
      | undefined,
    b:
      | ListEvaluationScheduleSlotsRequest
      | PlainMessage<ListEvaluationScheduleSlotsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationScheduleSlotsRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationScheduleSlotsResponse
 */
export class ListEvaluationScheduleSlotsResponse extends Message<ListEvaluationScheduleSlotsResponse> {
  /**
   * @generated from field: repeated marketplace.demand.v1.EvaluationSchedule schedules = 1;
   */
  schedules: EvaluationSchedule[] = [];

  constructor(data?: PartialMessage<ListEvaluationScheduleSlotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationScheduleSlotsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'schedules', kind: 'message', T: EvaluationSchedule, repeated: true },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationScheduleSlotsResponse {
    return new ListEvaluationScheduleSlotsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleSlotsResponse {
    return new ListEvaluationScheduleSlotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleSlotsResponse {
    return new ListEvaluationScheduleSlotsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListEvaluationScheduleSlotsResponse
      | PlainMessage<ListEvaluationScheduleSlotsResponse>
      | undefined,
    b:
      | ListEvaluationScheduleSlotsResponse
      | PlainMessage<ListEvaluationScheduleSlotsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationScheduleSlotsResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AcceptEvaluationScheduleSlotRequest
 */
export class AcceptEvaluationScheduleSlotRequest extends Message<AcceptEvaluationScheduleSlotRequest> {
  /**
   * @generated from field: string evaluation_schedule_slot_id = 1;
   */
  evaluationScheduleSlotId = '';

  constructor(data?: PartialMessage<AcceptEvaluationScheduleSlotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AcceptEvaluationScheduleSlotRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_schedule_slot_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEvaluationScheduleSlotRequest {
    return new AcceptEvaluationScheduleSlotRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationScheduleSlotRequest {
    return new AcceptEvaluationScheduleSlotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationScheduleSlotRequest {
    return new AcceptEvaluationScheduleSlotRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AcceptEvaluationScheduleSlotRequest
      | PlainMessage<AcceptEvaluationScheduleSlotRequest>
      | undefined,
    b:
      | AcceptEvaluationScheduleSlotRequest
      | PlainMessage<AcceptEvaluationScheduleSlotRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(AcceptEvaluationScheduleSlotRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.AcceptEvaluationScheduleSlotResponse
 */
export class AcceptEvaluationScheduleSlotResponse extends Message<AcceptEvaluationScheduleSlotResponse> {
  constructor(data?: PartialMessage<AcceptEvaluationScheduleSlotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.AcceptEvaluationScheduleSlotResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): AcceptEvaluationScheduleSlotResponse {
    return new AcceptEvaluationScheduleSlotResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationScheduleSlotResponse {
    return new AcceptEvaluationScheduleSlotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): AcceptEvaluationScheduleSlotResponse {
    return new AcceptEvaluationScheduleSlotResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | AcceptEvaluationScheduleSlotResponse
      | PlainMessage<AcceptEvaluationScheduleSlotResponse>
      | undefined,
    b:
      | AcceptEvaluationScheduleSlotResponse
      | PlainMessage<AcceptEvaluationScheduleSlotResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(AcceptEvaluationScheduleSlotResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RescheduleEvaluationRequest
 */
export class RescheduleEvaluationRequest extends Message<RescheduleEvaluationRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  constructor(data?: PartialMessage<RescheduleEvaluationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RescheduleEvaluationRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationRequest {
    return new RescheduleEvaluationRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a: RescheduleEvaluationRequest | PlainMessage<RescheduleEvaluationRequest> | undefined,
    b: RescheduleEvaluationRequest | PlainMessage<RescheduleEvaluationRequest> | undefined
  ): boolean {
    return proto3.util.equals(RescheduleEvaluationRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.RescheduleEvaluationResponse
 */
export class RescheduleEvaluationResponse extends Message<RescheduleEvaluationResponse> {
  constructor(data?: PartialMessage<RescheduleEvaluationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.RescheduleEvaluationResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RescheduleEvaluationResponse {
    return new RescheduleEvaluationResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a: RescheduleEvaluationResponse | PlainMessage<RescheduleEvaluationResponse> | undefined,
    b: RescheduleEvaluationResponse | PlainMessage<RescheduleEvaluationResponse> | undefined
  ): boolean {
    return proto3.util.equals(RescheduleEvaluationResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteEvaluationScheduleSlotRequest
 */
export class DeleteEvaluationScheduleSlotRequest extends Message<DeleteEvaluationScheduleSlotRequest> {
  /**
   * @generated from field: string evaluation_id = 1;
   */
  evaluationId = '';

  /**
   * @generated from field: string evaluation_schedule_slot_id = 2;
   */
  evaluationScheduleSlotId = '';

  constructor(data?: PartialMessage<DeleteEvaluationScheduleSlotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteEvaluationScheduleSlotRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'evaluation_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'evaluation_schedule_slot_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotRequest {
    return new DeleteEvaluationScheduleSlotRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteEvaluationScheduleSlotRequest
      | PlainMessage<DeleteEvaluationScheduleSlotRequest>
      | undefined,
    b:
      | DeleteEvaluationScheduleSlotRequest
      | PlainMessage<DeleteEvaluationScheduleSlotRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(DeleteEvaluationScheduleSlotRequest, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.DeleteEvaluationScheduleSlotResponse
 */
export class DeleteEvaluationScheduleSlotResponse extends Message<DeleteEvaluationScheduleSlotResponse> {
  constructor(data?: PartialMessage<DeleteEvaluationScheduleSlotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.DeleteEvaluationScheduleSlotResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DeleteEvaluationScheduleSlotResponse {
    return new DeleteEvaluationScheduleSlotResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | DeleteEvaluationScheduleSlotResponse
      | PlainMessage<DeleteEvaluationScheduleSlotResponse>
      | undefined,
    b:
      | DeleteEvaluationScheduleSlotResponse
      | PlainMessage<DeleteEvaluationScheduleSlotResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(DeleteEvaluationScheduleSlotResponse, a, b);
  }
}

/**
 * @generated from message marketplace.demand.v1.ListEvaluationScheduleAcceptedSlotsRequest
 */
export class ListEvaluationScheduleAcceptedSlotsRequest extends Message<ListEvaluationScheduleAcceptedSlotsRequest> {
  constructor(data?: PartialMessage<ListEvaluationScheduleAcceptedSlotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationScheduleAcceptedSlotsRequest';
  static readonly fields: FieldList = proto3.util.newFieldList(() => []);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsRequest {
    return new ListEvaluationScheduleAcceptedSlotsRequest().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsRequest {
    return new ListEvaluationScheduleAcceptedSlotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsRequest {
    return new ListEvaluationScheduleAcceptedSlotsRequest().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListEvaluationScheduleAcceptedSlotsRequest
      | PlainMessage<ListEvaluationScheduleAcceptedSlotsRequest>
      | undefined,
    b:
      | ListEvaluationScheduleAcceptedSlotsRequest
      | PlainMessage<ListEvaluationScheduleAcceptedSlotsRequest>
      | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationScheduleAcceptedSlotsRequest, a, b);
  }
}

/**
 * map of evaluation_id to EvaluationSchedule
 *
 * @generated from message marketplace.demand.v1.ListEvaluationScheduleAcceptedSlotsResponse
 */
export class ListEvaluationScheduleAcceptedSlotsResponse extends Message<ListEvaluationScheduleAcceptedSlotsResponse> {
  /**
   * @generated from field: map<string, marketplace.demand.v1.EvaluationSchedule> accepted_slots = 1;
   */
  acceptedSlots: { [key: string]: EvaluationSchedule } = {};

  constructor(data?: PartialMessage<ListEvaluationScheduleAcceptedSlotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = 'marketplace.demand.v1.ListEvaluationScheduleAcceptedSlotsResponse';
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'accepted_slots',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: EvaluationSchedule },
    },
  ]);

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsResponse {
    return new ListEvaluationScheduleAcceptedSlotsResponse().fromBinary(bytes, options);
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsResponse {
    return new ListEvaluationScheduleAcceptedSlotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListEvaluationScheduleAcceptedSlotsResponse {
    return new ListEvaluationScheduleAcceptedSlotsResponse().fromJsonString(jsonString, options);
  }

  static equals(
    a:
      | ListEvaluationScheduleAcceptedSlotsResponse
      | PlainMessage<ListEvaluationScheduleAcceptedSlotsResponse>
      | undefined,
    b:
      | ListEvaluationScheduleAcceptedSlotsResponse
      | PlainMessage<ListEvaluationScheduleAcceptedSlotsResponse>
      | undefined
  ): boolean {
    return proto3.util.equals(ListEvaluationScheduleAcceptedSlotsResponse, a, b);
  }
}
