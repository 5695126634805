import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import AllIcon from '@assets/images/icons/all.svg';
import ContractIcon from '@assets/images/icons/contract.svg';
import InterviewIcon from '@assets/images/icons/interview.svg';
import React from 'react';
import { useMarketplace } from '@store/marketplace';

const displayListTypes = [
  { name: 'all', img: AllIcon },
  { name: 'contracts', img: ContractIcon },
  { name: 'interviews', img: InterviewIcon },
];

const activeStateStyles = {
  transform: 'scale(1.5)',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
};

const FilterFeedItems = () => {
  const { setDisplayListType, displayListType } = useMarketplace();

  return (
    <Box display="flex" flexDirection="column" gap={2} py={1}>
      {displayListTypes.map((type) => {
        return (
          <Tooltip key={type.name} title="Show all demands" placement="left">
            <Box sx={{ cursor: 'pointer' }} onClick={() => setDisplayListType(type.name)}>
              <img
                style={displayListType === type.name ? activeStateStyles : undefined}
                width="24px"
                src={type.img}
                alt=""
              />
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default FilterFeedItems;
